
import * as React from 'react';
import { observer } from 'mobx-react';
import DateTimePeriod from '@models/builder/common/DateTimePeriod';
import Item from './Item';
import * as styles from './period.module.sass';
import Icon from '@components/common/Icon';

export interface IDateTimePeriodProps {
  period: DateTimePeriod;
  deletePeriod?: (period: DateTimePeriod) => void;
}

interface State {}

@observer
export default class DateTimePeriodComponent extends React.Component<IDateTimePeriodProps, State> {
  constructor(props: IDateTimePeriodProps) {
    super(props);
  }

  public render() {


    return (
      <div className={styles.container}>
        {!!this.props.deletePeriod ? 
          <div onClick={() => this.props.deletePeriod!(this.props.period)} className={styles.deleteContainer}>
            <div className={styles.lineDelete}></div>
            <Icon iconStyle={{ width: '10px' }} className={styles.deleteIcon} type='windowClose' />
            <p className={styles.deleteText}>Delete</p>
          </div>
        : null}
      
        <Item
          changeDate={this.props.period.changeStartDate}
          changeTime={this.props.period.changeStartTime}
          time={this.props.period.startTime}
          date={this.props.period.startDate}
          label='Event Starts'
          color='blue'
        />
        <Item
          changeDate={this.props.period.changeEndDate}
          changeTime={this.props.period.changeEndTime}
          time={this.props.period.endTime}
          date={this.props.period.endDate}
          label='Event Ends'
          color='orange'
          timePlaceholder='17:45'
        />
      </div>
    );
  }
}

