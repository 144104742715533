import * as shortid from 'shortid';
import { observable, action, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, getDefaultModelSchema, Context, SKIP, custom } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';
import emitter from '@util/emitter';
import { trackSuggestAutocomplete } from '@app/util/analytics';


// model interfaces

export interface IMetricsConfig extends IBaseConfiguration {
  suggestions: [
    {
      label: string;
      values: string[]
    }
  ]
}

export interface IMetricsInstance extends IBaseInstanceElement  {
  name: string;
  value: string;
}

export interface IMetrics extends IElement<IMetricsConfig, IMetricsInstance> {}

// model classes

export class MetricsInstance implements IMetricsInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  suggestOpen = false;

  @observable
  activeFilter = false;

  @observable
  @serializable
  name = ''

  @observable
  @serializable
  value = '';

  parent: Metrics
  constructor(parent: Metrics) {
    this.parent = parent;
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.name;
  }

  @computed get suggestionValues() {
    if (!this.parent || !this.parent.configuration || !this.parent.configuration.suggestions) {
      return [];
    }

    let suggestions = this.parent.configuration.suggestions;

    if (this.activeFilter) {
      let filter = suggestions.filter((item) => item.label.toLowerCase().includes(this.name.toLowerCase()))
      return filter
    }

    return suggestions;
  }

  @action.bound
  changeValue(value: string) {
    this.value = value;
  }

  @action.bound
  onChange(value: string) {
    this.name = value;
    this.activeFilter = true;
  }

  @action.bound
  onSelect(value: string) {
    let suggestions = this.parent.configuration.suggestions.find(item => item.label === value)!.values.concat();
    if (!suggestions) {
      return;
    }

    if (suggestions.length > 0) {
      this.onChange(suggestions.shift()!);
    }

    let instances = this.parent.instances.filter(item => item !== this);

    for (let suggestion of suggestions) {
      if (this.parent.canAddInstance) {
        let instance = this.parent.addInstance()
        if (instance) {
          instance.onChange(suggestion);
        }
      }
    }

    trackSuggestAutocomplete(this.parent.name, suggestions, this.parent.proposal.id)

    this.toggleSuggest(false)
  }

  @action.bound
  toggleSuggest(toggle: boolean) {
    this.suggestOpen = toggle;
    if (!toggle) {
      this.activeFilter = false;
    }
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(MetricsInstance).factory = (context: Context) => {
      return new MetricsInstance(context.args.parent);
    };
  }
}

export default class Metrics extends BaseElement<IMetrics, IMetricsConfig, MetricsInstance> {

  @observable
  @serializable(elementInstanceSerializer(MetricsInstance))
  readonly instances = observable<MetricsInstance>([]);

  @computed get overLimit() {
    if (this.root.user.premium) {
      return false;
    }

    if (this.instances.length >= this.limitation.maxKeyNumbers) {
      return true;
    } else {
      return false;
    }
  }


  static getInstanceClass() {
    return MetricsInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new MetricsInstance(this))
    }
  }

  @action.bound
  addInstance() {
    if (this.overLimit) {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'instance'
      })

      return;
    }

    let instance = new MetricsInstance(this)
    this.instances.push(instance);
    return instance;
  }

  @action.bound
  deleteInstance(instance: MetricsInstance) {
    this.instances.remove(instance);
  }


  static schema() {
    return getDefaultModelSchema(Metrics).factory = (context: Context) => {
      return new Metrics(context.args.root, context.args.proposal);
    };
  }
}

MetricsInstance.schema();
Metrics.schema();