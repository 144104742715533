import * as shortid from 'shortid';
import { observable, action, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, custom, SKIP } from 'serializr';
import File, { IFile } from '@util/elements/FileUpload';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';
import addInstance from '@app/components/builder/common/addInstance';

// model interfaces

export interface IAppendixConfig extends IBaseConfiguration {
  type: 'test'
}

export interface IAppendixInstance extends IBaseInstanceElement  {
  id: string;
  file: IFile;
  description: string;
}

export interface IAppendix extends IElement<IAppendixConfig, IAppendixInstance> {}

// model classes

export class AppendixInstance implements IAppendixInstance {
  @observable
  id = shortid.generate()

  @observable
  @serializable(object(File))
  file = new File();

  @observable
  @serializable
  description = '';

  @action.bound
  updateDescription(val: string) {
    this.description = val;
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.file.fileURL || !!this.description
  }

  parent: Appendix
  constructor(parent: Appendix) {
    this.parent = parent;
  }

  @action.bound
  removeAppendix() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(AppendixInstance).factory = (context: Context) => {
      return new AppendixInstance(context.args.parent);
    };
  }
}

export default class Appendix extends BaseElement<IAppendix, IAppendixConfig, AppendixInstance> {

  @observable
  @serializable(elementInstanceSerializer(AppendixInstance))
  readonly instances = observable<AppendixInstance>([]);

  static getInstanceClass() {
    return AppendixInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);
    
    if (!this.hasInstance) {
      this.addInstance();
    }
  }

  @action.bound
  addInstance() {
    this.instances.push(new AppendixInstance(this));
  }

  @action.bound
  deleteInstance(instance: AppendixInstance) {
    this.instances.remove(instance);
  }

  static schema() {
    return getDefaultModelSchema(Appendix).factory = (context: Context) => {
      return new Appendix(context.args.root, context.args.proposal);
    };
  }
}

AppendixInstance.schema();
Appendix.schema();