import { observable, action, flow, computed, reaction, toJS } from "mobx";
import { deserialize, update, serialize } from 'serializr';
import Proposal, { IProposal, ProposalSimplePreview } from "@models/builder/proposal";
import RootStore from "@stores/RootStore";
import FormStore from './formStore';
import testProposalObject from '@util/testProposal';
import { toast } from "react-toastify";
import { trackProposalCreate } from "@app/util/analytics";
import emitter from '@util/emitter';

export interface IExample {
  id: string;
  name: string;
  form_process_id: string;
  category_id: string;
  published_website_url: string;
  image_url: string;
}

export interface IFolder {
  name: string;
  type: 'folder';
  id: string;
  folder_id: string | null;
  children: Array<IFolder | ProposalSimplePreview>
}

export default class ProposalStore {

  @observable
  readonly sidebarOrder = observable<IFolder | ProposalSimplePreview>([]);

  readonly proposals = observable<Proposal>([])

  @observable
  readonly examples = observable<IExample>([]);

  @observable
  activeExampleId: string | undefined;

  @observable
  creatingProposal = false;

  @observable
  loadingProposals = false;

  readonly proposalCategories = observable<string>([]);

  @observable
  editingProposal: Proposal | undefined;

  @computed get activeExample() {
    return this.examples.find(item => item.id === this.activeExampleId);
  }

  @computed get folders() {
    return this.sidebarOrder.filter(item => item.type === 'folder') as IFolder[];
  }

  @computed get proposalList() {
    return this.sidebarOrder.map(item => {
      if (item.type === 'folder') {
        let folder = item as IFolder;
        return folder.children.concat()
      }
    
      return item;
    }).flat().filter(item => item.type === 'proposal') as ProposalSimplePreview[];
  }

  parent: RootStore;
  formStore: FormStore;
  constructor(parent: RootStore) {
    this.parent = parent;
  }

  @action.bound
  //** set proposal to editing, If exits in lists then pulls from proposal list and if not then it fetches proposal from server. Otherwise refresh existing one. */
  setEditingProposal = flow(function* (this: ProposalStore, search: Proposal | string) {
    if (search instanceof Proposal) {
      this.editingProposal = search;
      return;
    }

    let proposal = this.getProposal(search);

    if (!proposal) {
      proposal = yield this.pullProposal(search);
    } else {
      this.editingProposal = proposal;
      yield proposal.refresh();
    }

    this.editingProposal = proposal;
  })

  @action.bound
  stopEditingProposal() {
    this.editingProposal = undefined;
  }

  @action.bound
  getProposal(id: string) {
    return this.proposals.find((item) => item.id === id);
  }

  @action.bound
  pullProposal = flow(function* (this: ProposalStore, uuid: string) {
    let proposal = this.getProposal(uuid);

    if (proposal) {
      proposal.refresh();
    } else {
      let processObject = yield this.parent.api.getProcess(uuid);
      proposal = this.createProposalFromObject(processObject);
      this.proposals.push(proposal);
    }
  
    return Promise.resolve(proposal);
  })

  @action.bound
  pullExamples = flow(function* (this: ProposalStore) {
    try {
      let examples = yield this.parent.api.fetchExamples();
      this.examples.replace(examples);
    } catch (error) {
      console.error(error)
    }

  })

  @action.bound
  createProposalFromObject(processObject: any) {
    let proposal = deserialize(Proposal, processObject, (err, res) => {
      res.setEditingPage();
    }, {
      root: this.parent
    });

    return proposal;
  }

  @action.bound
  createTestElementProposal() {
    let proposal = this.createProposalFromObject(testProposalObject);
    proposal.readonly = true;
    this.editingProposal = proposal;
  }

  @action.bound
  deleteProposal = flow(function* (this: ProposalStore, id: string) {
    let toastId = toast.info('Deleting...', { autoClose: false });

    try {
      yield this.parent.api.deleteProposal(id);

      let proposal = this.proposals.find(item => item.id === id);

      if (proposal) {
        this.proposals.remove(proposal);
      }

      this.pullFolders()
      if (this.parent.router.location.pathname.includes(id)) {
        this.parent.router.replace('/proposals');
      }
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: 'Proposal deleted', autoClose: 3000 });
    } catch (error) {
      toast.update(toastId, { type: toast.TYPE.ERROR, render: 'Error. Could not delete.', autoClose: 3000 });
    }
  })

  @action.bound
  duplicateProposal = flow(function* (this: ProposalStore, id: string) {
    if (this.proposalList.length >= this.parent.user.limitation.maxProposals) {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'proposal'
      })

      return;
    }

    let toastId = toast.info('Duplicating...', { autoClose: false });

    let proposal = this.proposals.find(item => item.id === id);

    if (!proposal) {
      return;
    }

    try {
      let newProposalJSON = yield this.parent.api.duplicateProcess(id);
      let newProposal = this.createProposalFromObject(newProposalJSON);
      this.proposals.unshift(newProposal);
      this.pullFolders();
      this.parent.router.push(`/proposals/proposal/${newProposal.id}`)
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: 'Proposal duplicated', autoClose: 3000 });
      trackProposalCreate(proposal.name, proposal.id);
    } catch (error) {
      console.error(error);
      toast.update(toastId, { type: toast.TYPE.ERROR, render: 'Error. Could not duplicate.', autoClose: 3000 });
    }
  })

  @action.bound
  toggleExample(id: string | undefined) {
    if (id) {
      this.activeExampleId = id;
    } else {
      this.activeExampleId = undefined;
    }
  }

  @action.bound
  pullFolders = flow(function* (this: ProposalStore) {
    try {
      let res = yield this.parent.api.getFolders();
      let list = []

      for (let item of res.data) {
        let object = item as IFolder | ProposalSimplePreview;

        if (object.type === 'proposal') {
          let simpleProposal = deserialize(ProposalSimplePreview, object, undefined, {
            root: this.parent
          });
          list.push(simpleProposal);
        } else {
          let folder = object as IFolder
          folder.children = folder.children.map(item => deserialize(ProposalSimplePreview, item, undefined, { root: this.parent }))

          list.push(folder);
        }
      }

      this.sidebarOrder.replace(list);
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  })

  @action.bound
  moveProposalToFolder = flow(function* (this: ProposalStore, proposal_id: string, current_folder_id: string | null, new_folder_id: string | null) {
    let proposal: ProposalSimplePreview;
  
    let toastId = toast.info('moving...', { autoClose: false });

    try {
      let test = yield this.parent.api.puchProposalFolderChanges(proposal_id, new_folder_id);
      yield this.pullFolders();
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: 'Moved', autoClose: 3000 });
    } catch (error) {
      toast.update(toastId, { type: toast.TYPE.ERROR, render: 'Error moving proposal', autoClose: 3000 });
      console.error(error);
    }
  })

  @action.bound
  createNewFolder = flow(function*(this: ProposalStore, name:string) {
    try {
      yield this.parent.api.createFolder(name);
      yield this.pullFolders();
    } catch (error) {
      console.error(error);
    }
  })

  @action.bound
  renameFolder = flow(function*(this: ProposalStore, name:string, folderId: string) {
    let folder = this.folders.find(item => item.id === folderId)!;
    folder.name = name;

    try {
      yield this.parent.api.updateFolderName(folder.id, folder.name);
    } catch (error) {
      console.error(error);
    }
  })

  @action.bound
  deleteFolder = flow(function*(this: ProposalStore, folderId: string) {

    let toastId = toast.info('deleting...', { autoClose: false });
  
    try {
      yield this.parent.api.deleteFolder(folderId);
      yield this.pullFolders();
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: 'Folder deleted', autoClose: 3000 });
    } catch (error) {
      toast.update(toastId, { type: toast.TYPE.ERROR, render: 'Could not delete folder', autoClose: 3000 });
      console.error(error)
    }
  })
}