
import * as React from 'react';
import { observer } from 'mobx-react';
import * as styles from './resize.module.sass';

export interface IResizeProps {
  resizeColumn: (clientX: number, type: string) => void;
  type: 'form-template' | 'template-customization';
  dragging: boolean;
  toggleDrag: (dragging: boolean, type: string) => void;
}

interface State {}

@observer
export default class Resize extends React.Component<IResizeProps, State> {
  ref: HTMLDivElement | null;
  needForRAF: boolean;

  constructor(props: IResizeProps) {
    super(props);

    this.startDrag = this.startDrag.bind(this);
    this.mouseMove = this.mouseMove.bind(this);

    document.addEventListener('mousemove', this.mouseMove.bind(this))
    document.addEventListener('mouseup', this.stopDrag.bind(this))

    this.needForRAF = true;
  }

  startDrag() {
    this.props.toggleDrag(true, this.props.type)
  }

  stopDrag() {
    if (this.props.dragging) {
      this.props.toggleDrag(false, this.props.type)
    }
  }

  mouseMove(e: MouseEvent) {
    let x = e.clientX;
    
    if (this.props.dragging && this.needForRAF) {
      this.needForRAF = false;
      window.requestAnimationFrame(this.update.bind(this, x))
    }
  }

  update(x: number) {
    this.props.resizeColumn(x, this.props.type)
    this.needForRAF = true
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.mouseMove)
    document.removeEventListener('mouseup', this.stopDrag)
  }
  

  public render() {
    return (
      <div onMouseDown={this.startDrag} ref={(ref) => this.ref = ref} className={styles.resizer}>
        <div className={styles.dragContainer}>
          <div className={styles.dragItem}></div>
          <div className={styles.dragItem}></div>
          <div className={styles.dragItem}></div>
        </div>
      </div>
    );
  }
}
