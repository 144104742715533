
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/multiSelect';
import * as styles from './multiselector.module.sass';
import { DropdownCreatableMulti } from '@components/common/Dropdown';

export interface IInstanceProps {
  instance: Model.MultiSelectInstance,
  configuration: Model.IMultiSelectConfig
}

interface State { }

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {
    return (
      <div>
        <DropdownCreatableMulti
          onChange={this.props.instance.updateOption}
          options={this.props.configuration.suggestedOptions}
          value={this.props.instance.options}
          placeholder='Write or select multiple...'
        />
      </div>
    );
  }
}
