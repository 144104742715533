
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import RootStore from '@app/stores/RootStore';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import Icon from '@app/components/common/Icon';
import * as styles from './proposals.module.sass';
import Typewriter from '@assets/dashboard/typewriter.png'
import { rootContext } from '@app/index';
import Example from '../common/Example';
import { withRouterApp } from '@app/util/router';
import QS from 'query-string';

interface IDashboardProps {}

const Dashboard: React.FunctionComponent<IDashboardProps & RouteComponentProps<{force?: string}>> = (props) => {
  const rootStore = React.useContext(rootContext);

  let examples = rootStore.proposalStore.examples.map(item => <Example key={item.id} example={item} />)

  React.useEffect(() => {
    let force = QS.parse(props.location.search).force;
    if (rootStore.proposalStore.proposalList.length > 0 && !Boolean(force)) {
      rootStore.router.push(`/proposals/proposal/${rootStore.proposalStore.proposalList[0].id}`)
    }
  }, [rootStore.proposalStore.proposalList.length])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.label}>Bienvenue! We are glad to see you here.</p>
        <div className={styles.proposalNew}>
          <h2>Let's create your first proposal</h2>
          <Link to='/proposals/new/proposal' className={styles.button}>
            <p>Create new proposal</p>
            <Icon type='angle-solid' inheritStyle />
          </Link>
          <img className={styles.image} width='224' height='178' src={Typewriter}></img>
        </div>
        {examples.length > 0 &&
          <div className={styles.example}>
            <p className={styles.exampleLabel}>Or jump in with a read to go template.</p>
            <div className={styles.examples}>
              {examples}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default withRouterApp(observer(Dashboard));