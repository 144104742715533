
import * as React from 'react';
import { observer } from 'mobx-react';
import Model from '@models/builder/elements/detailedDropdown';
import Instance from './Instance';
import AddInstance from '@components/builder/common/AddInstance';
import ElementHeadline from '@components/builder/common/ElementHeadline';

export interface IDetailedDropdownProps {
  model: Model
}

interface State { }

@observer
export default class DetailedDropdown extends React.Component<IDetailedDropdownProps, State> {
  public render() {
    let configuration = this.props.model.configuration;

    let content = this.props.model.instances.map((item) => {
      return <Instance key={item.id} configuration={this.props.model.configuration} instance={item} />
    })

    if (this.props.model.canAddInstance) {
      content.push(<AddInstance key='add-new' addInstance={this.props.model.addInstance} text='+ Add new dropdown' />)
    }

    return (
      <div>
        <ElementHeadline complete={this.props.model.complete} text={this.props.model.name} markdown={configuration.tooltip} />
        <div>
          {content}
        </div>
      </div>
    );
  }
}
