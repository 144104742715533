import * as shortid from 'shortid';
import { observable, action, computed, when } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, getDefaultModelSchema, Context, custom, SKIP } from 'serializr';
import { customFieldSerializer, socialPickerSerializer, elementInstanceSerializer } from '@util/serializer';
import Field from '@util/elements/Field';
import File, { IFile } from '@util/elements/FileUpload';
import SocialMediaPicker, { ISocialMediaHandle } from '@models/builder/common/SocialMediaPicker';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { Omit } from '@util/types';

// releated interfacess

// model interfaces
export interface ITeamConfig extends IBaseConfiguration {
  social_networks: {
    name: string;
    example_link: string;
  }[]
}

export interface ITeamInstance extends IBaseInstanceElement  {
  description: string | Field<string>;
  name: string | Field<string>;
  company: string | Field<string>;
  position: string | Field<string>;
  phone: string;
  email: string;
  socialMediaLinks: ISocialMediaHandle[];
  file: IFile;
}

interface ITeamInstanceApp extends Omit<ITeamInstance, 'socialMediaLinks' | 'website'> {
  socialMediaLinks: SocialMediaPicker;
  phone: Field<string>;
  email: Field<string>;
}

export interface ITeam extends IElement<ITeamConfig, ITeamInstance> {}

// model classes

export class TeamInstance implements ITeamInstanceApp {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable(customFieldSerializer<string>())
  description = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  name = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  phone = new Field('')

  @observable
  @serializable(customFieldSerializer<string>())
  email = new Field('')

  @observable
  @serializable(customFieldSerializer<string>())
  company = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  position = new Field('');

  @observable
  @serializable(object(File))
  file = new File();

  @serializable(socialPickerSerializer())
  socialMediaLinks: SocialMediaPicker;

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.name.value
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get empty() {
    return !!!this.name.value;
  }

  parent: Team;
  constructor(parent: Team) {
    this.parent = parent;

    when(
      () => !!this.parent.configuration,
      () => this.init()
    )
  }

  init() {
    if (this.parent.configuration.social_networks[0]) {
      this.socialMediaLinks = new SocialMediaPicker(this.parent.configuration.social_networks[0].name);
    }
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(TeamInstance).factory = (context: Context) => {
      return new TeamInstance(context.args.parent);
    };
  }
}

export default class Team extends BaseElement<ITeam, ITeamConfig, TeamInstance> {

  @observable
  @serializable(elementInstanceSerializer(TeamInstance))
  readonly instances = observable<TeamInstance>([]);

  @action.bound
  addInstance() {
    this.instances.push(new TeamInstance(this));
  }

  @action.bound
  deleteInstance(instance: TeamInstance) {
    this.instances.remove(instance);
  }

  static getInstanceClass() {
    return TeamInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new TeamInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(Team).factory = (context: Context) => {
      return new Team(context.args.root, context.args.proposal);
    };
  }
}

TeamInstance.schema()
Team.schema()