import * as React from 'react';
import * as style from './control.module.sass';
import { PackagesInstance } from '@models/builder/elements/packages';
import PackageItem from '@models/builder/elements/packages/packageItem';
import { observer } from 'mobx-react';
import Dropdown from '../Dropdown';
import Icon from '@components/common/Icon';

export interface PackageControlProps {
    item: PackageItem;
    package: PackagesInstance;
}

@observer
export default class PackageControl extends React.Component<PackageControlProps, any> {
  public render() {

    let visibility: 'visible' | 'hidden' = this.props.item.selected ? 'visible' : 'hidden';

    return (
        <div
            onMouseEnter={() => this.props.item.toggleSelect(true)}
            onMouseLeave={() => this.props.item.toggleSelect(false)}
            className={style.packageControl}
        >
            <div className={style.menuContainer} style={{ visibility }}>
                <span onClick={() => this.props.package.duplicatePackageItem(this.props.item)}>
                    <Icon color='primary2' tooltip='Duplicate' type='duplicate' />
                </span>
                <div className={style.line}></div>

                <div className={style.menuIcon}>
                    <span style={{top: '-4px'}} className={style.more} onClick={() => this.props.item.toggleDropdown(true)}>
                        <Icon color='primary2' type='more' />
                        <div className={style.clickZone}></div>
                    </span>
                    <Dropdown package={this.props.package} item={this.props.item} />
                </div>
            </div>
        </div>
    );
  }
}
