
import * as React from 'react';
import { observer } from 'mobx-react';
import * as styles from './element.module.sass';
import { ElementTypes } from '@models/builder/elements';
import elements from './elements';

export interface IElementProps {
  model: ElementTypes
}

interface State {}

@observer
export default class Element extends React.Component<IElementProps, State> {
  public render() {

    let Component: any = elements[this.props.model.type];

    return (
      <div className={styles.element}>
        <Component key={this.props.model.id} model={this.props.model} />
      </div>
    );
  }
}
