import * as React from 'react'
import { observer } from 'mobx-react';
import RadioOff from '@assets/helpers/radio-off.svg';
import RadioOn from '@assets/helpers/radio-on.svg';
import * as styles from './radio.module.sass';
import Icon, { IconTypes } from '@app/components/common/Icon';

interface IRadioInputProps {
  value: string;
  name: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const RadioInput: React.FunctionComponent<IRadioInputProps> = (props) => {

  let icon: string | undefined;

  switch (props.value) {
    case 'Local':
      icon = LOCAL
      break;
    case 'Regional':
      icon = REGIONAL
      break;
    case 'National':
      icon = NATIONAL
      break;
    case 'Global':
      icon = GLOBAL
      break;
  }

  return (
    <div>
      <label className={styles.radioLabel}>
        <div className={styles.icon}>
          {props.checked ? <RadioOn /> : <RadioOff />}
        </div>
        {icon &&
          <div dangerouslySetInnerHTML={{ __html: icon }} style={{ width: '15px', height: '15px', marginRight: '6px' }}></div>
        }
        <p className={styles.text}>{props.value}</p>
        <input
          style={{display: 'none'}}
          onChange={(e) => props.onChange(e.target.value)}
          type='radio'
          name={props.name}
          value={props.value}
          checked={props.checked}
        />
      </label>
    </div>
  );
}

export default observer(RadioInput);


const GLOBAL = `
<svg width="15px" height="15px" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="24.002px" height="23.998px" viewBox="-0.009 -17.994 24.002 23.998" enable-background="new -0.009 -17.994 24.002 23.998"
	 xml:space="preserve">
<path d="M11.915-17.994c-1.763,0.012-3.428,0.412-4.928,1.104c0.106,0.011,0.181,0.019,0.181,0.019
	s2.158,0.19,3.184-0.228c1.023-0.416,1.852,0.706,1.442,0.711c-0.412,0.001-0.916,1.238-0.503,1.238
	c0.409-0.005,0.513,0.61,1.125-0.113c0.613-0.723,0.513-0.412,1.648,0.3c1.133,0.709-0.717,0.72-0.717,0.72s-0.402,0.826-0.917,0.83
	c-0.512,0.002-1.737,1.345-2.038,2.272c-0.302,0.926-0.403,0.928-0.821,0.212c-0.414-0.717-1.03-0.2-1.738,0.729
	C7.118-9.271,9.176-8.36,9.176-8.36l1.034,0.916c0,0,0.821-0.208,1.231-0.417c0.405-0.208,0.31,0.409,1.753,1.016
	c1.439,0.608,0.21,0.717,1.546,1.12c1.336,0.4,1.654,1.428,1.654,1.428l-0.406,0.822L15.585-2.24c0,0-0.612,0.208-0.915,1.238
	c-0.302,1.026-1.018,1.237-1.53,1.755c-0.512,0.515,0.113,1.642,0.426,2.255c0.313,0.618-0.615-0.098-1.027-0.198
	c-0.414-0.101-0.627-1.332-0.837-1.944c-0.207-0.613,0.092-2.054,0.085-2.773c-0.003-0.718-0.417-0.921-0.933-1.536
	c-0.517-0.612-0.52-0.713-0.833-1.842c-0.317-1.127-0.116-1.437,0.19-1.644c0.307-0.206-0.823-0.609-1.339-0.814
	c-0.513-0.202-1.031-0.61-1.75-1.114C6.4-9.366,6.189-10.186,5.875-10.595c-0.308-0.409-0.425-2.258-0.43-2.875
	c-0.003-0.613,0.708-1.338,0.398-1.853c-0.14-0.224-0.561-0.265-1.045-0.258c-2.938,2.208-4.833,5.714-4.808,9.667
	c0.014,2.288,0.681,4.412,1.805,6.226c0.415,0.143,1.367,0.453,1.774,0.453c0.525,0,1.978-0.129,2.371,0.924
	c0.395,1.053,1.056,1.185,0.131,2.24C5.96,4.059,5.86,4.166,5.772,4.254c1.839,1.119,3.991,1.764,6.302,1.749
	c6.624-0.042,11.962-5.452,11.917-12.081C23.948-12.703,18.541-18.036,11.915-17.994z"/>
</svg>
`

const REGIONAL = `
<svg width="15px" height="15px" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="21px" height="23.995px" viewBox="0.5 -17.988 21 23.995" enable-background="new 0.5 -17.988 21 23.995"
	 xml:space="preserve">
<path d="M10.535-17.966c0.322-0.03,0.646-0.019,0.968-0.022c0.021,0.124-0.135,0.208-0.079,0.333
	c-0.041,0.035-0.086,0.065-0.135,0.086c-0.082,0.032-0.133,0.104-0.201,0.153c-0.011,0.024-0.019,0.048-0.027,0.073
	c-0.043,0.005-0.097-0.005-0.132,0.029c-0.041,0.062-0.07,0.132-0.13,0.18c0,0.035,0,0.07-0.002,0.103
	c-0.039,0.005-0.076,0.005-0.114,0.013c-0.036,0.046-0.082,0.083-0.111,0.132c-0.021,0.056-0.013,0.115-0.011,0.174
	c-0.048,0.043-0.095,0.092-0.138,0.143c-0.019,0.105,0.005,0.243,0.116,0.286c0.103,0.045,0.157,0.156,0.265,0.19
	c0.07,0.019,0.1,0.089,0.119,0.151c0.101,0.011,0.198,0.027,0.295,0.027c0.041,0.167,0.222,0.237,0.298,0.385
	c0.029,0.005,0.062,0.013,0.092,0.019c-0.002,0.038-0.008,0.076-0.011,0.113c0.046,0.016,0.089,0.038,0.135,0.054
	c0.049,0.086,0.146,0.105,0.23,0.137c0.062-0.051,0.143-0.041,0.219-0.042c0.095-0.019,0.217-0.054,0.295,0.021
	c0.187,0.146,0.154,0.407,0.235,0.605c0.052,0.046,0.108,0.089,0.167,0.13c0.006,0.08,0.03,0.158,0.062,0.231
	c-0.002,0.011-0.008,0.033-0.011,0.043c0.06,0.011,0.114,0.041,0.16,0.078c0.021,0.226,0.035,0.463,0.143,0.667
	c0.068,0.021,0.144,0.048,0.182,0.115c0.035,0.06,0.078,0.118,0.138,0.159c0.003,0.037-0.017,0.083,0.014,0.113
	c0.065,0.081,0.141,0.156,0.192,0.248c0.022,0.11,0.079,0.21,0.114,0.314c0.019,0.041,0.032,0.095,0.079,0.11
	c0.06,0.006,0.141-0.021,0.173,0.046c0.07,0.132,0.189,0.223,0.306,0.315c0.057,0.048,0.141,0.024,0.2,0.07
	c0.151,0.124,0.352,0.202,0.454,0.379c0.041,0.079,0.119,0.127,0.195,0.161c0.043,0.095,0.057,0.194,0.078,0.291
	c0.035,0.078,0.108,0.132,0.146,0.21c0.01,0.057,0.014,0.113,0.021,0.169c0.037,0.064,0.116,0.089,0.184,0.11
	c0.027,0.083,0.119,0.107,0.187,0.054c0.073,0.088,0.192,0.115,0.284,0.18c0.055,0.054,0.104,0.113,0.154,0.167
	c0.064,0.067,0.086,0.161,0.141,0.234c0.068,0.033,0.141,0.057,0.208,0.092c0.041,0.041,0.071,0.091,0.089,0.147
	c-0.01,0.054-0.053,0.113-0.023,0.169c-0.016,0.008-0.052,0.03-0.068,0.041c0.033,0.045,0.014,0.11,0.049,0.156
	c-0.021,0.002-0.062,0.005-0.084,0.005c0.02,0.038,0.03,0.078,0.038,0.118c0.081,0.052,0.173,0.105,0.22,0.194
	c0.057-0.017,0.133-0.038,0.168,0.029c-0.021,0.029-0.084,0.054-0.057,0.1c0.029,0.013,0.059,0.021,0.092,0.029
	c-0.009,0.054-0.021,0.107-0.061,0.15c0.07,0.079,0.166,0.129,0.232,0.213c0.058,0.021,0.088-0.057,0.145-0.049
	c0.008,0.043,0.021,0.086,0.04,0.129c-0.019,0.03-0.062,0.059-0.049,0.1c0.017,0.103,0.11,0.159,0.181,0.223
	c0.079,0.078,0.195,0.089,0.287,0.143c0.068,0.038,0.141,0.057,0.217,0.057c0.086,0.113,0.197,0.205,0.322,0.272
	c0.08,0.051,0.178,0.091,0.221,0.183c0.006,0.089-0.031,0.175-0.043,0.264c0.03,0.108,0.104,0.197,0.152,0.299
	c-0.004,0.032-0.025,0.062-0.044,0.086c0.03,0.014,0.06,0.011,0.089,0.005c0.012,0.1,0.087,0.164,0.149,0.231
	c0.046,0.083-0.014,0.172-0.017,0.258c0.014,0.067,0.043,0.129,0.06,0.199c-0.005,0.07-0.034,0.14-0.04,0.212
	c0.027,0.029,0.054,0.057,0.078,0.086c0.02,0.049,0.002,0.102-0.011,0.148c0.013,0.008,0.04,0.026,0.057,0.035
	c-0.038,0.041-0.062,0.089-0.089,0.137c0.04,0.116,0.162,0.169,0.243,0.258c0.075,0.07,0.185,0.073,0.284,0.07
	c0.064-0.005,0.064,0.075,0.092,0.119c0.02,0.07,0.095,0.097,0.154,0.121c0.006,0.027,0.014,0.054,0.021,0.081
	c0.062,0.064,0.111,0.148,0.195,0.188c-0.052,0.067-0.114,0.178-0.041,0.25c0.024,0.029,0.051,0.054,0.081,0.078
	c0.011,0.072,0.022,0.15,0.062,0.212c0.039,0.032,0.087,0.043,0.133,0.057c0.003,0.062-0.014,0.132,0.03,0.18
	c0.03,0.038,0.046,0.083,0.06,0.126c-0.078,0.064-0.151,0.148-0.176,0.248c0.016,0.118,0.199,0.161,0.168,0.293
	c-0.025,0.116,0.021,0.229,0.016,0.345c0.107,0.016,0.168,0.118,0.268,0.147c0.084,0.027,0.143-0.043,0.215-0.073
	c0.107,0.105,0.242,0.172,0.338,0.294c0.118,0.04,0.243,0.075,0.367,0.099c0.014,0.083,0.011,0.175-0.054,0.239
	c-0.084,0.083-0.177,0.205-0.312,0.178c-0.011-0.019-0.029-0.054-0.041-0.073c-0.043,0.019-0.094,0.027-0.127,0.064
	c-0.037,0.035-0.021,0.095-0.032,0.14c0.058,0.011,0.108,0.035,0.165,0.038c-0.068,0.11-0.182,0.183-0.254,0.288
	c-0.079-0.07-0.182-0.11-0.29-0.105c0.041,0.092-0.021,0.162-0.06,0.239c0.057,0.019,0.158-0.011,0.174,0.062
	c-0.043,0.068-0.092,0.132-0.13,0.202c-0.054,0.116-0.212,0.143-0.252,0.272c-0.113,0.013-0.22-0.035-0.333-0.041
	c0,0.048-0.013,0.116,0.044,0.143c0.073,0.062,0.165,0.024,0.246,0.016c-0.027,0.143,0.035,0.291-0.021,0.427
	c-0.049,0.105-0.065,0.232-0.159,0.31c-0.03-0.038-0.096-0.078-0.139-0.038c-0.064,0.054-0.154,0.057-0.238,0.051
	c0.006,0.038,0.006,0.073-0.002,0.11c0.1,0.017,0.208,0.027,0.295-0.035c0.023,0.03,0.051,0.057,0.07,0.089
	c-0.064,0.135-0.133,0.266-0.22,0.391c-0.07,0.086-0.119,0.186-0.17,0.284c-0.052,0.081-0.044,0.184-0.052,0.275
	c-0.008,0.056,0.064,0.062,0.103,0.083c-0.011,0.075-0.081,0.126-0.081,0.204c-0.002,0.126-0.051,0.245-0.067,0.369
	c-0.167-0.008-0.271,0.161-0.312,0.304c-0.026,0.067-0.1,0.075-0.162,0.083c-0.021,0.118,0.016,0.239-0.02,0.355
	c-0.016,0.076-0.035,0.148-0.051,0.224c-0.049,0.005-0.098,0.002-0.146,0.002c-0.043,0.086-0.164,0.121-0.181,0.215
	c0.089,0.019,0.179,0.038,0.255,0.089c0.016-0.051,0.075-0.075,0.086-0.126c0.07,0.07,0.046,0.175,0.049,0.261
	c0,0.137-0.062,0.258-0.09,0.388c-0.019,0.118-0.124,0.199-0.138,0.32c-0.046,0.135-0.011,0.275,0.044,0.401
	c-0.074,0.003-0.133-0.035-0.188-0.078c-0.094-0.002-0.182,0.032-0.273,0.043c-0.094-0.009-0.184-0.062-0.281-0.038
	c-0.043-0.032-0.094-0.038-0.146-0.029c-0.017-0.035-0.04-0.07-0.087-0.067c-0.089-0.013-0.182,0.003-0.268-0.021
	c-0.072-0.057-0.133-0.15-0.235-0.121c-0.035-0.043-0.07-0.11-0.136-0.092c-0.137,0.027-0.299-0.008-0.361-0.147
	c-0.101,0.04-0.189-0.04-0.29-0.019c-0.005,0.086-0.013,0.18-0.087,0.239c-0.032-0.059-0.103-0.073-0.162-0.086
	c0.016,0.064,0.016,0.132,0.012,0.199c-0.033,0.006-0.066,0.014-0.098,0.022c-0.008,0.054-0.012,0.11-0.003,0.164
	c0.017,0.094-0.07,0.172-0.048,0.267c0.039,0.131,0.113,0.253,0.17,0.376c0.043,0.119-0.03,0.234-0.02,0.353
	c0.033,0.223-0.121,0.422-0.094,0.646c0.021,0.07-0.027,0.129-0.088,0.162c-0.1,0.032-0.191-0.043-0.289-0.041
	c-0.092-0.005-0.178-0.027-0.268-0.043c-0.067-0.027-0.081-0.105-0.105-0.164c-0.062-0.137,0.046-0.286,0-0.425
	c-0.02-0.041-0.057-0.062-0.092-0.081c-0.038-0.103-0.082-0.223,0.006-0.315c-0.201-0.002-0.4-0.016-0.599-0.038
	c-0.522-0.021-1.044-0.083-1.569-0.097c-0.162-0.016-0.324-0.035-0.489-0.045c-0.441-0.016-0.882-0.054-1.326-0.084
	c-0.149,0.003-0.294-0.021-0.443-0.032c-0.493-0.014-0.982-0.07-1.475-0.092C9.379,4.524,9.198,4.54,9.02,4.513
	C8.235,4.465,7.451,4.427,6.667,4.379C6.288,4.374,5.909,4.33,5.53,4.336C5.236,4.312,4.938,4.292,4.643,4.285
	c-0.259-0.03-0.519-0.006-0.779-0.046C3.81,4.153,3.745,4.066,3.653,4.021C3.559,3.862,3.55,3.671,3.545,3.491
	c0.003-0.156-0.127-0.256-0.197-0.382c-0.03-0.051,0.022-0.14-0.046-0.161C3.283,2.942,3.25,2.932,3.234,2.926
	c0.022-0.159,0.003-0.322-0.073-0.462c-0.038-0.064-0.035-0.14-0.065-0.208C3.05,2.197,2.988,2.146,2.963,2.07
	C2.947,2.006,2.874,1.992,2.831,1.952c-0.017-0.035-0.024-0.07-0.036-0.105C2.769,1.764,2.855,1.68,2.809,1.603
	C2.769,1.527,2.742,1.443,2.752,1.357C2.78,1.35,2.809,1.342,2.839,1.333C2.85,1.279,2.863,1.228,2.872,1.175
	C2.877,1.097,2.85,1.021,2.847,0.94c0.049-0.121,0.054-0.252,0.097-0.376c0-0.062-0.026-0.124-0.016-0.185
	C2.98,0.265,3.053,0.158,3.044,0.031C3.031-0.02,3.007-0.066,2.991-0.114c-0.014-0.078,0-0.161-0.022-0.239
	C2.942-0.399,2.896-0.437,2.88-0.488C2.85-0.606,2.731-0.674,2.685-0.784C2.677-0.851,2.723-0.916,2.701-0.98
	C2.679-1.053,2.688-1.133,2.636-1.19c0.019-0.092,0.035-0.186,0.003-0.274C2.601-1.564,2.674-1.661,2.65-1.76
	c0.027-0.005,0.057-0.014,0.086-0.019c0.002-0.081,0.059-0.14,0.108-0.196c0.033-0.038,0.019-0.094,0.019-0.14
	c0.014-0.005,0.043-0.019,0.06-0.024c-0.008-0.092,0.03-0.175,0.062-0.258c0.006-0.078-0.01-0.158,0.003-0.239
	C2.994-2.71,3.023-2.786,2.994-2.855c-0.009-0.057-0.065-0.137,0-0.177c0.07-0.041,0.097-0.119,0.148-0.175
	c0.062-0.049,0.141-0.067,0.213-0.092C3.37-3.337,3.38-3.374,3.407-3.407C3.45-3.439,3.512-3.439,3.553-3.472
	c0.051-0.064,0.081-0.14,0.143-0.19c-0.1-0.07-0.159-0.197-0.284-0.231c-0.07-0.027-0.146-0.046-0.208-0.089
	C3.153-4.044,3.223-4.098,3.256-4.144c0.07-0.086,0.065-0.202,0.067-0.305c0.057-0.029,0.06-0.083,0.035-0.137
	C3.318-4.599,3.275-4.612,3.242-4.639c-0.027-0.099,0-0.209-0.038-0.309C3.142-5.107,3.012-5.223,2.896-5.341
	C2.842-5.438,2.844-5.556,2.782-5.653c0.005-0.035,0.019-0.067,0.03-0.103c-0.1-0.064-0.138-0.183-0.127-0.298
	c0.014-0.062-0.041-0.098-0.068-0.143c-0.005-0.048,0-0.097,0.008-0.145c-0.06-0.048-0.13-0.097-0.152-0.175
	c0.003-0.057,0.011-0.116,0.027-0.172C2.46-6.713,2.409-6.734,2.404-6.786C2.366-7.004,2.328-7.222,2.282-7.437
	C2.23-7.803,2.144-8.161,2.101-8.526C2.046-8.739,2.041-8.959,1.99-9.175C1.922-9.718,1.8-10.256,1.725-10.8
	c-0.054-0.186-0.054-0.382-0.1-0.57c-0.016-0.113-0.043-0.224-0.057-0.336c-0.03-0.231-0.087-0.455-0.105-0.686
	c-0.127-0.544-0.168-1.103-0.287-1.646c-0.021-0.245-0.094-0.479-0.122-0.721c-0.073-0.325-0.122-0.656-0.197-0.982
	C0.743-16.46,0.591-17.17,0.5-17.891c0.341,0.005,0.684,0.005,1.028,0.005c0.244,0.005,0.487-0.019,0.73-0.014
	c0.163,0.006,0.325-0.003,0.484-0.011c0.652,0,1.301-0.003,1.95-0.003c0.108,0,0.214-0.026,0.319-0.016
	c0.254,0.03,0.509,0.008,0.763,0.016c0.208,0.008,0.414-0.032,0.622-0.002c0.919,0.002,1.839,0.005,2.759-0.005
	c0.081-0.009,0.16-0.041,0.244-0.038C9.777-17.951,10.156-17.966,10.535-17.966L10.535-17.966z"/>
</svg>
`

const LOCAL = `
  <svg width="15px" height="15px" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="24px" height="23.999px" viewBox="-0.5 -17.499 24 23.999" enable-background="new -0.5 -17.499 24 23.999"
    xml:space="preserve">
  <g>
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10,6.5h3V4.25h-1.5H10V6.5z M-0.5-7v3h2.25v-1.5V-7H-0.5z
        M13-17.499h-3v2.25h1.5H13V-17.499z M11.5-15.249c-5.384,0-9.749,4.365-9.749,9.75c0,5.384,4.365,9.75,9.749,9.75
        c5.385,0,9.75-4.365,9.75-9.75C21.25-10.884,16.885-15.249,11.5-15.249z M11.5,2C7.358,2,4-1.357,4-5.5S7.358-13,11.5-13
        c4.143,0,7.5,3.358,7.5,7.5S15.643,2,11.5,2z M21.25-7v1.5V-4h2.25v-3H21.25z M11.5-10.749c-2.899,0-5.25,2.35-5.25,5.25
        s2.35,5.25,5.25,5.25s5.25-2.35,5.25-5.25S14.399-10.749,11.5-10.749z"/>
    </g>
  </g>
  </svg>
`

const NATIONAL = `
<svg width="15px" height="15px" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="39.017px" height="24.018px" viewBox="-7.509 -17.494 39.017 24.018" enable-background="new -7.509 -17.494 39.017 24.018"
	 xml:space="preserve">
<g>
	<g>
		<path d="M31.186-14.494c0.013,0.047,0.029,0.095,0.042,0.142c-0.172-0.074-0.188-0.269-0.232-0.426
			c-0.095-0.011-0.188-0.023-0.28-0.037c-0.006-0.04-0.014-0.081-0.021-0.121c-0.031-0.11-0.058-0.221-0.096-0.331
			c-0.004,0-0.02-0.003-0.025-0.003c0.003-0.026,0.008-0.052,0.013-0.079h-0.042c0.006-0.026,0.011-0.052,0.016-0.079h-0.042
			c0.005-0.035,0.013-0.069,0.019-0.103c-0.01-0.002-0.031-0.002-0.045-0.002c0.006-0.026,0.01-0.052,0.016-0.079h-0.043
			l0.018-0.079h-0.043c0.006-0.026,0.01-0.053,0.016-0.079h-0.042c0.005-0.034,0.013-0.068,0.019-0.103
			c-0.011-0.003-0.031-0.003-0.045-0.003c0.006-0.026,0.01-0.053,0.016-0.079h-0.039c0.002-0.026,0.004-0.049,0.008-0.076
			c-0.138-0.063-0.275-0.134-0.418-0.182c-0.002,0.018-0.006,0.037-0.008,0.058c-0.111,0.044-0.201,0.131-0.256,0.233
			c-0.131-0.031-0.16-0.181-0.279-0.226c-0.047,0.124-0.107,0.242-0.131,0.374c-0.006,0.002-0.012,0.005-0.014,0.005
			c-0.035,0.126-0.088,0.242-0.141,0.36c-0.061,0.131,0.027,0.281-0.045,0.411c-0.084,0.163-0.033,0.35,0.037,0.51
			c-0.004,0.124-0.135,0.203-0.161,0.321c-0.019,0.119-0.052,0.232-0.073,0.345c-0.043-0.021-0.086-0.042-0.125-0.062
			c-0.014,0.039-0.021,0.082-0.031,0.121c-0.039-0.023-0.079-0.05-0.121-0.071c-0.029,0.111-0.045,0.229-0.104,0.332
			c-0.079,0.044-0.182,0.037-0.27,0.06c-0.352,0.069-0.703,0.148-1.037,0.279c-0.253,0.092-0.527,0.1-0.785,0.179
			c-0.24,0.079-0.351,0.327-0.478,0.526c-0.122,0.182-0.302,0.324-0.378,0.534c0.098,0.027,0.182,0.087,0.243,0.168
			c-0.019,0.002-0.053,0.011-0.069,0.013c0.02,0.089,0.064,0.176,0.051,0.269c-0.074,0.077-0.17,0.129-0.246,0.205
			c-0.1,0.221-0.393,0.126-0.547,0.281c-0.154-0.042-0.316-0.065-0.479-0.042c-0.206,0.024-0.395,0.124-0.592,0.185
			c0.034,0.087,0.061,0.179,0.084,0.269c0.113,0.016,0.141,0.131,0.17,0.221c-0.035,0.005-0.068,0.011-0.104,0.019
			c-0.111,0.266-0.373,0.424-0.576,0.613c-0.201,0.208-0.489,0.292-0.709,0.474c-0.189,0.126-0.326,0.334-0.549,0.408
			c-0.117,0.042-0.225,0.095-0.342,0.134c-0.1-0.005-0.177-0.087-0.274-0.111c-0.144-0.035-0.288-0.066-0.433-0.094
			c0.002-0.129,0.012-0.284,0.145-0.353c0-0.09,0-0.177,0.002-0.266c0.035,0,0.069,0,0.104,0.003
			c0.023-0.119,0.059-0.232,0.093-0.348c0.028,0.037,0.053,0.076,0.087,0.111c0.035-0.019,0.066-0.048,0.101-0.071
			c-0.003-0.218-0.019-0.444-0.14-0.634c-0.003-0.029-0.003-0.058-0.003-0.086c-0.011-0.003-0.029-0.003-0.037-0.003
			c0.005-0.034,0.011-0.068,0.016-0.103c-0.011-0.002-0.031-0.002-0.042-0.002c0.002-0.035,0.008-0.069,0.014-0.103
			c-0.006-0.002-0.021-0.002-0.027-0.002c-0.016-0.129-0.065-0.261-0.152-0.358c-0.088-0.076-0.184,0.019-0.266,0.055
			c-0.163,0.071-0.158,0.287-0.287,0.395c-0.045,0.019-0.096,0.014-0.146,0.019c-0.061-0.105-0.062-0.227-0.058-0.345
			c0.042,0.002,0.088,0.002,0.132,0.005c0.051-0.134,0.106-0.266,0.186-0.381c0.051-0.2,0.053-0.463-0.16-0.566
			c0.002-0.037,0.002-0.076,0.008-0.113c0.031,0.016,0.062,0.032,0.089,0.056c0.017,0,0.046,0.005,0.062,0.008
			c-0.051-0.098-0.102-0.205-0.186-0.276c-0.201-0.026-0.414-0.074-0.547-0.239c-0.064,0.005-0.122,0.013-0.183,0.018
			c-0.054-0.036-0.108-0.081-0.175-0.086c-0.1,0.016-0.169,0.103-0.234,0.174c-0.029,0.118,0.105,0.163,0.188,0.207
			c-0.096,0.011-0.209,0.006-0.275,0.084c-0.055,0.082-0.012,0.189-0.029,0.279c0.053,0.121-0.063,0.163-0.132,0.221
			c-0.005-0.126,0.013-0.25,0.048-0.371c-0.023-0.008-0.048-0.013-0.072-0.021c-0.018,0.068-0.041,0.135-0.068,0.2
			c-0.088,0.032-0.192,0.071-0.192,0.179c0,0.079-0.056,0.134-0.122,0.163c0.068,0.113,0.059,0.253,0.023,0.376
			c-0.002,0.003-0.005,0.009-0.008,0.011c-0.021,0.044-0.047,0.086-0.074,0.128c0.029,0.153,0.037,0.311-0.023,0.461
			c0.061,0.145,0.101,0.3,0.188,0.434c0.082,0.118,0.085,0.269,0.104,0.405c0.002,0.16,0.034,0.336-0.056,0.479
			c-0.065,0.103-0.108,0.218-0.103,0.342c-0.096,0.095-0.196,0.184-0.318,0.247c-0.105,0.045-0.224,0-0.332-0.01
			c-0.053-0.145-0.088-0.298-0.188-0.418c-0.122-0.132-0.021-0.334-0.045-0.492c-0.012-0.003-0.035-0.006-0.045-0.006
			c0.005-0.026,0.01-0.052,0.014-0.076c-0.006-0.003-0.022-0.003-0.029-0.006c0.004-0.168-0.051-0.331-0.066-0.5
			c0.016-0.182,0.105-0.35,0.078-0.536c-0.028-0.105-0.025-0.245,0.102-0.271c-0.02-0.04-0.021-0.073-0.043-0.107
			c0.032-0.234,0.108-0.461,0.209-0.671c0.059-0.103,0.09-0.221,0.098-0.34c-0.084,0.062-0.132,0.158-0.189,0.242
			c-0.164,0.187-0.26,0.429-0.467,0.576c0.029-0.187,0.094-0.371,0.221-0.519c0.123-0.255,0.256-0.508,0.324-0.787
			c0.024,0.037,0.049,0.074,0.069,0.111c0.073-0.032,0.146-0.061,0.22-0.09c0.051,0.023,0.104,0.061,0.16,0.037
			c0.046-0.052,0.02-0.129,0.024-0.192c0.151-0.016,0.294-0.058,0.444-0.063c0.034-0.037,0.066-0.074,0.1-0.111
			c0.054-0.008,0.109-0.016,0.166-0.023c0,0.011,0,0.034,0.004,0.047c0.035-0.005,0.068-0.011,0.104-0.018v0.042h0.111
			c0.047,0.031,0.094,0.01,0.137-0.019c0.148-0.092,0.325,0.008,0.484-0.023c-0.055-0.082-0.119-0.156-0.156-0.245
			c-0.006-0.071-0.014-0.216-0.019-0.287c-0.201,0.058-0.397,0.174-0.614,0.124c0.009-0.098,0.012-0.2,0.018-0.297
			c-0.102,0.034-0.186,0.094-0.283,0.139c-0.146,0.042-0.307,0.011-0.45,0.074c-0.13,0.047-0.216,0.163-0.335,0.224
			c-0.066,0.008-0.133-0.008-0.196-0.011c0.003-0.021,0.008-0.042,0.013-0.06c-0.084,0.029-0.177,0.057-0.27,0.037
			c-0.084-0.084-0.137-0.2-0.229-0.276c-0.08-0.058-0.186-0.042-0.275-0.055c-0.006-0.011-0.02-0.037-0.023-0.051
			c-0.074,0.068-0.152,0.14-0.229,0.208c0.021-0.1,0.048-0.202,0.088-0.297c0.037-0.11,0.166-0.147,0.198-0.26
			c0.077-0.014,0.153-0.037,0.216-0.082c-0.082-0.029-0.168-0.05-0.256-0.019c-0.304,0.061-0.447,0.389-0.688,0.558
			c-0.098,0.116-0.264,0.09-0.393,0.134c-0.117,0.11-0.25,0.206-0.406,0.247c-0.081,0.005-0.168-0.087-0.242-0.013
			c-0.031-0.011-0.066-0.021-0.098-0.029c0.031-0.09,0.084-0.163,0.153-0.229c-0.017-0.029-0.04-0.055-0.063-0.077
			c-0.098,0.019-0.162,0.105-0.254,0.14c-0.098,0.04-0.201,0.074-0.296,0.121c-0.099,0.05-0.208-0.01-0.294-0.065
			c0.15-0.118,0.285-0.252,0.441-0.366c0.114-0.082,0.127-0.25,0.266-0.308c0.145-0.147,0.335-0.258,0.535-0.314
			c0.154-0.055,0.278-0.16,0.416-0.244c-0.119,0.013-0.24,0.023-0.363,0.023c-0.002-0.029-0.008-0.058-0.01-0.084
			c-0.129,0.008-0.256,0.026-0.386,0.031c-0.079,0.005-0.101-0.095-0.144-0.142c-0.108,0.05-0.205,0.121-0.309,0.179
			c-0.178,0.034-0.297-0.127-0.455-0.168c0.006-0.021,0.011-0.042,0.016-0.066c-0.283,0.068-0.486-0.187-0.742-0.239
			c-0.137,0-0.227,0.137-0.37,0.129c-0.014-0.034-0.026-0.069-0.038-0.103c-0.168-0.035-0.341-0.05-0.502-0.101
			c-0.124-0.139-0.079-0.344-0.126-0.51c-0.047-0.068-0.154-0.055-0.227-0.081c0,0.121-0.003,0.241-0.003,0.362
			c-1.065-0.013-2.131-0.034-3.193-0.05c-0.462,0.005-0.919-0.097-1.379-0.1c-0.373-0.002-0.748-0.019-1.116-0.076
			c-0.936-0.045-1.863-0.208-2.796-0.297c-0.473-0.103-0.967-0.105-1.438-0.227c-0.396-0.092-0.806-0.092-1.197-0.194
			c-0.275-0.045-0.547-0.095-0.817-0.164c-0.497-0.052-0.975-0.191-1.464-0.287c-0.465-0.105-0.936-0.197-1.398-0.332
			c-0.455-0.113-0.912-0.231-1.363-0.358c-0.307-0.047-0.595-0.171-0.894-0.258c-0.042,0.1,0.031,0.187,0.063,0.277
			c0.035,0.129-0.032,0.252-0.037,0.381c-0.019,0.137,0.034,0.271,0.063,0.405c-0.066-0.079-0.124-0.166-0.206-0.229
			c0.01-0.103,0.039-0.201,0.066-0.298c-0.063,0.068-0.125,0.142-0.177,0.221c-0.195-0.042-0.399-0.065-0.584-0.15
			c-0.222-0.089-0.344-0.316-0.548-0.434c-0.029,0.108-0.065,0.216-0.071,0.329c0.013,0.15,0.092,0.289,0.055,0.442
			c-0.008,0.229-0.042,0.5,0.132,0.676c-0.074,0.008-0.159,0.037-0.174,0.119c0.026,0.066,0.079,0.118,0.124,0.173
			c-0.021,0.042-0.04,0.087-0.055,0.129c-0.035-0.011-0.069-0.021-0.103-0.031c-0.016,0.055-0.037,0.107-0.061,0.161
			c0.035,0.037,0.069,0.073,0.106,0.107c0.05-0.002,0.1-0.021,0.148-0.034c-0.011,0.034-0.019,0.071-0.029,0.105
			c-0.063,0.008-0.124,0.018-0.188,0.029c-0.072,0.171-0.146,0.342-0.169,0.529h-0.029c0.002,0.026,0.008,0.053,0.013,0.079h-0.04
			c0.003,0.024,0.003,0.048,0.006,0.071c-0.151,0.234-0.225,0.505-0.328,0.763c-0.156,0.442-0.38,0.858-0.661,1.234
			c-0.146,0.158-0.137,0.39-0.119,0.589c-0.011,0-0.032,0.003-0.042,0.003c0.008,0.042,0.013,0.086,0.021,0.129
			c-0.016,0-0.042,0.002-0.058,0.005c0.026,0.089,0.082,0.176,0.087,0.271c-0.082,0.224-0.045,0.474-0.143,0.695
			c-0.047,0.173-0.167,0.315-0.28,0.452c-0.077,0.061-0.169,0.126-0.167,0.237c-0.008,0.313,0.338,0.534,0.23,0.866
			c-0.066,0.11-0.132,0.224-0.143,0.355C-7.427-8.729-7.475-8.59-7.396-8.495c0.119,0.15,0.211,0.321,0.28,0.498
			c0.051,0.142-0.047,0.31,0.051,0.437c0.061,0.092,0.153,0.157,0.251,0.207c0.005-0.1,0.026-0.197,0.077-0.284
			c0.045,0.04,0.087,0.082,0.126,0.124C-6.664-7.508-6.719-7.5-6.771-7.495C-6.7-7.34-6.647-7.172-6.682-7
			c-0.14-0.063-0.111-0.213-0.108-0.336h-0.098c-0.072,0.236-0.164,0.508,0.002,0.731c0.058,0.131,0.259,0.134,0.27,0.289
			c0.029,0.09-0.09,0.119-0.137,0.171C-6.819-6.116-6.795-6.04-6.801-5.982c-0.013,0-0.037,0.002-0.047,0.002
			c0,0.184,0.193,0.263,0.228,0.432c0.047,0.145,0.034,0.312,0.132,0.439c0.108,0.147,0.195,0.313,0.246,0.49
			c0.05,0.158-0.011,0.321-0.029,0.479c-0.008,0-0.027,0-0.034,0.002c0.005,0.034,0.013,0.068,0.018,0.103
			C-6.304-4.033-6.333-4.03-6.349-4.03c0.04,0.055,0.071,0.119,0.129,0.153c0.095,0.023,0.198,0.013,0.291,0.047
			C-5.81-3.753-5.68-3.69-5.542-3.648c0.201,0.095,0.164,0.402,0.399,0.461c0.116,0.034,0.251,0.034,0.333,0.142
			c-0.019,0.061-0.042,0.124-0.05,0.19c0.04,0.025,0.084,0.039,0.126,0.057c0.201,0.04,0.28,0.248,0.402,0.387
			c0.225,0.235,0.153,0.589,0.198,0.884c0.473,0.068,0.949,0.171,1.43,0.147c0.002,0.008,0.002,0.029,0.005,0.039
			c0.061-0.008,0.122-0.01,0.18-0.018c0.002,0.01,0.002,0.026,0.005,0.034c0.079-0.002,0.174-0.019,0.235,0.045
			C-2.31-1.233-2.349-1.193-2.384-1.151c0.071,0.074,0.174,0.103,0.256,0.164C-1.765-0.791-1.43-0.549-1.07-0.349
			c0.097,0.048,0.198,0.098,0.29,0.158c0.296,0.182,0.603,0.342,0.896,0.526C0.257,0.404,0.375,0.53,0.55,0.491
			c0,0.01,0,0.029,0.002,0.04c0.042-0.008,0.087-0.013,0.13-0.019v0.045C0.727,0.548,0.77,0.543,0.812,0.538
			c0.002,0.008,0.008,0.029,0.01,0.037c0.05-0.003,0.098-0.008,0.148-0.014c0.002,0.014,0.002,0.034,0.002,0.045
			c0.071-0.005,0.14-0.011,0.211-0.019v0.045c0.071-0.006,0.14-0.01,0.211-0.018v0.039c0.19-0.026,0.362,0.063,0.544,0.082
			c0.204,0.016,0.404,0.05,0.605,0.05c0.042,0.013,0.084,0.026,0.129,0.04C2.699,0.686,2.72,0.546,2.741,0.406
			c0.027-0.013,0.053-0.026,0.082-0.04v0.05c0.066,0,0.135,0,0.201,0.003c0.047,0.005,0.095,0.01,0.14,0.016
			c0.002,0.008,0.002,0.029,0.005,0.039c0.059-0.007,0.122-0.013,0.183-0.019c0,0.011,0,0.035,0.003,0.045
			c0.058-0.008,0.121-0.01,0.182-0.019v0.045c0.071-0.005,0.14-0.01,0.211-0.019v0.045C3.82,0.548,3.888,0.543,3.96,0.538v0.031
			c0.05,0.008,0.119-0.013,0.153,0.035c0.071,0.094,0.114,0.21,0.174,0.313c0.05,0.015,0.117,0.023,0.148,0.076
			c0.135,0.179,0.246,0.379,0.42,0.521c0.079,0.063,0.198,0.068,0.256,0.153C5.221,1.788,5.295,1.932,5.34,2.085
			c0.034,0.169,0.01,0.35,0.077,0.511C5.522,2.83,5.739,3.003,5.974,3.104c0.161,0.129,0.33,0.258,0.526,0.34
			c0.162-0.151,0.286-0.342,0.36-0.55c0.05-0.14,0.208-0.19,0.343-0.206C7.219,2.661,7.235,2.635,7.25,2.611
			c0.066,0.047,0.132,0.105,0.214,0.116c0.193,0.011,0.386,0.05,0.571,0.103C8.2,3.041,8.401,3.22,8.549,3.44
			C8.67,3.696,8.784,3.956,8.858,4.229C8.89,4.295,8.99,4.29,9.024,4.356c0.066,0.107,0.111,0.231,0.18,0.336
			c0.061,0.095,0.177,0.121,0.262,0.184C9.55,4.932,9.484,5.043,9.481,5.122c-0.01,0.002-0.034,0.013-0.047,0.016
			C9.469,5.153,9.503,5.167,9.54,5.18c0,0.058,0.002,0.118,0.008,0.179H9.492c0.084,0.124,0.164,0.252,0.198,0.4
			C9.72,5.84,9.72,5.984,9.833,6.003c0.15,0.029,0.27,0.137,0.417,0.174c0.148,0.055,0.27,0.158,0.417,0.205
			c0.003-0.016,0.005-0.05,0.008-0.065c0.193,0.021,0.346,0.145,0.523,0.2c0.122,0.045,0.204-0.124,0.119-0.205
			c-0.019-0.116-0.023-0.237-0.082-0.34c-0.002-0.029-0.002-0.058-0.002-0.084c-0.008,0-0.021-0.002-0.027-0.005
			c-0.005-0.114-0.04-0.227-0.04-0.339c0.019-0.129,0.029-0.264,0.095-0.376c0.119-0.224,0.222-0.463,0.412-0.637
			c0-0.021-0.002-0.042-0.002-0.063c0.016-0.003,0.029-0.006,0.045-0.008c0.092-0.082,0.208-0.126,0.304-0.206
			c0.203-0.203,0.489-0.282,0.732-0.426c0.208-0.1,0.346-0.3,0.544-0.416c0.066-0.04,0.138-0.079,0.182-0.15
			c0.064-0.107,0.193-0.131,0.299-0.181c0.124-0.061,0.264-0.084,0.396-0.124c0.042-0.023,0.087-0.042,0.132-0.058
			c0.153-0.015,0.307-0.057,0.459-0.026c0.123,0.047,0.244,0.092,0.365,0.135c0.129,0.086,0.291,0.079,0.437,0.034
			c0,0.013,0,0.042,0.003,0.055c0.047-0.029,0.095-0.063,0.143-0.098c-0.056-0.018-0.111-0.034-0.167-0.05
			c0.04-0.113,0.217-0.21,0.272-0.061c0.061-0.065,0.145-0.058,0.223-0.024c0.016,0.061,0.031,0.121,0.05,0.182
			c0.087,0.029,0.177,0.058,0.267,0.089c-0.039,0.042-0.076,0.085-0.116,0.126c0.19,0.084,0.376,0.21,0.589,0.197
			c0.008-0.05,0.014-0.1,0.02-0.15c0.092-0.013,0.185-0.044,0.279-0.042c0.027,0.037,0.043,0.081,0.059,0.124
			c0.006,0,0.019,0,0.023-0.003c0.029-0.026,0.061-0.055,0.093-0.082c-0.023-0.121,0.045-0.292,0.188-0.294
			c-0.006,0.05-0.009,0.1-0.014,0.15c0.146,0.058,0.349,0.047,0.426,0.21c0.113,0.056,0.246-0.005,0.271-0.125
			c-0.111-0.14-0.299-0.159-0.459-0.192c-0.074-0.029-0.176-0.116-0.123-0.203c0.027-0.068,0.128-0.073,0.178-0.115
			c0.053-0.029,0.08-0.092,0.113-0.14c-0.025,0-0.053-0.002-0.076-0.002c-0.006-0.034-0.014-0.069-0.018-0.103
			c-0.035-0.011-0.072-0.019-0.107-0.029C17.657,2.52,17.629,2.58,17.602,2.64c-0.055-0.002-0.11-0.008-0.164-0.013
			c-0.021-0.15,0.117-0.208,0.215-0.273c0.071-0.045,0.092-0.131,0.143-0.195c0.09-0.007,0.188,0.013,0.271-0.034
			c0.072-0.04,0.148,0.005,0.22,0.016c0.147,0.034,0.299-0.014,0.438-0.061c0.088-0.084,0.117-0.21,0.133-0.326
			C18.971,1.859,19.031,2,19.09,2.141c-0.059,0.023-0.113,0.044-0.17,0.071c0.244,0.005,0.458-0.121,0.688-0.179
			c0.223-0.037,0.453-0.11,0.68-0.076c0.029,0.003,0.059,0.005,0.088,0.008c0,0.005,0.002,0.016,0.002,0.021
			c0.246,0.05,0.466,0.176,0.677,0.305c0.069,0.053,0.077,0.144,0.111,0.218V2.467c0.13-0.011,0.259-0.04,0.386-0.066
			c0.066-0.103,0.153-0.192,0.27-0.232c0.071-0.015,0.076-0.092,0.096-0.149c0.197-0.05,0.434,0,0.576,0.155
			c0.087,0.089,0.219,0.147,0.246,0.284c0.14,0.05,0.195,0.231,0.358,0.226c0,0.005,0,0.018,0.003,0.024
			c0.035-0.006,0.068-0.011,0.104-0.014v0.038c0.023-0.003,0.05-0.008,0.074-0.011c0.021,0.069,0.045,0.135,0.078,0.195
			c0.117,0.194,0.096,0.437,0.057,0.652h-0.029c0.039,0.174,0.008,0.395,0.186,0.5c0.012-0.063,0.025-0.129,0.041-0.192
			c-0.031,0-0.066,0.003-0.098,0.005c-0.01-0.028-0.021-0.058-0.031-0.083c0.096,0.01,0.19,0.026,0.285,0.034
			c-0.029,0.161-0.116,0.3-0.219,0.421c0.095,0.142,0.24,0.25,0.316,0.405c0.048,0.098,0.121,0.182,0.217,0.234
			c-0.002-0.079-0.006-0.158-0.008-0.236c0.032,0.013,0.066,0.023,0.102,0.037c0.01,0.126,0.008,0.273,0.097,0.376
			c0.069,0.071,0.173,0.121,0.198,0.224c0.031,0.116,0.068,0.231,0.143,0.329c0.076-0.021,0.15-0.047,0.232-0.055
			c0.102,0.092,0.18,0.205,0.266,0.308c0.062,0.124,0.01,0.297,0.143,0.381c0.004-0.013,0.012-0.042,0.016-0.058h0.092
			c0.006-0.021,0.014-0.045,0.02-0.066c0.105-0.011,0.221,0,0.314-0.061c0.073-0.055,0.135-0.126,0.18-0.208
			c-0.109-0.089-0.017-0.239-0.006-0.353c0.031,0.01,0.061,0.021,0.092,0.034c-0.044-0.463,0.046-0.989-0.271-1.376
			c-0.121-0.236-0.262-0.465-0.418-0.682c-0.082-0.113-0.096-0.252-0.113-0.386c0.025-0.002,0.053-0.008,0.079-0.011
			c-0.158-0.261-0.396-0.458-0.571-0.703c-0.086-0.158-0.208-0.299-0.267-0.474c-0.105-0.303-0.262-0.597-0.28-0.924
			c-0.034-0.281,0.119-0.534,0.153-0.805c0.016-0.143,0.124-0.248,0.156-0.384c0.029-0.142,0.159-0.223,0.267-0.305
			c0.004-0.014,0.004-0.042,0.004-0.058c-0.018,0-0.049-0.002-0.062-0.005c0.138-0.119,0.296-0.218,0.412-0.361
			c0.082-0.09,0.188-0.171,0.214-0.297c0.161-0.008,0.301-0.158,0.28-0.324c0.003-0.163,0.048-0.327,0.147-0.458
			c0.102-0.105,0.244-0.15,0.371-0.219c0.09-0.042,0.219-0.021,0.279-0.113c0.053-0.118,0.045-0.26,0.117-0.374
			c0.092-0.144,0.256-0.224,0.355-0.36c0.117-0.095,0.273-0.103,0.408-0.159c0.002,0.017,0.005,0.051,0.005,0.069
			c0.138-0.034,0.18-0.179,0.23-0.297c-0.099-0.029-0.198-0.063-0.3-0.087c0.025-0.084,0.051-0.166,0.08-0.25
			c-0.014-0.002-0.039-0.005-0.053-0.005c0-0.011-0.002-0.034-0.006-0.044c0.053-0.005,0.109-0.011,0.162-0.017
			c0.05-0.007,0.105-0.01,0.152-0.034c0.035-0.071,0.035-0.155,0.062-0.229c0.044-0.032,0.101-0.042,0.153-0.058
			c-0.008-0.087,0.006-0.187-0.074-0.242c-0.026-0.037-0.051-0.076-0.084-0.104c-0.144,0.036-0.31,0.026-0.424,0.137
			c0.006-0.029,0.016-0.055,0.023-0.082c-0.065,0.05-0.135,0.1-0.219,0.098c0.062-0.187,0.301-0.182,0.418-0.324
			c0.076,0.029,0.152,0.061,0.225,0.097c0.016-0.019,0.034-0.034,0.053-0.052c-0.092-0.168-0.161-0.348-0.275-0.503
			c-0.055-0.071-0.082-0.155-0.104-0.239c-0.082,0.021-0.16,0.05-0.24,0.074c-0.002,0.026-0.008,0.053-0.01,0.079
			c-0.135-0.061-0.243-0.168-0.294-0.311c0.093,0.037,0.188,0.084,0.21,0.191c0.025-0.015,0.102-0.019,0.14-0.039
			c-0.099-0.113-0.175-0.24-0.235-0.374c0.055,0.029,0.105,0.063,0.156,0.095c-0.024-0.101-0.045-0.203-0.064-0.306
			c-0.023,0.002-0.045,0.005-0.065,0.008c0.024-0.071,0.045-0.147,0.04-0.224c-0.059-0.071-0.146-0.105-0.223-0.147
			c-0.127-0.09-0.283-0.05-0.426-0.084c0.08-0.026,0.162-0.042,0.243-0.063c0.003,0.013,0.003,0.042,0.005,0.056
			c0.043-0.006,0.086-0.014,0.127-0.019c0.006,0.016,0.016,0.047,0.024,0.063c0.028-0.045,0.073-0.095,0.053-0.156
			c-0.056-0.21-0.259-0.403-0.175-0.636c0.019-0.074-0.029-0.137-0.047-0.203c0.031-0.084,0.092-0.15,0.135-0.227
			c0.013-0.002,0.037-0.008,0.05-0.01c-0.011,0.079-0.024,0.158-0.058,0.229c0.045,0.053,0.095,0.103,0.124,0.166
			c-0.009,0.079-0.069,0.142-0.085,0.218c0.053,0.037,0.113,0.063,0.169,0.1c-0.04,0.013-0.079,0.029-0.121,0.042
			c0.032,0.1,0.071,0.2,0.111,0.297c0.063,0.002,0.126,0.002,0.188,0.005c0.003-0.019,0.008-0.05,0.011-0.068
			c0.107,0.098,0.223,0.208,0.258,0.353c-0.035,0.205-0.131,0.402-0.105,0.618c0.029,0.05,0.07,0.121,0.139,0.119
			c0.107-0.203,0.095-0.445,0.118-0.666c0.119-0.195,0.254-0.403,0.229-0.645c0.006-0.129-0.07-0.237-0.1-0.358
			c-0.063-0.029-0.133-0.053-0.186-0.095c-0.059-0.111-0.098-0.231-0.158-0.34c-0.08-0.016-0.135-0.074-0.18-0.137
			c0.002-0.042,0.006-0.082,0.01-0.121c0.08,0.058,0.143,0.131,0.215,0.2c0.092,0.081,0.223,0.097,0.338,0.125
			c0,0.074,0,0.147,0.006,0.224c0.068-0.042,0.129-0.1,0.137-0.187c0.032-0.195,0.166-0.342,0.236-0.521
			c0.113-0.279,0.065-0.586,0.013-0.874c-0.061,0.008-0.122,0.014-0.183,0.021c0.105-0.182,0.334-0.203,0.514-0.255
			c0.123-0.055,0.248-0.119,0.354-0.203c0.148-0.134,0.35-0.208,0.428-0.408c-0.062-0.011-0.127-0.019-0.192-0.027
			c-0.054,0.197-0.267,0.208-0.415,0.297c-0.082,0.047-0.178,0.01-0.264,0.016C28.121-9.447,28.066-9.371,28-9.308
			c0.016-0.071,0.031-0.142,0.048-0.216c0.103-0.058,0.19-0.139,0.293-0.197c0.04-0.055,0.063-0.139,0.141-0.15
			c0.093-0.016,0.183-0.029,0.27-0.063c0.159-0.063,0.344-0.066,0.483-0.171c0.088-0.068,0.228-0.084,0.246-0.213
			c0.111-0.011,0.207-0.069,0.299-0.126c-0.011-0.04-0.019-0.079-0.027-0.118c0.025-0.003,0.049-0.003,0.074-0.003
			c0.012-0.031,0.02-0.062,0.029-0.091c0.056,0.044,0.09,0.105,0.105,0.171c0.035-0.048,0.064-0.1,0.099-0.15
			c0.124-0.037,0.243-0.095,0.372-0.113c-0.021-0.047-0.041-0.092-0.061-0.14c-0.061,0.048-0.116,0.095-0.174,0.143
			c-0.066,0-0.135,0-0.201,0.002c-0.068-0.131-0.138-0.26-0.23-0.376c-0.062-0.034-0.168,0.029-0.209-0.055
			c-0.081-0.116,0.041-0.223,0.053-0.336c-0.047-0.082-0.105-0.166-0.072-0.271c0.039-0.203,0.092-0.406,0.125-0.611
			c0.029-0.034,0.049-0.074,0.029-0.116c-0.031-0.082,0.037-0.147,0.077-0.213c0.048,0.019,0.099,0.082,0.151,0.04
			c0.041-0.021,0.078-0.05,0.119-0.074c0.115-0.11,0.253-0.197,0.355-0.318c-0.016-0.002-0.047-0.008-0.062-0.011
			c0.045-0.139,0.053-0.284-0.019-0.413c0.081,0.026,0.138,0.087,0.155,0.171v-0.053c0.048,0.005,0.096,0.008,0.146,0.013
			c0-0.037,0-0.074,0.002-0.11c0.088-0.061,0.146-0.198,0.275-0.155c0.002,0.036,0.005,0.071,0.01,0.107
			c0.138-0.121,0.219-0.297,0.391-0.379c0.109-0.05,0.213-0.145,0.215-0.271C31.469-14.331,31.328-14.438,31.186-14.494z
			 M-4.163-16.786c0.038,0,0.04-0.066,0-0.063C-4.2-16.847-4.203-16.783-4.163-16.786z"/>
	</g>
</g>
</svg>
`