import RootStore from "./RootStore";
import Builder from '@stores/ui/builder';
import { observable, action, reaction } from 'mobx';
import Sidebar from '@stores/ui/sidebar';

export default class UiStore {

  root: RootStore;
  builder: Builder;
  sidebar: Sidebar;

  @observable
  userSelectEnabled = true;

  @observable windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight
  };

  constructor(root: RootStore) {
    this.root = root;
    this.builder = new Builder(this.root, this);
    this.sidebar = new Sidebar(this.root, this);

    window.onresize = () => {
      this.windowDimensions.height = window.innerHeight;
      this.windowDimensions.width = window.innerWidth;
    };
  }

  @action.bound
  toggleUserSelect(enabled: boolean) {
    this.userSelectEnabled = enabled;
  }
}