import * as shortid from 'shortid';
import { observable, action, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, custom, getDefaultModelSchema, Context, SKIP } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';
import { VenueInstance } from './venue';

// releated interfacess

// model interfaces

export interface IWebsitePickerConfig extends IBaseConfiguration {}

export interface IWebsitePickerInstance extends IBaseInstanceElement {
  name: string;
  link: string;
  keyMetric: string;
  description: string;
}

export interface IWebsitePicker extends IElement<IWebsitePickerConfig, IWebsitePickerInstance> {}

// model classes

export class WebsitePickerInstance implements IWebsitePickerInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable
  name = '';

  @observable
  @serializable
  link = '';

  @observable
  @serializable
  keyMetric = '';

  @observable
  @serializable
  description = ''

  @observable
  expand = false;

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return this.name.length > 0 && this.link.length > 0;
  }
  
  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get empty() {
    return !!!this.name && !!!this.link && !!!this.keyMetric && !!!this.description;
  }

  @computed get hasInfo() {
    return this.keyMetric.length > 0 || this.description.length > 0;
  }

  @computed get order() {
    return this.parent.instances.indexOf(this) + 1;
  }

  parent: WebsitePicker
  constructor(parent: WebsitePicker) {
    this.parent = parent;
  }

  @action.bound
  updateName(val: string) {
    this.name = val;
    this.link = '';
  }

  @action.bound
  updateLink(link: string) {
    this.link = link;
  }

  @action.bound
  updateKeyMetric(value: string) {
    this.keyMetric = value;
  }

  @action.bound
  updateDescription(value: string) {
    this.description = value;
  }

  @action.bound
  toggleExpand(toggle: boolean) {
    this.expand = toggle;

    if (!toggle) {
      this.description = '';
      this.keyMetric = '';
    }
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(WebsitePickerInstance).factory = (context: Context) => {
      return new WebsitePickerInstance(context.args.parent);
    };
  }
}

export default class WebsitePicker extends BaseElement<IWebsitePicker, IWebsitePickerConfig, WebsitePickerInstance> {

  @observable
  @serializable(elementInstanceSerializer(WebsitePickerInstance))
  readonly instances = observable<WebsitePickerInstance>([]);

  @action.bound
  addInstance() {
    this.instances.push(new WebsitePickerInstance(this));
  }

  @action.bound
  deleteInstance(instance: WebsitePickerInstance) {
    this.instances.remove(instance);
  }

  static getInstanceClass() {
    return WebsitePickerInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new WebsitePickerInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(WebsitePicker).factory = (context: Context) => {
      return new WebsitePicker(context.args.root, context.args.proposal);
    };
  }
}

WebsitePickerInstance.schema();
WebsitePicker.schema();