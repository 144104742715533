import * as React from 'react'
import { observer } from 'mobx-react-lite';
import CreatableSelect from 'react-select/lib/Creatable';
import dropdownStyle from './styles';
import * as styles from './dropdown.module.sass';
import BlockLabel from '@components/builder/common/BlockLabel';


interface Option {
  label: string;
  value: string;
}

interface IDropdownCreatableProps {
  options: string[] | Option[],
  value: string | Option | null | undefined;
  onChange: (value: string) => void;
  label?: string;
  placeholder: string;
  maxHeight?: number;
}

let DropdownCreatable: React.FunctionComponent<IDropdownCreatableProps> = (props) => {
  let sentObject = true;
  let option = props.value as any;
  let options: any = props.options;

  if (options.some((item: any) => typeof item !== 'object' && !item.hasOwnProperty('value') && !item.hasOwnProperty('label'))) {
    options = options.map((item: any) => {
      return {
        label: item,
        value: item
      }
    })

    option = option ? {
      label: option,
      value: option
    } : null

    sentObject = false;
  }

  return (
    <div style={{position: 'relative'}}>
      {props.label ?
        <div className={styles.labelBlock}>
          <BlockLabel text={props.label} />
        </div>
        : null}
      <CreatableSelect
        styles={dropdownStyle(false, false)}
        value={option}
        placeholder={props.placeholder}
        maxMenuHeight={props.maxHeight}
        theme={base => ({
          ...base, colors: {
            ...base.colors,
            primary: '#0076ff'
          }
        })}
        onChange={(selectedOption: any) => {
            props.onChange(!sentObject ? selectedOption.value : selectedOption)
          }
        }
        options={options as any}
      />
    </div>
  )
}

DropdownCreatable = observer(DropdownCreatable);

export default DropdownCreatable;