
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import Proposal from '@models/builder/proposal';
import Form from '@components/builder/Form';
import Template from '@components/builder/Template';
import * as styles from './builder.module.sass';
import Nav from '@components/builder/Nav';
import Resizer from '@components/builder/Resizer';
import Customization from '@components/builder/customization';
import RootStore from '@stores/RootStore';
import BuilderUI from '@stores/ui/builder';
import { Spring, animated } from 'react-spring/renderprops';
import hotkeys from 'hotkeys-js';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

interface IBuilderProps extends RouteComponentProps<any>, React.Props<any> {
  store?: RootStore;
}

interface State {}

@inject('store')
@observer
class Builder extends React.Component<IBuilderProps, State> {
  builderUI: BuilderUI;

  constructor(props: IBuilderProps) {
    super(props);
    this.builderUI = this.props.store!.ui.builder;
    this.toggleCustomization = this.toggleCustomization.bind(this);

    hotkeys('command+b,shift+b', this.toggleCustomization.bind(this));
    hotkeys('command+m,shift+m', this.togglePreview.bind(this));
  }

  toggleCustomization(e: KeyboardEvent) {
    e.preventDefault();
    this.builderUI.toggleCustomization();
  }

  togglePreview(e: KeyboardEvent) {
    e.preventDefault();
    this.builderUI.togglePreview();
  }

  componentDidMount() {
    if (this.props.match.params.uuid) {
      this.props.store!.proposalStore.setEditingProposal(this.props.match.params.uuid);
    }

    if (this.props.match.path === '/test-element-proposal') {
      this.props.store!.proposalStore.createTestElementProposal();
    }
  }

  componentWillUnmount() {
    hotkeys.unbind('command+b,ctrl+b', this.toggleCustomization);
    hotkeys.unbind('command+m,ctrl+m', this.togglePreview);
    this.props.store!.proposalStore.stopEditingProposal();
  }

  public render() {

    let proposal = this.props.store!.proposalStore.editingProposal;

    if (!proposal) {
      return null;
    }

    let configContainer = {
      tension: 10,
      friction: 10,
      duration: 50
    }

    let config = {
      tension: 10,
      friction: 10,
      duration: 50
    }

    let transition = {
      from: {
        transform: `translateX(${0}px) translateZ(0px)`,
      },
      to: {
        transform: `translateX(${0}px) translateZ(0px)`
      }
    }

    if (this.builderUI.customizationOpen || this.builderUI.previewOpen) {
      transition.to.transform = `translateX(-${this.builderUI.transition}px) translateZ(0px)`
    }
  
    return (
      <div>
        <Nav builderUI={this.props.store!.ui.builder} proposal={proposal} />
        <div className={styles.container}>
          <Spring
            native
            from={transition.from}
            to={transition.to}
            config={configContainer}
            onRest={() => this.builderUI.animationsDone()}
          >
            {containerProps => {
              return <Content
                proposal={proposal!}
                config={config}
                animatedProps={containerProps}
                builderUI={this.builderUI}
              />
            }
            }
          </Spring>
        </div>
      </div>
    );
  }
}

interface IContentProps {
  proposal: Proposal;
  config: any;
  animatedProps: {
    transform: string;
  };
  builderUI: BuilderUI;
}

type Content = IContentProps & {
  children?: React.ReactNode;
}

const Content: React.FunctionComponent<IContentProps> = observer((props: Content) => {
  return (
    <animated.div style={props.animatedProps} className={styles.content}>
      <Spring
        native
        from={{ width: props.builderUI.formWidth }}
        to={{ width: props.builderUI.formWidth }}
        config={props.config}
      >
        {animateProps => {
          return (
            <animated.div style={{ width: animateProps.width }} className={styles.form}>
              <Form proposal={props.proposal} />
            </animated.div>
          )
        }
        }
      </Spring>
      <Resizer
        dragging={props.builderUI.resizerDrag['form-template']}
        resizeColumn={props.builderUI.resizeColumn}
        type='form-template'
        toggleDrag={props.builderUI.toggleDrag}
      />
      <Template builderUI={props.builderUI} proposal={props.proposal} />
      <Resizer
        dragging={props.builderUI.resizerDrag['template-customization']}
        resizeColumn={props.builderUI.resizeColumn}
        type='template-customization'
        toggleDrag={props.builderUI.toggleDrag}
      />
      <Spring
        native
        from={{ width: props.builderUI.customizationWidth }}
        to={{ width: props.builderUI.customizationWidth }}
        config={props.config}
      >
        {animateProps => {
          return (
            <animated.div style={{ width: animateProps.width }} className={styles.customization}>
              <Customization proposal={props.proposal} />
            </animated.div>
          )
        }
        }
      </Spring>
      <div className={styles.overflow}></div>
    </animated.div>
  );
})

export default withRouter(Builder);