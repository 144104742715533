import * as shortid from 'shortid';
import { observable, computed, action, when, reaction } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, getDefaultModelSchema, Context, custom, SKIP, serialize, deserialize } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

// releated interfacess
interface IOpportunityItem {
  id: string;
  name: string;
  value: string | undefined;
  svg_icon?: string;
}

interface IOpportunityItemConfig {
  name: string;
  options: string[];
  dropdown_label: string;
  has_dropdown: boolean;
  svg_icon?: string;
}

// model interfaces

export interface IOpportunityConfig extends IBaseConfiguration {
  opportunities: IOpportunityItemConfig[];
}

interface IOpportunityInstance extends IBaseInstanceElement {
  opportunities: IOpportunityItem[];
}

export interface IOpportunity extends IElement<IOpportunityConfig, IOpportunityInstance> {}
// model classes

class OpportunityItem implements IOpportunityItem {

  @serializable
  @observable
  id = shortid.generate();

  @serializable
  @observable
  name = '';

  @serializable
  @observable
  value: string | undefined;

  @serializable
  @observable
  svg_icon: string | undefined;

  @action.bound
  selectValue(value: string) {
    this.value = value;
  }

  constructor(name: string, icon?: string) {
    this.name = name;
    this.svg_icon = icon;
  }
}

export class OpportunityInstance implements IOpportunityInstance {
  @observable
  id = shortid.generate()

  // hack. If you delete config item we also want to delete selected option
  @serializable(custom((value) => {
    let list: any[] = []
    for (let item of value) {
      list.push(serialize(item))
    }
    return list
  }, (json, context) => {
    let parent: Opportunity = context.args.parent;
    let options = parent.configuration.opportunities.map(item => item.name);
    let opts = json as IOpportunityItem[];
    opts = opts.filter(item => {
      return options.includes(item.name);
    })
    let items = deserialize(OpportunityItem, opts);
    return items
  }))
  @observable
  readonly opportunities = observable<OpportunityItem>([]);

  parent: Opportunity;

  @action.bound
  selectOption(value: string) {
    let opportunity = this.opportunities.find(item => item.name === value);
    let configOpportunity = this.parent.configuration.opportunities.find(item => item.name === value)!;

    if (opportunity) {
      this.opportunities.remove(opportunity);
      return;
    } else {
      this.opportunities.push(new OpportunityItem(value, configOpportunity.svg_icon));
    }
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return this.opportunities.length > 0;
  }

  constructor(parent: Opportunity) {
    this.parent = parent;
  }

  static schema() {
    return getDefaultModelSchema(OpportunityInstance).factory = (context: Context) => {
      return new OpportunityInstance(context.args.parent);
    };
  }
}

export default class Opportunity extends BaseElement<IOpportunity, IOpportunityConfig, OpportunityInstance> {
  @observable
  @serializable(elementInstanceSerializer(OpportunityInstance))
  readonly instances = observable<OpportunityInstance>([]);

  static getInstanceClass() {
    return OpportunityInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new OpportunityInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(Opportunity).factory = (context: Context) => {
      return new Opportunity(context.args.root, context.args.proposal);
    };
  }
}

OpportunityInstance.schema();
Opportunity.schema();