import { observable, computed, reaction, action, flow, toJS, IObservableArray } from 'mobx';
import Page, { IPage } from './page';
import { serializable, serialize, custom, deserialize, Context, getDefaultModelSchema, update, object, SKIP, serializeAll, list } from 'serializr';
import RootStore from '@app/stores/RootStore';
import { Omit } from '@util/types';
import Template, { ITemplate } from './customization';
import { toast } from 'react-toastify';
import { trackPoroposalPublished, trackProposalPageChange } from '@app/util/analytics';
import { TEMPLATE_URL } from '@app/util/apiRoot';
import Settings, { ISettings } from '@models/builder/settings';
import Section from './section';
import * as React from 'react';
import CRM from './crm';
import API_URL from '@util/apiRoot';

interface IStats {
  time_spent: number;
  leads_count: number;
  total_views: number;
}

export interface IProposal {
  name: string;
  id: string;
  slug: string;
  pages: IPage[];
  template: ITemplate;
  settings: ISettings
}

interface IProposalApp extends Omit<IProposal, 'pages' | 'template' | 'settings'> {
  pages: Page[];
  template: Template;
  stats: IStats;
  settings: Settings;
}

function serializePages(pages: Page[]) {
  let data = [];
  for (let page of pages) {
    data.push(page.json)
  }
  return data;
}

function deserializePages(jsonValue: any, context: Context, oldValue: Page[]) {

  let data = [];
  for (let jsonPage of jsonValue) {

    let oldPage = oldValue.find((item) => item.id === jsonPage.id);

    if (oldPage) {
      update(oldPage, jsonPage, () => {}, Object.assign(context.args, {
        proposal: context.target
      }));
      data.push(oldPage)
    } else {
      let page: any;
      page = deserialize(Page, jsonPage, undefined, Object.assign(context.args, {
        proposal: context.target
      }));
      data.push(page);
    }
  }

  return data;
}

interface IVersion {
  created_at: string;
  id: string;
  status: string;
  updated_at: string;
}

export default class Proposal implements IProposalApp {

  @observable
  @serializable(object(Template))
  template: Template;

  @serializable
  @observable
  name: string;

  @observable
  renaming = false;

  @observable
  @serializable(object(Settings))
  settings: Settings;

  @serializable(custom((item: any) => toJS(item), (item) => observable.array<IVersion>(item, { deep: false })))
  readonly versions = observable.array<IVersion>([], { deep: false });

  @serializable(custom(item => toJS(item), item => item))
  @observable
  version_active: IVersion | null = null;

  @serializable
  @observable
  id: string;

  @serializable
  @observable
  slug: string;

  @observable
  editingPage: Page | undefined;

  @observable
  readonly = false

  @observable
  @serializable(custom(item => toJS(item), item => item))
  stats: IStats;

  @observable
  @serializable(object(CRM))
  crm = new CRM();

  @observable
  @serializable(custom(serializePages, deserializePages))
  readonly pages = observable<Page>([]);

  @computed get publishedLink() {
    return `https://${this.root.user.organization || 'organization'}.${TEMPLATE_URL}/${this.slug}`
  }

  @computed get visiblePages() {
    return this.activePages.filter(item => !item.hidden);
  }

  @computed get activePages() {
    return this.pages.filter((item) => item.status === 'active')
  }

  @computed get premiumPages() {
    return this.pages.filter(item => item.premium);
  }

  @computed get filledPremiumSections() {
    return this.premiumPages.filter(item => !item.hidden);
  }

  @computed get json() {
    return serialize(this)
  }

  @computed get sections(): IObservableArray<Section> {
    let sections = this.pages
      .map(item => item.sections)
      .reduce((prevArr, nextArr) => {
        return prevArr.concat(nextArr.slice() as any) as any;
      }, [])

    return sections;
  }

  @computed get elements() {
    let elements = this.pages
      .map(item => item.sections.map(item => item.elements))
      .reduce((prevArr, nextArr) => {
        return prevArr.concat(nextArr.slice())
      }, []).reduce((prev, next) => {
        return prev.concat(next.slice() as any) as any;
      }, [])

    return elements;
  }

  @computed get combinedSections() {
    let combined = [...this.sections, ...this.settings.virtualSections]

    let sorted = this.settings.sectionOrder.map(id => {
      return combined.find(item => item.id === id)!
    }).filter(item => !!item)

    return sorted;
  }

  @computed get customizationElements() {
    if (!this.template) {
      return observable([]);
    }

    return this.template.elements;
  }

  @computed get isFirstPage() {
    if (!this.editingPage) {
      return false
    }

    return this.pages.indexOf(this.editingPage) === 0 ? true : false;
  }

  @computed get isLastPage() {
    if (!this.editingPage) {
      return false
    }

    return this.pages.indexOf(this.editingPage) === this.pages.length - 1 ? true : false;
  }

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;

    reaction(
      () => this.editingPage,
      (page) => {
        if (page) {
          trackProposalPageChange(page.name, this.id)
        }
      }
    )
  }

  // ** Refresh data about the proposal. Fetches all data if only partial! */
  @action.bound
  refresh = flow(function* (this: Proposal) {
    let processObject = yield this.root.api.getProcess(this.id);
    update(this, processObject, () => {}, {
      root: this.root
    })
  });

  /** Get versions for specific proposals */
  @action.bound
  getProposalVersions = flow(function* (this: Proposal) {
    let processObject = yield this.root.api.getProcess(this.id);
    let versions = processObject.versions;
    this.version_active = processObject.version_active;
    this.versions.replace(versions);
  })

  /** Sets active page of proposal. If no page is sent then it tries to default to first page in list of pages */
  @action.bound
  setEditingPage(page?: Page) {
    if (!page) {
      this.editingPage = this.pages[0];
      return;
    }

    this.editingPage = page;
  }

  @action.bound
  shiftPage(forward: boolean) {
    if (!this.editingPage) {
      return;
    }

    let index = this.visiblePages.indexOf(this.editingPage);

    let shiftIndex = forward ? index + 1 : index - 1;

    if (shiftIndex >= 0 && shiftIndex <= this.visiblePages.length - 1) {
      let nextPage = this.visiblePages[shiftIndex];
      this.setEditingPage(nextPage);
    }
  }

  @action.bound
  publishProposal = flow(function* (this: Proposal) {
    let toastId = toast.info('Publishing proposal, please wait...', { autoClose: false });

    let latestVersion = this.versions[0];
    try {
      yield this.root.api.publishProposalVersion(latestVersion.id, this.id);
      yield this.getProposalVersions(); 

      let element = React.createElement('div', { className: 'publish-text' }, 'Published: ', React.createElement('a',
        { href: this.publishedLink, target: '_blank', style: { color: 'white' } },
        `${this.publishedLink}`
      ));
  
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: element, autoClose: 5000 });
      trackPoroposalPublished(this.publishedLink, this.id, this.name)
    } catch (error) {
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: 'Error publishing', autoClose: 5000 });
    }
  })

  @action.bound
  openPdf = flow(function* (this: Proposal) {
    window.open(`${API_URL}/form_processes/${this.id}/pdf?token=${this.root.token}`,'_blank')
  })

  @action.bound
  rename = flow(function* (this: Proposal, name: string) {
    this.renaming = true;

    try {
      yield this.root.api.renameProposal(this.id, name);
      yield this.refresh();
      toast.success(`Proposal renamed to ${name}`);
    } catch (error) {
      toast.error('Error renaming proposal');
    }

    this.renaming = false;
  })

  static schema() {
    return getDefaultModelSchema(Proposal).factory = (context: Context) => {
      return new Proposal(context.args.root);
    };
  }
}

export class ProposalSimplePreview {
  @observable
  @serializable
  id: string;

  @observable
  @serializable
  name: string;

  @observable
  @serializable
  type = 'proposal';

  @observable
  @serializable
  folder_id: string | null;

  @observable
  renameOpen = false;

  @observable
  renaming = false;

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }

  @action.bound
  toggleRename(toggle: boolean) {
    this.renameOpen = toggle;
  }

  @action.bound
  rename = flow(function* (this: ProposalSimplePreview, name: string) {
    this.renaming = true;

    try {
      yield this.root.api.renameProposal(this.id, name);
      yield this.root.proposalStore.pullFolders();
      yield this.root.proposalStore.pullProposal(this.id);
      toast.success(`Proposal renamed to ${name}`);
      this.toggleRename(false);
    } catch (error) {
      toast.error('Error renaming proposal');
    }

    this.renaming = false;
  })

  static schema() {
    return getDefaultModelSchema(ProposalSimplePreview).factory = (context: Context) => {
      return new ProposalSimplePreview(context.args.root);
    };
  }
}

ProposalSimplePreview.schema();
Proposal.schema();