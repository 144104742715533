import * as shortid from 'shortid';
import { observable, action, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, getDefaultModelSchema, Context, custom, SKIP } from 'serializr';
import { customFieldSerializer, socialPickerSerializer, elementInstanceSerializer } from '@util/serializer';
import Field from '@util/elements/Field';
import File, { IFile } from '@util/elements/FileUpload';
import SocialMediaPicker, { ISocialMediaHandle } from '@models/builder/common/SocialMediaPicker';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';

// releated interfacess

// model interfaces
export interface IContactConfig extends IBaseConfiguration {
  social_networks: {
    name: string;
    example_link: string;
  }[]
}

interface IContactInstance extends IBaseInstanceElement {
  description: string | Field<string>;
  name: string | Field<string>;
  website: string | Field<string>;
  company: string | Field<string>;
  position: string | Field<string>;
  socialMediaLinks: ISocialMediaHandle[] | SocialMediaPicker;
  file: IFile;
}

export interface IContact extends IElement<IContactConfig, IContactInstance> { }

// model classes

export class ContactInstance implements IContactInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable(customFieldSerializer<string>())
  description = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  name = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  website = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  company = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  position = new Field('');

  @observable
  @serializable(object(File))
  file = new File();

  @serializable(socialPickerSerializer())
  socialMediaLinks = new SocialMediaPicker()

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.name.value
  }

  parent: Contact;
  constructor(parent: Contact) {
    this.parent = parent;
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(ContactInstance).factory = (context: Context) => {
      return new ContactInstance(context.args.parent);
    };
  }
}

export default class Contact extends BaseElement<IContact, IContactConfig, ContactInstance> {

  @observable
  @serializable(elementInstanceSerializer(ContactInstance))
  readonly instances = observable<ContactInstance>([]);

  @action.bound
  addInstance() {
    this.instances.push(new ContactInstance(this));
  }

  @action.bound
  deleteInstance(instance: ContactInstance) {
    this.instances.remove(instance);
  }

  static getInstanceClass() {
    return ContactInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new ContactInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(Contact).factory = (context: Context) => {
      return new Contact(context.args.root, context.args.proposal);
    };
  }
}

ContactInstance.schema()
Contact.schema()