
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import SectionModel from '@models/builder/section';
import Element from '@components/builder/elements/Element';
import * as styles from './section.module.sass';
import ReactMarkdown from 'react-markdown';
import Icon from '@app/components/common/Icon';
import OutsideClick from 'react-outside-click-handler';
import cn from 'classnames';

export interface ISectionProps {
  section: SectionModel;
}

let Section: React.FunctionComponent<ISectionProps> = (props) => {

  const [dropdownOpen, toggleDropdown] = React.useState(false);

  let elements = props.section.elements.map((element) => {
    return <Element key={element.id} model={element} />
  })

  function itemClick(action: Function) {
    action();
    toggleDropdown(false);
  }

  return (
    <div className={styles.section}>
      <div className={styles.menu}>
        <div className={styles.text}>
          <p className={styles.headline}>{props.section.name.toLocaleLowerCase()}</p>
          <div className={styles.description}><ReactMarkdown source={props.section.description} /></div>
        </div>
        <div className={styles.moreContainer}>
          <div onClick={() => toggleDropdown(!dropdownOpen)} className={cn(styles.more, { [styles.moreActive]: dropdownOpen })}>
            <Icon inheritStyle type='three-dots' />
          </div>
          {dropdownOpen &&
            <OutsideClick onOutsideClick={() => toggleDropdown(false)}>
              <div className={styles.dropdown}>
                <div onClick={() => itemClick(props.section.toggleVisibility)} className={styles.dropdownItem}>
                  <Icon type={!props.section.visible_for_user ? 'eye' : 'eye-slashed'} inheritStyle />
                  <p>{!props.section.visible_for_user ? 'Show' : 'Hide'}</p>
                </div>
              </div>
            </OutsideClick>
          }
        </div>
      </div>
      <div>
        {elements}
      </div>
    </div>
  )
}


export default observer(Section);