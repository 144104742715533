
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import PageModel from '@models/builder/page';
import * as styles from './page.module.sass';
import cn from 'classnames';
import Icon from '@components/common/Icon';
import CompleteIcon from '@assets/builder/page-complete.svg';
import { Tooltip } from 'react-tippy';
import { rootContext } from '@app/index';

export interface IPageProps {
  page: PageModel;
  order: number;
  pageRef: (ref: HTMLDivElement | null, uuid: string) => void;
}

interface State {
  hover: boolean;
}

const Page: React.FunctionComponent<IPageProps> = (props) => {
  const [hover, toggleHover] = React.useState(false);
  const user = React.useContext(rootContext).user;

  let premium = false;

  if (props.page.premium) {
    premium = true;
  }
  
  if (user.premium) {
    premium = false;
  }

  let itemClass = cn(styles.item,
    { [styles.itemActive]: props.page.isEditing },
    { [styles.itemPremium]: premium },
  )

  let leftSide: any = `${props.order}.`;
  let leftSideMargin = 9
  let leftFontSize = 13

  let page = (
    <div ref={(ref) => props.pageRef(ref, props.page.id)} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)} className={itemClass}>
      {props.page.svg_icon &&
        <span dangerouslySetInnerHTML={{ __html: props.page.svg_icon }} style={{ width: '12px', marginRight: leftSideMargin, fontSize: leftFontSize, marginBottom: '2px' }}></span>
      }
      <p onClick={() => props.page.switchToPage()}>{props.page.name}</p>
    </div>
  );

  return (
    <div className={styles.container}>
      {page}
    </div>
  )
}

export default observer(Page);