import { observable, action, flow, computed, reaction } from "mobx";
import RootStore from "@stores/RootStore";
import { toast } from "react-toastify";
import { trackRegister } from '@util/analytics';

export default class AuthStore {

  @observable
  inProgress = false;

  @observable
  values = {
    email: '',
    password: '',
    organization: ''
  };

  @computed get isAuthorized() {
    return !!this.root.token;
  }

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }

  @action.bound
  setPassword(val: string) {
    this.values.password = val;
  }

  @action.bound
  setEmail(val: string) {
    this.values.email = val;
  }

  @action.bound
  setOrganization(val: string) {
    this.values.organization = val;
  }

  @action.bound
  reset() {
    this.values.email = '';
    this.values.password = '';
    this.values.organization = '';
  }

  @action.bound
  loginWithToken = flow(function* (this: AuthStore) {
    try {
      yield this.root.user.getUser();
      this.root.router.push('/proposals')
      toast.success('Welcome!')
    } catch (error) {
      toast.error('There was an error. Please try again later')
      console.error(error)
    }
  })

  @action.bound
  setToken(token: string) {
    this.root.setToken(token);
  }

  @action.bound
  login = flow(function* (this: AuthStore) {
    this.inProgress = true;

    try {
      let { token } = yield this.root.api.login({ password: this.values.password, email: this.values.email });
      this.root.setToken(token);
      yield this.root.user.getUser();
      this.reset();
      console.log(this.root.router.location)
      if (this.root.router.location.state && this.root.router.location.state.from) {
        this.root.router.push(this.root.router.location.state.from)
      } else {
        this.root.router.push('/')
      }
    } catch (error) {
      console.error(error.response)
      if (error.response && error.response.data && error.response.status === 401) {
        toast.error('Invalid email or password')
      } else {
        toast.error('Please try again later')
      }
    }

    this.inProgress = false;
  })

  @action.bound
  register = flow(function* (this: AuthStore) {
    this.inProgress = true;

    try {
      yield this.root.api.register(this.values);
      let { token } = yield this.root.api.login(this.values);
      this.root.setToken(token);
      yield this.root.user.getUser();
      this.reset();
      this.root.router.push('/')
      trackRegister(this.root.user.id as string, this.root.user.email as string, this.root.user.organization as string)
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.message && error.response.data.message.email) {
          toast.error('Email has been already taken')
        } else {
          toast.error('Email or organization name has been taken')
        }
      } else {
        toast.error('Please try again later') 
      }
    }

    this.inProgress = false;
  })

  @action.bound
  requestNewEmail = flow(function* (this: AuthStore,) {
    let toastId = toast.info('Sending email...');

    try {
      yield this.root.api.resetEmail(this.root.user.email || this.values.email);
      this.root.removeToken();
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: 'Email sent. Please check your inbox.', autoClose: 3000 });
    } catch (error) {
      toast.update(toastId, { type: toast.TYPE.ERROR, render: 'Error changing your email', autoClose: 3000 });
      console.error(error)
    }
  })

  @action.bound
  setNewEmail = flow(function* (this: AuthStore, newEmail: string, token: string) {
    this.inProgress = true;

    try {
      yield this.root.api.setNewEmail(token, newEmail);
      this.root.router.replace('/proposals');
      toast.success('Email reset done. You can now sign-in.')
    } catch (error) {
      toast.error('Error reseting your email. Try again.')
    }

    this.inProgress = false;
  })

  @action.bound
  resetPassEmail = flow(function* (this: AuthStore) {
    this.inProgress = true;

    try {
      this.root.removeToken();      
      yield this.root.api.resetPasswordEmail(this.root.user.email || this.values.email);
      toast.success('Password reset email sent. Check your email. :)')
    } catch (error) {
      toast.error('Error sending email. Did you enter correct email?')
    }

    this.inProgress = false;
  })

  @action.bound
  resetPassword = flow(function* (this: AuthStore, newPassword: string, token: string) {
    this.inProgress = true;

    try {
      yield this.root.api.setNewPassword(token, newPassword);
      this.root.router.replace('/proposals');
      toast.success('Password reset done. You can now sign-in.')
    } catch (error) {
      toast.error('Error reseting your password. Try again.')
    }

    this.inProgress = false;
  })

  @action.bound
  logOut() {
    this.root.removeToken();
    this.root.user.forgetUser();
    this.root.router.push('/auth/signin')
  }
}