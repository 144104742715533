
import * as React from 'react';
import { observer } from 'mobx-react';
import Model from '@app/models/builder/elements/packages';
import Instance from './Instance';
import ElementHeadline from '@components/builder/common/ElementHeadline';

export interface IPackagesProps {
  model: Model
}

interface State {}

@observer
export default class Packages extends React.Component<IPackagesProps, State> {
  public render() {
    let configuration = this.props.model.configuration;

    let content = this.props.model.instances.map((item) => {
      return <Instance key={item.id} configuration={this.props.model.configuration} instance={item} />
    })

    return (
      <div>
        <ElementHeadline complete={this.props.model.complete} text={this.props.model.name} markdown={configuration.tooltip} />
        <div>
          {content}
        </div>
      </div>
    );
  }
}
