import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Label from '@components/builder/customization/elements/common/Label';

import { DropdownBasic } from '@app/components/common/Dropdown';
import { SettingsContext } from '@app/components/builder/Form/context';
import currencies, { CurrencyKey } from '@common/util/currencies';

interface ICurrencyProps {

}

let Currency: React.FunctionComponent<ICurrencyProps> = (props) => {
  const settings = React.useContext(SettingsContext);
  
  let currencyValue = {
    value: settings.currency,
    label: currencies[settings.currency].label
  }

  let currencyList = Object.entries(currencies).map(item => { return {
    value: item[0],
    label: item[1].label
  }});

  return (
    <div>
      <Label helpText='Change proposal currency'  label='Currency' />
      <div>
        <DropdownBasic
          options={currencyList}
          value={currencyValue}
          placeholder='test'
          onChange={(value: any) => settings.changeCurrency(value.value)}
          theme='dark'
        />
      </div>
    </div>
  )
}

Currency = observer(Currency);

export default Currency;