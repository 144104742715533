import * as shortid from 'shortid';
import { observable, computed, action } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, custom, SKIP } from 'serializr';
import { customFieldSerializer, elementInstanceSerializer } from '@util/serializer';
import File, { IFile } from '@util/elements/FileUpload';
import Field from '@util/elements/Field';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { Omit } from '@app/util/types';

// releated interfacess

// model interfaces

export interface IQuoteConfig extends IBaseConfiguration {}

export interface IQuoteInstance extends IBaseInstanceElement  {
  name: string;
  website: string;
  company: string;
  position: string;
  description: string;
  file: IFile;

}

interface IQuoteInstanceApp extends Omit<IQuoteInstance, 'name' | 'website' | 'company' | 'position' | 'description'> {
  name: Field<string>;
  website: Field<string>;
  company: Field<string>;
  position: Field<string>;
  description: Field<string>;
}

export interface IQuote extends IElement<IQuoteConfig, IQuoteInstance> { }

// model classes

export class QuoteInstance implements IQuoteInstanceApp {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable(customFieldSerializer<string>())
  description = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  name = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  website = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  company = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  position = new Field('');

  @observable
  @serializable(object(File))
  file = new File();

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.name.value
  }

  parent: Quote
  constructor(parent: Quote) {
    this.parent = parent;
  }


  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(QuoteInstance).factory = (context: Context) => {
      return new QuoteInstance(context.args.parent);
    };
  }
}

export default class Quote extends BaseElement<IQuote, IQuoteConfig, QuoteInstance> {

  @observable
  @serializable(elementInstanceSerializer(QuoteInstance))
  readonly instances = observable<QuoteInstance>([]);

  @action.bound
  addInstance() {
    this.instances.push(new QuoteInstance(this));
  }

  @action.bound
  deleteInstance(instance: QuoteInstance) {
    this.instances.remove(instance);
  }

  static getInstanceClass() {
    return QuoteInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new QuoteInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(Quote).factory = (context: Context) => {
      return new Quote(context.args.root, context.args.proposal);
    };
  }
}

QuoteInstance.schema();
Quote.schema();