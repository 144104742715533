import * as React from 'react';
import * as styles from './form.module.sass';
import TextareaAutosize from 'react-autosize-textarea';
import OutsideClickHandler from 'react-outside-click-handler';
import { observer } from 'mobx-react';
import Icon from '@components/common/Icon';
import Tier from '@models/builder/elements/packages/tier';
import { ITierData } from '@models/builder/elements/packages/interfaces';
import { CurrencyInputFormat } from '@app/components/builder/common/Input';
import { SettingsContext } from '@app/components/builder/Form/context';

export interface FormProps {
  tier: Tier;
}

interface IForm {
  name: string;
  price: number | undefined;
  type: 'month' | 'week' | 'day' | 'year' | 'month-3' | 'one-time';
  current: number | undefined;
  available: number | undefined;
  description: string | undefined;
  badge: string | undefined;
  humanType: string;
}

interface State extends IForm {};

const name: React.FunctionComponent<FormProps> = (props) => {
  let tier = props.tier;

  let formData: IForm = {
    name: tier.name,
    price: tier.pricing.price,
    type: tier.pricing.type,
    humanType: tier.pricing.humanType,
    current: tier.tickets.current,
    available: tier.tickets.available,
    description: tier.description,
    badge: tier.badge
  }

  const [data, setData] = React.useState(formData);

  function save() {
    let tierData: ITierData = {
      name: data.name,
      description: data.description,
      badge: data.badge,
      pricing: {
        price: data.price,
        type: data.type,
        humanType: data.humanType
      },
      tickets: {
        current: data.current,
        available: data.available
      }
    };

    props.tier.saveNewData(tierData);
    props.tier.toggleSelect(false);
  }

  return (
    <div>

    </div>
  )
}

@observer
export default class Form extends React.Component<FormProps, State> {
  constructor(props: FormProps) {
    super(props);
    let tier = this.props.tier;

    this.state = {
      name: tier.name,
      price: tier.pricing.price,
      type: tier.pricing.type,
      humanType: tier.pricing.humanType,
      current: tier.tickets.current,
      available: tier.tickets.available,
      description: tier.description,
      badge: tier.badge
    };
  }

  save() {
    let data: ITierData = {
      name: this.state.name,
      description: this.state.description,
      badge: this.state.badge,
      pricing: {
        price: this.state.price,
        type: this.state.type,
        humanType: this.state.humanType
      },
      tickets: {
        current: this.state.current,
        available: this.state.available
      }
    };

    this.props.tier.saveNewData(data);
    this.props.tier.toggleSelect(false);
  }

  public render() {
    let tier = this.props.tier;

    let options = Object.entries(tier.pricing.keys()).map((item) => {
      let key = item[0];
      let value = item[1];
      return <option value={key}>{value}</option>;
    });

    return (
      <OutsideClickHandler onOutsideClick={() => this.props.tier.toggleSelect(false)}>
        <div className={styles.form}>
          <div onClick={() => this.props.tier.toggleSelect(false)} className={styles.close}>
            <Icon color='primary2' type='close' />
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputContainer}>
              <div className={styles.labelContainer}>
                <label className={styles.label}>Name</label>
              </div>
              <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value})} className={styles.input}></input>
            </div>
            <div className={styles.inputRowSmall}>
              <p className={styles.rowLabel}>price</p>
              <div>
                <SettingsContext.Consumer>
                  {settings => (
                    <div className={styles.inputContainerSmall}>
                      <CurrencyInputFormat
                        placeholder='1000'
                        onValueChange={(value) => this.setState({ price: value.floatValue })}
                        value={this.state.price}
                        currency={settings.currency} />
                      {/* <input placeholder='$350' value={this.state.price} onChange={(e) => this.setState({ price: e.target.value })} className={styles.input}></input> */}
                    </div>
                  )}
                </SettingsContext.Consumer>
                <p className={styles.splitter}>Of</p>
                <div className={styles.inputContainerSmall}>
                  <select value={this.state.type} onChange={(e: any) => this.setState({ type: e.target.value })} className={styles.input}>
                    {options}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputContainer}>
              <div className={styles.labelContainer}>
                <label className={styles.label}>Description</label>
              </div>
              <TextareaAutosize placeholder='Description of the tier' value={this.state.description} onChange={(e) => this.setState({ description: e.currentTarget.value })} className={styles.teaxtarea}></TextareaAutosize>
            </div>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputRowSmall}>
              <p className={styles.rowLabel}>Tickets availability</p>
              <div>
                <div className={styles.inputContainerSmall}>
                  <input placeholder='4' type='number' value={this.state.current} onChange={(e) => this.setState({ current: parseInt(e.target.value) })}  className={styles.input}></input>
                </div>
                <p className={styles.splitter}>Of</p>
                <div className={styles.inputContainerSmall}>
                  <input placeholder='12' type='number' value={this.state.available} onChange={(e) => this.setState({ available: parseInt(e.target.value) })} className={styles.input}></input>
                </div>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.labelContainer}>
                <label className={styles.label}>BADGE</label>
              </div>
              <input placeholder='Best value' value={this.state.badge} onChange={(e) => this.setState({ badge: e.target.value})}  className={styles.input}></input>
            </div>
          </div>
          <div onClick={() => this.save()} className={styles.save}>Save</div>
        </div>
      </OutsideClickHandler>
    );
  }
}
