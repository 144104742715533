import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './file.module.sass';
import cn from 'classnames';
import File, { IFile } from '@app/util/elements/FileUpload';
import Icon from '@components/common/Icon';

interface IContentProps {
  file: File;
}

let Content: React.FunctionComponent<IContentProps> = (props) => {
  const [hover, toggleHover] = React.useState(false);


  if (!props.file.uploaded) {
    return (
      <div>
        <div className={styles.content}>
          <Icon iconStyle={{ fontSize: '28px', marginBottom: '7px' }} type='image' />
          <p className={styles.text}>Click to <span>upload</span> file</p>
        </div>
      </div>
    )
  }

  let mimetype = props.file.mimetype;

  let preview = hover ? 
    null :
    <div>
      <Icon inheritStyle type='file' />
    </div>

  if (!mimetype) {
    preview = (
      <img className={cn(styles.image, styles.imageNoOpacity)} src={props.file.fileURL} />
    )
  }

  if (mimetype && mimetype.includes('video')) {
    preview = (
      <video src={props.file.fileURL} className={cn(styles.image, styles.imageNoOpacity)} />
    )
  }

  if (mimetype && mimetype.includes('image')) {
    preview = (
      <img className={cn(styles.image, styles.imageNoOpacity)} src={props.file.fileURL} />
    )
  }

  return (
    <div className={styles.content} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
      {preview}
      {hover &&
        <div className={styles.opacity}>
          <div className={styles.content} >
            <Icon type='image' />
            <p className={styles.uploadChangeText}>Change...</p>
          </div>
          <p className={styles.fileName}>{props.file.name}</p>
        <div
          onClick={(e) => { e.stopPropagation(); props.file.removeFile() }}
          className={styles.close}>
          <Icon type='windowClose' />
        </div>
        </div>
      }
    </div>
  );
}



Content = observer(Content);
export default Content;