
import * as React from 'react';
import { observer } from 'mobx-react';
import Proposal from '@models/builder/proposal';
import Icon from '@components/common/Icon';
import Button from '@components/common/Button';
import * as styles from './nav.module.sass';
import Builder from '@stores/ui/builder';
import { RouteComponentProps } from 'react-router';
import { withRouter, Link } from 'react-router-dom';
import Publish from './Publish';
import cn from 'classnames';
import { trackPreviewClick } from '@app/util/analytics';

export interface INavProps extends RouteComponentProps<any>, React.Props<any> {
  proposal: Proposal;
  builderUI: Builder;
}

interface State {}

@observer
class Nav extends React.Component<INavProps, State> {

  async jumpToPreview() {
    if (this.props.proposal.version_active) {
      await trackPreviewClick(this.props.proposal.id)
      window.open(this.props.proposal.publishedLink, '_blank')
    }
  }

  public render() {
    return (
      <div className={styles.nav}>
        <Link to={`/proposals/proposal/${this.props.proposal.id}`} className={cn(styles.navItem, styles.goBack)}>
          <Icon inheritStyle type='angle-solid' rotate={180} />
        </Link>
        <div className={styles.navItemFull}>
          <p>{this.props.proposal.name}</p>
        </div>
        <div className={styles.publish}>

          <div onClick={this.jumpToPreview.bind(this)} className={styles.link}>
            <Icon type='link' inheritStyle />
            <p>{this.props.proposal.version_active ? this.props.proposal.publishedLink :  'Publish proposal to get URL'}</p>
          </div>

          <Publish proposal={this.props.proposal} />
        </div>
      </div>
    );
  }
}

export default withRouter(Nav);