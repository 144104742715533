import * as shortid from 'shortid';
import { observable, action, computed, when } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, getDefaultModelSchema, Context, custom, SKIP } from 'serializr';
import { customFieldSerializer, socialPickerSerializer, elementInstanceSerializer } from '@util/serializer';
import Field from '@util/elements/Field';
import File, { IFile } from '@util/elements/FileUpload';
import SocialMediaPicker, { ISocialMediaHandle } from '@models/builder/common/SocialMediaPicker';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { Omit } from '@util/types';

// releated interfacess

// model interfaces
export interface ISpeakerConfig extends IBaseConfiguration {
  social_networks: {
    name: string;
    example_link: string;
  }[]
}

export interface ISpeakerInstance extends IBaseInstanceElement {
  description: string | Field<string>;
  name: string | Field<string>;
  website: string;
  company: string | Field<string>;
  position: string | Field<string>;
  socialMediaLinks: ISocialMediaHandle[];
  file: IFile;
}

interface ISpeakerInstanceApp extends Omit<ISpeakerInstance, 'socialMediaLinks' | 'website'> {
  socialMediaLinks: SocialMediaPicker;
  website: Field<string>;
}

export interface ISpeaker extends IElement<ISpeakerConfig, ISpeakerInstance> { }

// model classes

export class SpeakerInstance implements ISpeakerInstanceApp {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable(customFieldSerializer<string>())
  description = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  name = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  website = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  company = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  position = new Field('');

  @observable
  @serializable(object(File))
  file = new File();

  @serializable(socialPickerSerializer())
  socialMediaLinks: SocialMediaPicker;

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.name.value
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get empty() {
    return !!!this.name.value;
  }

  parent: Speaker;
  constructor(parent: Speaker) {
    this.parent = parent;

    when(
      () => !!this.parent.configuration,
      () => this.init()
    )
  }

  init() {
    if (this.parent.configuration.social_networks[0]) {
      this.socialMediaLinks = new SocialMediaPicker(this.parent.configuration.social_networks[0].name);
    }
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(SpeakerInstance).factory = (context: Context) => {
      return new SpeakerInstance(context.args.parent);
    };
  }
}

export default class Speaker extends BaseElement<ISpeaker, ISpeakerConfig, SpeakerInstance> {

  @observable
  @serializable(elementInstanceSerializer(SpeakerInstance))
  readonly instances = observable<SpeakerInstance>([]);

  @action.bound
  addInstance() {
    this.instances.push(new SpeakerInstance(this));
  }

  @action.bound
  deleteInstance(instance: SpeakerInstance) {
    this.instances.remove(instance);
  }

  static getInstanceClass() {
    return SpeakerInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new SpeakerInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(Speaker).factory = (context: Context) => {
      return new Speaker(context.args.root, context.args.proposal);
    };
  }
}

SpeakerInstance.schema()
Speaker.schema()