import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Label from '../Label';
import * as styles from './input.module.sass';
import ElementContent from '../ElementContent';


interface ITextInputProps {
  defaultValue?: string;
  onChange: (value: string) => void;
  value: string | null;
  placeholder?: string;
  help_text?: string;
  label?: string;
  premium?: boolean;
  labelOpacity?: number;
}

let TextInput: React.FunctionComponent<ITextInputProps> = (props) => {
  return (
    <div>
      {props.label &&
        <Label labelOpacity={props.labelOpacity} helpText={props.help_text} label={props.label} premiumLabel={props.premium} />
      }
      <ElementContent premium={props.premium}>
        <input
          className={styles.input}
          onChange={(e) => props.onChange(e.target.value)}
          placeholder={props.placeholder}
          value={props.value === null ? props.defaultValue : props.value}
          type='text'>
        </input>
      </ElementContent>
    </div>
  )
}

TextInput = observer(TextInput);
export default TextInput;