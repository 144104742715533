import * as React from 'react'
import { observer } from 'mobx-react';
import * as styles from './input.module.sass';
import { FieldState } from 'formstate';
import classNames from 'classnames';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import BlockLabel from '@components/builder/common/BlockLabel';
import Icon, { IconTypes } from '@components/common/Icon';
import Currency, { CurrencyKey } from '@common/util/currencies';

interface IInputProps {
  error?: string | undefined;
  placeholder?: string;
  onChange: (val: string) => void;
  value: string | number | undefined;
  label?: string;
  styles?: React.CSSProperties;
  className?: string;
  icon?: IconTypes;
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
interface ICurrencyInputFormat extends Omit<IInputProps, 'onChange'> {
  onValueChange: (val: NumberFormatValues) => void;
  currency: CurrencyKey;

}

interface IFormattedInput extends Omit<IInputProps, 'onChange'> {
  onValueChange: (val: NumberFormatValues) => void;
  isAllowed?: (val: NumberFormatValues) => boolean;
  prefix?: string;
  suffix?: string;
}

const Input: React.FunctionComponent<IInputProps> = (props) => {
  let inputClassName = classNames(styles.input, props.className, { [styles.inputPadding]: !!props.icon })

  return (
    <div className={styles.container}>
      <div className={styles.labelContainer}>
        {props.label ? 
          <div className={styles.labelBlock}>
            <BlockLabel text={props.label} />
          </div>
        : null}
        <input style={props.styles} value={props.value} onChange={(e) => props.onChange(e.target.value)} placeholder={props.placeholder} className={inputClassName} />
        {props.icon ?
          <Icon className={styles.icon} type={props.icon} />
          : null}
      </div>
      {props.error ? <p className={styles.errorText}>{props.error}</p> : null}
    </div>
  );
}

const CurrencyInputFormatComponent: React.FunctionComponent<ICurrencyInputFormat> = (props) => {
  let inputClassName = classNames(styles.input, props.className, { [styles.inputPadding]: !!props.icon })

  let currency = Currency[props.currency];

  return (
    <div className={styles.container}>
      <NumberFormat
        style={props.styles}
        thousandSeparator=' '
        {...currency}
        value={props.value}
        onValueChange={(values) => props.onValueChange(values)}
        placeholder={props.placeholder}
        className={inputClassName}
      />
      {props.icon ?
        <Icon className={styles.icon} type={props.icon} />
        : null}
      {props.error ? <p className={styles.errorText}>{props.error}</p> : null}
    </div>
  );
}

const FormattedInputComponent: React.FunctionComponent<IFormattedInput> = (props) => {
  let inputClassName = classNames(styles.input, props.className, { [styles.inputPadding]: !!props.icon })
  return (
    <div className={styles.container}>
      <NumberFormat
        style={props.styles}
        value={props.value}
        suffix={props.suffix}
        prefix={props.prefix}
        onValueChange={(values) => props.onValueChange(values)}
        placeholder={props.placeholder}
        className={inputClassName}
        isAllowed={props.isAllowed}
      />
      {props.icon ? 
        <Icon className={styles.icon} type={props.icon} />
      : null}
      {props.error ? <p className={styles.errorText}>{props.error}</p> : null}
    </div>
  );
}

interface IInputStateProps {
  field: FieldState<string>;
  placeholder: string;
  icon?: IconTypes;
}

const StateInputComponent: React.FunctionComponent<IInputStateProps> = (props) => {
  let fieldClass = classNames(styles.input, { [styles.error]: props.field.hasError }, { [styles.inputPadding]: !!props.icon })
  return (
    <div className={styles.container}>
      <input
        onChange={(e) => props.field.onChange(e.target.value)}
        placeholder={props.placeholder}
        className={fieldClass}
        value={props.field.value}
      />
      {props.icon ?
        <Icon className={styles.icon} type={props.icon} />
        : null}
      {props.field.error ? <p className={styles.errorText}>{props.field.error}</p> : null}
    </div>
  );
}

export const StateInput = observer(StateInputComponent);
export const CurrencyInputFormat = observer(CurrencyInputFormatComponent)
export const FormattedInput = observer(FormattedInputComponent);
export default observer(Input);