
import * as React from 'react';
import { observer } from 'mobx-react';
import ElementHeadline from '@components/builder/common/ElementHeadline';
import Model from '@app/models/builder/elements/audienceGroup';
import Instance from './Instance';
import AddInstance from '@components/builder/common/AddInstance';
import * as styles from './spillter.module.sass';

export interface IAudienceGroupProps {
  model: Model
}

interface State {}

@observer
export default class AudienceGroup extends React.Component<IAudienceGroupProps, State> {
  public render() {
    let configuration = this.props.model.configuration;

    let content = this.props.model.instances.map((item) => {
      return <Instance key={item.id} configuration={this.props.model.configuration} instance={item} />
    })


    if (this.props.model.canAddInstance) {
      content.push(<AddInstance key='add-new' addInstance={this.props.model.addInstance} text='+ Add new target audience' />)
    }

    return (
      <div>
        <ElementHeadline complete={this.props.model.complete} text={this.props.model.name} markdown={configuration.tooltip} />
        <div className={styles.instanceContainer}>
          {content}
        </div>
      </div>
    );
  }
}
