import * as React from 'react';
import * as style from './attachment.module.sass';
import { observer } from 'mobx-react';
import PackageItemModel from '@models/builder/elements/packages/packageItem';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactFilestack from 'filestack-react';
import Icon from '@components/common/Icon';
import File, { IFile } from '@util/elements/FileUpload';

export interface AttachmentProps {
  packageItem: PackageItemModel;
}

interface State {
  file: IFile;
  note: string | undefined;
  openFileStack: boolean;
}

@observer
export default class Attachment extends React.Component<AttachmentProps, State> {
  constructor(props: AttachmentProps) {
    super(props);

    this.state = {
      file: this.props.packageItem.file,
      note: this.props.packageItem.attachmentNote,
      openFileStack: false
    };

    this.save = this.save.bind(this);
  }

  open(e: any, onPick: Function) {
    this.setState({
      openFileStack: true
    });
    onPick(e);
  }

  success(data: any) {
    this.setState({
      openFileStack: false,
      file: {
        name: data.filesUploaded[0].filename,
        fileURL: data.filesUploaded[0].url,
        mimetype: data.filesUploaded[0].mimetype,
        handle: data.filesUploaded[0].handle,
        size: data.filesUploaded[0].size,
      }
    });
  }

  error(error: any) {
    this.setState({ openFileStack: false });
  }

  save() {
    this.props.packageItem.attachmentSave(this.state.file, this.state.note);
    this.props.packageItem.toogleAttachment(false);
  }

  public render() {
    let options = {
      onClose: () => this.setState({ openFileStack: false })
    };

    let isImage = !!this.state.file.mimetype ? this.state.file.mimetype.includes('image') : false;

    let currentFile: JSX.Element | null = null;

    if (this.state.file.name) {
      currentFile = <p className={style.currentFile}>Current file: {this.state.file.name}</p>;
    }

    if (isImage) {
      currentFile = <img className={style.image} src={this.state.file.fileURL} />;
    }

    return (
      <div className={style.attachments}>
        <p className={style.headline}>Benefit attachement</p>
        <div className={style.content}>
          <div className={style.fileContainer}>
            <ReactFilestack
              apikey={'AneRIP5PPRfSpXpVYzkOMz'}
              onSuccess={(data: any) => this.success(data)}
              onError={(data: any) => this.error(data)}
              options={options}
              render={( bind: any ) => (
                <div onClick={(e) => this.open(e, bind.onPick)} className={style.filePicker}>
                  <div className={style.filePickerContent}>
                    {currentFile}
                    <p className={style.filePickerHeadline}>Select files from your computer</p>
                    <p className={style.filePickerDescription}>or simply <b>Drag & Drop</b> it here</p>
                  </div>
                </div>
              )}
            />
          </div>
          <div className={style.text}>
            <div>
              <p className={style.label}>Note</p>
              <textarea value={this.state.note} onChange={(e) => this.setState({ note: e.currentTarget.value })} className={style.textarea}></textarea>
            </div>
            <div onClick={this.save} className={style.saveButton}>Save</div>
          </div>
        </div>
      </div>
    );
  }
}
