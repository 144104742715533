
import * as React from 'react';
import { observer } from 'mobx-react';
import { IPlace } from '@models/builder/elements/venue';
import * as styles from './places.module.sass';
import BlockLabel from '@components/builder/common/BlockLabel';
import Icon from '@components/common/Icon';

export interface IPlacesAutocompleteProps {
  place: IPlace | undefined;
  onChange: (place: IPlace) => void;
  locationBias?: {
    lat: number;
    lng: number;
  } | undefined;
}

interface State {}

@observer
export default class PlacesAutocomplete extends React.Component<IPlacesAutocompleteProps, State> {
  autocomplete: google.maps.places.Autocomplete | undefined;

  constructor(props: IPlacesAutocompleteProps) {
    super(props);
    
  }

  inputReference(input: HTMLInputElement | null) {
    if (!input) {
      return;
    }

    if (this.props.place) {
      input.value = this.props.place.formattedAddress;
    }

    this.autocomplete = new google.maps.places.Autocomplete(input);
    (this.autocomplete as any).setFields(['address_components', 'place_id', 'types', 'formatted_address' ,'geometry', 'name'])
    let autocomplete = this.autocomplete;

    let self = this;
    autocomplete.addListener('place_changed', function() {
      let place = autocomplete.getPlace();

      let placeData: IPlace = {
        name: place.name,
        place_id: place.place_id,
        types: place.types,
        formattedAddress: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        addressComponents: place.address_components
      }

      self.props.onChange(placeData);
    })
  }

  componentWillReceiveProps(nextProps: IPlacesAutocompleteProps) {
    if (nextProps.locationBias && this.autocomplete) {

      let circle = new google.maps.Circle({
        center: nextProps.locationBias,
        radius: 100
      });

      this.autocomplete.setBounds(circle.getBounds());
    }
  }

  public render() {
    return (
      <div>
        <BlockLabel text='Venue name or address' />
        <div className={styles.inputContainer}>
          <input placeholder='K10 coworking, Prague' className={styles.input} type='text' ref={(ref) => this.inputReference(ref)} id={name} />
          <Icon className={styles.icon} type='search' />
        </div>
      </div>
    );
  }
}
