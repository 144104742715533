import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Label from '../common/Label';
import { Checkbox as CheckboxModel } from '@models/builder/customization/elements';
import CheckboxInput from '../common/Checkbox'
import ElementContent from '../common/ElementContent';

interface ICheckboxProps {
  element: CheckboxModel
}

let Checkbox: React.FunctionComponent<ICheckboxProps> = (props) => {
  return (
    <div>
      <Label helpText={props.element.help_text} label={props.element.label} premiumLabel={props.element.internal_name === 'hide-watermark'} />
      <ElementContent premium={props.element.internal_name === 'hide-watermark'}>
        <div>
          <CheckboxInput
            name={props.element.internal_name}
            checked={props.element.checked}
            toggleCheck={() => props.element.toggleCheckbox(!props.element.checked)}
            text={props.element.text}
          />
        </div>
      </ElementContent>
    </div>
  )
}

Checkbox = observer(Checkbox);
export default Checkbox;