import * as shortid from 'shortid';
import { observable, action, toJS, reaction, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, custom, getDefaultModelSchema, Context, SKIP } from 'serializr';
import { FieldState } from 'formstate';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

export interface IDropdownInstance extends IBaseInstanceElement {
  customSelected: boolean;
  selectedOption: string | null;
}

export interface IDropdownConfig extends IBaseConfiguration {
  customPlaceholder: string;
  options: string[];
  customValueAllowed: boolean;
}

export interface IDropdown extends IElement<IDropdownConfig, IDropdownInstance> {}
const required = (val: string) => !val && 'This field can’t stay empty.';

export class DropdownInstance implements IDropdownInstance {

  @observable
  @serializable
  id = shortid.generate()

  customOption = 'Custom'

  @observable
  @serializable
  customSelected = false

  @observable
  @serializable(custom((item: any) => toJS(item), (item: any) => item))
  selectedOption: string| null = null;

  @serializable(custom((item: FieldState<string>) => item.value, (item) => new FieldState(item).validators(required)))
  customValue = new FieldState('').validators(required);

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    if (this.customSelected) {
      return !!this.customValue.value && this.customValue.value.length > 0
    } else {
      return !!this.selectedOption
    }
  }

  @action
  changeOption(option: string) {
    this.selectedOption = option;

    if (this.customOption === option) {
      this.customSelected = true;
    } else {
      this.customSelected = false;
    }
  }
}

export default class Dropdown extends BaseElement<IDropdown, IDropdownConfig, DropdownInstance> {

  @observable
  @serializable(elementInstanceSerializer(DropdownInstance))
  readonly instances = observable<DropdownInstance>([]);

  static getInstanceClass() {
    return DropdownInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new DropdownInstance())
    }
  }

  static schema() {
    return getDefaultModelSchema(Dropdown).factory = (context: Context) => {
      return new Dropdown(context.args.root, context.args.proposal);
    };
  }
}

Dropdown.schema();