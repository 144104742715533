import * as shortid from 'shortid';
import { observable, action, toJS, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, custom, SKIP } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

// releated interfacess

// model interfaces

interface Proportion {
  name: string;
  color: string;
}

export interface IProportionSliderConfig extends IBaseConfiguration {
  proportion1: Proportion;
  proportion2: Proportion;
}

export interface IProportionSliderInstance extends IBaseInstanceElement  {
  proportion1Value: number;
  proportion2Value: number;
}

export interface IProportionSlider extends IElement<IProportionSliderConfig, IProportionSliderInstance> {}

// model classes

export class ProportionSliderInstance implements IProportionSliderInstance {
  @observable
  @serializable
  id = shortid.generate()

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  filled = true;

  @observable
  @serializable
  proportion1Value = 50;

  @observable
  @serializable
  proportion2Value = 50;

  parent: ProportionSlider;

  @action.bound
  changeValue(val: number) {
    let max = 100;

    this.proportion1Value = val;
    this.proportion2Value = max - this.proportion1Value;
  }

  @computed get sliderValue() {
    return this.proportion1Value;
  }

  constructor(parent: ProportionSlider) {
    this.parent = parent;
  }

  static schema() {
    return getDefaultModelSchema(ProportionSliderInstance).factory = (context: Context) => {
      return new ProportionSliderInstance(context.args.parent);
    };
  }
}

export default class ProportionSlider extends BaseElement<IProportionSlider, IProportionSliderConfig, ProportionSliderInstance> {

  @observable
  @serializable(elementInstanceSerializer(ProportionSliderInstance))
  readonly instances = observable<ProportionSliderInstance>([]);

  static getInstanceClass() {
    return ProportionSliderInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new ProportionSliderInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(ProportionSlider).factory = (context: Context) => {
      return new ProportionSlider(context.args.root, context.args.proposal);
    };
  }
}

ProportionSlider.schema();
ProportionSliderInstance.schema();