import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './account.module.sass';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { withRouterApp } from '@app/util/router';
import Icon from '@components/common/Icon';


interface IAccountProps {
}

let Account: React.FunctionComponent<IAccountProps & RouteComponentProps<any>> = (props) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.headline}>Account & Settings</h2>
      <div>
        <NavLink activeClassName={styles.itemActive} className={styles.item} to={'/account/general'}>
          <p>General Settings</p>
          <Icon inheritStyle type='caret' />
        </NavLink>
        <NavLink activeClassName={styles.itemActive} className={styles.item} to={'/account/billing'}>
          <p>Billing Settings</p>
          <Icon inheritStyle type='caret' />
        </NavLink>
      </div>
    </div>
  )
}

Account = withRouterApp<IAccountProps>(observer(Account));

export default Account;