import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Icon, { IconTypes } from '@app/components/common/Icon';
import * as styles from './button.module.sass';
import cn from 'classnames';
import Modal from '@app/components/common/Modal';
import OutsideClickHandler from 'react-outside-click-handler';
import Tooltip from 'rc-tooltip';
import Tippy from '@tippy.js/react';

interface IButtonProps {
  text: string;
  icon?: IconTypes;
  onClick: () => void;
  danger?: boolean;
  save?: boolean;
  confirmText?: string | React.ReactChild;
  inlineBlock?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const Button: React.FunctionComponent<IButtonProps> = (props) => {
  const [modalOpen, toggleModal] = React.useState(false);


  function click(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();

    if (props.disabled) {
      return;
    }

    if (props.confirmText && !modalOpen) {
      toggleModal(true)
    } else {
      props.onClick();
      toggleModal(false);
    }
  }

  function outsideClick() {
    toggleModal(false);
  }

  return (
      <div>
        <Modal onRequestClose={() => { outsideClick() }} maxWidth={450} isOpen={modalOpen}>
          <div>
            {React.isValidElement(props.confirmText) ?
              props.confirmText
              :
              <p style={{ marginTop: '20px', textAlign: 'center' }}>{props.confirmText}</p>
            }
            <div onClick={(e) => click(e)} className={cn(styles.button, styles.modalButton)}>
              <Icon inheritStyle type='checkmark-fontawesome'></Icon>
              <p>YES</p>
            </div>
          </div>
        </Modal>
        <Tippy
          content={props.tooltip || ''}
          trigger='mouseenter'
          theme='honeybee'
          animateFill={false}
          placement='top'
          animation='shift-toward'
          inertia={true}
          isEnabled={!!props.tooltip}
        >
          <div
            onClick={(e) => click(e)}
            className={cn(styles.button, { [styles.danger]: props.danger, [styles.inline]: props.inlineBlock, [styles.save]: props.save, [styles.disabled]: props.disabled })}
          >
            {props.icon && <Icon inheritStyle type={props.icon} />}
            <p>{props.text}</p>
          </div>
        </Tippy>
      </div>
  )
}

export default observer(Button);