import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { rootContext } from '@app/index';
import * as styles from './billing.module.sass';
import servicebotId from "@util/servicebot";
import {debounce} from "debounce";
import {toast} from "react-toastify";
import Button from '@app/components/common/Button';

function loadServicebot() {
  // @ts-ignore
  console.log('Billflow load'); var s = document.createElement('script'); s.src = 'https://js.billflow.io/billflow-embed.js'; s.async = true; s.type = 'text/javascript'; var x = document.getElementsByTagName('script')[0]; // @ts-ignore
  // @ts-ignore

  x.parentNode.insertBefore(s, x);
}

const debounceLoadServicebot = debounce(loadServicebot, 3000, true)


interface IBillingProps { }

const Billing: React.FunctionComponent<IBillingProps> = (props) => {
  const rootStore = React.useContext(rootContext);
  const user = rootStore.user;
  // const debouncedGetUser = debounce(user.getUser, 600)

  // React.useEffect(() => {

  //   (window as any).billflowSettings = {
  //     "billing_page_id": "MbLHZIxLVTUtALs5lRlg",
  //     email: user.email,
  //     hash: user.servicebot_hash,
  //     "handleResponse": async function(payload: any){
  //       console.log("Event ", payload.event);
  //       console.log("Response Object ", payload.response);
  //       setTimeout(() => {
  //         debouncedGetUser()
  //       }, 700)


  //       if (payload.event == "create_subscription") {
  //         setTimeout(() => {
  //           debouncedGetUser().then(() => {
  //             rootStore.router.push('/proposals')
  //             toast.success('🎉 Awesome, your plan was changed', {
  //               autoClose: 9000
  //             });
  //           })
  //         }, 3500)
  //       }
  //     },
  //   };

  //   let scripts = document.querySelectorAll('.service-bot-scripts')

  //   scripts.forEach(item => {
  //     document.head.removeChild(item);
  //   });

  //   debounceLoadServicebot();
  // }, [])

  return (
    <div className={styles.content}>
        <h2 className={styles.headline}>Billing settings</h2>
        <Button onClick={() => {
          window.open(`https://billing.stripe.com/p/login/4gw6oB9TF2Rk3kI7ss?prefilled_email=${encodeURIComponent(user.email)}`, '_blank')
        }} rotateIcon={0} text='Edit your billing informations' type='primary' icon='pen-solid' />
    </div>
  )
}


export default observer(Billing);
