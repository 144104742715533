import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { ColorPicker as ColorPickerModel } from '@models/builder/customization/elements';
import ColorPickerElement from '../common/ColorPicker';

interface IColorPickerProps {
  element: ColorPickerModel;
}

let ColorPicker: React.FunctionComponent<IColorPickerProps> = (props) => {
  return (
    <ColorPickerElement
      label={props.element.label}
      isMultiple={true}
      help_text={props.element.help_text}
      colors={props.element.colors}
      color_presets={props.element.color_presets}
      changeColor={props.element.changeColor}
      activatePreset={props.element.activatePreset}
      premium
    />
  )
}

export default observer(ColorPicker);