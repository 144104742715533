
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@app/models/builder/elements/team';
import FileUpload from '@components/builder/common/FileUpload';
import Input from '@components/builder/common/Input';
import * as styles from './team.module.sass';
import Textarea from '@components/builder/common/Textarea';
import Icon from '@components/common/Icon';
import SocialMediaPicker from '@components/builder/common/SocialMediaPicker';

export interface IInstanceProps {
  instance: Model.TeamInstance,
  configuration: Model.ITeamConfig
}

interface State {}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {
    let instance = this.props.instance;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerItem}>
            <FileUpload file={this.props.instance.file} />
          </div>
          <div className={styles.headerItem}>
            <div className={styles.nameInputWrap}>
              <Input icon='idCard' placeholder='Joana Doe' label='Team member name' value={instance.name.value} onChange={instance.name.onChange}></Input>
              <Icon onClick={instance.delete} type='trashcan' />
            </div>
            <div className={styles.rowInputs}>
              <Input icon='building' placeholder='Sponseasy' label='Company' value={instance.company.value} onChange={instance.company.onChange} />
              <Input icon='position' placeholder='CMO' label='Position' value={instance.position.value} onChange={instance.position.onChange} />
            </div>
            <div className={styles.rowInputs}>
              <Input icon='phone' placeholder='+23 941 012 001' label='Phone' value={instance.phone.value} onChange={instance.phone.onChange} />
              <Input icon='email' placeholder='joseph.tanedy@team.com' label='Email' value={instance.email.value} onChange={instance.email.onChange} />
            </div>
            <SocialMediaPicker networks={this.props.configuration.social_networks} picker={this.props.instance.socialMediaLinks} label='Social media' />
          </div>
        </div>
        <div className={styles.description}>
          <Textarea
            value={this.props.instance.description.value}
            onChange={this.props.instance.description.onChange}
            label='Description of the team member'
            placeholder='Shortly describe why is the team member the best for what he do'
          />
        </div>
      </div>
    );
  }
}
