import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Label from '../common/Label';
import { Dropdown as DropdownModel } from '@models/builder/customization/elements';
import { DropdownBasic } from '@app/components/common/Dropdown';
import ElementContent from '../common/ElementContent';

interface IDropdownProps {
  element: DropdownModel
}

let Dropdown: React.FunctionComponent<IDropdownProps> = (props) => {
  return (
    <div>
      <Label helpText={props.element.help_text}  label={props.element.label} />
      <ElementContent>
        <div>
          <DropdownBasic
            options={props.element.options}
            value={props.element.value}
            placeholder='test'
            onChange={props.element.changeValue}
            theme='dark'
          />
        </div>
      </ElementContent>
    </div>
  )
}

Dropdown = observer(Dropdown);

export default Dropdown;