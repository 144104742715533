
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/headlineParagraph';
import RichTextEditor from 'react-rte';
import * as styles from './editor.module.sass';

export interface IInstanceProps {
  instance: Model.HeadlineParagraphInstance,
  configuration: Model.IHeadlineParagraphConfig
}

interface State {
  editorValue: any;
}

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC'},
    { label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ],
};


@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  constructor(props: IInstanceProps) {
    super(props);

    this.state = {
      editorValue: RichTextEditor.createValueFromString(this.props.instance.markdown, 'markdown')
    }

    this.onUpdate = this.onUpdate.bind(this)
  }

  onUpdate(value: any) {
    this.setState({ editorValue: value }, () => {
      let markdown = this.state.editorValue.toString('markdown')
      this.props.instance.updateEditor(markdown);
    })
  }

  public render() {
    return (
      <div className={styles.wrapper}>
        <RichTextEditor
          className={styles.root}
          toolbarClassName={styles.toolbar}
          onChange={this.onUpdate}
          value={this.state.editorValue}
          toolbarConfig={toolbarConfig}
          editorClassName={styles.editor}
          placeholder='Here you can describe to the detail what you want'
        />
        <div className={styles.inputContainer}>
          <input
            onChange={(e) => this.props.instance.updateHeadline(e.target.value)}
            value={this.props.instance.headline}
            placeholder='Type headline of the section'
            className={styles.input}
            type='text' />
          <div className={styles.line}></div>
        </div>
        <p
          style={{ color: this.props.instance.remainingColor!.color }}
          className={styles.characters}>{this.props.instance.remainingColor!.text.replace('{}', this.props.instance.charactersRemaining.toString())}</p>
      </div>
    );
  }
}
