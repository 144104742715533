
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@app/models/builder/elements/sponsor';
import FileUpload from '@components/builder/common/FileUpload';
import Input from '@components/builder/common/Input';
import * as styles from './entity.module.sass';
import Textarea from '@components/builder/common/Textarea';
import { DropdownCreatable } from '@components/common/Dropdown';
import Icon from '@components/common/Icon';

export interface IInstanceProps {
  instance: Model.SponsorInstance,
  configuration: Model.ISponsorConfig
}

interface State {}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {
    let instance = this.props.instance;
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerItem}>
            <FileUpload file={this.props.instance.file} />
          </div>
          <div className={styles.headerItem}>
            <div className={styles.nameInputWrap}>
              <Input icon='idCard' placeholder='Joana Doe' label='Sponsor’s name' value={instance.name.value} onChange={instance.name.onChange}></Input>
              <Icon onClick={instance.delete} type='trashcan' />
            </div>
            <DropdownCreatable
              value={instance.sponsorType.option}
              onChange={instance.sponsorType.select}
              options={this.props.configuration.sponsor_types}
              label='Sponsor Type'
              placeholder='Write or select...'
              />
            <Input icon='link' placeholder='https://website.com' label='website' value={instance.website.value} onChange={instance.website.onChange}></Input>
          </div>
        </div>
        <div className={styles.description}>
          <Textarea
            value={this.props.instance.description.value}
            onChange={this.props.instance.description.onChange}
            label='Description of the team member'
            placeholder='Shortly describe why is the team member the best for what he do'
          />
        </div>
      </div>
    );
  }
}
