
import * as React from 'react';
import { observer } from 'mobx-react';
import Icon, { IconTypes } from '@components/common/Icon';
import cn from 'classnames';
import * as styles from './button.module.sass';

export interface IButtonProps {
  onClick: () => void;
  text: string;
  icon?: IconTypes;
  type: 'primary' | 'premium' | 'control' | 'light',
  state?: 'active' | 'disabled' | null,
  rotateIcon?: number;
}

interface State {}

@observer
export default class Button extends React.Component<IButtonProps, State> {
  
  constructor(props: IButtonProps) {
    super(props);
    
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick()
  }

  public render() {

    let className = cn(styles.button, 
      { [styles.buttonPrimary]: this.props.type === 'primary' },
      { [styles.buttonPremium]: this.props.type === 'premium' },
      { [styles.buttonControl]: this.props.type === 'control' },
      { [styles.buttonLight]: this.props.type === 'light' },
    )

    className = cn(className,
      { [styles.active]: this.props.state === 'active' }
    );

    return (
      <div onClick={this.onClick} className={className}>
        <div className={styles.content}>
          <p>{this.props.text}</p>
          {this.props.icon ?
            <Icon inheritStyle rotate={this.props.rotateIcon} className={styles.icon} type={this.props.icon} />
            : null}
        </div>
      </div>
    );
  }
}
