import classnames from 'classnames';
import * as React from 'react';
import Tippy from '@tippy.js/react';
import * as style from './button.module.sass';

import Icon, { IconTypes } from '@components/common/Icon';

export interface ButtonProps {
    text: string;
    type: 'primary' | 'secondary' | 'confirm' | 'empty-primary' | 'app-action';
    icon?: IconTypes;
    iconWidth?: number;
    iconRotate?: number;
    size?: 'big';
    hideIcon?: boolean;
    onClick?: () => void;
    minWidth?: number;
    tooltip?: string;
    grayed?: boolean;
}

interface State {
  hover: boolean;
}

export default class Button extends React.Component<ButtonProps, State> {
  constructor(props: ButtonProps) {
    super(props);

    this.state = {
      hover: false
    };
  }

  public render() {

    let buttonClass = style.button;
    let color = 'blue';
    let showIcon = true;
    let iconPadding = '8px';

    let styleContainer = {
      height: '35px',
      fontSize: '12px',
      paddingLeft: '12px',
      paddingRight: '12px',
      minWidth: 'auto',
      opacity: 1
    };

    if (this.props.type === 'secondary') {
      buttonClass = classnames(buttonClass, style.secondary);
      color = 'blue';
    } else if (this.props.type === 'primary') {
      buttonClass = classnames(buttonClass, style.greenButton);
      color = this.state.hover ? 'white' : 'green';
    } else if (this.props.type === 'confirm') {
      buttonClass = classnames(buttonClass, style.redButton);
      showIcon = false;
    } else if (this.props.type === 'empty-primary') {
      showIcon = false;
      buttonClass = classnames(buttonClass, style.emptyPrimary);
    } else if (this.props.type === 'app-action') {
      buttonClass = classnames(buttonClass, style.appAction);
      color = 'black';
    }

    if (this.props.size === 'big') {
      styleContainer.height = '50px';
      styleContainer.fontSize = '16px';
      styleContainer.paddingLeft = '17px';
      styleContainer.paddingRight = '17px';
      iconPadding = '12px';
    }

    if (this.props.minWidth) {
      styleContainer.minWidth = `${this.props.minWidth}px`;
    }

    if (this.props.grayed) {
      styleContainer.opacity = 0.4;
    }

    let button = (
      <div
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        style={styleContainer}
        onClick={this.props.onClick}
        className={buttonClass}>
        <p>{this.props.text}</p>
        {showIcon ?
          <div style={{ paddingLeft: iconPadding }} className={style.icon}>
            {this.props.hideIcon ?
              null : <Icon rotate={this.props.iconRotate} type={this.props.icon!} />
            }
          </div> : null
        }
      </div>
    );

    return (
      <div>
        {this.props.tooltip ?
          <Tippy
            content={this.props.tooltip}
            trigger='mouseenter'
            theme='honeybee'
            animateFill={false}
            animation='shift-toward'
            inertia={true}
          >
            {button}
          </Tippy>
          : button}
      </div>
    );
  }
}
