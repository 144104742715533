
import { observable, computed, toJS, reaction, action } from 'mobx';
import {
  Context,
  getDefaultModelSchema,
  serialize,
  serializable,
  custom
} from 'serializr';
import components from '.';
import debounce from 'debounce';

import RootStore from '@stores/RootStore';
import Proposal from '../proposal';
import Types from './index';
import { IPremiumLimitation } from '@app/util/premium';
import { trackElementFilledChange, trackAddedRemovedElementInstance } from '@util/analytics';

type types = keyof typeof Types

export interface IBaseConfiguration {
  min: number;
  max: number;
  tooltip: string;
}

export interface IBaseInstanceElement { 
  filled: boolean;
  id: string;
}

export interface IElement<T, D> {
  id: string;
  type: types;
  name: string;
  configuration: T;
  data: {
    instances?: D[]
  }
}

export default class BaseComponent<ComponentType, Config extends IBaseConfiguration, InstanceClass extends IBaseInstanceElement> {

  @observable
  @serializable
  type: keyof typeof components;

  @serializable
  @observable
  name: string;

  @serializable
  @observable
  id: string;

  @observable
  @serializable
  status: 'active' | 'not_active';

  @observable
  readonlyElement = false;

  @serializable(custom((item: any) => toJS(item), (item: any) => item))
  @observable
  configuration: Config;

  @observable
  readonly instances = observable<InstanceClass>([]);

  @computed get json() {
    let data: ComponentType = serialize(this);
    return data;
  }

  @computed get hasInstance() {
    return this.instances.length > 0;
  }

  @computed get numberOfInstances() {
    return this.instances.length;
  }

  @computed get canAddInstance() {
    return this.configuration.max > this.numberOfInstances;
  }

  @computed get complete() {
    return this.instances.every((item) => item.filled === true)
  }

  @computed get readonly() {
    return this.proposal.readonly || this.readonlyElement
  }

  root: RootStore;
  proposal: Proposal;
  limitation: IPremiumLimitation;

  constructor(root: RootStore, proposal: Proposal) {
    this.root = root;
    this.proposal = proposal;
    this.limitation = this.root.user.limitation

    const debouncedUpdate = debounce(this.updateElementRemote.bind(this), 1000)

    reaction(
      () => this.json,
      () => { !this.readonly ? debouncedUpdate() : null }
    )

    reaction(
      () => this.complete,
      () => {
        trackElementFilledChange(this.name, this.complete, this.proposal.id)
      }
    )

    reaction(
      () => this.instances.length,
      () => {
        trackAddedRemovedElementInstance(this.name, this.proposal.id, this.instances.length)
      }
    )

    reaction(
      () => this.root.user.limitation,
      (limitation) => this.limitation = limitation
    )
  }

  @action.bound
  updateElementRemote() {
    let json: any = this.json;
    let data = json.data;
    this.root.api.updateElement(this.id, data);
  }
}
