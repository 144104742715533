import * as React from 'react'
import { observer } from 'mobx-react';
import Select from 'react-select';
import dropdownStyle from './styles';
import * as styles from './dropdown.module.sass';
import BlockLabel from '@components/builder/common/BlockLabel';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';

interface Option {
  label: string;
  value: string;
}

interface IDropdownBasicProps {
  value: Option | undefined;
  label?: string;
  placeholder: string;
  loadOptions: (value: string) => Promise<Option>;
}

const DropdownAsync: React.FunctionComponent<IDropdownBasicProps> = (props) => {
  return (
    <div style={{ position: 'relative' }}>
      {props.label ?
        <div className={styles.labelBlock}>
          <BlockLabel text={props.label} />
        </div>
        : null}
      <AsyncCreatableSelect
        styles={dropdownStyle(false, false)}
        value={props.value}
        cacheOptions
        loadOptions={props.loadOptions}
        placeholder={props.placeholder}
        theme={base => ({
          ...base, colors: {
            ...base.colors,
            primary: '#0076ff'
          }
        })}
      />
    </div>
  )
}

export default observer(DropdownAsync);