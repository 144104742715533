import { observable, action, flow, computed, reaction, when } from "mobx";
import RootStore from "./RootStore";
import { toast } from "react-toastify";
import { indetifyUser } from "@app/util/analytics";
import moment from 'moment';
import plans, { IPremiumLimitation } from "@app/util/premium";

interface IExtraInfo {
  first_name: string;
  last_name: string;
  organization_category: string;
  position: string;
  usage_reason: string;
}

interface IUser {
  id: string;
  api_key: string;
  email: string;
  premium: boolean;
  organization: string;
  extra_info: IExtraInfo | null;
  servicebot_hash: string;
  subscription: {
    id: string;
    current_period_end: number;
    current_period_start: number;
    status: string;
    plan: {
      created: number;
      id: string;
      product: string;
      trial_period_days: number;
      interval: string;
      amount: number;
    }
  } | null;
  coupon: {
    amount_off: number | null;
    created: number;
    currency: string | null;
    id: string;
    name: string;
    percent_off: number | null;
    valid: boolean;
  } | null;
}

export default class UserStore {

  @observable
  userLoaded = false;

  @observable
  id: string | undefined;

  @observable
  email: string | undefined;

  @observable
  premium: boolean | undefined;

  @observable
  api_key: string | undefined;

  @observable
  organization: string | undefined;

  @observable
  servicebot_hash: string | undefined;

  @observable
  coupon : {
    amount_off: number | null;
    created: number;
    currency: string | null;
    id: string;
    name: string;
    percent_off: number | null;
    valid: boolean;
  } | null = null;

  @observable
  subscription: {
    current_period_end: number;
    current_period_start: number;
    status: string;
    plan: {
      created: number;
      id: string;
      product: string;
      trial_period_days: number;
      interval: string;
      amount: number;
    }
  } | null = null;

  @observable
  extraInfo: IExtraInfo | null = null

  @observable
  limitation: IPremiumLimitation = {
    maxProposals: 1,
    maxKeyNumbers: 3,
    maxAudienceGroups: 3,
    maxPackageTiers: 3,
    maxPackageBenefits: 5,
    maxParagraphs: 1,
    pdfExport: false
  }

  @computed get requestPremium() {
    return this.userLoaded && !this.premium
  }

  @computed get requestOnboarding() {
    return this.userLoaded && !this.extraInfo
  }

  @computed get requestOrganization() {
    return this.userLoaded && !this.organization;
  }

  @computed get premiumProductId() {
    if (!this.subscription) {
      return null
    }

    return this.subscription.plan.product
  }

  @computed get nextBilling() {
    if (!this.subscription) {
      return null;
    }

    let date = moment.unix(this.subscription.current_period_end);
    return date
  }

  @computed get startDate() {
    if (!this.subscription) {
      return null;
    }

    let date = moment.unix(this.subscription.current_period_start);
    return date;
  }

  @computed get canceled() {
    if (!this.subscription) {
      return false;
    }

    return this.subscription.status === 'canceled';
  }

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;

    reaction(
      () => this.premium,
      (premium) => {
        window.sessionStorage.setItem('premium', JSON.stringify(premium));
      }
    )

    when(
      () => this.premium && !!!this.subscription,
      () => {
        this.limitation = {
          maxProposals: 999,
          maxKeyNumbers: 999,
          maxAudienceGroups: 999,
          maxPackageTiers: 999,
          maxPackageBenefits: 999,
          maxParagraphs: 999,
          pdfExport: true
        }
      }
    )

    reaction(
      () => this.subscription && this.subscription.plan.product,
      (id) => {
        console.log('checking plans, product id', id)
        let plan = Object.values(plans).find(item => item.id === id)!;
        this.limitation = plan.limitation;
      }
    )
  }

  userLoad(user: IUser) {
    this.id = user.id;
    this.api_key = user.api_key;
    this.email = user.email;
    this.premium = user.premium;
    this.organization = user.organization;
    this.userLoaded = true;
    this.subscription = user.subscription
    this.extraInfo = user.extra_info
    this.servicebot_hash = user.servicebot_hash;
    this.coupon = user.coupon;
  }

  forgetUser() {
    this.userLoaded = false;
    this.email = undefined;
    this.id = undefined;
    this.api_key = undefined;
    this.premium = undefined;
    this.organization = undefined;
    this.subscription = null;
    this.root.token = null;
    this.extraInfo = null;
    this.servicebot_hash = undefined;
    this.coupon = null;
  }

  @computed get analyticsData() {
    return {
      email: this.email,
      isPremium: this.premium,
      organization: this.organization,
    }
  }

  // @ts-ignore
  @action.bound
  // @ts-ignore
  waitPremiumStatus = flow(function* (this: UserStore) {
    try {
      yield this.getUser()
      if (!this.premium) {
        return this.waitPremiumStatus()
      }
    } catch (e) {
      toast.error('Could not add premium plan please refresh or contact support')
    }
  })

  @action.bound
  verifyPremium = flow(function* (this: UserStore, checkout_session_id: string) {
    try {
      yield this.getUser()
      if (!this.premium) {
        yield this.root.api.verifyPremium(checkout_session_id)
        return this.waitPremiumStatus()
      }
    } catch (e) {
      toast.error('Could not add premium plan please refresh or contact support')
    }
  })

  @action.bound
  getUser = flow(function* (this: UserStore) {
    try {
      let user = yield this.root.api.userInfo();
      this.userLoad(user);
      indetifyUser(this.id as string, this.analyticsData)
    } catch (error) {
      console.error(error);
    }
  })

  @action.bound
  updateOrganization = flow(function* (this: UserStore, org: string) {
    let toastId = toast.info('Updating your organization...')
    try {
      yield this.root.api.updateUser({ organization: org })
      yield this.getUser();
      this.root.router.push('/proposals')
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: 'Organization updated', autoClose: 3000 });
    } catch (error) {
      toast.update(toastId, { type: toast.TYPE.ERROR, render: 'Organization update error', autoClose: 3000 });
      console.error(error)
    }
  })

  @action.bound
  updateOnboarding = flow(function* (this: UserStore, values: IExtraInfo) {
    this.extraInfo = values;
    let toastId = toast.info('Updating...')

    try {
      yield this.root.api.addExtraInfo(values);
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: 'Thanks! You are good to go.', autoClose: 3000 });
      this.root.router.push('/')
    } catch(e) {
      toast.update(toastId, { type: toast.TYPE.ERROR, render: 'Could not update. Please try later.', autoClose: 3000 });
      this.extraInfo = null
    }
  })

  @action.bound
  deleteUser = flow(function* (this: UserStore) {
    try {
      yield this.root.api.deleteUser();
      this.forgetUser();
      toast.success('Your account was deleted')
    } catch(error) {
      toast.error('There was an error. Please try again later')
      console.error(error)
    }
  })

  @action.bound	  @action.bound
  applyCupon = flow(function* (this: UserStore, value: string) {
    let toastId = toast.info('Applying...')

    try {
      yield this.root.api.applyCupon(value);
      yield this.getUser();
      toast.update(toastId, { type: toast.TYPE.SUCCESS, render: 'Coupon applied', autoClose: 3000 });
    } catch (error) {
      toast.update(toastId, { type: toast.TYPE.ERROR, render: 'Coupon does not exist...', autoClose: 3000 });
      console.error(error)
    }
  })
}
