import * as shortid from 'shortid';
import { observable, computed, action } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, getDefaultModelSchema, Context, custom, SKIP } from 'serializr';
import characterRemainColor from '@util/helpers/characterRemainColor';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

// releated interfacess

// model interfaces

export interface ITextConfig extends IBaseConfiguration {
  maxLength: number;
}

export interface ITextInstance extends IBaseInstanceElement  {
  text: string;
}

export interface IText extends IElement<ITextConfig, ITextInstance> { }

// model classes

export class TextInstance implements ITextInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable
  text = ''

  parent: Text;

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return this.text.length > 0
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get empty() {
    return !!!this.text;
  }

  @computed get characters() {
    return Object.assign(this.remainingColor, {
      charactersRemaining: this.charactersRemaining
    })
  }

  @computed get remainingColor() {

    let currentPercent = (1 - this.charactersRemaining / this.parent.configuration.maxLength);

    let data = [{
      color: '#a7b6c7',
      percent: 0,
      text: '{} characters remaining'
    }, {
      color: '#7de40b',
      percent: 0.7,
      text: '{} characters remaining. Good job!'
    }, {
      color: '#e49d0b',
      percent: 0.9,
      text: 'Wow, only {} characters left. Let’s keep it short :)'
    }, {
      color: '#ff0000',
      percent: 1,
      text: 'Yep, we are done. {} characters left'
    }]

    return characterRemainColor(data, currentPercent);
  }

  @computed get charactersRemaining() {
    if (!this.parent) {
      return 0;
    }

    let remain = this.parent.configuration.maxLength - this.charactersCount
    if (remain <= 0) {
      remain = 0
    }

    return remain;
  }

  @computed get charactersCount() {
    return this.text.length;
  }

  constructor(parent: Text) {
    this.parent = parent;
  }

  @action.bound
  updateValue(value: string) {
    this.text = value;
  }

  static schema() {
    return getDefaultModelSchema(TextInstance).factory = (context: Context) => {
      return new TextInstance(context.args.parent);
    };
  }
}

export default class Text extends BaseElement<IText, ITextConfig, TextInstance> {

  @observable
  @serializable(elementInstanceSerializer(TextInstance))
  readonly instances = observable<TextInstance>([]);

  static getInstanceClass() {
    return TextInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new TextInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(Text).factory = (context: Context) => {
      return new Text(context.args.root, context.args.proposal);
    };
  }
}

TextInstance.schema()
Text.schema();