import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Icon from '@app/components/common/Icon';
import * as sectionStyles from '../sections.module.sass';
import VirtualSectionModel from '@models/builder/customization/VirtualSection';
import cn from 'classnames';

import FileUpload from '@components/builder/customization/elements/common/FileUpload';
import Checkbox from '../../elements/common/Checkbox';
import TextInput from '../../elements/common/TextInput';
import TextArea from '../../elements/common/TextArea';
import ColorPicker from '../../elements/common/ColorPicker';

interface IVirtualSection {
  dragProps: any;
  virtual: VirtualSectionModel;
  open: boolean;
  toggleOpen: (id: string) => void;
}

let VirtualSection: React.FunctionComponent<IVirtualSection> = (props) => {
  const [hover, toggleHover] = React.useState(false);

  function toggleOpen() {
    if (props.open) {
      props.toggleOpen('')
      return;
    }

    props.toggleOpen(props.virtual.id)
  }

  function deleteSection() {
    props.virtual.delete();
  }

  return (
    <div className={cn({ [sectionStyles.sectionOpen]: props.open })}>
      <div onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)} onClick={toggleOpen} className={sectionStyles.container}>
        <div {...props.dragProps} className={sectionStyles.drag}>
          <Icon type='drag-combined' inheritStyle />
        </div>
        <div className={cn(sectionStyles.item, sectionStyles.itemTransition)}>
          <p style={{ paddingLeft: '12px'}}>{props.virtual.name}</p>
          <div className={sectionStyles.icon} >
            <Icon stopPropagation onClick={() => deleteSection()} style={{marginRight: '12px', visibility: hover ? 'visible' : 'hidden' }} type='trashcan-solid' inheritStyle />
            <Icon type='cog' inheritStyle />
          </div>
        </div>
      </div>
      {props.open &&
        <div className={sectionStyles.sectionContainer}>
          {(!props.virtual.isCodeSection && !props.virtual.isVideoSection) &&
            <div>
              <div className={sectionStyles.sectionElement}>
                <FileUpload
                  help_text='Recommended size 1920 x 1080px'
                  label='Background image'
                  file={props.virtual.background}
                />
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    checked={props.virtual.backgroundFitContent}
                    toggleCheck={props.virtual.toggleBackgroundFitContent}
                    name='Fit Content'
                    text='Fit image to grid.'
                  />
                </div>
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    checked={props.virtual.backgroundOverlay}
                    toggleCheck={props.virtual.toggleBackgroundOverlay}
                    name='Add overlay'
                    text='Add color overlay'
                  />
                </div>
              </div>
              {props.virtual.backgroundOverlay &&
                <div className={sectionStyles.sectionElement}>
                  <ColorPicker
                    label='Overlay color'
                    isMultiple={false}
                    color={props.virtual.backgroundOverlayColor}
                    changeColor={props.virtual.changeOverlayColor}
                  />
                </div>
              }
              <div className={sectionStyles.sectionElement}>
                <TextInput
                  help_text=''
                  label='headline'
                  placeholder='Amazing Conference'
                  value={props.virtual.headline}
                  onChange={props.virtual.setHeadline}
                />
              </div>
              <div className={sectionStyles.sectionElement}>
                <TextInput
                  help_text=''
                  placeholder='Longer text...'
                  label='text'
                  value={props.virtual.text}
                  onChange={props.virtual.setText}
                />
              </div>
              <div className={sectionStyles.sectionElement}>
                <TextInput
                  help_text=''
                  label='button'
                  placeholder='I am interested'
                  value={props.virtual.buttonText}
                  onChange={props.virtual.setButtonText}
                />
                <div style={{ marginTop: '12px' }}>
                  <Checkbox
                    checked={props.virtual.useDifferentLink}
                    toggleCheck={props.virtual.toggleCustomLink}
                    name='Custom Link'
                    text='Use different than the default link.'
                  />
                </div>
                {props.virtual.useDifferentLink &&
                  <div style={{ marginTop: '12px' }}>
                    <TextInput
                      help_text=''
                      placeholder='https://sponseasy.com'
                      value={props.virtual.link}
                      onChange={props.virtual.setLink}
                    />
                  </div>                
                }
              </div>
              <div className={sectionStyles.sectionElement}>
                <Checkbox
                  checked={props.virtual.isVideoSection}
                  toggleCheck={props.virtual.toggleVideoSection}
                  name='Video'
                  label='Video'
                  text='Use video in this section'
                />
              </div>

              <div className={sectionStyles.sectionElement}>
                <Checkbox
                  checked={props.virtual.isCodeSection}
                  toggleCheck={props.virtual.toggleCodeSection}
                  name='Code'
                  label='Code'
                  text='Paste custom code into this section'
                />
              </div>
            </div>
          }

          {props.virtual.isVideoSection &&
            <div>
              <div className={sectionStyles.sectionElement}>
                <Checkbox
                  checked={props.virtual.isVideoSection}
                  toggleCheck={props.virtual.toggleVideoSection}
                  name='Video'
                  label='Video'
                  text='Use video in this section'
                />
              </div>
              <div className={sectionStyles.sectionElement}>
                <TextInput
                  help_text=''
                  label='Video URL'
                  placeholder='Paste video URL (Youtube)'
                  value={props.virtual.videoURL!}
                  onChange={props.virtual.setVideoUrl}
                />
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    checked={props.virtual.backgroundFitContent}
                    toggleCheck={props.virtual.toggleBackgroundFitContent}
                    name='Fit Content'
                    text='Fit video to grid.'
                  />
                </div>
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    checked={props.virtual.videoRemoveRecommended}
                    toggleCheck={props.virtual.toggleVideoRecommended}
                    name='Remove recommended'
                    text='Remove recommended section'
                  />
                </div>
              </div>
              <div className={sectionStyles.sectionElement}>
              </div>
            </div>
          }

          {props.virtual.isCodeSection &&
            <div>
              <div className={sectionStyles.sectionElement}>
                <Checkbox
                  checked={props.virtual.isCodeSection}
                  toggleCheck={props.virtual.toggleCodeSection}
                  name='Code'
                  label='Code'
                  text='Paste custom code into this section'
                />
              </div>
              <div className={sectionStyles.sectionElement}>
                <TextArea value={props.virtual.css} onChange={props.virtual.setCss} label='css' />
              </div>
              <div className={sectionStyles.sectionElement}>
                <TextArea value={props.virtual.html} onChange={props.virtual.setHTML} label='html' />
              </div>
            </div>
          }

        </div>
      }

    </div>
  )
}

VirtualSection = observer(VirtualSection);
export default VirtualSection;