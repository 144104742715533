import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from '../auth.module.sass';
import { RouteComponentProps } from 'react-router-dom';

import Icon from '@app/components/common/Icon';
import { rootContext } from '@app/index';
import { Link } from 'react-router-dom';
import QS from 'query-string';
import { withRouterApp } from '@app/util/router.tsx';


interface ISetEmailProps { }

let SetEmail: React.FunctionComponent<ISetEmailProps & RouteComponentProps> = (props) => {
  const rootStore = React.useContext(rootContext);
  const authStore = rootStore.auth;
  const [email, changeEmail] = React.useState('');

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let qs = QS.parse(props.location.search);

    if (qs.token) {
      authStore.setNewEmail(email, qs.token as string);
    }
  }

  return (
    <div className={styles.contentSimple}>
      <div className={styles.content}>
        <h2 className={styles.headline}>Set your new email</h2>
        <form onSubmit={onSubmit}>
          <div className={styles.inputContainer}>
            <label>Email</label>
            <input onChange={(e) => changeEmail(e.target.value)} value={email} required type='email' placeholder='Email'></input>
          </div>
          <div className={styles.buttons}>
            <button disabled={authStore.inProgress} type='submit' className={styles.button}>
              {authStore.inProgress ? 'Loading...' : 'Set email'}
              <Icon rotate={180} type='arrow' />
            </button>
          </div>
          <Link className={styles.linkText} to='/auth/signin'>Remembered? Click here to login.</Link>
        </form>
      </div>
    </div>
  )
}

SetEmail = withRouterApp(observer(SetEmail));
export default SetEmail;