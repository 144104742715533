import { IPackagesInstance } from './index';
import shortid from 'shortid';

let benefitsIds = {
  'first': shortid.generate(),
  'second': shortid.generate(),
  'third': shortid.generate()
}

let tierIds = {
  'first': shortid.generate(),
  'second': shortid.generate(),
  'third': shortid.generate()
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type defaultData = Omit<IPackagesInstance, 'id' | 'filled'>

let data: defaultData = {
  tiers: [{
    id: tierIds.first,
    name: 'Gold',
    description: undefined,
    tickets: {
      current: undefined,
      available: undefined
    },
    pricing: {
      price: undefined,
      type: 'one-time',
    },
    badge: undefined
  }, {
    id: tierIds.second,
    name: 'Silver',
    description: undefined,
    tickets: {
      current: undefined,
      available: undefined
    },
    pricing: {
      price: undefined,
      type: 'one-time',
    },
    badge: undefined
  }, {
    id: tierIds.third,
    name: 'Bronze',
    description: undefined,
    tickets: {
      current: undefined,
      available: undefined
    },
    pricing: {
      price: undefined,
      type: 'one-time',
    },
    badge: undefined
  }],
  packageItems: [{
    id: benefitsIds.first,
    text: 'benefit one!',
    attachmentNote: undefined,
    file: {
      fileURL: undefined,
      name: undefined,
      handle: undefined,
      mimetype: undefined,
      size: undefined
    }
  }, {
    id: benefitsIds.second,
    text: 'benefit two!',
    attachmentNote: undefined,
    file: {
      fileURL: undefined,
      name: undefined,
      handle: undefined,
      mimetype: undefined,
      size: undefined
    }
  }, {
    id: benefitsIds.third,
    text: 'benefit three!',
    attachmentNote: undefined,
    file: {
      fileURL: undefined,
      name: undefined,
      handle: undefined,
      mimetype: undefined,
      size: undefined
    }
  }],
  itemToTierConnections: [{
    active: true,
    packageItem: benefitsIds.first,
    tier: tierIds.first
  }, {
    active: true,
    packageItem: benefitsIds.first,
    tier: tierIds.second
  }, {
    active: true,
    packageItem: benefitsIds.first,
    tier: tierIds.third
  }, {
    active: true,
    packageItem: benefitsIds.second,
    tier: tierIds.first
  }, {
    active: true,
    packageItem: benefitsIds.second,
    tier: tierIds.second
  }, {
    active: true,
    packageItem: benefitsIds.third,
    tier: tierIds.first
  }]
}

export default data;