import { observable, action, computed } from "mobx";
import { serializable, object, list, serializeAll } from "serializr";
import { description } from "@app/components/builder/elements/Sponsor/entity.module.sass";

export interface ISocialMediaHandle {
  name: string;
  link: string;
  keyMetric: string;
  description: string;
}

export class SocialMediaHandle implements ISocialMediaHandle {
  
  @observable
  @serializable
  name = '';

  @observable
  @serializable
  link = '';

  @observable
  @serializable
  keyMetric = '';

  @observable
  @serializable
  description = ''

  @observable
  expand = false;

  @action.bound
  updateName(val: string) {
    this.name = val;
    this.link = '';
  }

  @action.bound
  updateLink(link: string) {
    this.link = link;
  }

  @action.bound
  updateKeyMetric(value: string) {
    this.keyMetric = value;
  }

  @action.bound
  updateDescription(value: string) {
    this.description = value;
  }

  @action.bound
  toggleExpand(toggle: boolean) {
    this.expand = toggle;
  }

  constructor(networkName?: string) {
    if (networkName) {
      this.name = networkName;
    }
  }
}

export default class SocialMediaPicker {

  @observable
  @serializable(list(object(SocialMediaHandle)))
  readonly options = observable<SocialMediaHandle>([]);

  @action.bound
  addNewHandle(network?: string) {
    this.options.push(new SocialMediaHandle(network))
  }

  @action.bound
  removeHandle(handle: SocialMediaHandle) {
    this.options.remove(handle);
  }

  @computed get hasData() {
    return this.options.length > 0 && this.options.some((item) => !!item.link && !!item.name);
  }

  constructor(firstDefaultHandle?: string) {
    if (this.options.length === 0) {
      this.addNewHandle(firstDefaultHandle);
    }
  }
}