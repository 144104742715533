import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { rootContext } from '@app/index';
import * as styles from './proposals.module.sass';
import Icon, { IconTypes } from '@components/common/Icon';
import { NavLink, Link, RouteComponentProps } from 'react-router-dom'; 
import { withRouterApp } from '@app/util/router';
import Tippy from '@tippy.js/react';
import cn from 'classnames';
import Proposal, { ProposalSimplePreview } from '@models/builder/proposal';
import ContextMenu from '@components/common/ContextMenu';
import OutsideClickHandler from 'react-outside-click-handler';
import Modal from '@app/components/common/Modal';
import Input from '@app/components/common/Input';
import Button from '@app/components/common/Button';
import Loading from '@components/common/Loading';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { IFolder } from '@app/stores/ProposalStore';
import shortid from 'shortid';
import emitter from '@util/emitter';


interface IProposalsProps {}

let Proposals: React.FunctionComponent<IProposalsProps & RouteComponentProps<any>> = (props) => {
  const rootStore = React.useContext(rootContext);
  const user = rootStore.user;

  const [creatingNewFolder, toggleFolderCreate] = React.useState(false);

  let folderTree = rootStore.proposalStore.sidebarOrder.map((item, index) => {
    return (
      item.type === 'proposal' ?
          <ProposalItem key={item.id} depth={0} currentFolder={item.folder_id} item={item as ProposalSimplePreview} />
        :
          <Folder depth={0} key={item.id} folder={item as IFolder} />
    )
  })

  function newProposal() {
    if (rootStore.proposalStore.proposalList.length >= user.limitation.maxProposals)  {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'proposal'
      })

      return;
    }

    rootStore.router.push(`${props.match.path}/new/proposal`);
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.headline}>Proposals</h2>
      <div className={styles.controls}>
        <div onClick={newProposal} className={styles.newProposal}>
          <p>New Proposal</p>
          <Icon inheritStyle={true} type='plus' />
        </div>
        <div onClick={() => toggleFolderCreate(true)} className={styles.addFolder}>
          <Icon tooltip='Create new Folder' inheritStyle type='folder-plus' />
        </div>
      </div>
      <div className={styles.list}>
        {!folderTree && rootStore.proposalStore.loadingProposals ?
          <Loading style={{ width: '100px', height: '100px', margin: '0 auto'}} loadingID='proposals-loading' />
          :
          <div>
            <div className={styles.label}>
              <p>Your Proposals</p>
            </div>
            <div className={styles.items}>
              {creatingNewFolder &&
                <Folder creating creatingToggle={toggleFolderCreate} depth={0} folder={{ id: shortid.generate(), name: '', type: 'folder', folder_id: null, children: [] }} />
              }
              {folderTree}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

interface IProposalItemProps {
  item: ProposalSimplePreview;
  depth: number;
  currentFolder: string | null;
}

let ProposalItemComponent: React.FunctionComponent<IProposalItemProps & RouteComponentProps<any>> = (props) => {

  const [hover, toggleHover] = React.useState(false);
  const [open, toggleOpen] = React.useState(false);
  const [newName, changeNewName] = React.useState('');
  const [nameChangeActive, toggleNameChange] = React.useState(false);

  const rootStore = React.useContext(rootContext);

  let showTooltip = props.item.name.length > 20;

  function dropdown(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    toggleOpen(true);
  }

  function onNameChange(val: string) {
    toggleNameChange(true);
    changeNewName(val);
  }

  let leftPadding = props.depth * 11;

  let folders = rootStore.proposalStore.folders.map(item => {
    return {
      text: item.name,
      icon: 'folder' as IconTypes,
      action: () => { rootStore.proposalStore.moveProposalToFolder(props.item.id, props.currentFolder, item.id) },
    }
  })

  let contextMenu = folders;

  if (props.depth !== 0) {
    contextMenu.unshift({
      text: 'Move to top',
      icon: 'move-top',
      action: () => { rootStore.proposalStore.moveProposalToFolder(props.item.id, props.currentFolder, null) }
    })
  }

  return (
    <div>
      <NavLink
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
        activeClassName={styles.itemActive}
        to={`${props.match.url}/proposal/${props.item.id}`}
        key={props.item.id} className={styles.item}>

        <Tippy
          content={props.item.name}
          trigger='mouseenter'
          theme='honeybee'
          animateFill={false}
          placement='top-end'
          animation='shift-toward'
          inertia={true}
          isEnabled={showTooltip}
        >
          <p style={{ paddingLeft: leftPadding }}>{props.item.name}</p>
        </Tippy>
        <div onClick={dropdown} className={cn(styles.icon, { [styles.iconHover]: hover || open })}>
          <Icon type='three-dots' inheritStyle={true} />
        </div>
        {open &&
            <div className={styles.menu}>
                <ContextMenu
                  onClose={() => toggleOpen(false)}
                  items={
                    [{
                      text: 'Duplicate',
                      icon: 'clone' as IconTypes,
                      action: () => rootStore.proposalStore.duplicateProposal(props.item.id)
                    },
                    {
                      text: 'Rename',
                      icon: 'pen-solid' as IconTypes,
                      action: () => props.item.toggleRename(true)
                    },
                    {
                      text: 'Move to',
                      icon: 'arrow',
                      iconStyle: {transform: 'rotate(180deg)'},
                      action: () => {},
                      depthItems: contextMenu
                    },
                    {
                      text: 'Delete',
                      icon: 'trashcan-solid' as IconTypes,
                      confirmAction: true,
                      action: () => rootStore.proposalStore.deleteProposal(props.item.id)
                    }]
                  }
                />
            </div>
        }
      </NavLink>
      <Modal maxWidth={450} isOpen={props.item.renameOpen} onRequestClose={() => props.item.toggleRename(false)}>
        <div className={styles.modal}>
          <p className={styles.text} style={{ marginTop: '15px', marginBottom: '20px'}}>Be careful. If you rename a proposal it will also change the URL of the published website.</p>
          <div style={{ marginBottom: '20px'}}>
            <Input name='proposal-new-name' value={nameChangeActive ? newName : props.item.name} onChange={(val) => onNameChange(val)} placeholder='New name...' />
          </div>
          <button onClick={() => props.item.rename(newName)} disabled={props.item.renaming || newName.length === 0} type='submit' className={styles.button}>
            {false ? 'Loading...' : 'Rename'}
            <Icon rotate={180} type='arrow' />
          </button>
        </div>
      </Modal>
    </div>
  )
}

interface IFolderProps {
  folder: IFolder;
  depth: number;
  creating?: boolean;
  creatingToggle?: (open: boolean) => void;
}

let Folder: React.FunctionComponent<IFolderProps> = (props) => {
  const [expand, toggleExpand] = React.useState(false);
  const rootStore = React.useContext(rootContext);
  const [newName, setName] = React.useState('');

  const [hover, toggleHover] = React.useState(false);
  const [open, toggleOpen] = React.useState(false);
 
  const [rename, toggleRename] = React.useState(false);

  const inputRef = React.useRef(null as any);

  React.useEffect(() => {
    if (props.creating || rename) {
      inputRef.current!.focus()
    }
  }, [inputRef, rename])

  function activateRename() {
    setName(props.folder.name)
    toggleRename(true)
  }

  function dropdown(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    toggleOpen(true);
  }

  async function inputBlur() {
    if (newName && props.creating) {
      await rootStore.proposalStore.createNewFolder(newName)
      props.creatingToggle!(false);
      return;
    } else if (newName) {
      await rootStore.proposalStore.renameFolder(newName, props.folder.id)
      toggleRename(false)
    }
  }


  let proposals = props.folder.children.map(item => {
    return <ProposalItem currentFolder={props.folder.id} depth={props.depth + 1} key={item.id} item={item as ProposalSimplePreview} />
  })

  let name = props.folder.name;

  if (props.folder.children.length) {
    name = `${name} (${props.folder.children.length})`
  }

  return (
    <div>
      <div
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
        key={props.folder.id} className={styles.item}
      >
        <div onClick={() => toggleExpand(!expand)} className={styles.folderWrap}>
          <Icon rotate={expand ? 90 : 0} className={styles.caret} inheritStyle type='caret' />
          <Icon className={styles.folder} inheritStyle type='folder' />
          {(props.creating || rename) ?
            <input placeholder='Add folder name' onBlur={inputBlur} value={newName} onChange={(e) => setName(e.target.value)} ref={inputRef} className={styles.folderInput} type='text' />
          : 
            <p>{name}</p>
          }
        </div>
        <div onClick={dropdown} className={cn(styles.icon, { [styles.iconHover]: hover || open })}>
          <Icon type='three-dots' inheritStyle={true} />
        </div>
        {open &&
          <div className={styles.menu}>
            <ContextMenu
              onClose={() => toggleOpen(false)}
              items={
                [{
                  text: 'Rename',
                  icon: 'pen-solid' as IconTypes,
                  action: () => { activateRename() }
                }, 
                {
                  text: 'Delete',
                  icon: 'trashcan-solid' as IconTypes,
                  confirmAction: true,
                  action: () => rootStore.proposalStore.deleteFolder(props.folder.id)
                }
              ]
              }
            />
          </div>
        }
      </div>
      {expand &&
        proposals
      }
    </div>
  )
}

Folder = observer(Folder);
let ProposalItem = withRouterApp<IProposalItemProps>(observer(ProposalItemComponent));
export default withRouterApp<IProposalsProps>(observer(Proposals));