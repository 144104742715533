
import * as React from 'react';
import { observer } from 'mobx-react';
import * as styles from './file.module.sass';
import ReactFilestack from 'filestack-react';
import File, { IFile } from '@util/elements/FileUpload';
import BlockLabel from '@components/builder/common/BlockLabel';
import Icon from '@components/common/Icon';
import classNames from 'classnames';
import Content from './Content';

export interface IFileUploadProps {
  file: File;
  label?: string;
  theme?: 'dark'
  allowVideo?: boolean;
  allowAnyFile?: boolean;
}

interface State {
  hover: boolean;
}

@observer
export default class FileUpload extends React.Component<IFileUploadProps, State> {
  constructor(props: IFileUploadProps) {
    super(props);

    this.state = {
      hover: false
    }
  }

  success(data: any) {

    let fileData = {
      name: data.filesUploaded[0].filename,
      fileURL: data.filesUploaded[0].url,
      mimetype: data.filesUploaded[0].mimetype,
      handle: data.filesUploaded[0].handle,
      size: data.filesUploaded[0].size,
    }

    this.props.file.uploadFile(fileData)
  }

  error(error: any) {
    console.log(error);
  }

  public render() {

    let accept = ['image/*'];

    if (this.props.allowVideo) {
      accept.push('video/*')
    }

    let options = {
      accept : accept,
      maxFiles: 1,
      minFiles: 1,
      maxSize: 2 * 1024 * 1024,
    };

    if (this.props.allowAnyFile) {
      delete options.accept;
    }

    let fileURL = this.props.file.fileURL
    let file = this.props.file;


    return (
      <div className={classNames(styles.container, { [styles.themeDark]: this.props.theme === 'dark' ? true : false })}>
        {this.props.label ?
          <div className={styles.labelBlock}>
            <BlockLabel text={this.props.label} />
          </div>
          : null}
        <ReactFilestack
          apikey={'AneRIP5PPRfSpXpVYzkOMz'}
          onSuccess={(data: any) => this.success(data)}
          onError={(data: any) => this.error(data)}
          options={options}
          render={(bind: any) => {
            return (
              this.props.children ?
                <div onClick={bind.onPick}>
                  {this.props.children}
                </div>
              :
                <div
                  id='entity-file-upload'
                  className={styles.picker}
                  onClick={bind.onPick}
                  >
                    <Content file={file} />
                </div>
          )}}
        />
      </div>
    );
  }
}
