import * as shortid from 'shortid';
import { observable, action, toJS, computed, flow, reaction } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, custom, SKIP, serialize } from 'serializr';
import File, { IFile } from '@util/elements/FileUpload';
import DateTimePeriod, { IDateTimePeriod, IDateTimePeriodApp } from '@models/builder/common/DateTimePeriod';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';
import { Omit } from '@util/types';

interface IOption {
  label: string;
  value: string;
}

// releated interfacess
interface IDateTime {
  date: string;
  time: string;
}

export interface IPlace {
  name: string;
  formattedAddress: string;
  lat: number;
  lng: number;
  place_id: string;
  types: string[];
  addressComponents: any[];
}

// model interfaces

export interface IVenueConfig extends IBaseConfiguration {}

export interface IVenueInstance extends IBaseInstanceElement  {
  place: IPlace | undefined;
  venueDateTime: IDateTimePeriod;
  dates: IDateTimePeriod[];
  picture: IFile;
  pictureDescription: string;
}

interface IVenueInstanceApp extends Omit<IVenueInstance, 'venueDateTime' | 'dates'> {
  venueDateTime: IDateTimePeriodApp;
  dates: IDateTimePeriodApp[];
}

export interface IVenue extends IElement<IVenueConfig, IVenueInstance> {}

// model classes
export class VenueInstance implements IVenueInstanceApp {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable(custom((item: any) => toJS(item), (item: any) => item))
  place: IPlace | undefined;

  @observable
  @serializable(object(DateTimePeriod))
  venueDateTime = new DateTimePeriod();

  @observable
  @serializable(list(object(DateTimePeriod)))
  readonly dates = observable<DateTimePeriod>([])

  @observable
  @serializable(object(File))
  picture = new File();

  @observable
  @serializable
  pictureDescription = '';

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.place && !!this.place.name && !!this.place.formattedAddress
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get empty() {
    return !!!this.place || !!!this.place.name;
  }

  @computed get showVenueName() {
    if (!this.place) {
      return false;
    }

    return true
  }

  @observable
  location: {
    lat: number;
    lng: number;
  } | undefined;

  parent: Venue

  constructor(parent: Venue) {
    this.parent = parent;
    this.geolocate();
  }

  @action.bound
  addDate() {
    this.dates.push(new DateTimePeriod())
  }

  @action.bound
  deleteDate(date: DateTimePeriod) {
    this.dates.remove(date);
  }

  @action.bound
  changePlace(place: IPlace) {
    this.place = place;
  }

  @action.bound
  changeVenueName(val: string) {
    this.place!.name = val;
  }

  @action.bound
  changePictureDescription(val: string) {
    if (this.pictureDescription.length < 70 || val.length < this.pictureDescription.length) {
      this.pictureDescription = val;
    }
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.location = geolocation;
      });
    }
  }

  static schema() {
    return getDefaultModelSchema(VenueInstance).factory = (context: Context) => {
      let instance = new VenueInstance(context.args.parent)
      return instance;
    };
  }
}

export default class Venue extends BaseElement<IVenue, IVenueConfig, VenueInstance> {

  @observable
  @serializable(elementInstanceSerializer(VenueInstance))
  readonly instances = observable<VenueInstance>([]);

  static getInstanceClass() {
    return VenueInstance;
  }

  @action.bound
  addInstance() {
    this.instances.push(new VenueInstance(this))
  }

  @action.bound
  deleteInstance(instance: VenueInstance) {
    this.instances.remove(instance);
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new VenueInstance(this));
    }

  }

  static schema() {
    return getDefaultModelSchema(Venue).factory = (context: Context) => {
      return new Venue(context.args.root, context.args.proposal);
    };
  }
}

VenueInstance.schema()
Venue.schema()