import { observable, action, computed, reaction, flow, when, toJS } from "mobx";
import { serializable, list, primitive, custom, serialize, alias, getDefaultModelSchema, Context } from "serializr";
import * as shortid from 'shortid';
import File, { IFile } from "@app/util/elements/FileUpload";
import { Omit } from "@app/util/types";
import RootStore from "@app/stores/RootStore";
import WebFont from 'webfontloader';

export const fileSerializer = () => {
  return alias('image_url', custom((item: File) => {
    return item.fileURL || null
  }, (json: string | null) => {
    let file = new File();
    file.fileURL = json ? json : undefined;
    return file
  }))
}

interface IBaseElement {
  type: ElementNames;
  id: string;
  label: string;
  internal_name: string;
  help_text: string;
}

class BaseElement implements IBaseElement {

  @observable
  @serializable
  type: ElementNames;

  @observable
  @serializable
  id: string;

  @observable
  @serializable
  label: string;

  @observable
  @serializable
  help_text: string;

  @observable
  @serializable
  internal_name: string;

  @computed get json() {
    return serialize(this);
  }
}

export interface IFontSelector extends IBaseElement {
  font_family: string;
}

interface IFont {
  family: string;
  files: {
    regular: string;
  }
}

export class FontSelector extends BaseElement implements IFontSelector {
  @observable
  @serializable
  type: ElementNames = 'FormProcess::Customization::FontSelector'

  @observable
  @serializable
  internal_name = 'font-selector'

  @observable
  @serializable
  font_family = 'Roboto';

  @observable
  filter = '';

  @observable
  showEmptyFilter = false;

  @observable
  loaded = false;

  @observable
  cssImportDone = false;

  @observable
  open = false;
  
  @computed get filteredFamilies() {
    return this.families.filter(item => item.toLowerCase().includes(this.filter.toLowerCase()))
  }

  readonly fontList = observable<IFont>([], { deep: false });
  readonly families = observable<string>([], { deep: false });

  root: RootStore;
  constructor(root: RootStore) {
    super();
    this.root = root;

    when(
      () => this.loaded && this.open && !this.cssImportDone,
      () => this.loadCSS()
    )

    reaction(
      () => this.open,
      () => {
        this.filter = ''
        this.showEmptyFilter = false;
      }
    )
  }

  @action.bound
  changeFont(fontFamily: string) {
    this.font_family = fontFamily
    this.open = false;
  }

  @action.bound
  setFilter(filter: string) {
    this.filter = filter;

    if (!filter) {
      this.showEmptyFilter = true;
    }
  }

  @action.bound
  toggleOpen(toggle: boolean) {
    this.open = toggle;
  }

  @action.bound
  loadCSS() {

    WebFont.load({
      google: {
        families: this.families
      }
    });

    this.cssImportDone = true;
  }

  @action.bound
  loadFonts = flow(function* (this: FontSelector) {
    try {
      let fonts = yield this.root.api.google.loadFonts();
      fonts = fonts.items.slice(0, 30);
      this.loaded = true;
      this.fontList.replace(fonts);
      this.families.replace(this.fontList.map(item => item.family));
    } catch (error) {
      console.error(error)
    }
  })

  static schema() {
    return getDefaultModelSchema(FontSelector).factory = (context: Context) => {
      return new FontSelector(context.args.root)
    };
  }
}

FontSelector.schema();

export interface ICheckbox extends IBaseElement {
  checked: boolean;
  text: string;
}

export class Checkbox extends BaseElement implements ICheckbox {
  @observable
  @serializable
  type: ElementNames = 'FormProcess::Customization::Checkbox'

  @observable
  @serializable
  text: string = '';

  @observable
  @serializable
  checked = false;

  @action.bound
  toggleCheckbox(toggle: boolean) {
    this.checked = toggle;
  }
}

export interface IColorPicker extends IBaseElement {
  colors: string[];
  color_presets: string[][];
}

export class ColorPicker extends BaseElement implements IColorPicker {

  @observable
  @serializable
  type: ElementNames = 'FormProcess::Customization::ColorPicker'

  @observable
  @serializable
  internal_name = 'color-picker'

  @observable
  @serializable
  label = 'Color Picker'

  @observable
  @serializable(list(primitive()))
  readonly colors = observable(['#F43944', '#061C2B'])

  @observable
  color_presets = [['#F43944', '#061C2B'], ['#1F0419', '#E9C26E'], ['#49256D', '#F47E60'], ['#440D0D', '#52DFA4'], ['#545C86', '#EFF1FF'], ['#33484B', '#00FFD1'], ['#773448', '#FFD7E3']]

  constructor() {
    super();
  }

  @action.bound
  changeColor(index: number, color: string) {
    this.colors[index] = color;
  }

  @action.bound
  activatePreset(colors: string[]) {
    this.colors.replace(colors);
  }
}

export interface IImageUpload extends IBaseElement {
  image_url: string;
}

interface IImageUploadApp extends Omit<IImageUpload, 'image_url'> {
  file: File;
}

export class ImageUpload extends BaseElement implements IImageUploadApp {

  @observable
  @serializable
  type: ElementNames = 'FormProcess::Customization::ImageUpload'

  @observable
  @serializable(fileSerializer())
  file: File = new File();

  constructor(name?: string) {
    super()
  }
}

interface IDropdown extends IBaseElement {
  options: string[];
  value: string | undefined;
}

export class Dropdown extends BaseElement implements IDropdown {

  @observable
  @serializable
  type: ElementNames = 'FormProcess::Customization::Dropdown'

  @observable
  @serializable
  label = 'Dropdown'

  @observable
  @serializable(list(primitive()))
  readonly options = observable<string>(['Test dropdown 1', 'Test dropdown 2', 'Test dropdown 3']);

  @observable
  @serializable
  value: string | undefined;

  @action.bound
  changeValue(option: string) {
    this.value = option;
  }

  constructor() {
    super();
    this.value = this.options[0];
  }
}

export interface IContact extends IBaseElement {
  phone: string | null;
  name: string | null;
  email: string | null;
}

export class Contact extends BaseElement implements IContact {

  @observable
  @serializable
  type: ElementNames = 'FormProcess::Customization::Contact'

  @observable
  @serializable
  phone: string | null = null;

  @observable
  @serializable
  name: string | null = null;

  @observable
  @serializable
  email: string | null = null;

  @action.bound
  setName(name: string) {
    this.name = name;
  }

  @action.bound
  setEmail(email: string) {
    this.email = email;
  }

  @action.bound
  setPhone(phone: string) {
    this.phone = phone;
  }
}

export interface ITextInput extends IBaseElement {
  value: string | null;
}

export class TextInput extends BaseElement implements ITextInput {

  @observable
  @serializable
  type: ElementNames = 'FormProcess::Customization::TextInput'

  @observable
  @serializable
  value: string | null = null;

  @action.bound
  setValue(val: string) {
    this.value = val;
  }
}

export interface IWordingKeys {
  date_and_location: string;
  get_in_touch: string;
  inquiry: string;
}

export interface IWording extends IBaseElement {
  wording: IWordingKeys
}

export class Wording extends BaseElement implements IWording {

  @observable
  @serializable
  type: ElementNames = 'FormProcess::Customization::Wording'

  @observable
  @serializable(custom(v => toJS(v), (val, ctx, oldValue) => {
    let emtpy = Object.entries(val).length === 0 && val.constructor === Object
    return emtpy ? oldValue : val
  }))
  wording = {
    date_and_location: 'Date And Location',
    get_in_touch: "Let's get in touch",
    inquiry: 'Inquiry'
  }

  @action.bound
  updateWording(key: string, value: string) {
    (this.wording as any)[key] = value;
  }

  constructor(name?: string) {
    super()
    if (name) {
      this.label = name;
    }
  }
}


export type ElementTypes = ColorPicker | ImageUpload | Dropdown | Contact | TextInput | Checkbox | FontSelector | Wording;
export type IElementInterfaces = IColorPicker | IImageUpload | IDropdown | IContact | ITextInput | ICheckbox | IFontSelector | IWording;
export type ElementNames = 'FormProcess::Customization::ColorPicker' | 'FormProcess::Customization::ImageUpload' | 'FormProcess::Customization::Dropdown' | 'FormProcess::Customization::Contact' | 'FormProcess::Customization::TextInput' | 'FormProcess::Customization::Checkbox' | 'FormProcess::Customization::FontSelector' | 'FormProcess::Customization::Wording';

export const Elements: {
  [key in ElementNames]: Object
} = {
  'FormProcess::Customization::ColorPicker': ColorPicker,
  'FormProcess::Customization::ImageUpload': ImageUpload,
  'FormProcess::Customization::Dropdown': Dropdown,
  'FormProcess::Customization::Contact': Contact,
  'FormProcess::Customization::TextInput': TextInput,
  'FormProcess::Customization::Checkbox': Checkbox,
  'FormProcess::Customization::FontSelector': FontSelector,
  'FormProcess::Customization::Wording': Wording
}