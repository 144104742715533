import { observable, action, computed } from 'mobx';
import { serializable } from 'serializr';
import characterRemainColor from '@util/helpers/characterRemainColor';

export interface IMarkdownField {
  markdown: string;
}

export default class MarkdownField implements IMarkdownField {

  @observable
  @serializable
  markdown = ''

  @observable
  characterLimit: number | undefined;

  @computed get charactersCount() {
    let markdown = this.markdown
    let length = markdown.replace(/[#_*↵+_]/g, '').trim().length;
    return length;
  }

  @computed get charactersRemaining() {
    if (!this.characterLimit) {
      return undefined;
    }

    let remain = this.characterLimit - this.charactersCount
    if (remain <= 0) {
      remain = 0
    }

    return remain;
  }

  @computed get remainingColor() {
    if (!this.charactersRemaining) {
      return undefined;
    }

    let currentPercent = (1 - this.charactersRemaining / this.characterLimit!);

    let data = [{
      color: '#a7b6c7',
      percent: 0,
      text: '{} characters remaining'
    }, {
      color: '#7de40b',
      percent: 0.7,
      text: '{} characters remaining. Good job!'
    }, {
      color: '#e49d0b',
      percent: 0.9,
      text: 'Wow, only {} characters left. Let’s keep it short :)'
    }, {
      color: '#ff0000',
      percent: 1,
      text: 'Yep, we are done. {} characters left'
    }]

    return characterRemainColor(data, currentPercent);
  }

  constructor(charLimit?: number) {
    this.characterLimit = charLimit;
  }

  @action.bound
  updateMarkdown(markdown: string) {
    this.markdown = markdown;
  }
}