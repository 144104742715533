import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { Contact as ContactModel } from '@models/builder/customization/elements';
import Label from '../common/Label';
import * as styles from './contact.module.sass';
import ElementContent from '../common/ElementContent';

interface IContactProps {
  element: ContactModel
}

const Contact: React.FunctionComponent<IContactProps> = (props) => {
  return (
    <div>
      <Label helpText={props.element.help_text}  label={props.element.label} />
      <ElementContent>
        <div style={{ marginTop: '15px' }}>
          <div className={styles.contactLabel}>
            <p className={styles.label}>Name</p>
            <input onChange={(e) => props.element.setName(e.target.value)} value={props.element.name!} type='text'></input>
          </div>
          <div className={styles.contactLabel}>
            <p className={styles.label}>Email</p>
            <input onChange={(e) => props.element.setEmail(e.target.value)} value={props.element.email!} type='email'></input>
          </div>
          <div className={styles.contactLabel}>
            <p className={styles.label}>Phone</p>
            <input onChange={(e) => props.element.setPhone(e.target.value)} value={props.element.phone!} type='text'></input>
          </div>
        </div>
      </ElementContent>
    </div>
  )
}

export default observer(Contact);