
import * as React from 'react';
import { observer } from 'mobx-react';
import Icon from '@components/common/Icon';
import * as styles from './social.module.sass';
import BlockLabel from '@components/builder/common/BlockLabel';
import Modal from '@components/common/Modal';
import ModalContent from './ModalContent';
import SocialMediaPickerModel from '@models/builder/common/SocialMediaPicker';

export interface ISocialMediaPickerProps {
  label?: string;
  picker: SocialMediaPickerModel;
  networks: {name: string; example_link: string;}[];
}

interface State {
  modalOpen: boolean;
}

@observer
export default class SocialMediaPicker extends React.Component<ISocialMediaPickerProps, State> {
  constructor(props: ISocialMediaPickerProps) {
    super(props);
    
    this.state = {
      modalOpen: false
    }
  }

  public render() {
    return (
      <div className={styles.container}>
        {this.props.label ?
          <div className={styles.labelBlock}>
            <BlockLabel text={this.props.label} />
          </div>
          : null}
        <div onClick={() => this.setState({ modalOpen: true })} className={styles.button}>
          <p>{this.props.picker.hasData ? 'Edit Social Media' : 'Choose social media to display'}</p>
          <Icon type='plus' />
        </div>
        <Modal maxWidth={715} onRequestClose={() => this.setState({ modalOpen: false })} isOpen={this.state.modalOpen}>
          <ModalContent close={() => this.setState({ modalOpen: false})} networks={this.props.networks} picker={this.props.picker} />
        </Modal>
      </div>
    );
  }
}
