import * as React from 'react'
import { observer } from 'mobx-react';
import * as styles from './textarea.module.sass';
import Textarea from 'react-textarea-autosize';
import BlockLabel from '@components/builder/common/BlockLabel';

interface ITextAreaProps {
  value: string,
  onChange: (value: string) => void;
  placeholder?: string;
  characters?: {
    color: string;
    charactersRemaining: number;
    text: string;
  },
  label?: string;
  style?: React.CSSProperties;
}

const TextArea: React.FunctionComponent<ITextAreaProps> = (props) => {
  return (
    <div className={styles.wrapper}>
      {props.label ?
        <div className={styles.blockContainer}>
          <BlockLabel text={props.label} />
        </div>
      : null}
      <Textarea
        className={styles.textarea}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
        style={props.style}
      />
      {props.characters? 
        <p
          style={{ color: props.characters.color }}
          className={styles.characters}>
          {props.characters.text.replace('{}', props.characters.charactersRemaining.toString())}
        </p>
      : null}
    </div>
  );
}

export default observer(TextArea);