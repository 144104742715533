import * as React from 'react'
import { observer } from 'mobx-react';
import * as styles from './suggest.module.sass';
import className from 'classnames';
import ClickOutside from 'react-click-outside';
import CircleEmpty from '@assets/elements/suggestion/circle-empty.svg';
import CircleFull from '@assets/elements/suggestion/circle-full.svg';

interface ISuggestInputProps {
  open: boolean;
  placeholder?: string;
  items: {
    label: string;
    values: string[];
  }[];
  onChange: (val: string) => void;
  onSelect: (val: string) => void;
  toggle: (open: boolean) => void;
  value: string;
}

const SuggestInput: React.FunctionComponent<ISuggestInputProps> = (props) => {
  let inputClass = className(styles.input, { [styles.inputActive]: props.open })

  let suggestions = props.items.map((item) => {

    return (
      <div className={styles.itemContainer} key={item.label}>
        <p onClick={() => props.onSelect(item.label)} key={item.label} className={styles.item}>{item.label}</p>
      </div>
    )
  })

  function toggle(tog: boolean) {
    if (!tog && !props.open) {
      return;
    }

    props.toggle(tog)
  }

  return (
    <ClickOutside onClickOutside={() => toggle(false)}>
      <div style={{position: 'relative'}}>
        <div className={styles.inputContainer}>
          <div className={styles.suggestBox}>
            <div className={styles.line}></div>
            <p onClick={() => toggle(true)} className={styles.text}>Suggest</p>
          </div>
          <input value={props.value} onChange={(e) => props.onChange(e.target.value)} placeholder={props.placeholder} className={inputClass} type='text' />
        </div>
        {props.open ?
          <div className={styles.menu}>
            {suggestions}
          </div>
          : null
        }
      </div>
    </ClickOutside>

  )
}

export default observer(SuggestInput);