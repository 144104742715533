import ProposalStore from "./ProposalStore";
import RootStore from "./RootStore";
import { observable, action, flow, computed } from "mobx";


interface IForm {
  created_at: string;
  id: string;
  name: string;
  status: string;
  template_category_id: string;
  updated_at: string;
}

interface ICategory {
  created_at: string;
  id: string;
  name: string;
  suggestion_group_id: string;
  updated_at: string;
}

export default class FormStore {

  @observable
  readonly forms = observable<IForm>([]);

  @observable
  readonly formCategories = observable <ICategory>([]);

  @computed get categoryOptions() {
    return this.formCategories.map((item) => {
      return { label: item.name, value: item.id }
    })
  }

  @computed get groupedCategories() {
    return this.formCategories.map(category => {
      let forms = this.forms.filter(form => form.template_category_id === category.id);
      return {
        ...category,
        forms
      }
    })
  }

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }

  @action.bound
  pullForms = flow(function* (this: FormStore) {
    try {
      let forms = yield this.root.api.userForms();
      this.forms.replace(forms);
    } catch (error) {
      console.error(error);
    }
  })

  @action.bound
  pullCategories = flow(function* (this: FormStore) {
    try {
      let categories = yield this.root.api.formCategories();
      this.formCategories.replace(categories);
    } catch (error) {
      console.error(error);
    }
  })

  @action.bound
  fetchFormsAndCategories() {
    this.pullCategories();
    this.pullForms();
  }
}