
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/appendix';
import FileUpload from '@components/builder/common/FileUpload';
import Textarea from '@components/builder/common/Textarea';
import * as styles from './appendix.module.sass';
import Icon from '@components/common/Icon';

export interface IInstanceProps {
  instance: Model.AppendixInstance;
  configuration: Model.IAppendixConfig;
}

interface State {}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {
    return (
      <div className={styles.item}>
        <div className={styles.fileUpload}>
          <div onClick={() => this.props.instance.removeAppendix()} className={styles.close}>
            <Icon type='windowClose' />
          </div>
          <FileUpload allowAnyFile file={this.props.instance.file} />
        </div>
        <Textarea placeholder='Appendix text...' style={{ minHeight: '50px' }} onChange={this.props.instance.updateDescription} value={this.props.instance.description} />
      </div>
    );
  }
}
