import * as shortid from 'shortid';
import { observable, action } from 'mobx';
import { serializable, object, identifier } from 'serializr';
import { IPackageItem } from './interfaces';
import File, { IFile } from '@util/elements/FileUpload';


export default class PackageItem implements IPackageItem {
  @observable
  @serializable(identifier())
  id = shortid.generate();

  @observable
  @serializable
  text: string = '';

  @observable
  @serializable
  isHeadline = false;

  @observable
  @serializable
  attachmentNote: string | undefined;

  @observable
  @serializable(object(File))
  file = new File()

  @observable
  selected = false;

  @observable
  dropdownActive = false;

  @observable
  attachmentModalOpen = false;

  @action
  changeText(value: string) {
    this.text = value;
  }

  @action
  toggleSelect(toggle: boolean) {
    if (this.dropdownActive && toggle === false) {
      return;
    }

    this.selected = toggle;
  }

  @action
  toggleDropdown(toggle: boolean) {
    this.dropdownActive = toggle;
    if (!toggle) {
      this.toggleSelect(false);
    }
  }

  @action
  toogleAttachment(toggle: boolean) {
    this.attachmentModalOpen = toggle;
  }

  @action
  attachmentSave(file: IFile, note: string | undefined) {
    this.file.uploadFile(file);
    this.attachmentNote = note;
  }

  @action.bound
  toggleHeadline() {
    this.isHeadline = !this.isHeadline;
  }
}