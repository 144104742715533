import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { TextInput as TextInputModel } from '@models/builder/customization/elements';
import Proposal from '@models/builder/proposal';
import TextInput from '../common/TextInput';
import Label from '../common/Label';
import ElementContent from '../common/ElementContent';

interface ITextInputProps {
  element: TextInputModel;
  proposal: Proposal;
}

const TextInputProxy: React.FunctionComponent<ITextInputProps> = (props) => {
  return (
    <TextInput
      value={props.element.value}
      onChange={props.element.setValue}
      defaultValue={props.proposal.name}
      help_text={props.element.help_text}
      label={props.element.label}
    />
  )
}

export default observer(TextInputProxy);