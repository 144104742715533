import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from '../customization.module.sass';
import Proposal from '@models/builder/proposal';
import Elements from '../elements';
import Currency from '../settings/Currency';
import DateFormat from '../settings/DateFormat';

interface IGeneralProps {
  proposal: Proposal;
}

let General: React.FunctionComponent<IGeneralProps> = (props) => {

  let customization = props.proposal.template.customization;
  let elements = customization.general.map(item => {
    let Component = Elements[item.type];
    return <Component key={item.id} element={item} proposal={props.proposal} />
  })

  return (
    <div className={styles.elements}>
      <Currency />
      <DateFormat />
      {elements}
    </div>
  )
}

General = observer(General);
export default General;