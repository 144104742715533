import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './crm.module.sass';
import Lead from './Lead';
import Proposal from '@app/models/builder/proposal';
import { rootContext } from '@app/index';

interface ICRMProps {
  proposal: Proposal;
}

let CRM: React.FunctionComponent<ICRMProps> = (props) => {
  const user = React.useContext(rootContext).user

  let leads = props.proposal.crm.leads.length === 0 ? props.proposal.crm.exampleLeads : props.proposal.crm.leads;

  let leadsComponents = leads.map((item, index) => {
    let len = leads.length - index;
    return <Lead key={item.id} lead={item} proposal={props.proposal} hide={len > 1 && !user.premium && !item.example} />
  })

  return (
    <div>
      <p className={styles.headline}>Leads</p>
      <div className={styles.list}>
        <div className={styles.columnMedium}>
          <p>Name</p>
        </div>
        <div className={styles.columnMedium}>
          <p>Company</p>
        </div>
        <div className={styles.columnMedium}>
          <p>Interested in</p>
        </div>
        <div className={styles.columnLong}>
          <p>Message</p>
        </div>
        <div className={styles.columnSmall}>
          <p>Date</p>
        </div>
        <div className={styles.columnMedium}>
          <p>Status</p>
        </div>
      </div>
      <div>
        {leadsComponents}
      </div>
    </div>
  );
}

export default observer(CRM);