
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import * as Model from '@models/builder/elements/finance';
import * as styles from './finance.module.sass';
import Input, { CurrencyInputFormat } from '@components/builder/common/Input';
import Icon from '@components/common/Icon';
import { colors } from '@util/constants';
import CurrencyFormat from '@components/builder/common/CurrencyFormat';
import { SettingsContext } from '../../Form/context';

export interface IInstanceProps {
  instance: Model.FinanceInstance;
  configuration: Model.IFinanceConfig;
}

interface State {}

let Instance: React.FunctionComponent<IInstanceProps> = (props) => {
  return (
    <div>
      <div className={styles.container}>
        <FinanceTable
          addNew={props.instance.addExpense}
          items={props.instance.expenses}
          deleteItem={props.instance.deleteExpense}
          color={colors.gamboge}
          footerText='Total expenese:'
          headerText='Expenses'
          total={props.instance.totalExpense}
          addNewText='+ Add new expense'
          placeholder={{
            label: 'Speakers salary',
            value: '$3000'
          }}
        />
        <FinanceTable
          color={colors.azureRadiance}
          addNew={props.instance.addIncome}
          items={props.instance.income}
          deleteItem={props.instance.deleteIncome}
          footerText='Total income:'
          headerText='Income'
          total={props.instance.totalIncome}
          addNewText='+ Add new income item'
          placeholder={{
            label: 'Revenue from tickets',
            value: '$1000'
          }}
        />
      </div>
    </div>
  )
}

interface IFinanceTableProps {
  items: Model.FinanceItem[];
  addNew: () => void;
  color: string;
  placeholder: {
    label: string;
    value: string;
  }
  deleteItem: (item: Model.FinanceItem) => void;
  footerText: string;
  headerText: string;
  total: number;
  addNewText: string;
}

let FinanceTable: React.FunctionComponent<IFinanceTableProps> = (props) => {
  const settings = React.useContext(SettingsContext);

  let items = props.items.map((item) => {
    return (
      <div key={item.id} className={styles.item}>
        <div className={styles.label}>
          <Input placeholder={props.placeholder.label} onChange={item.updateLabel} value={item.label} className={styles.input} />
        </div>
        <div className={styles.currency}>
          <CurrencyInputFormat
            placeholder={props.placeholder.value}
            onValueChange={(value) => item.updateValue(value.floatValue)}
            value={item.value}
            className={styles.input}
            styles={{color: props.color}}
            currency={settings.currency}
          />
        </div>
        <div onClick={() => props.deleteItem(item)} className={styles.deleteIcon}>
          <Icon type='trashcan' />
        </div>
      </div>
    )
  })

  return (
    <div className={styles.financeTable}>
      <div className={styles.header}>
        <p>{props.headerText}</p>
      </div>
      <div className={styles.items}>
        {items}
        <p onClick={() => props.addNew()} className={styles.addNew}>{props.addNewText}</p>
      </div>
      <div className={styles.footer}>
        <p className={styles.footerExplain}>{props.footerText}</p>
        <CurrencyFormat currency={settings.currency} style={{ color: props.color }} className={styles.footerAmount} value={props.total} />
      </div>
    </div>
  )
}

FinanceTable = observer(FinanceTable);
Instance = observer(Instance);

export default Instance
