import RootStore from '@stores/RootStore';
import UiStore from '@stores/uiStore';
import { observable, computed, action, reaction } from 'mobx';


export default class Sidebar {
  root: RootStore;
  ui: UiStore;

  @observable
  isExpanded = true;

  @observable
  hiddenExpandRoutes = [
    '/proposals/new/proposal',
    '/proposals/new/example',
    '/account/pricing',
    '/account/premium/complete',
    '/account/premium/error'
  ]

  @computed get hiddenRoute() {
    let path = this.root.router.location.pathname;
    return this.hiddenExpandRoutes.includes(path);
  }

  @computed get hidden() {
    return !this.isExpanded || this.hiddenRoute;
  }

  constructor(root: RootStore, ui: UiStore) {
    this.root = root;
    this.ui = ui;
  }
}
