import * as React from 'react'
import { observer } from 'mobx-react-lite';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import Currency, { CurrencyKey } from '@common/util/currencies';

interface ICurrencyFormaProps {
  currency: CurrencyKey;
  style?: React.CSSProperties;
  className?: string;
  value: number;
}

const CurrencyFormat: React.FunctionComponent<ICurrencyFormaProps> = (props) => {

  let currency = Currency[props.currency];

  return (
    <NumberFormat
      style={props.style}
      thousandSeparator=' '
      {...currency}
      value={props.value}
      className={props.className}
      displayType='text'
    />
  )
}

export default observer(CurrencyFormat);