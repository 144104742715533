import * as shortid from 'shortid';
import { observable, computed } from 'mobx';
import { serializable, object, identifier, custom, SKIP } from 'serializr';
import { ITierTickets, ITierPrice, ITier, ITierData } from './interfaces';

class TierTickets implements ITierTickets {
  @observable
  @serializable
  current: number | undefined;

  @observable
  @serializable
  available: number | undefined;
}

class TierPrice implements ITierPrice {
  @observable
  @serializable
  price: number | undefined;

  @observable
  @serializable
  type: 'month' | 'week' | 'day' | 'year' | 'month-3' | 'one-time';

  constructor(price: number | undefined, type: 'month' | 'week' | 'day' | 'year' | 'month-3' | 'one-time') {
    this.price = price;
    this.type = type;
  }

  keys() {
    return {
      'month': 'Month',
      'day': 'Day',
      'week': 'Week',
      'year': 'Year',
      'month-3': '3 Months',
      'one-time': 'One Time'
    };
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get humanType() {
    let keys = this.keys();
    let humanType = keys[this.type];
    return humanType;
  }
}

export default class Tier implements ITier {
  @observable
  @serializable(identifier())
  id = shortid.generate();

  @observable
  @serializable
  name: string;

  @observable
  @serializable(object(TierPrice))
  pricing: TierPrice = new TierPrice(undefined, 'one-time');

  @observable
  @serializable
  description: string | undefined;

  @observable
  @serializable(object(TierTickets))
  tickets: TierTickets = {
    current: undefined,
    available: undefined
  };

  @observable
  @serializable
  badge: string | undefined;

  @observable
  selected = false;

  toggleSelect(select: boolean) {
    this.selected = select;
  }

  saveNewData(data: ITierData) {
    this.name = data.name;
    this.description = data.description;
    this.badge = data.badge;
    this.pricing.price = data.pricing.price;
    this.pricing.type = data.pricing.type;
    this.tickets.available = data.tickets.available;
    this.tickets.current = data.tickets.current;
  }
}