import * as shortid from 'shortid';
import { observable, action, computed, reaction } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, custom, SKIP } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

// releated interfacess
export interface IFinanceItem {
  label: string;
  value: number | undefined;
}

// model interfaces

export interface IFinanceConfig extends IBaseConfiguration {}

export interface IFinanceInstance extends IBaseInstanceElement  {
  expenses: IFinanceItem[];
  income: IFinanceItem[];
}

export interface IFinance extends IElement<IFinanceConfig, IFinanceInstance> {}

// related classes
export class FinanceItem implements IFinanceItem {

  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable
  label = ''

  @observable
  @serializable
  value: number | undefined;

  @action.bound
  updateLabel(val: string) {
    this.label = val;
  }

  @action.bound
  updateValue(val: number) {
    this.value = val;
  }
}

// model classes

export class FinanceInstance implements IFinanceInstance {
  @observable
  id = shortid.generate()

  @observable
  @serializable(list(object(FinanceItem)))
  readonly expenses = observable<FinanceItem>([]);

  @observable
  @serializable(list(object(FinanceItem)))
  readonly income = observable<FinanceItem>([]);

  @computed get totalExpense() {
    return this.expenses.reduce((prevVal, currentVal) => {
      return prevVal + (currentVal.value || 0)
    }, 0)
  }

  @computed get totalIncome() {
    return this.income.reduce((prevVal, currentVal) => {
      return prevVal + (currentVal.value || 0)
    }, 0)
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return this.income.some((item) => !!item.value && !!item.label) || this.expenses.some((item) => !!item.value && !!item.label)
  }

  parent: Finance
  constructor(parent: Finance) {
    this.parent = parent;

    if (this.expenses.length === 0 && this.income.length === 0) {
      this.addExpense()
      this.addIncome()
    }
  }

  @action.bound
  addExpense() {
    this.expenses.push(new FinanceItem())
  }

  @action.bound
  addIncome() {
    this.income.push(new FinanceItem())
  }

  @action.bound
  deleteExpense(item: FinanceItem) {
    this.expenses.remove(item);
  }

  @action.bound
  deleteIncome(item: FinanceItem) {
    this.income.remove(item);
  }

  static schema() {
    return getDefaultModelSchema(FinanceInstance).factory = (context: Context) => {
      return new FinanceInstance(context.args.parent);
    };
  }
}

export default class Finance extends BaseElement<IFinance, IFinanceConfig, FinanceInstance> {

  @observable
  @serializable(elementInstanceSerializer(FinanceInstance))
  readonly instances = observable<FinanceInstance>([]);

  static getInstanceClass() {
    return FinanceInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);
    
    if (!this.hasInstance) {
      this.instances.push(new FinanceInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(Finance).factory = (context: Context) => {
      return new Finance(context.args.root, context.args.proposal);
    };
  }
}

FinanceInstance.schema()
Finance.schema();