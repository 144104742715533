import * as React from 'react';
import Icon from '@components/common/Icon';
import * as styles from './dropdown.module.sass';
import OutsideClickHandler from 'react-outside-click-handler';
import { PackagesInstance } from '@models/builder/elements/packages';
import PackageItem from '@models/builder/elements/packages/packageItem';
import { observer } from 'mobx-react';
import Button from '../Button';

export interface DropdownProps {
    item: PackageItem;
    package: PackagesInstance;
}

interface State {
    deleteOpen: boolean;
}

@observer
export default class Dropdown extends React.Component<DropdownProps, State> {
    constructor(props: DropdownProps) {
        super(props);

        this.state = {
            deleteOpen: false
        };
    }

    toggleDropdown(toggle: boolean) {
        if (!this.state.deleteOpen) {
            this.props.item.toggleDropdown(toggle);
        }
    }

    deleteToggle(toggle: boolean) {
        this.setState({ deleteOpen: toggle });
    }

    public render() {
        if (!this.props.item.dropdownActive) {
            return null;
        }

        return (
            <div className={styles.dropdown}>
                <OutsideClickHandler onOutsideClick={() => this.toggleDropdown(false)}>
                    <div onClick={() => this.deleteToggle(true)} className={styles.option}>
                        <Icon color='primary2' type='trash' />
                        <p>Delete Benefit</p>
                        {this.state.deleteOpen ?
                            <OutsideClickHandler onOutsideClick={() => this.deleteToggle(false)}>
                                <div className={styles.confirmDelete}>
                                    <p className={styles.deleteText}>Delete benefit?</p>
                                    <Button onClick={() => this.props.package.deletePackageItem(this.props.item)} type='confirm' text='Delete' />
                                </div>
                            </OutsideClickHandler>
                            : null
                        }
                    </div>

                    <div onClick={() => this.props.item.toggleHeadline()} className={styles.option}>
                        <Icon style={{fontSize: '13px'}} color='primary2' type='newspapers' inheritStyle />
                        <p>{!this.props.item.isHeadline ? 'Make headline' : 'Remove headline'}</p>
                    </div>

                </OutsideClickHandler>
            </div>
        );
    }
}
