export default function ease(startValue: number, endValue: number, durationMs: number, cb: (value: number) => void) {
  const stepCount = durationMs / 16;
  const valueIncrement = (endValue - startValue) / stepCount;
  const sinValueIncrement = Math.PI / stepCount;

  let currentValue = startValue;
  let currentSinValue = 0;

  function step() {
    currentSinValue += sinValueIncrement;
    currentValue += valueIncrement * (Math.sin(currentSinValue) ** 2) * 2;

    if (currentSinValue < Math.PI) {
      cb(currentValue);
      window.requestAnimationFrame(step);
    } else {
      cb(endValue);
    }
  }

  step();
}