import * as React from 'react'
import { observer } from 'mobx-react';
import Icon from '@app/components/common/Icon';
import { IconTypes } from '@app/components/common/Icon/icons';
import * as styles from './card.module.sass';
import cn from 'classnames';

interface ICardProps {
  headline: string;
  icon: IconTypes;
  highlight?: boolean;
}

const Card: React.FunctionComponent<ICardProps> = (props) => {
  return (
    <div className={cn(styles.card, { [styles.cardHighlight]: props.highlight })}>
      <div className={styles.headline}>
        <Icon inheritStyle type='eye' />
        <p>{props.headline}</p>
      </div>
      {props.children}
    </div>
  )
}

interface IValueCardProps {
  headline: string;
  icon: IconTypes;
  value: string | number;
  text: string;
  highlight?: boolean;
}

let ValueCard: React.FunctionComponent<IValueCardProps> = (props) => {
  return (
    <Card highlight={props.highlight} headline={props.headline} icon={props.icon}>
      <div className={styles.valueContainer}>
        <p className={styles.value}>{props.value} <span>{props.text}</span></p>
      </div>
    </Card>
  )
}

ValueCard = observer(ValueCard);
export { ValueCard } 
export default observer(Card);