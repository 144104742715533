import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './new.module.sass';
import Input from '@components/common/Input';
import { DropdownBasic } from '@components/common/Dropdown';
import { rootContext } from '@app/index';
import cn from 'classnames';
import Icon from '@components/common/Icon';
import Example from '../common/Example';
import { Link } from 'react-router-dom';

interface IOption {
  label: string;
  value: string;
}

interface INewProposalProps {}

let NewProposal: React.FunctionComponent<INewProposalProps> = (props) => {
  const rootStore = React.useContext(rootContext);
  const form = rootStore.forms.processCreatorForm;

  React.useEffect(() => {
    rootStore.formStore.fetchFormsAndCategories();
  }, [])

  let examples = rootStore.proposalStore.examples.map(item => <Example key={item.id} example={item} />)

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <form onSubmit={form.onSubmit} className={styles.content}>
          <Link className={styles.goBack} to='/proposals'>{'< Back to dashboard'}</Link>
          <h1 className={styles.headline}>Let's start</h1>
          <div className={styles.item}>
            <Input
              label='Name your proposal'
              placeholder='Paris Jazz Festival for example'
              name='proposalName'
              onChange={form.name.onChange}
              value={form.name.value}
              error={form.name.error}
              required
            />
          </div>
          <div className={cn(styles.item, styles.dropdown)}>
            <p>Project category</p>
            <DropdownBasic
              grouped={form.formattedGroup}
              value={form.selectedForm}
              placeholder='Select your proposal type'
              onChange={(val: IOption) => {
                form.selectForm(val.value);
              }}
            />
          </div>
          <div className={styles.control}>
            <button disabled={form.inProgress} className={styles.button}>
              {form.inProgress ? 'Loading...' : 'Create your sponsorship proposal 🚀'}
              <Icon rotate={180} type='arrow' />
            </button>
          </div>
        </form>
      </div>
      {examples.length > 0 &&
        <div className={styles.examples}>
          <p className={styles.exampleHeadline}>Get your proposal done faster with templates</p>
          <div className={styles.exampleList}>
            {examples}
          </div>
        </div>
      }
    </div>
  )
}

export default observer(NewProposal);