
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/websitePicker';
import Input from '@components/builder/common/Input';
import Icon from '@components/common/Icon';
import DoubleLine from '@assets/helpers/double-line.svg';
import Textarea from '@components/builder/common/Textarea';
import ItemDelete from '@assets/helpers/item-delete.svg';
import * as styles from './website.module.sass';


export interface IInstanceProps {
  instance: Model.WebsitePickerInstance,
  configuration: Model.IWebsitePickerConfig
}

interface State { }

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {


    return (
      <div className={styles.instance}>
        <div className={styles.container}>
          <div>
            <div style={{ maxWidth: '210px' }}>
              <Input placeholder='Website name...' label={this.props.instance.order === 1 ? 'Name' : undefined} onChange={this.props.instance.updateName} value={this.props.instance.name} />
            </div>
            <Input placeholder='https://mywebsite.com' label={this.props.instance.order === 1 ? 'Link' : undefined} onChange={this.props.instance.updateLink} value={this.props.instance.link} />
          </div>
          <div onClick={() => this.props.instance.delete()} className={styles.deleteIcon}>
            <Icon type='trashcan' />
          </div>
        </div>
        {
          this.props.instance.hasInfo || this.props.instance.expand ?
            <div className={styles.moreInfo}>
              <div className={styles.lineContainer}>
                <DoubleLine />
                <ItemDelete onClick={() => this.props.instance.toggleExpand(false)} className={styles.hideMoreInfo} />
              </div>
              <div className={styles.item}>
                <Textarea
                  label={'description'}
                  onChange={this.props.instance.updateDescription}
                  value={this.props.instance.description}
                  placeholder='Add a description explaining how this exposure can benefit to your.'
                />
              </div>
            </div>
          :
            <p className={styles.explainExpand} onClick={() => this.props.instance.toggleExpand(true)}>+ Add more information</p>
        }
      </div>
    )
  }
}
