import * as shortid from 'shortid';
import { observable, computed, action, reaction } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from '../baseElement';
import { serializable, object, list, Context, getDefaultModelSchema, SKIP, serialize, deserialize, update, custom } from 'serializr';
import { ITier, IPackageItem, IPackageItemToTierConnection } from './interfaces';
import defaultData from './defaultData';
import emitter from '@util/emitter';

import Tier from './tier';
import PackageItemToTierConnection from './connection';
import PackageItem from './packageItem';
import RootStore from '@app/stores/RootStore';
import Proposal from '../../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';
import { trackPackageTierChange } from '@app/util/analytics';

// model interfaces
export interface IPackagesConfig extends IBaseConfiguration {
  tooltip: string;
}

export interface IPackagesInstance extends IBaseInstanceElement  {
  tiers: ITier[];
  packageItems: IPackageItem[];
  itemToTierConnections: IPackageItemToTierConnection[];
}

export interface IPackages extends IElement<IPackagesConfig, IPackagesInstance> {}

// model classes

export class PackagesInstance implements IPackagesInstance {
  @observable
  @serializable
  id = shortid.generate()

  
  @serializable(custom((item: any) => item, (item: any) => SKIP ))
  filled = true;

  @observable
  @serializable(list(object(Tier)))
  readonly tiers = observable<Tier>([]);

  @observable
  @serializable(list(object(PackageItem)))
  readonly packageItems = observable<PackageItem>([]);

  @observable
  @serializable(list(object(PackageItemToTierConnection)))
  readonly itemToTierConnections = observable<PackageItemToTierConnection>([]);

  @observable
  readonly autocompleteItems = observable<{ label: string; value: string; }>([])

  @computed get activePackageAttachment() {
    return this.packageItems.find((item) => item.attachmentModalOpen);
  }

  @computed get specialFocus() {
    let focus = !!this.activePackageAttachment;
    return focus;
  }

  @computed get tierOverLimit() {
    if (this.parent.root.user.premium) {
      return false;
    }

    if (this.tiers.length >= this.parent.limitation.maxPackageTiers) {
      return true;
    } else {
      return false;
    }
  }

  @computed get benefitsOverLimit() {
    if (this.parent.root.user.premium) {
      return false;
    }

    if (this.packageItems.length >= this.parent.limitation.maxPackageBenefits) {
      return true;
    } else {
      return false;
    }
  }

  parent: Packages
  constructor(parent: Packages) {
    this.parent = parent;

    reaction(
      () => this.tiers.length,
      (len) => {
        trackPackageTierChange(len, this.parent.proposal.id);
      }
    )
  }

  @action
  addPackageItem() {
    if (this.benefitsOverLimit) {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'benefit'
      })

      return;
    }

    let packageItem = new PackageItem();
    this.packageItems.push(packageItem);
    this.updateConnections();
  }

  @action
  addTier() {
    if (this.tierOverLimit) {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'tier'
      })

      return;
    }

    let tier = new Tier();
    tier.name = `Tier ${this.tiers.length + 1}`;
    this.tiers.push(tier);
    this.updateConnections();
  }

  @action
  movePackageItems(sourceIndex: number, destinationIndex: number) {
    let cutOut = this.packageItems.splice(sourceIndex, 1)[0];
    this.packageItems.splice(destinationIndex, 0, cutOut);
    this.updateConnections();
  }

  @action
  moveTiers(sourceIndex: number, destinationIndex: number) {
    let cutOut = this.tiers.splice(sourceIndex, 1)[0];
    this.tiers.splice(destinationIndex, 0, cutOut);
    this.updateConnections();
  }

  @action
  duplicatePackageItem(item: PackageItem) {
    let serializedItem = serialize(item);
    let cloned = deserialize(PackageItem, serializedItem);
    cloned.id = shortid.generate();
    cloned.text = `${cloned.text} copy`;
    this.packageItems.splice(this.packageItems.indexOf(item) + 1, 0, cloned);
    this.copyConnections(item, cloned);
  }

  @action
  duplicateTier(tier: Tier) {
    let serializedItem = serialize(tier);
    let cloned = deserialize(Tier, serializedItem);
    cloned.id = shortid.generate();
    cloned.name = `${cloned.name} copy`;
    this.tiers.splice(this.tiers.indexOf(tier) + 1, 0, cloned);
    this.copyConnections(tier, cloned);
  }

  @action
  deletePackageItem(item: PackageItem) {
    this.packageItems.remove(item);
    let filter = this.itemToTierConnections.filter((con) => {
      return item.id !== con.packageItem.id;
    });
    this.itemToTierConnections.replace(filter);
  }

  @action
  deleteTier(tier: Tier) {
    this.tiers.remove(tier);
    let filter = this.itemToTierConnections.filter((item) => {
      return tier.id !== item.tier.id;
    });
    this.itemToTierConnections.replace(filter);
  }

  @action
  updateConnections() {
    for (let item of this.packageItems) {
      for (let tier of this.tiers) {
        let connection = this.itemToTierConnections.find((con) => con.packageItem === item && con.tier === tier);
        if (!connection) {
          this.itemToTierConnections.push(new PackageItemToTierConnection(tier, item));
        }
      }
    }
  }

  @action
  copyConnections(item: Tier | PackageItem, newItem: Tier | PackageItem) {
    let connections = this.itemToTierConnections.filter((connection) => {
      if (item instanceof Tier) {
        return item === connection.tier;
      } else if (item instanceof PackageItem) {
        return item === connection.packageItem;
      }
      return;
    });

    for (let connection of connections) {
      if (newItem instanceof Tier) {
        this.itemToTierConnections.push(new PackageItemToTierConnection(newItem, connection.packageItem, connection.active));
      } else if (newItem instanceof PackageItem) {
        this.itemToTierConnections.push(new PackageItemToTierConnection(connection.tier, newItem, connection.active));
      }
    }
  }

  @action.bound
  fetchAutocomplete() {
    return new Promise((resolve) => resolve())
  }

  static schema() {
    return getDefaultModelSchema(PackagesInstance).factory = (context: Context) => {
      return new PackagesInstance(context.args.parent);
    };
  }
}

export default class Packages extends BaseElement<IPackages, IPackagesConfig, PackagesInstance> {

  @observable
  @serializable(elementInstanceSerializer(PackagesInstance))
  readonly instances = observable<PackagesInstance>([]);

  static getInstanceClass() {
    return PackagesInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);
    
    if (!this.hasInstance) {
      let instance = new PackagesInstance(this);
      update(instance, defaultData);
      instance.updateConnections();
      this.instances.push(instance)
    }
  }

  static schema() {
    return getDefaultModelSchema(Packages).factory = (context: Context) => {
      return new Packages(context.args.root, context.args.proposal);
    };
  }
}

PackagesInstance.schema()
Packages.schema();