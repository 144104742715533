
import * as React from 'react';
import { observer } from 'mobx-react';
import ElementHeadline from '@components/builder/common/ElementHeadline';
import * as styles from './finance.module.sass';
import FinanceModel from '@models/builder/elements/finance';
import Instance from './Instance';

export interface IFinanceProps {
  model: FinanceModel;
}

interface State { }

@observer
export default class Finance extends React.Component<IFinanceProps, State> {
  constructor(props: IFinanceProps) {
    super(props);
  }

  public render() {

    let instances = this.props.model.instances.map((item) => {
      return <Instance key={item.id} configuration={this.props.model.configuration} instance={item} />
    })

    return (
      <div>
        <ElementHeadline
          complete={this.props.model.complete}
          text={this.props.model.name}
          markdown={this.props.model.configuration.tooltip}
        />
        {instances}
      </div>
    );
  }
}
