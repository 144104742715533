import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './checkbox.module.sass';
import Label from '../Label';

import CustCheckboxInactive from '@assets/helpers/cust-checkbox-inacitve.svg';
import CustCheckboxActive from '@assets/helpers/cust-checkbox-active.svg';
import ElementContent from '../ElementContent';

interface ICheckboxProps {
  checked: boolean;
  toggleCheck: () => void;
  text: string;
  name: string;
  label?: string;
  help_text?: string;
  premium?: boolean;
}

let Checkbox: React.FunctionComponent<ICheckboxProps> = (props) => {

  function toggle() {
    props.toggleCheck()
  }

  return (
    <div>
      
      {props.label &&
        <Label label={props.label} helpText={props.help_text} premiumLabel={props.premium} />
      }
      <ElementContent premium={props.premium}>
        <div onClick={toggle} className={styles.label}>
          <input style={{ display: 'none' }} type='checkbox' name={props.name} />
          {props.checked ? <CustCheckboxActive /> : <CustCheckboxInactive />}
          <span>{props.text}</span>
        </div>
      </ElementContent>
    </div>
  )
}

Checkbox = observer(Checkbox)
export default Checkbox;