import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './layout.module.sass';
import LogoEA from '@assets/logos/logo_EA.svg';

interface ILayoutProps {}

let Layout: React.FunctionComponent<ILayoutProps> = (props) => {
  if (!props.children || !Array.isArray(props.children)) {
    return null;
  }

  return (
    <div className={styles.layout}>
      <div className={styles.columnLeft}>
        <div className={styles.logo}>
          <LogoEA />
        </div>
        {props.children[0]}
      </div>
      <div className={styles.columnRight}>
        {props.children[1]}
      </div>
    </div>
  )
}

Layout = observer(Layout);
export default Layout;