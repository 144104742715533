
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/proportionSlider';
import Slider from '@components/builder/common/Slider';
import * as styles from './slider.module.sass';

export interface IInstanceProps {
  instance: Model.ProportionSliderInstance,
  configuration: Model.IProportionSliderConfig
}

interface State {}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {
    return (
      <div>
        <Slider
          trackColor={this.props.configuration.proportion1.color}
          railColor={this.props.configuration.proportion2.color}
          valueChange={this.props.instance.changeValue}
          value={this.props.instance.sliderValue}
        />
        <div className={styles.legend}>
          <p style={{ color: this.props.configuration.proportion1.color }} className={styles.legendText}>{this.props.configuration.proportion1.name}: {this.props.instance.proportion1Value}%</p>
          <p style={{ color: this.props.configuration.proportion2.color }} className={styles.legendText}>{this.props.configuration.proportion2.name}: {this.props.instance.proportion2Value}%</p>
        </div>
      </div>
    );
  }
}
