import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Icon from '@app/components/common/Icon';
import styles from './done.module.sass';
import { Link } from 'react-router-dom';
import Done from '@assets/app/celebration_ilu@2x.jpg';
import { rootContext } from '@app/index';
import QS from 'query-string';

interface IPaymentDoneProps {
  name: string,
}

const PaymentDone: React.FunctionComponent<IPaymentDoneProps> = (props) => {
  const rootStore = React.useContext(rootContext);

  React.useEffect(() => {
    let query = QS.parse(props.location.search);
    let id = query.id as string;

    rootStore.user.verifyPremium(id);
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img style={{marginBottom: '30px'}} width={462} src={Done}></img>
        <h1>Thank you for trying out Sponseasy!</h1>
        <p className={styles.text}>Your plan is now active.</p>
        <Link to='/proposals#premium_success' className={styles.link}>
          <Icon inheritStyle type='arrow' />
          <p>Go back to the proposals</p>
        </Link>
      </div>
    </div>
  );
}

export default observer(PaymentDone);