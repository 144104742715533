
import * as React from 'react';
import { observer } from 'mobx-react';
import ReactModal from 'react-modal';
import Icon from '@components/common/Icon';
import * as styles from './modal.close.sass';

export interface IModalProps {
  isOpen: boolean;
  parent?: 'root' | 'builder'
  onRequestClose: () => void;
  maxWidth?: number;
  clean?: boolean;
  padding?: number;
}

interface State {}

@observer
export default class Modal extends React.Component<IModalProps, State> {

  constructor(props: IModalProps) {
    super(props);
    this.getParent = this.getParent.bind(this)
  }

  getParent() {
    if (!this.props.parent) {
      return document.querySelector('body')!
    }

    return document.getElementById(`${parent}`)!
  }

  public render() {

    let overlayClass = this.props.clean ? 'modal-overlay-clean' : 'modal-overlay'
    let containerClass = this.props.clean ? 'modal-container-clean' : 'modal-container'

    return (
      <div>
        <ReactModal
          isOpen={this.props.isOpen}
          parentSelector={this.getParent}
          onRequestClose={() => this.props.onRequestClose()}
          overlayClassName={overlayClass}
          className={containerClass}
          style={{
            content: {
              maxWidth: this.props.maxWidth,
              padding: this.props.padding
            }
          }}
        >
          <div className={styles.close} onClick={() => this.props.onRequestClose()}>
            <Icon type='close' />
          </div>
          {this.props.children}
        </ReactModal>
      </div>
    );
  }
}
