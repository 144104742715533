
import * as React from 'react';
import { observer } from 'mobx-react';
import ElementHeadline from '@components/builder/common/ElementHeadline';
import Model from '@models/builder/elements/multiSelect';
import Instance from './Instance';
import AddInstance from '@components/builder/common/AddInstance';
import * as styles from './multiselect.module.sass';

export interface IPercentageSplitterProps {
  model: Model
}

interface State { }

@observer
export default class PercentageSplitter extends React.Component<IPercentageSplitterProps, State> {
  public render() {
    let configuration = this.props.model.configuration;

    let content = this.props.model.instances.map((item) => {
      return <Instance key={item.id} configuration={this.props.model.configuration} instance={item} />
    })


    return (
      <div>
        <ElementHeadline complete={this.props.model.complete} text={this.props.model.name} markdown={configuration.tooltip} />
        <div>
          {content}
        </div>
      </div>
    );
  }
}
