
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/Dropdown';
import Select from 'react-select';
import { StateInput } from '@components/builder/common/Input';
import * as styles from './dropdown.module.sass';
import { DropdownBasic } from '@components/common/Dropdown';

export interface IDropdownInstanceProps {
  configuration: Model.IDropdownConfig;
  instance: Model.DropdownInstance;
}

interface State {}

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    padding: '3px',
    borderRadius: '2px',
    borderWidth: '1px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    boxShadow: 'none',
    fontSize: '14px',
    height: '40px',
    '&:hover': {
      border: `1px solid ${state.theme.colors.primary}`
    }
  }),
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      borderRadius: '2px',
      marginTop: '-1px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      border: `1px solid ${state.theme.colors.primary}`,
      borderTop: `1px solid ${state.theme.colors.neutral20}`,
      boxShadow: '0 2px 0 rgba(0, 118, 255, 0.2)',
      color: 'white',
      fontWeight: 'bold'
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      width: '0px'
    }
  },
  option: (provided: any, state: any) => {
    let option =  {
      ...provided,
      fontWeight: state.isSelected ? 'bold' : '400',
      color: 'black',
      fontSize: '14px',
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: state.theme.colors.primary,
        color: 'white',
        cursor: 'pointer'
      },
      ':active': {
        color: 'white',
        fontWeight: 'bold'
      }
    }
    return option
  },
}

@observer
export default class DropdownInstance extends React.Component<IDropdownInstanceProps, State> {
  public render() {
    return (
      <div>
        <DropdownBasic
          value={this.props.instance.selectedOption}
          onChange={(selectedOption: string) => {
            this.props.instance.changeOption(selectedOption)
          }}
          options={this.props.configuration.options}
          placeholder='Select...'
        />
        {this.props.instance.customSelected ?
          <div className={styles.customInput}>
            <StateInput placeholder={this.props.configuration.customPlaceholder} field={this.props.instance.customValue} />
          </div> : null
        }
      </div>
    );
  }
}
