import * as React from 'react'
import { observer } from 'mobx-react-lite';
import PageModel from '@models/builder/page';
import Section from '@components/builder/Form/Section';
import * as styles from './page.module.sass';
import Button from '@components/common/Button';
import ReactMarkdown from 'react-markdown';
import Icon from '@app/components/common/Icon';

import Lock from '@assets/builder/lock.png';
import { rootContext } from '@app/index';
import { Link } from 'react-router-dom';

interface IRendererProps {
  page: PageModel,
}

const Page: React.FunctionComponent<IRendererProps> = (props) => {
  const user = React.useContext(rootContext).user

  let sections = props.page.activeSections.map((item) => {
    return <Section key={item.id} section={item} />
  })

  let showPopup = true;

  if (props.page.canOpenPage) {
    showPopup = false;
  }

  if (user.premium) {
    showPopup = false;
  }

  return (
    <div>
      {showPopup ?
        <div className={styles.premium}>
          <div className={styles.description}>
            <div className={styles.headline}>
              <p>This section is</p>
              <Icon inheritStyle type='crown' />
              <p>Premium</p>
            </div>
            <div className={styles.premiumDescription}><ReactMarkdown source={props.page.description} /></div>
            <Link to='/account/pricing?from=section' className={styles.unlock}>Unlock this section</Link>
          </div>
          <div>
            <div style={{ backgroundImage: `url(${Lock})`, backgroundSize: 'cover', width: '160px', height: '100%', backgoundPosition: 'center', minHeight: '150px' }}></div>
          </div>
        </div>
        : null}

      {sections}
    </div>
  );
}

export default observer(Page);