import * as React from 'react'
import { observer } from 'mobx-react';
import RCslider, { Handle, HandleProps } from 'rc-slider';
import SliderController from '@assets/elements/slider-controler.svg';
import * as styles from './slider.module.sass';
import classNames from 'classnames';

interface ISliderProps {
  trackColor: string;
  railColor: string;
  valueChange: (value: number) => void;
  value: number;
}

const CustomHandle = (props: any) => {
  const { value, dragging, index, ...restProps } = props;

  restProps.className = classNames(styles.handleControl)

  return (
    <Handle {...restProps} value={value} >
      <div>
        <SliderController />
      </div>
    </Handle>
  )
} 

const Slider: React.FunctionComponent<ISliderProps> = (props) => {
  let railAndTrackBase = {
    height: '10px'
  }

  let trackStyle = {
    ...railAndTrackBase,
    backgroundColor: props.trackColor
  }

  let railStyle = {
    ...railAndTrackBase,
    backgroundColor: props.railColor
  }

  let handleStyle = {

  }

  return (
    <div>
      <RCslider handleStyle={handleStyle} handle={CustomHandle} value={props.value} onChange={(value) => props.valueChange(value)} trackStyle={trackStyle} railStyle={railStyle} />
    </div>
  );
}

export default observer(Slider);