import Dropdown from './Dropdown';
import RadioSelector from './RadioSelector';
import ProportionSlider from './ProportionSlider';
import HeadlineParagraph from './HeadlineParagraph';
import TextElement from './TextElement'
import CheckboxSelector from './CheckboxSelector';
import AudienceGroup from './AudienceGroup';
import Metrics from './Metrics';
import Sponsor from './Sponsor';
import Team from './Team';
import Quote from './Quote';
import Finance from './Finance';
import MultiSelector from './MultiSelector'
import Appendix from './Appendix';
import DetailedDescription from './DetailedDescription';
import SocialMedia from './SocialMedia';
import Venue from './Venue';
import Packages from './Packages';
import Opportunity from './Opportunity';
import AdditionalOpportunity from './AdditionalOpportunity';
import MediaCoverage from './MediaCoverage';
import WebsitePicker from './WebsitePicker';
import DetailedDropdown from './DetailedDropdown';
import Contact from './Contact';
import Speaker from './Speaker';

export default {
  'Element::Dropdown': Dropdown,
  'Element::Radio': RadioSelector,
  'Element::Slider': ProportionSlider,
  'Element::Headline': HeadlineParagraph,
  'Element::Text': TextElement,
  'Element::Checkbox': CheckboxSelector,
  'Element::Percentage': AudienceGroup,
  'Element::Metric': Metrics,
  'Element::Sponsor': Sponsor,
  'Element::Team': Team,
  'Element::Quote': Quote,
  'Element::Finance': Finance,
  'Element::Multiselect': MultiSelector,
  'Element::Appendix': Appendix,
  'Element::Description': DetailedDescription,
  'Element::Social': SocialMedia,
  'Element::Venue': Venue,
  'Element::Package': Packages,
  'Element::Opportunity': Opportunity,
  'Element::AdditionalOpportunity': AdditionalOpportunity,
  'Element::MediaCoverage': MediaCoverage,
  'Element::WebsitePicker': WebsitePicker,
  'Element::DetailDropdown': DetailedDropdown,
  'Element::Contact': Contact,
  'Element::Speaker': Speaker
}
