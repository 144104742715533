import { ElementNames } from '@models/builder/customization/elements/index';

import ColorPicker from './ColorPicker';
import ImageUpload from './ImageUpload';
import Dropdown from './Dropdown';
import Contact from './Contact';
import TextInput from './TextInput';
import Checkbox from './Checkbox';
import FontSelector from './FontSelector';
import Wording from './Wording';

const elements: {
  [key in ElementNames]: React.FunctionComponent<any>
} = {
  'FormProcess::Customization::ColorPicker': ColorPicker,
  'FormProcess::Customization::ImageUpload': ImageUpload,
  'FormProcess::Customization::Dropdown': Dropdown,
  'FormProcess::Customization::Contact': Contact,
  'FormProcess::Customization::TextInput': TextInput,
  'FormProcess::Customization::Checkbox': Checkbox,
  'FormProcess::Customization::FontSelector': FontSelector,
  'FormProcess::Customization::Wording': Wording
}

export default elements;