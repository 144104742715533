import * as shortid from 'shortid';
import { observable, action, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, getDefaultModelSchema, Context, SKIP, custom } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';
import File, { IFile } from '@util/elements/FileUpload';

// releated interfacess

// model interfaces

export interface IAdditionalOpportunityConfig extends IBaseConfiguration {}

export interface IAdditionalOpportunityInstance extends IBaseInstanceElement {
  name: string;
  value: number | undefined;
  id: string;
  file: IFile,
  note: string;
}

export interface IAdditionalOpportunity extends IElement<IAdditionalOpportunityConfig, IAdditionalOpportunityInstance> { }

// model classes

export class AdditionalOpportunityInstance implements IAdditionalOpportunityInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable
  name = ''

  @observable
  @serializable
  value: number | undefined;

  @observable
  @serializable(object(File))
  file = new File();

  @observable
  @serializable
  note = '';

  parent: AdditionalOpportunity
  constructor(parent: AdditionalOpportunity) {
    this.parent = parent;
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return this.name.length > 0;
  }

  @action.bound
  changeValue(value: number) {
    this.value = value;
  }

  @action.bound
  changeName(name: string) {
    this.name = name;
  }

  @action.bound
  setNote(value: string) {
    this.note = value;
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(AdditionalOpportunityInstance).factory = (context: Context) => {
      return new AdditionalOpportunityInstance(context.args.parent);
    };
  }
}

export default class AdditionalOpportunity extends BaseElement<IAdditionalOpportunity, IAdditionalOpportunityConfig, AdditionalOpportunityInstance> {

  @observable
  @serializable(elementInstanceSerializer(AdditionalOpportunityInstance))
  readonly instances = observable<AdditionalOpportunityInstance>([]);

  @action.bound
  addInstance() {
    this.instances.push(new AdditionalOpportunityInstance(this));
  }

  @action.bound
  deleteInstance(instance: AdditionalOpportunityInstance) {
    this.instances.remove(instance);
  }

  static getInstanceClass() {
    return AdditionalOpportunityInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new AdditionalOpportunityInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(AdditionalOpportunity).factory = (context: Context) => {
      return new AdditionalOpportunity(context.args.root, context.args.proposal);
    };
  }
}

AdditionalOpportunityInstance.schema();
AdditionalOpportunity.schema();