import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { rootContext } from '@app/index';
import Example from '../common/Example';
import * as styles from './templates.module.sass';

interface ITemplatesProps {
  
}

const Templates: React.FunctionComponent<ITemplatesProps> = (props) => {
  const rootStore = React.useContext(rootContext);

  React.useEffect(() => {
    rootStore.proposalStore.pullExamples();
  }, [])

  console.log('rootStore.proposalStore.examples', rootStore.proposalStore.examples)
  let examples = rootStore.proposalStore.examples.map(item => <Example key={item.id} example={item} />);

  console.log('examples', examples);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
      <p className={styles.label}>Bienvenue! We are glad to see you here.</p>
      {examples.length > 0 &&
          <div className={styles.example}>
            <p className={styles.exampleLabel}>Jump in with a read to go template.</p>
            <div className={styles.examples}>
              {examples}
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default observer(Templates);