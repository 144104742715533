
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/quote';
import FileUpload from '@components/builder/common/FileUpload';
import Input from '@components/builder/common/Input';
import * as styles from './quote.module.sass';
import Textarea from '@components/builder/common/Textarea';
import Icon from '@components/common/Icon';

export interface IInstanceProps {
  instance: Model.QuoteInstance,
  configuration: Model.IQuoteConfig
}

interface State { }


@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {
    let instance = this.props.instance;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerItem}>
            <FileUpload file={this.props.instance.file} />
          </div>
          <div className={styles.headerItem}>
            <div className={styles.nameInputWrap}>
              <Input icon='idCard' placeholder='Joana Doe' label='Quote name' value={instance.name.value} onChange={instance.name.onChange}></Input>
              <Icon onClick={instance.delete} type='trashcan' />
            </div>
            <div className={styles.rowInputs}>
              <Input icon='building' placeholder='Coca Cola' label='Company' value={instance.company.value} onChange={instance.company.onChange} />
              <Input icon='position' placeholder='CMO' label='Position' value={instance.position.value} onChange={instance.position.onChange} />
            </div>
            <Input icon='link' placeholder='https://website.com' label='website' value={instance.website.value} onChange={instance.website.onChange}></Input>
          </div>
        </div>
        <div className={styles.description}>
          <Textarea
            value={this.props.instance.description.value}
            onChange={this.props.instance.description.onChange}
            label='Quote'
            placeholder='Quote text here'
          />
        </div>
      </div>
    );
  }
}
