import * as React from 'react'
import { observer } from 'mobx-react-lite';
import SectionModel from '@models/builder/section';
import Icon from '@app/components/common/Icon';
import * as sectionStyles from '../sections.module.sass';
import cn from 'classnames';
import TextInput from '../../elements/common/TextInput';

interface ISectionProps {
  section: SectionModel;
  dragProps: any;
  open: boolean;
  toggleOpen: (id: string) => void;
}

let Section: React.FunctionComponent<ISectionProps> = (props) => {
  const [hover, toggleHover] = React.useState(false);
  let hidden = !(props.section.complete && props.section.visible_for_user)

  function toggleOpen() {
    if (props.open) {
      props.toggleOpen('')
      return;
    }

    if (hidden) {
      return;
    }

    props.toggleOpen(props.section.id)
  }

  function toggleHideSection() {
    if (!props.section.complete) {
      return;
    }

    props.section.toggleVisibility()
  }

  return (
    <div className={cn({ [sectionStyles.sectionOpen]: props.open})}>
      <div onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)} onClick={toggleOpen} className={sectionStyles.container}>
        <div {...props.dragProps} className={sectionStyles.drag}>
          <Icon type='drag-combined' inheritStyle />
        </div>
        <div className={cn(sectionStyles.item, { [sectionStyles.emptyOrHidden]: hidden  })}>
          <p>{props.section.name.toLowerCase()}</p>
          {hidden ?
            <Icon stopPropagation onClick={() => toggleHideSection()} tooltip={!props.section.complete ? 'This section is not filled' : undefined} className={sectionStyles.icon} type='eye-slashed' inheritStyle />
          : 
            <div className={sectionStyles.iconWrap}>
              {hover &&
                <Icon stopPropagation onClick={() => toggleHideSection()} style={{ marginRight: '10px' }} className={sectionStyles.icon} type='eye' inheritStyle />
              }
              <Icon className={sectionStyles.icon} type='cog' inheritStyle />
            </div>
          }
          
        </div>
      </div>
      {props.open &&
        <div className={sectionStyles.sectionContainer}>
          <div className={sectionStyles.sectionElement}>
            <TextInput
              help_text=''
              placeholder='Section headline...'
              label='Headline'
              value={props.section.settings.headline}
              onChange={props.section.settings.setHeadline}
              premium
            />
        </div>
        </div>
      }
    </div>
  )
}

Section = observer(Section);
export default Section;