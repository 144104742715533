import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Select from 'react-select';
import dropdownStyle from './styles';
import * as styles from './dropdown.module.sass';
import BlockLabel from '@components/builder/common/BlockLabel';
import { string } from 'prop-types';


interface IOption {
  label: string;
  value: string;
}

interface IGroup {
  label: string;
  options: IOption[];
}

interface IDropdownBasicProps {
  options?: string[] | IOption[];
  value: string | IOption | undefined | null;
  onChange: (value: string | IOption) => void;
  label?: string;
  placeholder: string;
  theme?: 'dark'
  grouped?: IGroup[]
}

let DropdownBasic: React.FunctionComponent<IDropdownBasicProps> = (props) => {
  let sentObject = true;
  let option = props.value as any;
  let options = props.options;

  if (options && options.some((item: any) => typeof item !== 'object' && !item.hasOwnProperty('value') && !item.hasOwnProperty('label'))) {
    options = (options as IOption[]).map((item: any) => {
      return {
        label: item,
        value: item
      }
    })

    option = option ? {
      label: option,
      value: option
    } : null

    sentObject = false;
  }

  return (
    <div style={{ position: 'relative' }}>
      {props.label ?
        <div className={styles.labelBlock}>
          <BlockLabel text={props.label} />
        </div>
        : null}
      <Select
        styles={dropdownStyle(false, false, props.theme)}
        value={option as any}
        placeholder={props.placeholder}
        theme={base => {
          return ({
          ...base, colors: {
            ...base.colors,
            neutral0: props.theme === 'dark' ? '#2c242c' : "hsl(0, 0%, 100%)",
            neutral80: props.theme === 'dark' ? '#a6b6c7' : 'hsl(0, 0%, 20%)',
            neutral90: props.theme === 'dark' ? '#a6b6c7' : 'hsl(0, 0%, 10%)',
            neutral20: props.theme === 'dark' ? '#484e56' : 'hsl(0, 0%, 80%)',
            primary: '#0076ff',
            primary25: props.theme === 'dark' ? '#5998f9' : base.colors.primary25
          }
        })}}
        onChange={(selectedOption: any) => {
            props.onChange(!sentObject ? selectedOption.value : selectedOption)
          }
        }
        options={(options as any) || props.grouped}
      />
    </div>
  )
}

DropdownBasic = observer(DropdownBasic);

export default DropdownBasic;