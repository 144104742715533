import * as React from 'react'
import { observer } from 'mobx-react';
import TooltipQuestion from '@assets/helpers/tooltip_question.svg';
import classNames from 'classnames';
import * as styles from './headline.module.sass';
import { Tooltip } from 'react-tippy';
import ReactMarkdown from 'react-markdown';
import Icon from '@app/components/common/Icon';

interface IElementHeadlineProps {
  markdown: string;
  text: string;
  complete: boolean;
}

const ElementHeadline: React.FunctionComponent<IElementHeadlineProps> = (props) => {

  let stateClassName = classNames(styles.stateCircle, { [styles.active]: props.complete });

  return (
    <div className={styles.container}>
      <div className={stateClassName}></div>
      <p className={styles.text}>{props.text}</p>
      <Tooltip
        html={
          <ReactMarkdown source={props.markdown} />
        }
        theme='honeybee'
        position='right-start'
        animateFill={false}
        className={styles.tooltip}
      >
        <Icon type='question-circle' className={styles.icon} inheritStyle/>
      </Tooltip>
    </div>
  );
}

export default observer(ElementHeadline);