import RootStore from "@stores/RootStore";
import { FormState, FieldState } from 'formstate';
import { computed, flow, action, runInAction, observable } from "mobx";
import { trackProposalCreate } from "@app/util/analytics";
import emitter from "@app/util/emitter";

export default class ExampleCreatorForm {

  @observable
  inProgress = false;

  @observable
  exampleId: string;

  name = new FieldState('').validators((val) => !val && 'Process name required');

  form = new FormState({
    name: this.name,
  })

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }

  onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.inProgress = true;

    if (this.root.proposalStore.proposalList.length >= this.root.user.limitation.maxProposals) {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'proposal'
      })

      this.inProgress = false;
      return;
    }

    const res = await this.form.validate();

    if (res.hasError) {
      this.inProgress = false;
      return;
    }

    let values = res.value;

    try {
      let res = await this.root.api.createProcessFromExample(values.name.$, this.exampleId);

      let proposal = this.root.proposalStore.createProposalFromObject(res);
      this.root.proposalStore.proposals.push(proposal);
      this.root.router.push(`/process/builder/${proposal.id}`)
      this.form.reset();
      trackProposalCreate(proposal.name, proposal.id);
    } catch (error) {
      console.error(error);
    }

    this.inProgress = false;
  }
}