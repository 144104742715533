
import React from 'react';
import { observer } from 'mobx-react';
import Icons, { IconTypes } from './icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as styles from './icon.module.sass';
import cn from 'classnames';
import Tippy from '@tippy.js/react';

type colors = 'primary0' | 'primary1' | 'primary2' | 'primary3' | 'primary4' | 'primary5';

export interface IIconProps {
  type: IconTypes;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
  iconStyle?: React.CSSProperties;
  color?: colors;
  tooltip?: string;
  rotate?: number;
  active?: boolean;
  fill?: string;
  tooltipPosition?: 'top' | 'right' | 'left' | 'bottom';
  inheritStyle?: boolean;
  stopPropagation?: boolean;
}

interface State {}

@observer
export default class Icon extends React.Component<IIconProps, State> {
  constructor(props: IIconProps) {
    super(props);
  }

  iconClick(e: any) {
    if (this.props.stopPropagation) {
      e.stopPropagation()
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  public render() {
    let rotate = this.props.rotate || 0;


    let Icon: any = Icons[this.props.type];
    let Component: any;


    let colorClass;

    if (!this.props.inheritStyle) {
      colorClass = styles.primary0;

      if (this.props.type === 'trashcan' || this.props.type === 'times-circle') {
        colorClass = styles['primary4']
      }

      if (this.props.color) {
        colorClass = styles[this.props.color];
      }
    }


    let className = cn(styles.icon, this.props.className, colorClass, {
      [styles.active]: this.props.active 
    })

    let style = Object.assign(this.props.iconStyle || { }, { transform: `rotate(${rotate}deg)` })
    if (typeof Icon === 'function') {
      Component = <Icon style={style} />
    } else if (typeof Icon === 'object') {
      Component = <FontAwesomeIcon style={style} icon={Icon} />
    }

    let renderElement = (
      <div
        className={className}
        onClick={(e) => this.iconClick(e)}
        style={this.props.style}>
        {Component}
      </div>
    )

    if (this.props.tooltip) {
      let position = this.props.tooltipPosition || 'top'
      renderElement = (
        <Tippy
          content={this.props.tooltip}
          trigger='mouseenter'
          theme='honeybee'
          animateFill={false}
          placement={position}
          animation='shift-toward'
          inertia={true} >
          {renderElement}
        </Tippy>
      );
    }

    return renderElement
  }
}

export type IconTypes = IconTypes;
