import * as React from 'react'
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { withRouterApp } from '@app/util/router.tsx';
import * as styles from './account.module.sass';
import Sidebar from '@components/Sidebar';
import { Switch, Route } from 'react-router-dom';
import General from './General';
import Pricing from './premium/Pricing';
import PaymentDone from './premium/PaymentDone';
import PaymentError from './premium/PaymentError';

import Billing from './Billing';

interface IAccountProps { };

let Account: React.FunctionComponent<IAccountProps & RouteComponentProps<any>> = (props) => {

  let white = props.location.pathname === '/account/pricing';

  return (
    <div className={styles.account}>
      <Sidebar />
      <div className={cn(styles.content, { [styles.contentWhite]: white })}>
        <Switch>
          <Route exact path='/account/pricing' component={Pricing} />
          <Route exact path='/account/premium/complete' component={PaymentDone} />
          <Route exact path='/account/premium/error' component={PaymentError} />
          <Route exact path='/account/billing' component={Billing} />
          <Route exact path='/account/general' component={General} />
        </Switch>
      </div>
    </div>
  )
}

Account = withRouterApp(observer(Account));

export default Account;
