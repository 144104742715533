import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Icon from '@app/components/common/Icon';
import styles from './error.module.sass';
import { Link } from 'react-router-dom';

interface IPaymentErrorProps {
  name: string,
}

const PaymentError: React.FunctionComponent<IPaymentErrorProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>There was an error with your payment</h1>
        <p className={styles.text}>Please try again Later</p>
        <Link to='/account/pricing' className={styles.link}>
          <Icon inheritStyle type='arrow' />
          <p>Go back to tiers</p>
        </Link>
      </div>
    </div>
  );
}

export default observer(PaymentError);