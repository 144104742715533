import { serializable, serialize } from 'serializr';
import { observable, action, computed } from 'mobx';

export interface IField<T> {
  value: T;
}

export default class Field<T> implements IField<T> {

  @observable
  @serializable
  value: T;

  @action.bound
  onChange(val: T) {
    this.value = val;
  }

  constructor(defaultValue: T) {
    this.value = defaultValue;
  }
}

export class SelectField {

  @observable
  @serializable
  value: string | undefined;

  @computed get option() {
    return this.value || undefined
  }

  @action.bound
  select(option: string) {
    this.value = option;
  }

  constructor(defaultValue?: string) {
    if (defaultValue) {
      this.value = defaultValue;
    }
  }
}