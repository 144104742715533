import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './example.module.sass';
import Input from '@components/common/Input';
import Icon from '@components/common/Icon';
import Typewriter from '@assets/dashboard/typewriter.png'
import { withRouterApp } from '@app/util/router';
import { RouteComponentProps } from 'react-router';
import QS from 'query-string';
import { rootContext } from '@app/index';
import { IExample } from '@app/stores/ProposalStore';
import { Link } from 'react-router-dom';

interface IFromExampleProps {}

let FromExample: React.FunctionComponent<IFromExampleProps & RouteComponentProps> = (props) => {
  const rootStore = React.useContext(rootContext);
  const form = rootStore.forms.exampleCreatorForm;
  let example = rootStore.proposalStore.activeExample;

  let query = QS.parse(props.location.search);
  let id = query.id as string;

  React.useEffect(() => {
    rootStore.proposalStore.toggleExample(id);
    form.exampleId = id;
  }, [id])

  if (!example) {
    return null;
  }

  return (
    <div className={styles.container}>
      <form onSubmit={form.onSubmit} className={styles.formContainer}>
        <Link to='/proposals/new/proposal' className={styles.goBack}>{'< Back to creating from scratch'}</Link>
        {example.image_url &&
          <div className={styles.img} style={{
            background: `url(${example.image_url}) no-repeat`,
            backgroundSize: 'cover',
            height: '120px'
          }}></div>
        }
        <p className={styles.headline}>{example.name}</p>
        <div className={styles.buttons}>
          <Input
            label='Name your proposal'
            placeholder='Run the Prague'
            name='proposalName'
            onChange={form.name.onChange}
            value={form.name.value}
            error={form.name.error}
            required
            dark
          />
        </div>
        <button disabled={form.inProgress} className={styles.button}>
          {form.inProgress ? 'Loading...' : 'Start editing the proposal'}
          <Icon rotate={180} type='arrow' />
        </button>
      </form>
    </div>
  );
}

export default withRouterApp(observer(FromExample));