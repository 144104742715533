
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/radioSelector';
import RadioInput from '@components/builder/common/RadioInput';
import * as styles from './radio.module.sass';

export interface IInstanceProps {
  instance: Model.RadioSelectorInstance,
  configuration: Model.IRadioSelectorConfig
}

interface State {}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {

    let options = this.props.configuration.radioOptions;

    if (typeof options === 'string') {
      options = (options as any).split(',')
    }

    let radios = options.map((item) => {
      return <RadioInput
        checked={this.props.instance.selectedOption === item}
        value={item}
        key={item}
        name={this.props.instance.id}
        onChange={this.props.instance.selectOption}
        />
    })

    return (
      <div>
        <form className={styles.radioInputs}>
          {radios}
        </form>
      </div>
    );
  }
}
