import axios, { AxiosInstance } from 'axios';

export default class GoogleAPI {

  readonly apiKey: string;
  axios: AxiosInstance;

  constructor() {
    this.apiKey = 'AIzaSyD06wvpymEQ-_VMK9McLRcuBpR23BNIx8k';
    this.axios = axios.create({
      params: {
        key: this.apiKey
      }
    })
  }

  loadFonts() {
    return this.axios.get('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&fields=items(family%2C%20files(regular))').then(res => res.data);
  }
}