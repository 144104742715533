import Dropdown, { IDropdown } from './dropdown';
import RadioSelector, { IRadioSelector } from './radioSelector';
import ProportionSlider, { IProportionSlider } from './proportionSlider';
import HeadlineParagraph, { IHeadlineParagraph } from './headlineParagraph';
import TextElement, { IText } from './text';
import CheckboxSelector, { ICheckboxSelector } from './checkboxSelector';
import AudienceGroup, { IAudienceGroup } from './audienceGroup';
import Metrics, { IMetrics } from './metrics';
import Sponsor, { ISponsor } from './sponsor';
import Team, { ITeam } from './team';
import Quote, { IQuote } from './quote';
import Finance, { IFinance } from './finance';
import MultiSelect, { IMultiSelect } from './multiSelect';
import Appendix, { IAppendix } from './appendix';
import DetailDescription, { IDetailedDescription } from './detailedDescription';
import SocialMedia, { ISocialMedia } from './socialMedia';
import Venue, { IVenue } from './venue';
import Packages, { IPackages } from './packages';
import Opportunity, { IOpportunity } from './opportunity';
import AdditionalOpportunity, { IAdditionalOpportunity } from './additionalOpportunity';
import MediaCoverage, { IMediaCoverage } from './mediaCoverage';
import WebsitePicker, { IWebsitePicker } from './websitePicker';
import DetailedDropdown, { IDetailedDropdown } from './detailedDropdown';
import Contact, { IContact } from './contact';
import Speaker, { ISpeaker } from './Speaker';

export default {
  'Element::Dropdown': Dropdown,
  'Element::Radio': RadioSelector,
  'Element::Slider': ProportionSlider,
  'Element::Headline': HeadlineParagraph,
  'Element::Text': TextElement,
  'Element::Checkbox': CheckboxSelector,
  'Element::Percentage': AudienceGroup,
  'Element::Metric': Metrics,
  'Element::Sponsor': Sponsor,
  'Element::Team': Team,
  'Element::Quote': Quote,
  'Element::Finance': Finance,
  'Element::Multiselect': MultiSelect,
  'Element::Appendix': Appendix,
  'Element::Description': DetailDescription,
  'Element::Social': SocialMedia,
  'Element::Venue': Venue,
  'Element::Package': Packages,
  'Element::Opportunity': Opportunity,
  'Element::AdditionalOpportunity': AdditionalOpportunity,
  'Element::MediaCoverage': MediaCoverage,
  'Element::WebsitePicker': WebsitePicker,
  'Element::DetailDropdown': DetailedDropdown,
  'Element::Contact': Contact,
  'Element::Speaker': Speaker
}

export type ElementTypes = Dropdown | RadioSelector | ProportionSlider | HeadlineParagraph | TextElement | CheckboxSelector | AudienceGroup | Metrics | Sponsor | Team | Quote | Finance | MultiSelect | Appendix | DetailDescription | SocialMedia | Venue | Packages | Opportunity | AdditionalOpportunity | MediaCoverage | WebsitePicker | DetailedDropdown | Contact | Speaker;
export type ElementInterfaces = IDropdown | IRadioSelector | IProportionSlider | IHeadlineParagraph | IText | ICheckboxSelector | IAudienceGroup | IMetrics | ISponsor | ITeam | IQuote | IFinance | IMultiSelect | IAppendix | IDetailedDescription | ISocialMedia | IVenue | IPackages | IOpportunity | IAdditionalOpportunity | IMediaCoverage | IWebsitePicker | IDetailedDropdown | IContact | ISpeaker;