import * as shortid from 'shortid';
import { observable, action, toJS, when, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, custom, getDefaultModelSchema, Context, SKIP } from 'serializr';
import { FieldState } from 'formstate';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

export interface IDetailedDropdownInstance extends IBaseInstanceElement {
  selectedOption: string | undefined;
  description: string;
}

export interface IDetailedDropdownConfig extends IBaseConfiguration {
  options: string[];
}

export interface IDetailedDropdown extends IElement<IDetailedDropdownConfig, IDetailedDropdownInstance> {}

export class DetailedDropdownInstance implements IDetailedDropdownInstance {

  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable
  selectedOption: string | undefined;

  @observable
  @serializable
  description = ''

  @observable
  descriptionOpen = false;

  @computed get hasDescription() {
    return this.description.length > 0;
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.selectedOption
  }

  parent: DetailedDropdown
  constructor(parent: DetailedDropdown) {
    this.parent = parent;
  }

  @action.bound
  changeOption(option: string) {
    this.selectedOption = option;
  }

  @action.bound
  updateDescription(value: string) {
    this.description = value;
  }

  @action.bound
  openDescription() {
    this.descriptionOpen = true;
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  @action.bound
  closeDescription() {
    this.descriptionOpen = false;
    this.description = '';
  }

  static schema() {
    return getDefaultModelSchema(DetailedDropdownInstance).factory = (context: Context) => {
      return new DetailedDropdownInstance(context.args.parent);
    };
  }
}

export default class DetailedDropdown extends BaseElement<IDetailedDropdown, IDetailedDropdownConfig, DetailedDropdownInstance> {

  @observable
  @serializable(elementInstanceSerializer(DetailedDropdownInstance))
  readonly instances = observable<DetailedDropdownInstance>([]);

  static getInstanceClass() {
    return DetailedDropdownInstance;
  }

  @action.bound
  deleteInstance(instance: DetailedDropdownInstance) {
    this.instances.remove(instance);
  }

  @action.bound
  addInstance() {
    this.instances.push(new DetailedDropdownInstance(this));
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    when(
      () => !!this.configuration,
      () => this.init()
    )
  }

  init() {
    if (!this.hasInstance) {
      let instance = new DetailedDropdownInstance(this);
      this.instances.push(instance)
    }

  }

  static schema() {
    return getDefaultModelSchema(DetailedDropdown).factory = (context: Context) => {
      return new DetailedDropdown(context.args.root, context.args.proposal);
    };
  }
}

DetailedDropdown.schema();
DetailedDropdownInstance.schema();