
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@app/models/builder/elements/packages';
import PackageCreator from './PackageCreator';

export interface IPackagesProps {
  instance: Model.PackagesInstance;
  configuration: Model.IPackagesConfig;
}

interface State {}

@observer
export default class Packages extends React.Component<IPackagesProps, State> {
  public render() {
    return (
      <div>
        <PackageCreator packages={this.props.instance} />
      </div>
    );
  }
}
