import * as React from 'react'
import { observer } from 'mobx-react';
import * as styles from './navigation.module.sass';
import Icon from '@components/common/Icon';
import cn from 'classnames';

interface INavigationProps {
  shiftPage: (forward: boolean) => void;
  nextPageEnabled: boolean;
  prevPageEnabled: boolean;
}

const Navigation: React.FunctionComponent<INavigationProps> = (props) => {

  let prevClassName = cn(styles.button, {
    [styles.inactive]: !props.prevPageEnabled
  })

  let nextClassName = cn(styles.button, {
    [styles.inactive]: !props.nextPageEnabled,
  }, styles.forward)

  return (
    <div className={styles.container}>
      <button onClick={() => props.shiftPage(false)} disabled={!props.prevPageEnabled} className={prevClassName}>
        <Icon type='arrow' />
        Go to previous page
      </button>
      <div className={styles.autosave}>
        <Icon type='checkmark-fontawesome' inheritStyle />
        <p>Sponseasy auto-saves your work</p>
      </div>
      <button onClick={() => props.shiftPage(true)} disabled={!props.nextPageEnabled} className={nextClassName}>
        Continue to next page
        <Icon type='arrow' rotate={180} />
      </button>
    </div>
  )
}

export default observer(Navigation);