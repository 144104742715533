
import * as React from 'react';
import { observer } from 'mobx-react';
import AddInstance from '@components/builder/common/AddInstance';
import * as styles from './social.module.sass';
import SocialMediaLink from '@components/builder/common/SocialMediaLink';
import SocialMediaPicker from '@models/builder/common/SocialMediaPicker';
import { ITeamConfig } from '@app/models/builder/elements/team';

export interface IModalContentProps {
  picker: SocialMediaPicker;
  networks: { name: string; example_link: string; }[];
  close: () => void;
}

interface State {}

@observer
export default class ModalContent extends React.Component<IModalContentProps, State> {
  public render() {
    let links = this.props.picker.options.map((item, index) => {
      return <SocialMediaLink
        showLabel={index === 0 ? true : false}
        key={index}
        networks={this.props.networks}
        remove={this.props.picker.removeHandle}
        handle={item} />
    })

    return (
      <div>
        <p className={styles.modalHeader}>Social media to display</p>
        <div className={styles.links}>
          {links}
        </div>
        <div className={styles.addInstance}>
          <AddInstance addInstance={this.props.picker.addNewHandle} text='+ Add social media' />
        </div>
        <div onClick={() => this.props.close()} className={styles.buttonSave}>Save & close</div>
      </div>
    );
  }
}
