import mitt from 'mitt';

export enum EmitterEvent {
  REQUEST_CONFIRMATION = 'REQUEST_CONFIRMATION',
  CONFIRMED = 'CONFIRMED',
  REFUSED_CONFIRMATION = 'REFUSED_CONFIRMATION'
}

const emitter = new mitt();

export default emitter;