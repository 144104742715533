
import { observable, action } from 'mobx';
import { serializable, reference } from 'serializr';
import { IPackageItemToTierConnection } from './interfaces';
import Tier from './tier';
import PackageItem from './packageItem';

export default class PackageItemToTierConnection implements IPackageItemToTierConnection {
  @observable
  @serializable(reference(Tier))
  tier: Tier;

  @observable
  @serializable(reference(PackageItem))
  packageItem: PackageItem;

  @observable
  @serializable
  active = false;

  constructor(tier: Tier, packageItem: PackageItem, active?: boolean) {
    this.tier = tier;
    this.packageItem = packageItem;

    if (active) {
      this.active = active;
    }
  }

  @action
  changeState() {
    this.active = !this.active;
  }
}