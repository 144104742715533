import { IProposal } from "@models/builder/proposal";
import TemplateModel from '@models/builder/customization'
import * as shortid from 'shortid';
import { IOpportunity } from "@app/models/builder/elements/Opportunity";
import { IAdditionalOpportunity } from "@app/models/builder/elements/additionalOpportunity";
import { IMediaCoverage } from "@app/models/builder/elements/mediaCoverage";
import { IWebsitePicker } from "@app/models/builder/elements/websitePicker";
import { ICheckboxSelector } from "@app/models/builder/elements/checkboxSelector";
import { IDetailedDropdown } from "@app/models/builder/elements/detailedDropdown";
import { ITeam } from "@app/models/builder/elements/team";
import { IContact } from "@app/models/builder/elements/contact";
import Template from "@app/components/builder/Template";

const Opportunity: IOpportunity = {
  type: 'Element::Opportunity',
  name: 'Element Opportunity',
  id: shortid.generate(),
  configuration: {
    opportunities: [
      {
        name: 'Finance',
        options: ['more then $2000'],
        dropdown_label: 'How much?',
        has_dropdown: true
      },
      {
        name: 'Support',
        options: ['$200 in television'],
        dropdown_label: 'Media Finance?',
        has_dropdown: true
      }
    ],
    min: 1,
    max: 1,
    tooltip: 'asdasdasdsadsad'
  },
  data: {}
}

const AdditionalOpportunity: IAdditionalOpportunity = {
  type: 'Element::AdditionalOpportunity',
  name: 'Additional Opportunity',
  id: shortid.generate(),
  configuration: {
    min: 1,
    max: 10,
    tooltip: 'Teeest'
  },
  data: {}
}

const MediaCoverage: IMediaCoverage = {
  type: 'Element::MediaCoverage',
  name: 'Media Coverage',
  id: shortid.generate(),
  configuration: {
    min: 1,
    max: 1,
    tooltip: 'asdasd'
  },
  data: {}
}

const WebsitePicker: IWebsitePicker = {
  type: 'Element::WebsitePicker',
  name: 'Your websites',
  id: shortid.generate(),
  configuration: {
    min: 1,
    max: 10,
    tooltip: 'Pick your websites'
  },
  data: {}
}

const Checkbox: ICheckboxSelector = {
  type: 'Element::Checkbox',
  name: 'Checkbox',
  id: shortid.generate(),
  configuration: {
    min: 1,
    max: 10,
    tooltip: 'Checkobx',
    options: ['Test 1', 'Test 2', 'Test 3']
  },
  data: {}
}


const DetailDropdown: IDetailedDropdown = {
  type: 'Element::DetailDropdown',
  name: 'Detailed Dropdown',
  id: shortid.generate(),
  configuration: {
    min: 1,
    max: 1,
    tooltip: 'test',
    options: ['asdasd', 'sadasd', 'sad', 'sdasd', 'sad']
  },
  data: {}
}

const Contact: IContact = {
  name: 'Contact element',
  type: 'Element::Contact',
  id: shortid.generate(),
  configuration: {
    min: 1,
    max: 4,
    tooltip: 'markdown example',
    social_networks: [
      { name: 'Facebook', example_link: 'https://facebook.com/page/asdsad' },
      { name: 'Instagram', example_link: 'https://instagram.com/page/asdsad' },
      { name: 'Google', example_link: 'https://google.com/page/asdsad' }
    ]
  },
  data: {}
}


const Proposal: IProposal = {
  name: 'Test Proposal',
  id: shortid.generate(),
  template: {
    id: 'asdasdasd',
    user_id: 'asdasd',
    customization: {
    }
  },
  pages: [
    {
      name: 'Test Page',
      id: shortid.generate(),
      premium: false,
      hidden: false,
      description: 'asdasd',
      status: 'active',
      sections: [
        {
          name: 'asdasd',
          internal_name: 'asdasd',
          id: shortid.generate(),
          description: 'asdasd',
          visible_for_user: true,
          status: 'active',
          settings: {
            headline: 'Test'
          },
          elements: [
            Opportunity,
            AdditionalOpportunity,
            MediaCoverage,
            WebsitePicker,
            Checkbox,
            DetailDropdown,
            Contact
          ]
        }
      ]
    }
  ]
}

export default Proposal