import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from '../auth.module.sass';
import Icon from '@app/components/common/Icon';
import { rootContext } from '@app/index';
import { Link } from 'react-router-dom';

interface IPasswordResetProps { }

let PasswordReset: React.FunctionComponent<IPasswordResetProps> = (props) => {
  const rootStore = React.useContext(rootContext);
  const authStore = rootStore.auth;

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    authStore.resetPassEmail();
  }

  return (
    <div className={styles.contentSimple}>
      <div className={styles.content}>
        <h2 className={styles.headline}>Let’s reset your password</h2>
        <form onSubmit={onSubmit}>
          <div className={styles.inputContainer}>
            <label>Email</label>
            <input onChange={(e) => authStore.setEmail(e.target.value)} value={authStore.values.email} required type='email' placeholder='email@gmail.com'></input>
          </div>
          <div className={styles.buttons}>
            <button disabled={authStore.inProgress} type='submit' className={styles.button}>
              {authStore.inProgress ? 'Loading...' : 'Send to email'}
              <Icon rotate={180} type='arrow' />
            </button>
          </div>
          <Link className={styles.linkText} to='/auth/signin'>Remembered? Click here to login.</Link>
        </form>
      </div>
    </div>
  )
}

PasswordReset = observer(PasswordReset);
export default PasswordReset;