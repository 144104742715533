import RootStore from '../RootStore';
import ProcessCreatorForm from './processCreatorForm';
import ExampleCreatorForm from './exampleCreatorFrom';

export default class Forms {

  root: RootStore;

  processCreatorForm: ProcessCreatorForm;
  exampleCreatorForm: ExampleCreatorForm;
  constructor(root: RootStore) {
    this.root = root;

    this.exampleCreatorForm = new ExampleCreatorForm(this.root);
    this.processCreatorForm = new ProcessCreatorForm(this.root);
  }
}