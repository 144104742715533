import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './tier.module.sass';
import Icon from '@app/components/common/Icon';
import cn from 'classnames';
import Modal from '@app/components/common/Modal';
import { rootContext } from '@app/index';
import Sad from '@assets/app/sad.jpg'
import premium from '@util/premium';

interface ITierProps {
  state: 'normal' | 'favorite'
  headline: string;
  description: string;
  items: ITierItem[];
}

interface ITierPropsFree extends ITierProps {
  isPremium: boolean;
  onFreeClick: () => void;
}

interface ITierPropsPaid extends ITierProps {
  priceMo: number;
  priceYear: number;
  billingRange: 'month' | 'year';
  onClick: (plan: string) => void;
  planIdMonth: string;
  planIdYear: string;
  productId: string
  currentProductId: string | null;
}

interface ITierBizProps extends ITierProps {
  secondDescription: string;
}

let Tier: React.FunctionComponent<ITierPropsPaid> = (props) => {
  const [modalOpen, toggleModal] = React.useState(false);

  const user = React.useContext(rootContext).user

  let price = props.billingRange === 'month' ? props.priceMo : props.priceYear;
  let planId = props.billingRange === 'month'? props.planIdMonth : props.planIdYear;

  let isCurrentPlan = props.currentProductId === props.productId;

  let text = 'You are on this plan';

  if (user.subscription && user.subscription.status === 'canceled' && isCurrentPlan) {
    text = `Active till ${user.nextBilling!.format('MMMM DD YYYY')}`
  }

  let timeText = 'Renews on';

  if (user.subscription && user.subscription.status === 'trialing') {
    timeText = 'Trial till'
  }

  if (user.subscription && user.subscription.status === 'canceled') {
    timeText = 'Cancels on'
  }

  function click() {
    if (user.subscription && user.subscription.plan.product === premium['Enterprise'].id && premium['Premium'].id === props.productId) {
      toggleModal(true)
      return;
    }

    props.onClick(planId);
  }

  let priceNumber = props.billingRange === 'year' ? Math.round((price / 12)) : price;
  let prevPrice = priceNumber;

  let couponActive = user.coupon && user.coupon.valid;

  if (user.coupon && user.coupon.valid) {
    priceNumber = Math.floor(user.coupon.amount_off ? priceNumber - user.coupon.amount_off : priceNumber *  (1 -(user.coupon.percent_off! / 100)))
  }

  return (
    <div className={styles.tierContainer}>
      {props.state === 'favorite' &&
        <div className={styles.badge}>
          <p>64% businesses pick</p>
        </div>
      }
      <div className={cn(styles.tier, { [styles.tierFavorite]: props.state === 'favorite', [styles.tierActive]: isCurrentPlan })}>
        <div className={styles.header}>
          <p className={styles.name}>{props.headline}</p>
          <div style={{ position: 'relative' }}>
            {user.coupon && user.coupon.valid &&
              <p className={cn(styles.price, styles.priceDis)}>${prevPrice}<span>/mo</span></p>
            }
            <p style={{ color: couponActive ? '#0076ff' : undefined }} className={styles.price}>${priceNumber}<span>/mo</span></p>
          </div>
        </div>
        <p className={styles.description}>{props.description}</p>
        <div className={styles.control}>
          <button disabled={isCurrentPlan} onClick={() => click()} className={cn(styles.button, { [styles.buttonFavorite]: props.state === 'favorite', [styles.buttonActive]: isCurrentPlan })}>
            {isCurrentPlan ?
              <p>{text}</p> : <p>Start using {props.headline}</p>
            }

            {!isCurrentPlan &&
              <Icon rotate={180} type='arrow' inheritStyle />
            }
          </button>
          {(isCurrentPlan && user.subscription && user.premium) &&
            <div className={styles.plan}>
              <div>
                <p className={styles.label}>{timeText}</p>
                <p className={styles.item}>{user.nextBilling!.format('MMMM DD YYYY')}</p>
              </div>
              {user.subscription.status !== 'canceled' &&
                <div>
                  <p className={styles.label}>{user.subscription.status === 'trialing' ? 'After trial' : 'Renewal amount'}</p>
                  <p className={styles.item}>${user.subscription.plan.amount / 100} / {user.subscription.plan.interval}</p>
                </div>
              }
            </div>
          }
        </div>
        <div className={styles.items}>
          {props.items.map(item => <TierItem key={item.text} {...item} />)}
        </div>
      </div>
      <Modal maxWidth={715} onRequestClose={() => toggleModal(false)} isOpen={modalOpen}>
        <div>
          <h2 className={styles.freeTierHeadline}>You are about to downgrade your plan</h2>
          <p className={styles.freeTierDescription}>Please keep in mind that some features will be disabled or not accesable</p>
          <div className={styles.imgContainer}>
            <img width={300} style={{ marginBottom: '15px' }} src={Sad}></img>
          </div>
          <ul className={styles.freeTierList}>
            <li>You will be able to have only 1 active proposal</li>
            <li>You will have 1 User Seat by default</li>
          </ul>
          <div className={styles.buttonsFreeModal}>
            <div onClick={() => toggleModal(false)} className={styles.buttonFreeModal}>
              Continue using current plan
            </div>
            <div onClick={() => {
              toggleModal(false)
              props.onClick(planId)
            }} className={styles.buttonFreeModal}>
              Switch to this plan
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

let TierFree: React.FunctionComponent<ITierPropsFree> = (props) => {
  const [modalOpen, toggleModal] = React.useState(false);
  const user = React.useContext(rootContext).user

  let canceled = user.subscription && user.subscription.status === 'canceled';

  let isPremium = props.isPremium;

  let text = 'You are on this plan';

  if (canceled) {
    text = 'Will switch to free'
  }

  return (
    <div className={styles.tierContainer}>
      <div className={cn(styles.tier, styles.tierFree)}>
        <div>
          <div className={styles.header}>
            <p className={styles.name}>{props.headline}</p>
          </div>
          <p className={styles.description}>{props.description}</p>
        </div>
        <div className={cn(styles.items, styles.tierFreeItems)}>
          {props.items.map(item => <TierItem key={item.text} {...item} />)}
        </div>
        <div className={styles.control}>
          <button disabled={!!(!isPremium || canceled)} onClick={() => toggleModal(true)} className={cn(styles.button, styles.freeButton)}>
            {(!isPremium || canceled) ?
              <p style={{ textAlign: 'center' }}>{text}</p> : <p>Switch to free tier</p>
            }

            {(isPremium && !canceled) &&
              <Icon rotate={180} type='arrow' inheritStyle />
            }
          </button>
        </div>
      </div>
      <Modal maxWidth={715} onRequestClose={() => toggleModal(false)} isOpen={modalOpen}>
        <div>
          <h2 className={styles.freeTierHeadline}>Switching to free tier after being premium</h2>
          <p className={styles.freeTierDescription}>It would be pity to loose you, but if you decide to leave now there are several things that will happen on <b>{user.nextBilling && user.nextBilling.format('MMMM DD YYYY')}</b></p>
          <div className={styles.imgContainer}>
            <img width={300} style={{ marginBottom: '15px' }} src={Sad}></img>
          </div>
          <ul className={styles.freeTierList}>
            <li>All your active proposals will be unpublished, and stored for 3 months. In this period you can always come back and activate them by subscribing to premium again</li>
          </ul>
          <div className={styles.buttonsFreeModal}>
            <div onClick={() => toggleModal(false)} className={styles.buttonFreeModal}>
              Continue using Premium
            </div>
            <div onClick={() => {
              toggleModal(false)
              props.onFreeClick()
            }} className={styles.buttonFreeModal}>
              Switch to free tier
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

let TierBiz: React.FunctionComponent<ITierBizProps> = (props) => {
  return (
    <div className={styles.tierContainer}>
      <div className={cn(styles.tier, styles.tierBiz)}>
        <div className={styles.header}>
          <p className={styles.name}>{props.headline}</p>
        </div>
        <p className={styles.description}>{props.description}</p>
        <div className={styles.control}>
          <a href='mailto:app@sponseasy.com' className={cn(styles.button)}>
            <p>Contact Us</p>
            <Icon rotate={180} type='arrow' inheritStyle />
          </a>
        </div>
        <p style={{ marginTop: '20px' }} className={styles.description}>{props.secondDescription}</p>
        <div className={styles.items}>
          {props.items.map(item => <TierItem key={item.text} {...item} />)}
        </div>
      </div>
    </div>
  )
}

TierBiz = observer(TierBiz);

export interface ITierItem {
  active?: boolean
  text: string;
  tooltip?: string;
  soon?: boolean;
}

let TierItem: React.FunctionComponent<ITierItem> = (props) => {
  return (
    <div className={cn(styles.item, { [styles.itemActive]: props.active })}>
      <Icon className={styles.checkmark} type='checkmark-fontawesome' inheritStyle />
      <p className={styles.tierItemText}>{props.text}</p>
      {props.tooltip &&
        <Icon className={styles.question} type='question-circle' inheritStyle tooltip={props.tooltip} />
      }
      {props.soon &&
        <span className={styles.soon}>soon</span>
      }
    </div>
  )
}

TierFree = observer(TierFree);

export {
  TierFree,
  TierBiz
}

export default observer(Tier);