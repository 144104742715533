import * as shortid from 'shortid';
import { observable, toJS, action, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, custom, primitive, SKIP } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

// releated interfacess
interface Option {
  label: string;
  value: string;
}

// model interfaces

export interface IMultiSelectConfig extends IBaseConfiguration {
  suggestedOptions: string[]
}

interface IMultiSelectInstance extends IBaseInstanceElement  {
  options: string[]
}

export interface IMultiSelect extends IElement<IMultiSelectConfig, IMultiSelectInstance> {}


// model classes

export class MultiSelectInstance implements IMultiSelectInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable(list(primitive()))
  readonly options = observable<string>([]);

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return this.options.length > 0;
  }

  parent: MultiSelect
  constructor(parent: MultiSelect) {
    this.parent = parent;
  }

  @action.bound
  updateOption(option: string[]) {
    this.options.replace(option);
  }

  static schema() {
    return getDefaultModelSchema(MultiSelectInstance).factory = (context: Context) => {
      return new MultiSelectInstance(context.args.parent);
    };
  }
}

export default class MultiSelect extends BaseElement<IMultiSelect, IMultiSelectConfig, MultiSelectInstance> {

  @observable
  @serializable(elementInstanceSerializer(MultiSelectInstance))
  readonly instances = observable<MultiSelectInstance>([]);

  static getInstanceClass() {
    return MultiSelectInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new MultiSelectInstance(this));
    }
  }

  static schema() {
    return getDefaultModelSchema(MultiSelect).factory = (context: Context) => {
      return new MultiSelect(context.args.root, context.args.proposal);
    };
  }
}

MultiSelectInstance.schema();
MultiSelect.schema();