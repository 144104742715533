import { observable, action, computed } from 'mobx';
import { serializable, serializeAll } from 'serializr';

export interface IFile {
  fileURL: string | undefined;
  name: string | undefined;
  mimetype: string | undefined;
  handle: string | undefined;
  size: string | undefined;
}

@serializeAll
export default class File implements IFile {

  @observable
  @serializable
  fileURL: string | undefined;

  @observable
  @serializable
  name: string | undefined;

  @observable
  @serializable
  mimetype: string | undefined;

  @observable
  @serializable
  handle: string | undefined;

  @observable
  @serializable
  size: string | undefined;

  @computed get uploaded() {
    return !!this.fileURL;
  }

  @action.bound
  uploadFile(file: IFile) {
    this.fileURL = file.fileURL
    this.name = file.name
    this.handle = file.handle
    this.mimetype = file.mimetype
    this.size = file.size
  }

  @action.bound
  removeFile() {
    this.fileURL = undefined
    this.name = undefined
    this.handle = undefined
    this.mimetype = undefined
    this.size = undefined
  }
}