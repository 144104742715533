import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './input.module.sass';
import cn from 'classnames';

interface IInputProps {
  name: string;
  type?: string;
  placeholder?: string;
  label?: string;
  onChange: (val: string) => void;
  value: string;
  error?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  dark?: boolean;
}

const Input: React.FunctionComponent<IInputProps> = (props) => {
  return (
    <div className={cn(styles.container, props.className, {[styles.dark]: props.dark} )}>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <input type={props.type} className={cn({[styles.disabled]: props.disabled})} disabled={props.disabled} required={props.required} value={props.value} onChange={(e) => props.onChange(e.target.value)} placeholder={props.placeholder}></input>
    </div>
  )
}

export default observer(Input);