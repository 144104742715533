import * as React from 'react';
import * as style from './items.module.sass';
import PackageItemModel from '@models/builder/elements/packages/packageItem';
import { observer } from 'mobx-react';
import { Draggable } from 'react-beautiful-dnd';
import Icon from '@components/common/Icon';
import Autocomplete from 'react-autocomplete';
import Modal from '@components/common/Modal';
import Attachment from './Attachment';

export interface PackageItemProps {
  order: number;
  packageItem: PackageItemModel;
  index: number;
  fetchAutocomplete: (value: string) => void;
  autocompleteItems: {label: string, value: string}[]
}

@observer
export default class PackageItem extends React.Component<PackageItemProps, any> {
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: PackageItemProps) {
    super(props);

    this.inputRef = React.createRef();
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    this.inputRef.current!.focus();
  }

  handleText(value: string) {
    this.props.packageItem.changeText(value);
  }

  onBlur() {
    this.props.packageItem.toggleSelect(false);
  }

  mouseEnter = () => {
    this.props.packageItem.toggleSelect(true);
  }

  mouseLeave = () => {
    if (this.inputRef.current !== document.activeElement) {
      this.props.packageItem.toggleSelect(false);
    }
  }

  render() {
    let inputClass = style.input;

    if (this.props.packageItem.selected) {
      inputClass = `${inputClass} ${style.inputHover}`;
    }

    let text = this.props.packageItem.text;

    let autocompleteMenuStyle: React.CSSProperties = {
      position: 'absolute',
      left: '20px',
      top: '54px',
      zIndex: 20,
      maxHeight: '200px',
      background: 'white',
      borderRadius: '3px',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 12px',
      border: this.props.autocompleteItems.length > 0 ? '1px solid #0076ff' : 'none',
      overflowY: 'scroll'
    }

    return (
      <div>
        <Draggable draggableId={this.props.packageItem.id} index={this.props.index}>
          {(provider) => (
            <div
              {...provider.draggableProps}
              ref={provider.innerRef}
              onMouseEnter={this.mouseEnter}
              onMouseLeave={this.mouseLeave}
              className={`package-cell ${style.item} ${style.border} ${style.borderBottom}`}
            >
              {this.props.packageItem.selected ?
                <span {...provider.dragHandleProps} className={`${style.order} ${style.dragger}`} >
                  <div className={style.wrapper}>
                    <Icon color='primary2' type='drag' />
                  </div>
                </span>
                : <p {...provider.dragHandleProps} className={style.order}>{this.props.order}.</p>
              }
              <Autocomplete
                // onBlur={this.onBlur}
                ref={this.inputRef as any}
                onChange={(e: any) => {
                  this.props.fetchAutocomplete(e.target.value);
                  this.handleText(e.target.value)
                }}
                onSelect={(value: string) => {
                  this.handleText(value)
                }}
                wrapperStyle={{
                  flex: 1,
                  marginRight: '70px'
                }}
                menuStyle={autocompleteMenuStyle}
                value={text}
                autoHighlight={true}
                getItemValue={(item: any) => item.label}
                items={this.props.autocompleteItems.slice()}
                inputProps={{
                  className: inputClass,
                  onBlur: this.onBlur.bind(this)
                }}
                renderItem={(item: any, highlighted: any) =>
                  <div
                    className={style.option}
                    key={item.id}
                  >
                    {item.label}
                  </div>
                }
              />
              {/* <input onBlur={this.onBlur} ref={this.inputRef} onChange={this.handleText.bind(this)} className={inputClass} type='text' value={text}></input> */}
              {this.props.packageItem.selected || !!this.props.packageItem.file.fileURL || this.props.packageItem.attachmentNote ?
                <span className={style.icon}>
                  <Icon 
                    color='primary2' 
                    tooltip={!!this.props.packageItem.file.fileURL || this.props.packageItem.attachmentNote ? 'Edit attachment' : 'Add attachment'} 
                    onClick={() => this.props.packageItem.toogleAttachment(true)}
                    stopPropagation 
                    type='paperclip'
                  />
                </span>
                : null}
            </div>
          )}
        </Draggable>
        <Modal maxWidth={668} onRequestClose={() => this.props.packageItem.toogleAttachment(false)} isOpen={this.props.packageItem.attachmentModalOpen}>
            <Attachment packageItem={this.props.packageItem} />
        </Modal>
      </div>
    );
  }
}