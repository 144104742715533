import * as React from 'react';
import * as style from './packagecreator.module.sass';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { inject, observer } from 'mobx-react';
import PackageItem from './PackageItem';
import Tier from './Tier';
import Connection from './Connection';
import PackageControl from './PackageControl';
import Form from './Tier/Form';
import { PackagesInstance } from '@models/builder/elements/packages';
import Icon from '@components/common/Icon';

export interface PackageCreatorProps {
  packages: PackagesInstance;
}

interface State {
  showShadow: boolean;
  dragging: boolean;
}

@inject('store')
@observer
class PackageCreator extends React.Component<PackageCreatorProps, State> {
  scroll: HTMLDivElement | null;


  constructor(props: PackageCreatorProps) {
    super(props);

    this.state = {
      showShadow: false,
      dragging: false
    };
  }

  addTier() {
    this.props.packages.addTier();
    this.scrollEnd();
  }

  scrollEnd() {
    // execute function after next tick. This way we can get one more react render in
    // and get proper container width since the new tier will already be in DOM.
    setTimeout(() => {
      if (this.scroll) {
        let cWidth = this.scroll.clientWidth;
        let sWidth = this.scroll.scrollWidth;

        this.ease(this.scroll.scrollLeft, sWidth - cWidth + 120, 600, (value) => {
          this.scroll!.scrollLeft = value;
        });
      }
    });
  }

  scrollNext() {
    if (this.scroll) {
      this.ease(this.scroll.scrollLeft, this.scroll.scrollLeft + 120, 400, (value) => {
        this.scroll!.scrollLeft = value;
      });
    }
  }

  scrollPrev() {
    if (this.scroll) {
      this.ease(this.scroll.scrollLeft, this.scroll.scrollLeft - 120, 400, (value) => {
        this.scroll!.scrollLeft = value;
      });
    }
  }

  ease(startValue: number, endValue: number, durationMs: number, cb: (value: number) => void) {
    const stepCount = durationMs / 16;
    const valueIncrement = (endValue - startValue) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = startValue;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * (Math.sin(currentSinValue) ** 2) * 2;

      if (currentSinValue < Math.PI) {
        cb(currentValue);
        window.requestAnimationFrame(step);
      } else {
        cb(endValue);
      }
    }

    step();
  }


  handleScroll(e: React.UIEvent<HTMLDivElement>) {
    let left = e.currentTarget.scrollLeft;

    if (left > 0) {
      // performance
      if (!this.state.showShadow) {
        this.setState({ showShadow: true });
      }
    } else {
      if (this.state.showShadow) {
        this.setState({ showShadow: false });
      }
    }
  }

  dragEnd(res: DropResult) {
    if (res.source.droppableId === 'packages' && res.destination) {
      this.props.packages.movePackageItems(res.source.index, res.destination.index);
    }

    if (res.source.droppableId === 'tiers' && res.destination) {
      this.props.packages.moveTiers(res.source.index, res.destination.index);
    }

    this.setState({dragging: false});
  }

  dragStart() {
    this.setState({dragging: true});
  }

  public render() {
    let items: JSX.Element[] = [];
    let tiers: JSX.Element[] = [];
    let connections: JSX.Element[] = [];
    let controlItems: JSX.Element[] = [];


    let packages = this.props.packages;

    items = packages.packageItems.map((item, index) => {
      // return <PackageItem key={item.id} packageItem={item} order={index + 1} />;
      return <PackageItem
                autocompleteItems={this.props.packages.autocompleteItems}
                fetchAutocomplete={this.props.packages.fetchAutocomplete}
                key={item.id}
                packageItem={item}
                order={index + 1}
                index={index} />;
    });

    controlItems = packages.packageItems.map((item) => {
      return <PackageControl package={this.props.packages} item={item} key={item.id} />;
    });

    tiers = packages.tiers.map((item, index) => {
      return <Tier package={this.props.packages} key={item.id} item={item} order={index + 1} />;
    });


    let connectionRow = packages.packageItems.map((packageItem) => {
      let elements: JSX.Element[] = [];

      for (let tier of packages.tiers) {
        let connection = packages.itemToTierConnections.find((item) => item.packageItem === packageItem && item.tier === tier);
        if (connection) {
          elements.push(<Connection item={connection} key={connection.packageItem.id + connection.tier.id} />);
        }
      }

      return elements;
    });

    connections = connectionRow.map((item, index) => {
      return (
        <div key={packages.packageItems[index].id} className={style.connectionRow}>
          {item}
        </div>
      );
    });


    let lineClassName = style.line;

    if (this.state.showShadow) {
      lineClassName = lineClassName + ` ${style.lineActive}`;
    }

    let connectionsClassName = style.connections;

    if (this.state.dragging) {
      connectionsClassName += ` ${style.connectionsBlur}`;
    }

    let activeTier = packages.tiers.find((item) => item.selected);
    let activePackageAttachment = packages.activePackageAttachment;

    let creatorClassName = style.creator;

    if (activeTier) {
      creatorClassName += ` ${style.creatorBlur}`;
    }

    let whiteBackgroundActive = false;
    if (this.props.packages.specialFocus) {
      whiteBackgroundActive = true;
    }

    return (
      <DragDropContext onDragStart={this.dragStart.bind(this)} onDragEnd={this.dragEnd.bind(this)}>
        <div className={style.creatorMargin}>
          <div className={style.creatorWrap} style={{position: 'relative'}}>
            <div className={creatorClassName}>
              <div className={style.packages}>
                <div style={{position: 'relative'}}>
                  <div className={`package-cell ${style.benefitHeadline}`}>
                    <p className={style.packagesHeadline}>Benefits</p>
                  </div>
                  <Droppable droppableId='packages'>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps} className={style.packageItems}>
                        {items}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <div className={lineClassName}></div>
                </div>
              </div>
              <div className={style.tierArrows}>
                <Icon onClick={() => this.scrollPrev()} type='arrow-move' />
                <Icon onClick={() => this.scrollNext()} type='arrow-move' rotate={180} />
              </div>
              <div ref={(e) => this.scroll = e} onScroll={this.handleScroll.bind(this)} className={style.scrollConainer}>
                <div className={style.tierHeader}>
                  <Droppable droppableId='tiers' direction='horizontal'>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps} className={style.tiers}>
                        {tiers}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
                <div className={connectionsClassName}>
                  {connections}
                </div>
              </div>
              <div className={style.controls}>
                <div className={style.controlsLine}></div>
                <div className={style.controlItem}>
                  <div onClick={this.addTier.bind(this)} className={style.tierAdd}>
                    <Icon tooltip='Add tier' type='plus' color='primary1' />
                  </div>
                </div>
                {controlItems}
              </div>
            </div>

            {activeTier ? <Form tier={activeTier}></Form> : null}

            <div onClick={() => this.props.packages.addPackageItem()} className={style.addNewContainer}>
              <Icon type='plus' color='primary1' />
              <p>Add new benefit</p>
            </div>
          </div>
        </div>
      </DragDropContext>
    );
  }
}


export default PackageCreator;