import * as React from 'react'
import { observer } from 'mobx-react';
import * as styles from './checkbox.module.sass';
import CheckboxOff from '@assets/helpers/checkbox-off.svg';
import CheckboxOn from '@assets/helpers/checkbox-on.svg';


interface ICheckboxInputProps {
  name: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
  icon?: string;
}

const CheckboxInput: React.FunctionComponent<ICheckboxInputProps> = (props) => {
  return (
    <label className={styles.checkboxLabel}>
      <div className={styles.icon}>
        {props.checked ? <CheckboxOn /> : <CheckboxOff />}
      </div>
      {
        props.icon ? 
          <div style={{ marginRight: '6px' }} dangerouslySetInnerHTML={{ __html: props.icon }}></div>
          : null
      }
      <p className={styles.text}>{props.value}</p>
      <input
        style={{ display: 'none' }}
        onChange={(e) => props.onChange(e.target.value)}
        type='checkbox'
        name={props.name}
        value={props.value}
        checked={props.checked}
      />
    </label>
  );
}

export default observer(CheckboxInput);