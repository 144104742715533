import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Label from '../common/Label';
import { FontSelector as FontSelectorModel } from '@models/builder/customization/elements';
import * as styles from './font.module.sass';
import Icon from '@app/components/common/Icon';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import ElementContent from '../common/ElementContent';

interface IFontSelectorProps {
  element: FontSelectorModel
}

let FontSelector: React.FunctionComponent<IFontSelectorProps> = (props) => {

  let list = props.element.filteredFamilies.map(item => {
    return (
      <div onClick={(e) => props.element.changeFont(item)} key={item} className={styles.fontItem}>
        <p style={{ fontFamily: item }}>{item}</p>
      </div>
    )
  })

  let value = props.element.showEmptyFilter ? props.element.filter : props.element.filter || props.element.font_family

  React.useEffect(() => {
    props.element.loadFonts();
  }, [props.element.families.length === 0])

  function inputClick(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    if (props.element.open) {
      e.stopPropagation();
    }
  }

  return (
    <OutsideClickHandler onOutsideClick={() => { if (props.element.open) props.element.toggleOpen(false) }}>
      <div>
        <Label helpText={props.element.help_text} label={props.element.label} premiumLabel />
        <ElementContent premium>
          <div>
            <div style={{ position: 'relative' }}>
              <div onClick={() => props.element.toggleOpen(!props.element.open)} className={cn(styles.fontSelector, { [styles.fontSelectorActive]: props.element.open })}>
                <input onClick={(e) => inputClick(e)} onChange={(e) => props.element.setFilter(e.target.value)} value={value}></input>
                <Icon type='ange-down' inheritStyle />
              </div>
              {props.element.open &&
                <div className={styles.fontList}>
                  {list}
                </div>
              }
            </div>
          </div>
        </ElementContent>
      </div>
    </OutsideClickHandler>
  )
}

FontSelector = observer(FontSelector);

export default FontSelector;