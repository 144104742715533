import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Proposal from '@models/builder/proposal';
import Section from './Section';
import * as styles from './sections.module.sass';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import VirtualSectionModel from '@models/builder/customization/VirtualSection';
import VirtualSection from './VirtualSection';

interface ISectionsProps {
  proposal: Proposal;
}

let Sections: React.FunctionComponent<ISectionsProps> = (props) => {
  const [openId, toggleOpen] = React.useState('');

  let sections = props.proposal.combinedSections.map((item, index) => {
    return (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <div
            className={styles.dragContainer}
            ref={provided.innerRef}
            {...provided.draggableProps}
            // ugly hack to get dnd working on parent that has translateX
            // For some reason Draggable has a position fixed with left that is big number (in 1000s px)
            // I think it has something to with the customziation being out of view 
            style={Object.assign(provided.draggableProps.style, { left: '0px' })}
          >
            {item instanceof VirtualSectionModel ?
              <VirtualSection toggleOpen={toggleOpen} open={openId === item.id} dragProps={provided.dragHandleProps} key={item.id} virtual={item} />
            :
              <Section toggleOpen={toggleOpen} open={openId === item.id} dragProps={provided.dragHandleProps} key={item.id} section={item} />
            }
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    )
  })

  function onDragEnd(result: DropResult) {
    if (result.destination) {
      props.proposal.settings.changeSectionPositionTo(result.source.index, result.destination.index)
    }
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='customization-sections'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={styles.sections}>
              <p className={styles.label}>Sections</p>
              {sections}
              {provided.placeholder}
              <p onClick={() => props.proposal.settings.addVirtualSection()} className={styles.addNew}>+ Add Transition Section</p>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

Sections = observer(Sections)
export default Sections;