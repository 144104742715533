import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Proposals from './Proposals';
import { RouteComponentProps } from 'react-router-dom'; 
import { withRouterApp } from '@app/util/router.tsx';
import * as styles from './dashboard.module.sass';
import Sidebar from '@components/Sidebar';
import { Switch, Route } from 'react-router-dom'; 
import Proposal from '@components/dashboard/Proposal';
import NewProposal from '@components/dashboard/NewProposal';
import FromExample from './FromExample';
import { rootContext } from '@app/index';

interface IDashboardProps {};

let Dashboard: React.FunctionComponent<IDashboardProps & RouteComponentProps<any>> = (props) => {
  const rootStore = React.useContext(rootContext);

  React.useEffect(() => {
    rootStore.proposalStore.pullExamples();
    rootStore.proposalStore.pullFolders();
  }, [])

  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <div className={styles.content}>
        <Switch>
          <Route exact path={`${props.match.path}/proposal/:uuid`} component={Proposal} />
          <Route exact path={`${props.match.path}/new/proposal`} component={NewProposal} />
          <Route exact path={`${props.match.path}/new/example`} component={FromExample} />
          <Route exact path={`${props.match.path}`} component={Proposals} />
        </Switch>
      </div>
    </div>
  )
}

Dashboard = withRouterApp(observer(Dashboard));

export default Dashboard;