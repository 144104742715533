
import * as React from 'react';
import { observer } from 'mobx-react';
import Model from '@models/builder/elements/text';
import Instance from './Instance';
import ElementHeadline from '@components/builder/common/ElementHeadline';


export interface ITextProps {
  model: Model;  
}

interface State {}

@observer
export default class TextElement extends React.Component<ITextProps, State> {
  public render() {

    let configuration = this.props.model.configuration;

    let instances = this.props.model.instances.map((item) => {
      return <Instance key={item.id} configuration={this.props.model.configuration} instance={item} />
    })

    return (
      <div>
        <ElementHeadline complete={this.props.model.complete} text={this.props.model.name} markdown={configuration.tooltip} />
        <div>
          {instances}
        </div>
      </div>
    );
  }
}
