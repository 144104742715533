
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@app/models/builder/elements/audienceGroup';
import SuggestInput from '@components/builder/common/SuggestInput';
import * as styles from './spillter.module.sass';
import ArrowTop from '@assets/helpers/arrow-top.svg';
import Input, { FormattedInput } from '@components/builder/common/Input';
import ItemDelete from '@assets/helpers/item-delete.svg';
import { NumberFormatValues } from 'react-number-format';
import Icon from '@components/common/Icon';

export interface IInstanceProps {
  instance: Model.AudienceGroupInstance,
  configuration: Model.IAudienceGroupConfig
}

interface State {
  hover: boolean;
}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  constructor(props: IInstanceProps) {
    super(props);

    this.state = {
      hover: false
    }
  }

  updateValue(key: string, value: string) {
    this.props.instance.changeValue(key, value);
  }

  updatePercentValue(key: string, values: NumberFormatValues) {
    this.props.instance.changeValuePercent(key, values.floatValue);
  }

  public render() {
    let items = this.props.instance.values.map((item) => {

      let percent: string | undefined;

      if (item.percent) {
        percent = `${item.percent}%`
      }

      return (
        <div key={item.key} className={styles.item}>
          <div className={styles.itemDelete}>
            <ArrowTop className={styles.arrowItem} />
            <ItemDelete onClick={() => this.props.instance.deleteValue(item.key)} className={styles.itemDeleteIcon} />
          </div>
          <div className={styles.itemText}>
            <Input placeholder='Name of the category' onChange={this.updateValue.bind(this, item.key)} value={item.name} />
          </div>
          <div className={styles.itemPercent}>
            <FormattedInput
              suffix='%'
              placeholder={`${Math.floor(this.props.instance.remainPercentPlaceholder)}%`}
              onValueChange={this.updatePercentValue.bind(this, item.key)}
              value={percent}
            />
          </div>
        </div>
      )
    })

    return (
      <div>
        <div onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })} className={styles.splitterInputContainer}>
          <SuggestInput
            open={this.props.instance.suggestOpen}
            items={this.props.instance.suggestionValues}
            onChange={this.props.instance.onChange}
            onSelect={this.props.instance.onSelect}
            toggle={this.props.instance.toggleSuggest}
            value={this.props.instance.name}
          />
          <div className={styles.iconWrap}>
            {this.state.hover ? <Icon onClick={this.props.instance.delete} style={{margin: '0 auto'}} type='trashcan' /> : <div>{`${this.props.instance.totalPercent}%`}</div>}
          </div>
        </div>
        {items}
        <div onClick={() => this.props.instance.addValue()} className={styles.addCategory}>
          <ArrowTop className={styles.arrow} />
          <p>+ Add new category</p>
        </div>
      </div>
    );
  }
}
