import { observable, computed } from "mobx";
import { serializable, list, object, raw, deserialize } from "serializr";
import { ILeadData } from "@template/stores/form";
import { ITier } from "./elements/packages/interfaces";
import { IAdditionalOpportunityInstance } from "./elements/additionalOpportunity";
import shortid from "shortid";

interface ILead extends ILeadData {
  id: string;
  created_at: string;
}

export class Lead implements ILead {

  @observable
  @serializable
  id = shortid.generate();

  @observable
  @serializable
  form_process_id: string;

  @observable
  example = false;

  @observable
  @serializable
  created_at: string;

  @observable
  @serializable(raw())
  customer_data = {
    name: '',
    phoneNumber: '',
    email: '',
    company: '',
    position: '',
    website: '',
    note: ''
  }

  @observable
  @serializable
  status = 'recived';

  @observable
  @serializable(list(raw()))
  tiers = observable<ITier>([]);

  @observable
  @serializable(list(raw()))
  additional_offers = observable<IAdditionalOpportunityInstance>([]);

  @computed get tiresTotalPrice() {
    return this.tiers.reduce((prev, next) => {
      let nextPrice = next.pricing.price || 0;
      let prevPrice = typeof prev === 'number' ? prev : ((prev as ITier).pricing.price || 0)
      return nextPrice + prevPrice;
    }, 0)
  }

  @computed get offersTotalPrice() {
    return this.additional_offers.reduce((prev, next) => {
      let nextPrice = next.value || 0;
      let prevPrice = typeof prev === 'number' ? prev : ((prev as IAdditionalOpportunityInstance).value || 0)
      return nextPrice + prevPrice;
    }, 0)
  }

  @computed get totalPrice() {
    return this.tiresTotalPrice + this.offersTotalPrice
  }
}

export default class CRM {
  @observable
  @serializable(list(object(Lead)))
  leads = observable<Lead>([])

  @observable
  exampleLeads = observable<Lead>([]);

  constructor() {
    this.exampleLeads.replace(CRM.getExampleLeads());
  }

  static getExampleLeads() {
    let lead1data: ILead = {
      id: shortid.generate(),
      status: 'received',
      form_process_id: 'test',
      customer_data: {
        name: 'Roman Jackson',
        phoneNumber: '+420 442 643 135',
        email: 'roman@jackson.com',
        company: 'Jackson LLC',
        position: 'CEO',
        website: 'www.jackson.com',
        note: 'Hello, I am curious what your event can offer us in terms of visitors on.'
      },
      tiers: [{
        id: 'asdc-cvcx--ffvxx-dds',
        name: 'Gold Package',
        description: 'Everything is included',
        badge: 'favorite',
        pricing: {
          price: 2000,
          type: 'one-time',
          humanType: 'One Time'
        },
        tickets: {
          current: 0,
          available: 12
        }
      }],
      additional_offers: [{
        id: 'sadadzxzc',
        name: 'Standalone poster ',
        value: 3000,
        filled: true,
        file: {
          fileURL: undefined,
          name: undefined,
          mimetype: undefined,
          handle: undefined,
          size: undefined
        },
        note: ''
      }]
    }

    let lead2Data: ILead = {
      id: shortid.generate(),
      status: 'received',
      form_process_id: 'example',
      customer_data: {
        name: 'John Snow',
        phoneNumber: '+420 442 643 135',
        email: 'snow@johan.com',
        company: undefined,
        position: undefined,
        website: 'www.jackson.com',
        note: undefined
      },
      tiers: [{
        id: 'asdc-cvcx--ffvxx-dds',
        name: 'Standard Package',
        description: 'Everything is included',
        badge: 'favorite',
        pricing: {
          price: 0,
          type: 'one-time',
          humanType: 'One Time'
        },
        tickets: {
          current: 0,
          available: 12
        }
      }],
      additional_offers: []
    }


    let lead1 = deserialize(Lead, lead1data);
    let lead2 = deserialize(Lead, lead2Data);


    lead2.example = true;
    lead1.example = true;
    return [lead1, lead2]
  }
}