import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Label from '@components/builder/customization/elements/common/Label';

import { DropdownBasic } from '@app/components/common/Dropdown';
import { SettingsContext } from '@app/components/builder/Form/context';
import dateFormats from '@common/util/dateFormats';

interface IDateFormatProps {

}

let DateFormat: React.FunctionComponent<IDateFormatProps> = (props) => {
  const settings = React.useContext(SettingsContext);
  
  return (
    <div>
      <Label helpText='Change proposal date format'  label='Date format' />
      <div>
        <DropdownBasic
          options={dateFormats}
          value={settings.dateFormat}
          placeholder='test'
          onChange={(value: any) => settings.changeDateFormat(value)}
          theme='dark'
        />
      </div>
    </div>
  )
}

DateFormat = observer(DateFormat);

export default DateFormat;