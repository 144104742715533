import * as shortid from 'shortid';
import { observable, action, when, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, SKIP, custom } from 'serializr';
import File, { IFile } from '@app/util/elements/FileUpload';
import MarkdownField, { IMarkdownField } from '@models/builder/common/MarkdownField';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';
import emitter from '@util/emitter';

// releated interfacess

// model interfaces

export interface IDetailedDescriptionConfig extends IBaseConfiguration {
  maxLength: number;
}

export interface IDetailedDescriptionInstance extends IBaseInstanceElement  {
  file: IFile;
  description: IMarkdownField;
  headline: string;
}

export interface IDetailedDescription extends IElement<IDetailedDescriptionConfig, IDetailedDescriptionInstance> {}

// model classes

export class DetailedDescriptionInstance implements IDetailedDescriptionInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable(object(File))
  file = new File();

  @observable
  @serializable
  headline = '';

  @observable
  @serializable(object(MarkdownField))
  description = new MarkdownField()

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {

    return this.headline.length > 0 || this.description.charactersCount > 1
  }

  parent: DetailedDescription
  constructor(parent: DetailedDescription) {
    this.parent = parent;

    when(
      () => !!this.parent.configuration,
      () => this.init()
    )
  }

  init() {
    this.description.characterLimit = this.parent.configuration.maxLength
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  @action.bound
  changeHeadline(headline: string) {
    this.headline = headline;
  }

  static schema() {
    return getDefaultModelSchema(DetailedDescriptionInstance).factory = (context: Context) => {
      return new DetailedDescriptionInstance(context.args.parent);
    };
  }
}

export default class DetailedDescription extends BaseElement<IDetailedDescription, IDetailedDescriptionConfig, DetailedDescriptionInstance> {

  @observable
  @serializable(elementInstanceSerializer(DetailedDescriptionInstance))
  readonly instances = observable<DetailedDescriptionInstance>([]);

  @action.bound
  addInstance() {
    if (this.overLimit) {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'instance'
      })

      return;
    }

    this.instances.push(new DetailedDescriptionInstance(this));
  }

  @action.bound
  deleteInstance(instance: DetailedDescriptionInstance) {
    this.instances.remove(instance);
  }

  @computed get overLimit() {
    if (this.root.user.premium) {
      return false;
    }

    if (this.instances.length >= this.limitation.maxParagraphs) {
      return true;
    } else {
      return false;
    }
  }

  static getInstanceClass() {
    return DetailedDescriptionInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new DetailedDescriptionInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(DetailedDescription).factory = (context: Context) => {
      return new DetailedDescription(context.args.root, context.args.proposal);
    };
  }
}

DetailedDescriptionInstance.schema()
DetailedDescription.schema();