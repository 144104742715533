import * as shortid from 'shortid';
import { observable, action, computed, when } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, custom, SKIP } from 'serializr';
import { SocialMediaHandle, ISocialMediaHandle } from '@models/builder/common/SocialMediaPicker';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

// releated interfacess

// model interfaces

export interface ISocialMediaConfig extends IBaseConfiguration {
  social_networks: {
    name: string;
    example_link: string;
  }[]
}

export interface ISocialMediaInstance extends IBaseInstanceElement  {
  handle: ISocialMediaHandle;
}

export interface ISocialMedia extends IElement<ISocialMediaConfig, ISocialMediaInstance> {}

// model classes

export class SocialMediaInstance implements ISocialMediaInstance {
  @observable
  @serializable
  id = shortid.generate()

  @serializable(object(SocialMediaHandle))
  handle = new SocialMediaHandle();

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.handle.link && !!this.handle.name;
  }

  parent: SocialMedia
  constructor(parent: SocialMedia) {
    this.parent = parent;
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(SocialMediaInstance).factory = (context: Context) => {
      return new SocialMediaInstance(context.args.parent);
    };
  }
}

export default class SocialMedia extends BaseElement<ISocialMedia, ISocialMediaConfig, SocialMediaInstance> {

  @observable
  @serializable(elementInstanceSerializer(SocialMediaInstance))
  readonly instances = observable<SocialMediaInstance>([]);

  @action.bound
  addInstance() {
    this.instances.push(new SocialMediaInstance(this));
  }

  @action.bound
  deleteInstance(instance: SocialMediaInstance) {
    this.instances.remove(instance);
  }

  static getInstanceClass() {
    return SocialMediaInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    when(
      () => !!this.configuration,
      () => this.init()
    )
  }

  init() {
    if (!this.hasInstance) {
      let socialMediaInstance = new SocialMediaInstance(this)
      let firstItem = this.configuration.social_networks[0];
      if (firstItem) {
        socialMediaInstance.handle.name = firstItem.name;
      }
    
      this.instances.push(socialMediaInstance);
    }
  }

  static schema() {
    return getDefaultModelSchema(SocialMedia).factory = (context: Context) => {
      return new SocialMedia(context.args.root, context.args.proposal);
    };
  }
}

SocialMediaInstance.schema();
SocialMedia.schema();