import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './sidebar.module.sass';
import { withRouterApp } from '@app/util/router.tsx';
import cn from 'classnames';
import Icon from '@components/common/Icon';
import { RouteComponentProps, NavLink, Route, Switch, Link } from 'react-router-dom';
import LogoSmall from '@assets/logos/logo_small.svg';

import Proposals from './Proposals';
import Account from './Account';
import { rootContext } from '@app/index';

interface ISidebarProps {}

let Sidebar: React.FunctionComponent<ISidebarProps & RouteComponentProps<any>> = (props) => {
  const rootStore = React.useContext(rootContext);

  return (
    <div className={styles.container}>
      <div className={styles.sidenav}>
        <div className={styles.navigation}>
          <Link to='/proposals' className={styles.logoSmall}>
            <LogoSmall />
          </Link>
          {/* <div className={styles.navigationItem}>
            <Icon tooltipPosition='right' tooltip='Dashboard. Comming soon.' type='chart-line' />
          </div> */}
          <NavLink to='/proposals' activeClassName={styles.navigationItemActive} className={styles.navigationItem}>
            <Icon tooltipPosition='right' tooltip='Dashboard' type='proposals' />
          </NavLink>
          <div className={styles.navigationItem}>
            <Icon tooltipPosition='right' tooltip='Extensions. coming soon.' type='shapes' />
          </div>
          {/* <NavLink to='/settings' activeClassName={styles.navigationItemActive} className={styles.navigationItem}>
            <Icon type='users' />
          </NavLink> */}
        </div>
        <div className={styles.info}>
          <div className={styles.infoItem}>
            <Icon tooltip='Tutorials. coming soon.' type='video' />
          </div>
          {/* <div className={styles.infoItem}>
            <Icon type='info' />
          </div>
          <div className={styles.infoItem}>
            <Icon type='bell' />
          </div>
           */}
          <Link to='/account/pricing' className={cn(styles.infoItem, styles.infoItemPremium)}>
            <Icon tooltip='Premium' inheritStyle type='crown' />
          </Link>
          <NavLink activeClassName={styles.activeInfoItem} to='/account/general' className={styles.infoItem}>
            <Icon tooltip='Your profile' inheritStyle type='user-profile' />
          </NavLink>
        </div>
      </div>
      {!rootStore.ui.sidebar.hidden &&
        <div className={styles.content}>
          <Switch>
            <Route path='/proposals' component={Proposals} />
            <Route path='/account' component={Account} />
          </Switch>
        </div>
      }
    </div>
  )
}

export default withRouterApp<ISidebarProps>(observer(Sidebar));