import * as React from 'react'
import { observer } from 'mobx-react';
import CreatableSelect from 'react-select/lib/Creatable';
import dropdownStyle from './styles';
import * as styles from './dropdown.module.sass';
import BlockLabel from '@components/builder/common/BlockLabel';


interface Option {
  label: string;
  value: string;
}

interface IDropdownCreatableMultiProps {
  options: string[],
  value: string[],
  onChange: (value: string[]) => void;
  label?: string;
  placeholder: string;
  hideNoOption?: boolean;
}

const DropdownCreatableMulti: React.FunctionComponent<IDropdownCreatableMultiProps> = (props) => {

  let options = props.options.map((item) => {
    return { label: item, value: item }
  })

  let values = props.value.map((item) => {
    return { label: item, value: item}
  })

  let hideOptions = props.hideNoOption || props.options.length === 0

  return (
    <div style={{ position: 'relative' }}>
      {props.label ?
        <div className={styles.labelBlock}>
          <BlockLabel text={props.label} />
        </div>
        : null}
      <CreatableSelect
        isMulti={true}
        styles={dropdownStyle(true, hideOptions)}
        placeholder={props.placeholder}
        noOptionsMessage={hideOptions ? () => null : () => 'No options'}
        theme={base => ({
          ...base, colors: {
            ...base.colors,
            primary: '#0076ff'
          }
        })}
        onChange={(selectedOption: Option[]) => {
          let mapped = selectedOption.map((item) => item.label);
          props.onChange(mapped)
        }
        }
        options={options}
        value={values}
      />
    </div>
  )
}

export default observer(DropdownCreatableMulti);