import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from "@components/auth/auth.module.sass";
import Icon from "@components/common/Icon";
import { useFormik } from 'formik';
import {rootContext} from "@app/index";
import {Redirect, Route, Switch} from "react-router-dom";
import Logo from "@components/common/Logo";

interface IOnboardingProps {}

let Onboarding: React.FunctionComponent<IOnboardingProps> = (props) => {
    const rootStore = React.useContext(rootContext);
    const router = rootStore.router;
    const [step, toggleStep] = React.useState(1)

    function switchStep(e: any, step: number) {
        e.preventDefault()
        router.history.push(`/auth/onboarding/${step}`)
    }

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            organization_category: 'Event Oranizer (own events)',
            position: 'CEO',
            company_size: 'Just me',
            usage_reason: 'Build a web version of my existing sponsorship deck'
        },
        onSubmit: async values => {
            await rootStore.user.updateOnboarding(values);
        },
        validate: values => {
            window.sessionStorage.setItem('onboarding-form', JSON.stringify(values))
            return {}
        }
    });

    // @ts-ignore
    React.useEffect(() => {
        let data = window.sessionStorage.getItem('onboarding-form');
        if (data) {
            // @ts-ignore
            formik.setFormikState({ values: JSON.parse(data) })
        }
    }, [])

    React.useEffect(() => {
        const step = parseInt(router.location.pathname.split('/').slice(-1)[0]);
        if (Number.isInteger(step)) {
            toggleStep(step);
        }
    }, [router.location.pathname])

    return (
        <div className={styles.onboarding}>
            <div className={styles.logoContainer}>
                <Logo />
            </div>

            <Switch>
                <Redirect exact from={'/auth/onboarding'} to={'/auth/onboarding/1'} />
                <Route exact path={'/auth/onboarding/1'} render={() => {
                    return (
                        <div className={styles.onboardingContainer}>
                            <h1 style={{marginTop: '0px'}}>So what’s your name?</h1>
                            <form onSubmit={(e) => switchStep(e, 2)}>
                                <div className={styles.onboardingFlex}>
                                    <div className={styles.inputContainer}>
                                        <label>First name</label>
                                        <input required id='first_name' type='text' name='first_name' value={formik.values.first_name} onChange={formik.handleChange}/>
                                    </div>
                                    <div className={styles.inputContainer}>
                                        <label>SURNAME</label>
                                        <input required id='last_name' type='text' name='last_name' value={formik.values.last_name} onChange={formik.handleChange}/>
                                    </div>
                                </div>
                                <button disabled={!(formik.values.last_name && formik.values.first_name)} type='submit' className={styles.button}>
                                    Continue
                                    <Icon rotate={180} type='arrow' />
                                </button>
                            </form>
                        </div>
                    )
                }} />

                <Route exact path={`/auth/onboarding/2`} render={() => (
                    <div className={styles.onboardingContainer}>
                        <h1 style={{marginTop: '0px'}}>Now little bit more about your business...</h1>
                        <form onSubmit={(e) => switchStep(e, 3)}>
                            <div className={styles.inputContainer}>
                                <label>YOUR Organization’s category</label>
                                <select required id='organization_category' name='organization_category' value={formik.values.organization_category} onChange={formik.handleChange}>
                                    <option value='Event Oranizer (own events)' label={'Event Oranizer (own events)'} />
                                    <option value='Event Agency (own & client events)' label={'Event Agency (own & client events)'} />
                                    <option value='Athlete' label={'Athlete'} />
                                    <option value='Sport Team' label={'Sport Team'} />
                                    <option value='Non Profit' label={'Non Profit'} />
                                    <option value='E-Sport Team/ Player' label={'E-Sport Team/ Player'} />
                                    <option value='Education Group (club, society...)' label={'Education Group (club, society...)'} />
                                    <option value='Influencer (Youtube, Instagram...)' label={'Influencer (Youtube, Instagram...)'} />
                                    <option value='Online Media (magazine, blog...)' label={'Online Media (magazine, blog...)'} />
                                    <option value='Venue or place (coworking, stadium...)' label={'Venue or place (coworking, stadium...)'} />
                                    <option value='Newsletter' label={'Newsletter'} />
                                    <option value='Wifi Spot' label={'Wifi Spot'} />
                                    <option value='Magazine' label={'Magazine'} />
                                    <option value='Other' label={'Other'} />
                                </select>
                            </div>
                            <button disabled={!formik.values.organization_category} type='submit' className={styles.button}>
                                Continue
                                <Icon rotate={180} type='arrow' />
                            </button>
                        </form>
                    </div>
                )} />

                <Route exact path={`/auth/onboarding/3`} render={() => (
                    <div className={styles.onboardingContainer}>
                        <h1 style={{marginTop: '0px'}}>What do you do there?</h1>
                        <form onSubmit={(e) => switchStep(e, 4)}>
                            <div className={styles.inputContainer}>
                                <label>YOUR POSITION</label>
                                <select required id='position' name='position' value={formik.values.position} onChange={formik.handleChange}>
                                    <option value='CEO' label={'CEO'} />
                                    <option value='Executive Position' label={'Executive Position'} />
                                    <option value='Sponsorship Manager' label={'Sponsorship Manager'} />
                                    <option value='Graphic Designer' label={'Graphic Designer'} />
                                    <option value='Sales Team' label={'Sales Team'} />
                                    <option value='Others' label={'Others'} />
                                </select>
                            </div>
                            <button disabled={!formik.values.position} type='submit' className={styles.button}>
                                Continue
                                <Icon rotate={180} type='arrow' />
                            </button>
                        </form>
                    </div>
                )} />

                <Route exact path={`/auth/onboarding/4`} render={() => (
                    <div className={styles.onboardingContainer}>
                        <h1 style={{marginTop: '0px'}}>What is the size of the business?</h1>
                        <form onSubmit={(e) => switchStep(e, 5)}>
                            <div className={styles.inputContainer}>
                                <label>YOUR Organization’s category</label>
                                <select required id='company_size' name='company_size' value={formik.values.company_size} onChange={formik.handleChange}>
                                    <option value='Just me' label='Just me' />
                                    <option value='2 - 5 people' label='2 - 5 people' />
                                    <option value='5 - 10 People' label='10 - 50 people' />
                                    <option value='More than 50 people' label='More than 50 people' />
                                </select>
                            </div>
                            <button disabled={!formik.values.company_size} type='submit' className={styles.button}>
                                Continue
                                <Icon rotate={180} type='arrow' />
                            </button>
                        </form>
                    </div>
                )} />

                <Route exact path={`/auth/onboarding/5`} render={() => (
                    <div style={{maxWidth: '413px'}} className={styles.onboardingContainer}>
                        <h1 style={{marginTop: '0px'}}>Why are you here?</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className={styles.inputContainer}>
                                <label>WHAT DO YOU EXPECT FROM SPONSEASY</label>
                                <select required id='usage_reason' name='usage_reason' value={formik.values.usage_reason} onChange={formik.handleChange}>
                                    <option value='Build a web version of my existing sponsorship deck' label={'Build a web version of my existing sponsorship deck'} />
                                    <option value='Build a sponsorship deck for the first time' label={'Build a sponsorship deck for the first time'} />
                                    <option value='Better manage my sales process' label={'Better manage my sales process'} />
                                    <option value='Build a simple website' label={'Build a simple website'} />
                                    <option value='Manage my entire sponsorship sales cycle' label={'Manage my entire sponsorship sales cycle'} />
                                    <option value='Use it as my event website' label={'Use it as my event website'} />
                                    <option value='None of these' label={'None of these'} />
                                </select>
                            </div>
                            <button disabled={!formik.values.usage_reason || formik.isSubmitting} type='submit' className={styles.button}>
                                Finish
                                <Icon rotate={180} type='arrow' />
                            </button>
                        </form>
                    </div>
                )} />

            </Switch>

            <div style={{ width: `${step * 20}%` }} className={styles.onboardingLineContainer}>
                <p>Step {step}/5 🏁</p>
                <div/>
            </div>
        </div>
    )
}

Onboarding = observer(Onboarding);
export default Onboarding;