import React from 'react';
import Trashcan from '@assets/icons/trashcan.svg';
import DragCombined from '@assets/icons/drag.svg';

import { 
  faPlus,
  faTimes,
  faCalendarAlt, 
  faSearch, 
  faIdCard, 
  faBuilding,
  faPaintRoller,
  faLink,
  faAngleDoubleDown,
  faEye,
  faEyeSlash,
  faCrown,
  faDesktop,
  faArrowLeft,
  faStickyNote,
  faInfo,
  faUsers,
  faVideo,
  faQuestionCircle,
  faBell,
  faUserCircle,
  faPen,
  faAngleRight,
  faChevronRight,
  faCaretRight,
  faTrash,
  faClone,
  faSignOutAlt,
  faWindowRestore,
  faChartLine,
  faShapes,
  faCheckCircle,
  faEyeDropper,
  faFile,
  faSlidersH,
  faTimesCircle,
  faFolder,
  faCog,
  faFolderPlus,
  faPhone,
  faEnvelope,
  faBars,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons'

import { 
  faTrashAlt,
  faWindowClose, 
  faClock, 
  faImage,
  faNewspaper,
  faEdit,
  faFilePdf
} from '@fortawesome/free-regular-svg-icons'

import {
  faPaperclip,
  faAngleDown,
  faFileInvoiceDollar,
  faMobile,
  faEllipsisH,
  faCheck,
  faEye as faEyeRegular
} from '@fortawesome/pro-regular-svg-icons';

import {
  faArrowAltToTop
} from '@fortawesome/pro-solid-svg-icons'


import ArrowMove from '@assets/elements/packages/icons/icon_arrowMove.svg';
import Duplicate from '@assets/elements/packages/icons/icon_duplicate.svg';
import Edit from '@assets/elements/packages/icons/icon_edit.svg'
import Trash from '@assets/elements/packages/icons/icon_bin.svg';
import Drag from '@assets/elements/packages/icons/icon_dragDrop.svg';
import Attachment from '@assets/elements/packages/icons/icon_attachment.svg';
import More from '@assets/elements/packages/icons/icon_more.svg';
import Checkbox from '@assets/elements/packages/icons/icon_check_bold.svg';
import Cross from '@assets/elements/packages/icons/icon_cross_bold.svg';
import { faKey, faUsersClass } from '@fortawesome/pro-solid-svg-icons';

let sidebar = {
  'chart-line': faChartLine,
  'proposals': faWindowRestore,
  'users': faUsers,
  'video': faVideo,
  'question-circle': faQuestionCircle,
  'bell': faBell,
  'user-profile': faUserCircle,
  'shapes': faShapes
}

let packageIcons = {
  'arrow-move': ArrowMove,
  'duplicate': Duplicate,
  'edit': Edit,
  'trash': Trash,
  'drag': Drag,
  'attachment': Attachment,
  'more': More,
  'cross': Cross,
  'checkmark': Checkbox
}

const Icons = {
  'trashcan': faTrashAlt,
  'plus': faPlus,
  'close': faTimes,
  'windowClose': faWindowClose,
  'calendar': faCalendarAlt,
  'clock': faClock,
  'search': faSearch,
  'idCard': faIdCard,
  'building': faBuilding,
  'position': faPaintRoller,
  'link': faLink,
  'image': faImage,
  'doubleArrowDown': faAngleDoubleDown,
  'eye': faEye,
  'eye-regular': faEyeRegular,
  'eye-slashed': faEyeSlash,
  'crown': faCrown,
  'mobile': faMobile,
  'desktop': faDesktop,
  'editPage': faEdit,
  'arrow': faArrowLeft,
  'paperclip': faPaperclip,
  'sticky-note': faStickyNote,
  'info': faInfo,
  'ange-down': faAngleDown,
  'three-dots': faEllipsisH,
  'file': faFile,
  'pen-solid': faPen,
  'chevron': faChevronRight,
  'angle-solid': faAngleRight,
  'caret': faCaretRight,
  'clone': faClone,
  'trashcan-solid': faTrash,
  'sign-out': faSignOutAlt,
  'pdf': faFilePdf,
  'eye-dropper': faEyeDropper,
  'check-circle': faCheckCircle,
  'sliders-h': faSlidersH,
  'newspapers': faNewspaper,
  'checkmark-fontawesome': faCheck,
  'drag-combined': DragCombined,
  'folder': faFolder,
  'times-circle': faTimesCircle,
  'cog': faCog,
  'folder-plus': faFolderPlus,
  'email': faEnvelope,
  'phone': faPhone,
  'bars': faBars,
  'key': faKey,
  'move-top': faArrowAltToTop,
  ...packageIcons,
  ...sidebar
}

export type IconTypes = keyof typeof Icons;

export default Icons;