
import * as React from 'react';
import { observer } from 'mobx-react';
import Model from '@models/builder/elements/AdditionalOpportunity';
import Instance from './Instance';
import AddInstance from '@components/builder/common/AddInstance';
import * as styles from './additional.module.sass';
import ElementHeadline from '@components/builder/common/ElementHeadline';

export interface IAdditionalOpportunityProps {
  model: Model
}

interface State { }

@observer
export default class AdditionalOpportunity extends React.Component<IAdditionalOpportunityProps, State> {
  public render() {

    let configuration = this.props.model.configuration;

    let content = this.props.model.instances.map((item, index) => {
      return <Instance index={index} key={item.id} configuration={this.props.model.configuration} instance={item} />
    })

    if (this.props.model.canAddInstance) {
      content.push(
        <div key='add-new' className={styles.addInstance}>
          <AddInstance addInstance={this.props.model.addInstance} text='+ Add additional opportunity' />
        </div>
      )
    }

    return (
      <div>
        <ElementHeadline complete={this.props.model.complete} text={this.props.model.name} markdown={configuration.tooltip} />
        <div className={styles.element}>
          {content}
        </div>
      </div>
    );
  }
}
