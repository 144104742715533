
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import * as styles from './customization.module.sass';
import { rootContext } from '@app/index';
import Proposal from '@models/builder/proposal';
import cn from 'classnames';
import General from './General';
import Sections from './Sections';
import { SettingsContext } from '../Form/context';

export interface ICustomizationProps {
  proposal: Proposal;
}

let Customization: React.FunctionComponent<ICustomizationProps> = (props) => {
  const rootStore = React.useContext(rootContext);

  let generalClassName = cn(styles.tab, {
    [styles.tabActive]: rootStore.ui.builder.openCustomizationTab === 'general'
  });

  let sectionClassName = cn(styles.tab, {
    [styles.tabActive]: rootStore.ui.builder.openCustomizationTab === 'section'
  })

  return (
    <SettingsContext.Provider value={props.proposal.settings}>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <div onClick={() => rootStore.ui.builder.toggleCustomizationTab('general')} className={generalClassName}>
            <p>General</p>
          </div>
          <div onClick={() => rootStore.ui.builder.toggleCustomizationTab('section')} className={sectionClassName}>
            <p>Sections</p>
          </div>
        </div>
        {rootStore.ui.builder.openCustomizationTab === 'general' ?
          <General proposal={props.proposal} />
          :
          <Sections proposal={props.proposal} />
        }
      </div>
    </SettingsContext.Provider>

  )
}

Customization = observer(Customization);

export default Customization;