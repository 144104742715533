
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
import * as styles from './period.module.sass';
import Input, { CurrencyInputFormat } from '@components/builder/common/Input';
import OutsideClickHandler from 'react-outside-click-handler';
import BlockLabel from '@components/builder/common/BlockLabel';
import Icon from '@components/common/Icon';
import { SettingsContext } from '@components/builder/Form/context';

export interface IItemProps {
  changeDate: (date: Moment | null) => void;
  changeTime: (time: string) => void;
  time: string | undefined;
  date: Moment | null;
  label: string;
  color?: 'orange' | 'blue';
  timePlaceholder?: string;
}

let Item: React.FunctionComponent<IItemProps> = (props) => {
  const [showDatePicker, toggleDatePicker] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [focusedDate, focusDate] = React.useState(false);

  const settings = React.useContext(SettingsContext)

  let colorStyle = {
    color: 'initial'
  }

  if (props.color) {
    colorStyle.color = props.color === 'blue' ? '#1fa3d1' : '#e49d0b'
  }

  return (
    <OutsideClickHandler onOutsideClick={() => toggleDatePicker(false)}>
      <div className={styles.item}>
        <div className={styles.date}>
          <div className={styles.label}>
            <BlockLabel text={props.label} />
          </div>
          <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={colorStyle} onClick={() => toggleDatePicker(true)} className={styles.dateInput}>
            {!props.date ? 'Select date...' : props.date.format(settings.dateFormat)}
            <Icon style={{ visibility: hover ? 'visible' : 'hidden' }} onClick={() => props.changeDate(null)} stopPropagation type='times-circle' />
          </div>
          {showDatePicker ?
            <div className={styles.datePicker}>
              <DayPickerSingleDateController
                focused={!!focusedDate}
                date={props.date}
                onDateChange={(date) => { toggleDatePicker(false), props.changeDate(date) }}
                onFocusChange={(data) => focusDate(!!data.focused)}
                numberOfMonths={2}
              />
            </div>
            : null}
        </div>
        <div className={styles.line}></div>
        <div>
          <Input styles={colorStyle} placeholder='24:00 or 12pm ...' value={props.time} onChange={(val) => props.changeTime(val)} />
        </div>
      </div>
    </OutsideClickHandler>
  )
}

Item = observer(Item);

export default Item;