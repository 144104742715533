import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './menu.module.sass';
import { IconTypes } from '@components/common/Icon/icons';
import Icon from '@components/common/Icon';
import cn from 'classnames';
import Modal from '../Modal';
import ModalContent from '@app/components/builder/common/SocialMediaPicker/ModalContent';
import Input from '@app/components/common/Input';
import OutsideClickHandler from 'react-outside-click-handler';


interface IMenuItem {
  icon: IconTypes;
  iconStyle?: React.CSSProperties;
  text: string;
  action: () => void;
  confirmAction?: boolean;
  depthItems?: IMenuItem[];
}

interface IContextMenuProps {
  items: IMenuItem[];
  onClose: () => void;
  isDepthMenu?: boolean;
}

let ContextMenu: React.FunctionComponent<IContextMenuProps> = (props) => {
  const [closeEnabled, toggleCloseEnabled] = React.useState(true);

  return (
    <OutsideClickHandler onOutsideClick={() => closeEnabled ? props.onClose() : null}>
      <div className={cn(styles.menu, { [styles.depth]: props.isDepthMenu })}>
        {props.items.map(item => {
          return <ContextMenuItem onClose={props.onClose} key={item.text} item={item} toggleCloseEnabled={toggleCloseEnabled} />
        })}
      </div>
    </OutsideClickHandler>

  )
}

interface IContextMenuItemProps {
  item: IMenuItem;
  onClose: () => void;
  toggleCloseEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

let ContextMenuItem: React.FunctionComponent<IContextMenuItemProps> = (props) => {
  const [hover, toggleHover] = React.useState(false);
  const [confirm, toggleConfirm] = React.useState(false);

  function click(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();

    submit()
  }

  function submit() {

    if (props.item.depthItems) {
      return;
    }

    props.item.action();
    props.onClose();
    if (props.toggleCloseEnabled) {
      props.toggleCloseEnabled(true)
    }
    // toggleConfirm(false)
  }

  function open(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();

    if (props.item.confirmAction) {
      props.toggleCloseEnabled(false)
      toggleConfirm(true)
      return
    }

    click(e);
  }

  let contextMenu = props.item.depthItems ? <ContextMenu onClose={props.onClose} isDepthMenu items={props.item.depthItems!} /> : null

  return (
    <div>
      <div style={{ position: 'relative' }} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
        <div onClick={open} className={styles.item}>
          <Icon style={props.item.iconStyle} inheritStyle type={props.item.icon} />
          <p>{props.item.text}</p>
        </div>
        {(hover && props.item.depthItems) &&
          contextMenu
        }
      </div>
      <Modal maxWidth={450} isOpen={confirm} onRequestClose={() => toggleConfirm(false)}>
        <div className={styles.modal}>
          <p className={styles.text} style={{ marginTop: '15px', marginBottom: '20px' }}>Are you sure?</p>
          <button onClick={(e) => {
            e.stopPropagation();
            submit()
          }} className={styles.button}>
            {false ? 'Loading...' : 'Confirm'}
            <Icon rotate={180} type='arrow' />
          </button>
        </div>
      </Modal>
    </div>
  )
}

ContextMenuItem = observer(ContextMenuItem);
export default observer(ContextMenu);