import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { ImageUpload as ImageUploadModel } from '@models/builder/customization/elements';
import FileUpload from '../common/FileUpload';
import * as styles from './upload.module.sass';

interface IImageUpload {
  element: ImageUploadModel
}

let ImageUpload: React.FunctionComponent<IImageUpload> = (props) => {
  return <FileUpload help_text={props.element.help_text} label={props.element.label} file={props.element.file}  />
}

ImageUpload = observer(ImageUpload);

export default ImageUpload;