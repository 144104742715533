import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Icon from '@app/components/common/Icon';
import * as styles from './nav.module.sass';
import PageModel from '@app/models/builder/page';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { rootContext } from '@app/index';
import { trackMenuBuilderOpen } from '@app/util/analytics';

interface INavProps {
  pages: PageModel[];
}

let Nav: React.FunctionComponent<INavProps> = (props) => {
  const [navOpen, toggleNav] = React.useState(false);

  let pages = props.pages.map(item => <Page key={item.id} page={item} />)

  React.useEffect(() => {
    trackMenuBuilderOpen(navOpen)
  }, [navOpen])

  let hideIcon = !!window.localStorage.getItem('hide_nav_icon') || false;

  return (
    <div >
      <div onClick={() => {toggleNav(true), localStorage.setItem('hide_nav_icon', 'true')}} className={styles.navContainer}>
        <Icon type='bars' inheritStyle />
        {!hideIcon &&
          <div className={styles.icon}></div>
        }
      </div>
      {navOpen &&
        <OutsideClickHandler onOutsideClick={() => toggleNav(false)}>
          <div className={styles.menu}>
            <div className={styles.header}>
              <p>Pages</p>
              <Icon onClick={() => toggleNav(false)} inheritStyle type='close' />
            </div>
            <div className={styles.pages}>
              {pages}
            </div>
          </div>
        </OutsideClickHandler>
      }
    </div>
  )
}

interface IPage {
  page: PageModel;
}

let Page: React.FunctionComponent<IPage> = (props) => {
  const [hover, toggleHover] = React.useState(false);
  const user = React.useContext(rootContext).user;

  function onClick() {
    if (props.page.hidden) {
      props.page.toggleHide(!props.page.hidden)
      
    }

    props.page.switchToPage();
  }

  function eyeClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    props.page.toggleHide(!props.page.hidden)
  }

  let showPremiumIcon = false;

  if (props.page.premium) {
    showPremiumIcon = true;
  }

  if (user.premium) {
    showPremiumIcon = false;
  }

  return (
    <div 
      onClick={onClick}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
      className={cn(styles.page, { [styles.pageActive]: props.page.isEditing, [styles.pageHidden]: props.page.hidden })}
    >
      <div className={styles.nameContainer}>
        {showPremiumIcon &&
          <Icon tooltip='This is a premium page' className={styles.pageCrown} type='crown' inheritStyle />
        }
        <p style={{ marginLeft: (!props.page.premium && !user.premium) ? '24px' : '0px' }} className={styles.pageName}>{props.page.name}</p>
      </div>
      <div className={styles.pageViewIcons}>
        <div onClick={eyeClick}>
          <Icon type={props.page.hidden ? 'eye-slashed' : 'eye'} inheritStyle />
        </div>
      </div>
    </div>
  )
}

Page = observer(Page);
export default observer(Nav);