import { observable, computed, toJS, action, when } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, getDefaultModelSchema, Context, custom, SKIP } from 'serializr';
import * as shortid from 'shortid';
import addInstance from '@app/components/builder/common/addInstance';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';
import emitter from '@util/emitter';


// releated interfacess

// model interfaces

export interface CategoryItem {
  label: string;
  values: string[]
}

interface Value {
  name: string;
  percent: number | undefined;
  key: string;
}

export interface IAudienceGroupConfig extends IBaseConfiguration {
  suggestions: CategoryItem[]
}

export interface IAudienceGroupInstance extends IBaseInstanceElement  {
  name: string;
  values: Value[]
}

export interface IAudienceGroup extends IElement<IAudienceGroupConfig, IAudienceGroupInstance> { }

// model classes

export class AudienceGroupInstance implements IAudienceGroupInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable
  name = ''

  @serializable(custom((item: any) => toJS(item), (item: any) => item))
  @observable
  readonly values = observable<Value>([])

  @observable
  suggestOpen = false;

  @observable
  activeFilter = false;

  parent: AudienceGroup;

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return this.name.length > 0 && this.values.length > 0;
  }

  @computed get suggestionValues() {
    if (!this.parent || !this.parent.configuration || !this.parent.configuration.suggestions) {
      return [];
    }

    let suggestions = this.parent.configuration.suggestions;

    if (this.activeFilter) {
      let filter = suggestions.filter((item) => item.label.toLowerCase().includes(this.name.toLowerCase()))
      return filter
    }
  
    return suggestions;
  }

  @computed get emptyValuesCount() {
    return this.values.filter((item) => item.percent === undefined).length
  }

  @computed get totalPercent() {
    return this.values.map((item) => {
      return item.percent ? item.percent : 0;
    }).reduce((prevValue, currentValue) => {
      return prevValue + currentValue
    }, 0)
  }

  @computed get remainPercentPlaceholder() {
    return Math.max(100 - this.totalPercent, 0) / this.emptyValuesCount
  }

  @action.bound
  onChange(value: string) {
    this.name = value;
    this.activeFilter = true;
    if (this.values.length === 0) {
      this.addValue()
    }
  }

  @action.bound
  onSelect(value: string) {

    this.name = value;
    this.toggleSuggest(false)
    let suggestion = this.parent.configuration.suggestions.find(item => item.label === value);
    if (!suggestion) {
      return;
    }

    this.values.replace([]);
    for (let itemValue of suggestion.values) {
      this.addValue(itemValue);
    }
  }

  @action.bound
  toggleSuggest(toggle: boolean) {
    this.suggestOpen = toggle;
    if (!toggle) {
      this.activeFilter = false;
    }
  }

  @action.bound
  addValue(name: string = '') {
    let value: Value = {
      name: name,
      percent: undefined,
      key: shortid.generate()
    }

    this.values.push(value);
  }

  @action.bound
  changeValue(key: string, text: string) {
    let value = this.values.find((item) => item.key === key);
    if (value) {
      value.name = text
    }
  }

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  @action.bound
  deleteValue(key: string) {
    let value = this.values.find((item) => item.key === key);
    if (value) {
      this.values.remove(value);
    }
  }

  @action.bound
  changeValuePercent(key: string, percent: number) {
    let value = this.values.find((item) => item.key === key);

    if (value) {
      value.percent = percent
    }
  }

  constructor(parent: AudienceGroup) {
    this.parent = parent;


    when(
      () => !!this.parent,
      () => !this.parent.configuration.suggestions ? this.parent.configuration.suggestions = [] : null
    )
  }

  static schema() {
    return getDefaultModelSchema(AudienceGroupInstance).factory = (context: Context) => {
      return new AudienceGroupInstance(context.args.parent);
    };
  }
}

export default class AudienceGroup extends BaseElement<IAudienceGroup, IAudienceGroupConfig, AudienceGroupInstance> {

  @observable
  @serializable(elementInstanceSerializer(AudienceGroupInstance))
  readonly instances = observable<AudienceGroupInstance>([]);

  @computed get overLimit() {
    if (this.root.user.premium) {
      return false;
    }

    if (this.instances.length >= this.limitation.maxAudienceGroups) {
      return true;
    } else {
      return false;
    }
  }

  @action.bound
  addInstance() {
    if (this.overLimit) {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'instance'
      })
    
      return;
    }

    this.instances.push(new AudienceGroupInstance(this))
  }

  @action.bound
  deleteInstance(instance: AudienceGroupInstance) {
    this.instances.remove(instance);
  }

  static getInstanceClass() {
    return AudienceGroupInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new AudienceGroupInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(AudienceGroup).factory = (context: Context) => {
      return new AudienceGroup(context.args.root, context.args.proposal);
    };
  }
}

AudienceGroup.schema();
AudienceGroupInstance.schema();