import { observable, action } from "mobx";
import { serializable, object, getDefaultModelSchema, Context } from "serializr";
import shortid from "shortid";
import File, { IFile } from "@app/util/elements/FileUpload";
import Settings from "../settings";


export interface IVirtualSection {
  id: string;
  name: string;

  background: IFile
  backgroundFitContent: boolean;
  backgroundOverlay: boolean;
  backgroundOverlayColor: string;

  headline: string;
  text: string;
  buttonText: string;
  useDifferentLink: boolean;
  link: string;
  

  isCodeSection: boolean;
  html: string;
  css: string;

  isVideoSection: boolean;
  videoURL: string | undefined;
  videoRemoveRecommended: boolean;
}

export default class VirtualSection implements IVirtualSection {

  @observable
  @serializable
  readonly id = shortid.generate();

  @observable
  @serializable
  readonly name: string;

  @observable
  @serializable(object(File))
  background = new File();

  @observable
  @serializable
  backgroundFitContent = false;

  @observable
  @serializable
  videoRemoveRecommended = false;

  @observable
  @serializable
  backgroundOverlay = false;

  @observable
  @serializable
  backgroundOverlayColor = 'rgba(255, 255, 255, 0.6)';

  @observable
  @serializable
  headline = '';

  @observable
  @serializable
  text = '';

  @observable
  @serializable
  buttonText = '';

  @observable
  @serializable
  useDifferentLink = false;

  @observable
  @serializable
  link = '';

  @observable
  @serializable
  isCodeSection = false;

  @observable
  @serializable
  html = '';

  @observable
  @serializable
  css = '';

  @observable
  @serializable
  isVideoSection = false;

  @observable
  @serializable
  videoURL: string | undefined;

  settings: Settings;
  constructor(settings: Settings, name?: string) {
    this.settings = settings;

    if (name) {
      this.name = name;
    }
  }

  @action.bound
  toggleCodeSection() {
    this.isCodeSection = !this.isCodeSection;
  }

  @action.bound
  setHeadline(headline: string) {
    this.headline = headline;
  }

  @action.bound
  setText(text: string) {
    this.text = text;
  }

  @action.bound
  setButtonText(text: string) {
    this.buttonText = text;
  }

  @action.bound
  toggleBackgroundFitContent() {
    this.backgroundFitContent = !this.backgroundFitContent;
  }

  @action.bound
  toggleBackgroundOverlay() {
    this.backgroundOverlay = !this.backgroundOverlay;
  }

  @action.bound
  toggleCustomLink() {
    this.useDifferentLink = !this.useDifferentLink
  }

  @action.bound
  toggleVideoSection() {
    this.isVideoSection = !this.isVideoSection;
  }

  @action.bound
  setVideoUrl(url: string) {
    this.videoURL = url;
  }

  @action.bound
  setLink(link: string) {
    this.link = link;
  }

  @action.bound
  setCss(css: string) {
    this.css = css;
  }

  @action.bound
  setHTML(html: string) {
    this.html = html;
  }

  @action.bound
  changeOverlayColor(color: string) {
    this.backgroundOverlayColor = color;
  }

  @action.bound
  toggleVideoRecommended() {
    this.videoRemoveRecommended = !this.videoRemoveRecommended ;
  }

  @action.bound
  delete() {
    this.settings.sectionOrder.remove(this.id);
    this.settings.virtualSections.remove(this);
  }

  static schema() {
    return getDefaultModelSchema(VirtualSection).factory = (context: Context) => {
      return new VirtualSection(context.parentContext.target);
    };
  }
}

VirtualSection.schema();