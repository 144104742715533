
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import * as Model from '@models/builder/elements/Opportunity';
import CheckboxInput from '@components/builder/common/CheckboxInput';
import { DropdownCreatable } from '@components/common/Dropdown';
import * as styles from './opportunity.module.sass';
import { findAndReplaceCurrency } from '@app/util/currency';
import { SettingsContext } from '@components/builder/Form/context';


export interface IInstanceProps {
  instance: Model.OpportunityInstance,
  configuration: Model.IOpportunityConfig
}

let Instance: React.FunctionComponent<IInstanceProps> = (props) => {
  const settings = React.useContext(SettingsContext);

  let options = props.configuration.opportunities;

  let checkboxes = options.map((item) => {
    return (
      <div className={styles.checkboxItem} key={item.name}>
        <CheckboxInput
          checked={!!props.instance.opportunities.find(searchItem => searchItem.name === item.name)}
          value={item.name}
          key={item.name}
          name={props.instance.id}
          onChange={props.instance.selectOption}
          icon={options.find(configItem => configItem.name === item.name)!.svg_icon}
        />
      </div>
    )
  })

  let dropdowns = props.instance.opportunities.filter((item) => {
    let configItem = options.find(configItem => configItem.name === item.name)!;
    return configItem.has_dropdown
  }).map(item => {
    let configItem = options.find(configItem => configItem.name === item.name)!;

    let items = configItem.options.map(item => findAndReplaceCurrency(settings.currency, item))

    return (
      <div className={styles.dropdownItem} key={item.id}>
        <DropdownCreatable
          options={items}
          value={item.value}
          onChange={item.selectValue}
          placeholder='Select...'
          label={configItem.dropdown_label}
        />
      </div>
    )
  })

  return (
    <div>
      <div className={styles.checkbox}>
        {checkboxes}
      </div>
      {dropdowns.length > 0 ?
        <div className={styles.dropdown}>
          {dropdowns}
        </div>
        : null}
    </div>
  );
}

Instance = observer(Instance);
export default Instance