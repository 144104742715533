interface ColorState {
  color: string;
  percent: number;
  text: string;
}

export default function calculateColors(colorStates: ColorState[], currentPercent: number) {
  let state = colorStates[0];
  for (const c of colorStates) {
    if (currentPercent >= c.percent)
      state = c;
  }

  return state;
}