
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/metrics';
import BlockLabel from '@components/builder/common/BlockLabel';
import SuggestInput from '@components/builder/common/SuggestInput';
import Input from '@components/builder/common/Input';
import * as styles from './metric.module.sass';
import Icon from '@components/common/Icon';

export interface IInstanceProps {
  instance: Model.MetricsInstance;
  configuration: Model.IMetricsConfig;
  index: number;
}

interface State {
  hover: boolean;
}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  constructor(props: IInstanceProps) {
    super(props);
    
    this.state = {
      hover: false
    }
  }

  public render() {
    let instance = this.props.instance;
    let showBlocks = this.props.index === 0 ? true : false;

    return (
      <div
        onMouseEnter={() => this.setState({ hover: true})}
        onMouseLeave={() => this.setState({ hover: false })}
        className={styles.metricInstance}>
        <div className={styles.container}>
          <div>
            {showBlocks ?
              <div className={styles.metricBlock}>
                <BlockLabel text='Key metric name' />
              </div>
              : null
            }
            <SuggestInput
              open={instance.suggestOpen}
              toggle={instance.toggleSuggest}
              items={instance.suggestionValues}
              onChange={instance.onChange}
              onSelect={instance.onSelect}
              value={instance.name}
              placeholder='example: “Number of speakers"'
            />
          </div>
          <div className={styles.metricValue}>
            {showBlocks ? 
              <div className={styles.metricBlock}>
                <BlockLabel text='Key metric value' />
              </div>
              : null
            }
            <Input placeholder='example: “10”' onChange={instance.changeValue} value={instance.value} />
          </div>
        </div>
        <div className={styles.deleteIcon}>
          <Icon onClick={instance.delete} type='trashcan' />
        </div>
      </div>
    );
  }
}
