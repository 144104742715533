
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@app/models/builder/elements/socialMedia';
import SocialMediaLink from '@components/builder/common/SocialMediaLink';
import * as styles from './media.module.sass';

export interface IInstanceProps {
  instance: Model.SocialMediaInstance;
  configuration: Model.ISocialMediaConfig;
}

interface State {}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {
    return (
      <div className={styles.instance}>
        <SocialMediaLink
          remove={this.props.instance.delete}
          handle={this.props.instance.handle}
          networks={this.props.configuration.social_networks}
          showLabel={false}
          moreInfo={true}
          toggleMoreInfo={this.props.instance.handle.toggleExpand}
          expand={this.props.instance.handle.expand}
        />
      </div>
    );
  }
}
