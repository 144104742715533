import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Layout from '../Layout';
import * as styles from '../auth.module.sass';
import Icon from '@app/components/common/Icon';
import cn from 'classnames';
import { rootContext } from '@app/index';
import EventBrite from '@assets/logos/eventbrite-vector-logo.svg';
import { Link } from 'react-router-dom';
import API_URL from '@util/apiRoot';

import JordanSign from '@assets/app/JordanSign.png';
import Jordan from '@assets/app/jordan.png';

interface ISignUpProps {}

let SignUp: React.FunctionComponent<ISignUpProps> = (props) => {
  const rootStore = React.useContext(rootContext);
  const authStore = rootStore.auth;

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    authStore.register();
  }

  return (
    <div>
      <Layout>
        
        <div style={{ width: '90%'}}>
          <div className={styles.contentWelcome}>
            <h1>Handcrafted with pixels full of love ❤️</h1>
            <div>
              <p>Just like your favorite local bakery, we worked countless hours, with a lot of love and passion with a simple goal in mind: building the missing platform you will fall in love with when it comes to sponsorship management. </p>

              <p>“Cooked” with your success in mind, Sponseasy is your go-to platform to better pitch, sell and close sponsorship.</p>

              <p>So “Bon Appetit”</p>

              <p>
                Jordan, Co-Founder of Sponseasy
              </p>
              <img className={styles.sign} width={113} height={48} src={JordanSign}></img>
            </div>
          </div>
          <img width={354} height={217} className={styles.jordan} src={Jordan} />
        </div>

        <div className={styles.content}>
          <h2 className={styles.headline}>Let’s create your account</h2>
          <form onSubmit={onSubmit}>
            <div className={styles.inputContainer}>
              <label>Email</label>
              <input onChange={(e) => authStore.setEmail(e.target.value)} value={authStore.values.email} required type='email' placeholder='email@gmail.com'></input>
            </div>
            <div className={styles.inputContainer}>
              <label>Password</label>
              <input onChange={(e) => authStore.setPassword(e.target.value)} value={authStore.values.password} required type='password' placeholder='password'></input>
            </div>
            <div className={styles.inputContainer}>
              <label>Organization</label>
              <input onChange={(e) => authStore.setOrganization(e.target.value)} value={authStore.values.organization} required type='text' placeholder='Organization name...'></input>
            </div>
            <div className={styles.buttons}>
              <button disabled={authStore.inProgress} type='submit' className={styles.button}>
                {authStore.inProgress ? 'Loading...' : 'Register'}
                <Icon rotate={180} type='arrow' />
              </button>
              <p className={styles.or}>OR</p>
              <a href={`${API_URL}/sessions/eventbrite`} className={cn(styles.button, styles.eventbrite)}>
                <p>Register with</p>
                <EventBrite />
              </a>
            </div>
            <p className={styles.terms}>By clicking on the button register you agree to the <a target='_blank' href='https://www.iubenda.com/terms-and-conditions/82140892'>terms of service</a> and you are aware that we will use your personal data in accordance with our <a target='_blank' href='https://www.iubenda.com/privacy-policy/82140892'>privacy policy</a></p>
            <Link className={styles.linkText} to='/auth/signin'>Already have an account? Sign In.</Link>
          </form>
        </div>

      </Layout>
    </div>
  )
}

SignUp = observer(SignUp);
export default SignUp;