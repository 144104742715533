import "@babel/polyfill";
import 'react-hot-loader';

import 'normalize.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tippy/dist/tippy.css';
import 'rc-slider/assets/index.css';
import 'react-dates/initialize';
import "react-dates/lib/css/_datepicker.css";
import '@styles/index.sass';
import emitter from '@util/emitter';
import { enableLogging } from 'mobx-logger';

declare global {

  var process: {
    env: {
      NODE_ENV: string
    }
  }

  interface Window {
    'ROOT_STORE': RootStore
    LOADING_ANIMATION: any;
    analytics: any;
    Stripe: any;
  }
}

import * as React from 'react';
import { render } from 'react-dom';
import App from '@components/App';
import RootStore from "@stores/RootStore";
import { Provider } from "mobx-react";
import '@util/lang'
import createBrowserHistory from 'history/createBrowserHistory';
import { syncHistoryWithStore } from 'mobx-react-router';
import { Router } from "react-router-dom";


const browserHistory = createBrowserHistory();
const rootStore = window.ROOT_STORE = new RootStore();
const history = syncHistoryWithStore(browserHistory, rootStore.router);

export const rootContext = React.createContext(rootStore);

render(
  <Provider store={rootStore}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

emitter.on('*', (event: any) => {
  console.log(event);
})

enableLogging({
  action: true
});