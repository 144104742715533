import * as React from 'react';
import * as styl from './tier.module.sass';
import { If, Then, Else } from 'react-if';
import * as Packages from '@models/builder/elements/packages';
import * as TierModel from '@models/builder/elements/packages/tier';
import { observer } from 'mobx-react-lite';
import { Draggable } from 'react-beautiful-dnd';
import Icon from '@components/common/Icon';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from '../Button';
import CurrencyFormat from '@app/components/builder/common/CurrencyFormat';
import { SettingsContext } from '@app/components/builder/Form/context';

export interface TierProps {
  order: number;
  item: TierModel.default;
  package: Packages.PackagesInstance;
}

export interface State {
  isMouseInside: boolean;
  deleteOpen: boolean;
}


let Tier: React.FunctionComponent<TierProps> = (props) => {
  const settings = React.useContext(SettingsContext);

  const [isMouseInside, toggleMouse] = React.useState(false);
  const [deleteOpen, toggleDelete] = React.useState(false);

  let orderLetter = (props.order + 9).toString(36);

  let name = props.item.name;
  let price = props.item.pricing!.price ? <CurrencyFormat currency={settings.currency} value={props.item.pricing!.price} /> : `No price`;

  if (name.length > 25) {
    name = name.substr(0, 25) + '...'
  }

  return (
    <Draggable draggableId={props.item.id} index={props.order - 1}>
      {(provider) => (
        <div ref={provider.innerRef} {...provider.draggableProps} onMouseEnter={() => toggleMouse(true)} onMouseLeave={() => toggleMouse(false)} className={`package-cell ${styl.tier} ${styl.border}`}>
          {isMouseInside ?
            <div {...provider.dragHandleProps} className={`${styl.order} ${styl.dragger}`}>
              <Icon type='drag' rotate={0} />
            </div>
            : <p {...provider.dragHandleProps}
              className={styl.order}>{orderLetter}.</p>
          }
          <p className={styl.tierHeadline}>{name}</p>
          <div className={styl.menu}>
            <If condition={isMouseInside}>
              <Then>
                <div className={styl.menuContent}>
                  <div style={{ width: '12px' }} onClick={() => props.item.toggleSelect(true)} >
                    <Icon color='primary2' tooltip='Edit tier' type='edit' rotate={45} />
                  </div>
                  <div className={styl.line}></div>
                  <div onClick={() => props.package.duplicateTier(props.item)} style={{ width: '14px' }}>
                    <Icon color='primary2' tooltip='Duplicate' type='duplicate' />
                  </div>
                  <div className={styl.line}></div>
                  <div style={{ width: '12px' }}>
                    <Icon color='primary2' tooltip='Delete tier' onClick={() => toggleDelete(true)} type='trash' />
                    {deleteOpen ?
                      <OutsideClickHandler onOutsideClick={() => toggleDelete(false)}>
                        <div className={styl.deleteTier}>
                          <p className={styl.deleteText}>Delete Tier?</p>
                          <Button onClick={() => props.package.deleteTier(props.item)} text='Delete' type='confirm' />
                        </div>
                      </OutsideClickHandler> : null}
                  </div>
                </div>
              </Then>
              <Else>
                <div>
                  <p className={styl.price}>{price}</p>
                </div>
              </Else>
            </If>
          </div>
        </div>
      )}
    </Draggable>
  );
}

Tier = observer(Tier);
export default Tier;