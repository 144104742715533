import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Label from '../Label';
import FileUpload from '@components/builder/common/FileUpload';
import * as styles from './uploaded.module.sass';
import File, { IFile } from '@app/util/elements/FileUpload';
import ElementContent from '../ElementContent';

interface IImageUpload {
  label: string;
  help_text: string;
  file: File;
}

let ImageUpload: React.FunctionComponent<IImageUpload> = (props) => {
  return (
    <div>
      <Label label={props.label} helpText={props.help_text} />
      <ElementContent>
        <div style={{ height: props.file.fileURL ? '200px' : '70px' }} className={styles.fileUpload}>
          <FileUpload theme='dark' file={props.file} />
        </div>
      </ElementContent>
    </div>
  )
}

ImageUpload = observer(ImageUpload);

export default ImageUpload;