
import * as React from 'react';
import { observer } from 'mobx-react';
import Proposal, { IProposal } from '@models/builder/proposal';
import BuilderUI from '@stores/ui/builder';
import { Spring, animated } from 'react-spring/renderprops';
import * as styles from './template.module.sass';
import Navbar from './Navbar';
import classnames from 'classnames';
import IframePreview from '@util/iframePreview';

export interface ITemplateProps {
  proposal: Proposal;
  builderUI: BuilderUI;
}

interface State {}

@observer
export default class Template extends React.Component<ITemplateProps, State> {

  iframePreview: IframePreview;
  animation: any;

  constructor(props: ITemplateProps) {
    super(props);
    this.iframePreview = new IframePreview(this.props.proposal, this.props.builderUI!, () => {
      this.animation.destroy();
      document.getElementById('loading-animation-template')!.style.display = 'none';
    });
  }

  componentDidMount() {
    this.animation = (window as any).lottie.loadAnimation({
      container: document.getElementById('loading-animation-template'),
      renderer: 'svg',
      loop: true,
      animationData: (window as any).ANIMATION_LOADING_DATA
    });
  }

  public render() {

    let mobile = this.props.builderUI.mobileView;
    let wrapperClassName = classnames(styles.wrapper, {
      [styles.templateMobile]: mobile
    })

    let config = {
      tension: 10,
      friction: 10,
      duration: 50
    }

    let iframeOptions: {
      sandbox?: string;
    } = {}


    let defaultStyle: React.CSSProperties = {
      border: 'none',
      position: 'relative',
      pointerEvents: this.props.builderUI.animateColumnResize ? 'none' : 'all',
      transform: `translateZ(0px) scale(${this.props.builderUI.templateScale})`,
      transformOrigin: '0 0',
      width: `${100 / this.props.builderUI.templateScale}%`,
      height: `${100 / this.props.builderUI.templateScale}%`
    }

    if (mobile) {
      Object.assign(defaultStyle, {
        transform: `translateZ(0px)`,
        width: `100%`,
        height: `100%`
      })
    }

    if (this.props.builderUI.animationInProgress) {
      iframeOptions.sandbox = ''
    }

    let width = this.props.builderUI.lastTemplateWidth / (this.props.builderUI.lastTemplateWidth / this.props.builderUI.templateDefaultWidth);

    if (this.props.builderUI.lastTemplateWidth >= this.props.builderUI.templateDefaultWidth) {
      width = this.props.builderUI.lastTemplateWidth;
    }

    return (
        <Spring
          from={{ animatedWidth: this.props.builderUI.templateWidth }}
          to={{ animatedWidth: this.props.builderUI.templateWidth }}
          config={config}
          native
        >
          {animateProps => {
            let props = animateProps as any;

            let animateStyle = defaultStyle;

            if (this.props.builderUI.animationInProgress) {
              let height = 100 / this.props.builderUI.templateScale

              Object.assign(animateStyle, {
                transformOrigin: '0 0',
                transform: props.animatedWidth.interpolate((aw: any) => {
                  return `scale(${aw / width}) translateZ(0px)`
                }),
                width: `${width}px`,
                height: `${height}%`
              }, mobile ? {
                transform: `translateZ(0px)`,
                width: `100%`,
                height: `100%`
              }: null)
            }

            return (
              <div className={styles.container}>
                <div className='animation-container' style={{ width: '100%', height: '100%' }} id='loading-animation-template'></div>
                <Navbar
                  toggleCustomization={this.props.builderUI.toggleCustomization}
                  togglePreview={this.props.builderUI.togglePreview}
                  previewOpen={this.props.builderUI.previewOpen}
                  customization={this.props.builderUI.customizationOpen}
                  toggleMobile={this.props.builderUI.toggleMobile}
                  mobileActive={mobile}
                  generatePdf={this.props.proposal.openPdf}
                  proposalId={this.props.proposal.id}
                  pdfExportActive={true}
                />
                <animated.div style={{ width: props.animatedWidth }} className={styles.template}>
                  <div className={wrapperClassName}>
                    <animated.iframe ref={this.iframePreview.iframeCreated} {...iframeOptions} style={animateStyle} frameBorder='0' />
                  </div>
                </animated.div>
              </div>
            )
          }
          }
        </Spring>
    );
  }
}
