import * as React from 'react'
import { observer } from 'mobx-react';
import Label from '../Label';
import TextareaAutosize from 'react-textarea-autosize';
import * as styles from './area.module.sass';
import ElementContent from '../ElementContent';

interface ITextAreaProps {
  label?: string;
  helpText?: string;
  onChange: (val: string) => void;
  value: string;
}

const TextArea: React.FunctionComponent<ITextAreaProps> = (props) => {
  return (
    <div>
      {props.label && <Label label={props.label} helpText={props.helpText} />}
      <ElementContent>
        <div>
          <TextareaAutosize
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            placeholder='Paste here...'
            className={styles.area}
          />
        </div>
      </ElementContent>
    </div>
  );
}

export default observer(TextArea);