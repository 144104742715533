import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Proposal from '@models/builder/proposal';
import Icon from '@components/common/Icon';
import * as styles from './publish.module.sass';
import ClickOutside from 'react-click-outside';
import * as moment from 'moment';
import { rootContext } from '@app/index';
import { TEMPLATE_URL } from '@app/util/apiRoot';
import Modal from '@app/components/common/Modal';
import { Link } from 'react-router-dom';
import {Tooltip} from "react-tippy";
import classNames from "classnames";


interface IPublishProps {
  proposal: Proposal;
}

let Publish: React.FunctionComponent<IPublishProps> = (props) => {
  const rootStore = React.useContext(rootContext);
  const builderUI = rootStore.ui.builder;
  const [confirmModalOpen, toggleModal] = React.useState(false);

  let pages = props.proposal.filledPremiumSections.map(item => <span key={item.id}>{item.name}</span>)

  React.useEffect(() => {
    props.proposal.getProposalVersions();
  }, [builderUI.publishOpen])

  let version = props.proposal.version_active;

  async function publishClick() {
    if (!rootStore.user.premium && pages.length > 0 && !confirmModalOpen) {
      toggleModal(true)
      return;
    }

    toggleModal(false);
    await props.proposal.publishProposal()
    builderUI.togglePublish(false);
  }

  async function pdfClick() {
    if (!rootStore.user.limitation.pdfExport) {
      rootStore.router.push('/account/pricing');
      return;
    }

    props.proposal.openPdf()
  }

  let websiteClick: string | undefined;

  if (version) {
    websiteClick = `https://${rootStore.user.organization || 'organization'}.${TEMPLATE_URL}/${props.proposal.slug}`
  }

  function togglePublish(toggle: boolean) {
    if (!toggle && !builderUI.publishOpen) {
      return;
    }

    builderUI.togglePublish(toggle)
  }

  let className = classNames(styles.buttonPdf, { [styles.buttonPdfDisabled]: !rootStore.user.limitation.pdfExport })

  return (
    <React.Fragment>
      <ClickOutside onClickOutside={() => togglePublish(false)}>
        <div className={styles.container}>
          <div onClick={() => togglePublish(!builderUI.publishOpen)} className={styles.publish}>
            <p>Publish</p>
            <div className={styles.publishIcon}>
              <Icon inheritStyle type='ange-down' />
            </div>
          </div>
          {builderUI.publishOpen &&
            <div className={styles.publishBox}>
              <div className={styles.header}>
                <p>How do you want to publish your proposal?</p>
              </div>
              <div className={styles.buttonControl}>
                <div onClick={publishClick} className={styles.button}>
                  <p>Publish proposal as website</p>
                  <Icon type='arrow' rotate={180} inheritStyle />
                </div>
                  <Tooltip
                      html={<p><b>PDF is available only in Professional plan. <br /> <br /> Click to go to the pricing.</b></p>}
                      theme='honeybee'
                      animateFill={false}
                      disabled={rootStore.user.limitation.pdfExport}
                  >
                      <button onClick={pdfClick} className={className}>
                          <p>Create PDF from proposal</p>
                          <Icon type='pdf' rotate={180} inheritStyle />
                      </button>
                  </Tooltip>
                {version &&
                  <div className={styles.activePublish}>
                    <a target='_blank' href={websiteClick} className={styles.website}>
                      <Icon type='link' />
                      <p>Open website</p>
                    </a>
                    <p className={styles.lastPublish}>Last publish: <b>{moment(version.updated_at).fromNow()}</b></p>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </ClickOutside>
      <Modal clean onRequestClose={() => { toggleModal(false) }} maxWidth={565} isOpen={confirmModalOpen}>
        <div className={styles.modal}>
          <h2 className={styles.modalHeadline}>You are about to publish premium sections 🔒</h2>
          <p> Premium sections ({pages.reduce((prev, current) => {
            let split = ', '
            if (!prev) {
              split = ''
            }

            return [prev, split, current]
          }, null)}) are used in the proposal that you are about to publish.</p>
          <p><b>These sections will not be published.</b></p>
          <p>If you want still want them to be in, please go <br /> <Link to='/account/pricing'>premium.</Link> </p>
          <div onClick={publishClick} className={styles.buttonModal}>
            Publish without premium sections
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Publish = observer(Publish)
export default Publish;