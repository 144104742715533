import * as shortid from 'shortid';
import { observable, action, computed, when } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, getDefaultModelSchema, Context, createModelSchema, custom, SKIP } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

// releated interfacess

// model interfaces

export interface IRadioSelectorConfig extends IBaseConfiguration {
  radioOptions: string[];
  required: boolean;
  defaultValue: undefined | string;
  noValueOption: string;
}

export interface IRadioSelectorInstance extends IBaseInstanceElement  {
  selectedOption: string | undefined;
}

export interface IRadioSelector extends IElement<IRadioSelectorConfig, IRadioSelectorInstance> {}

// model classes

export class RadioSelectorInstance implements IRadioSelectorInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable
  selectedOption: string | undefined = undefined;

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.selectedOption
  }

  @action.bound
  selectOption(value: string) {
    if (this.parent.configuration.noValueOption === value) {
      this.selectedOption = undefined;
      return;
    }

    this.selectedOption = value;
  }

  @computed get selected() {
    let selected = false;

    if (this.parent.configuration.required) {
      selected = true;
      return;
    }

    if (this.selectedOption && this.selectedOption !== this.parent.configuration.noValueOption) {
      selected = true;
    }

    return selected;
  }

  parent: RadioSelector;

  constructor(parent: RadioSelector) {
    this.parent = parent;

    // instance is done before configuration json is parsed. So we need to wait and then do the init with configuration.
    // Normlany init function whould be content of the constructor. Stuff like default value etc...
    when(
      () => !!this.parent.configuration,
      () => this.init()
    )
  }

  init() {
    if (this.parent.configuration.required && !this.selectedOption) {
      this.selectedOption = this.parent.configuration.radioOptions[0];
    }
  }

  static schema() {
    return getDefaultModelSchema(RadioSelectorInstance).factory = (context: Context) => {
      return new RadioSelectorInstance(context.args.parent);
    };
  }
}

export default class RadioSelector extends BaseElement<IRadioSelector, IRadioSelectorConfig, RadioSelectorInstance> {

  @observable
  @serializable(elementInstanceSerializer(RadioSelectorInstance))
  readonly instances = observable<RadioSelectorInstance>([]);

  static getInstanceClass() {
    return RadioSelectorInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new RadioSelectorInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(RadioSelector).factory = (context: Context) => {
      return new RadioSelector(context.args.root, context.args.proposal);
    };
  }
}

RadioSelectorInstance.schema();
RadioSelector.schema();