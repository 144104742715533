
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import RootStore from '@stores/RootStore';
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom'; 
import PrivateRoute from '@components/router/privateRoute.tsx';
import Radium from 'radium';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader/root'
import View404 from '@components/404';
import * as styles from './app.module.sass';
import routes from '@app/routes';
import { rootContext } from '@app/index';
import Modal from '@app/components/common/Modal';
import { withRouterApp } from '@app/util/router';
import { Link } from 'react-router-dom';

export interface IAppProps extends RouteComponentProps < any >, React.Props < any > {
  store?: RootStore
}


const App: React.FunctionComponent<IAppProps> = (props) => {
  const rootStore = React.useContext(rootContext)
  const builderUI = rootStore.ui.builder;
  let auth = rootStore.auth;

  React.useEffect(() => {
    rootStore.toggleAppLoaded();
  }, [])

  let views = routes.map(item => {
    let component = item.wrapProps ? (props: any) => <item.component {...props} /> : item.component;
    let route = item.private ?
      <PrivateRoute
        key={item.path + item.name}
        path={item.path}
        component={component}
        isAuthorized={auth.isAuthorized}
        exact={item.exact}
        userLoaded={rootStore.user.userLoaded}
        appData={{
          requestOrganization: rootStore.user.requestOrganization,
          requestOrganizationPath: '/user/set/organization'
        }}
      />
      :
      <Route key={item.path + item.name} exact={item.exact} path={item.path} component={component} />

    return route;
  })

  let headline = `Oops, you have reached your plan limit dear friend. Upgrade to create more ${builderUI.premiumModalType}s`;
  let text = `Upgrading your plan will allow your create more ${builderUI.premiumModalType}s but also benefit from a lot of more premium features. `;

  if (builderUI.premiumModalText) {
    headline = builderUI.premiumModalText.headline;
    text = builderUI.premiumModalText.text
  }

  if (rootStore.user.requestOnboarding) {
      if (!rootStore.router.location.pathname.includes('/auth/onboarding'))
        return <Redirect to='/auth/onboarding' />
  }

  return (
    <div style={{ userSelect: rootStore.ui.userSelectEnabled ? 'initial' : 'none' }}>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      <Switch>
        {views}
        <Redirect exact from='/' to={auth.isAuthorized ? '/proposals' : '/auth/welcome'} />
        <Route render={() => <View404 />} />
      </Switch>
      <Modal clean maxWidth={800} onRequestClose={() => { builderUI.togglePremiumModal(false) }} isOpen={builderUI.premiumModalOpen}>
        <div className={styles.formModal}>
          <p className={styles.emoji}>🔒</p>
          <h2>{headline}</h2>
          <p>{text} please switch to <Link to='/account/pricing'>Lite or Professional plan</Link></p>
          <p style={{ marginTop: '15px' }}>If you take sponsorship seriously, that's probably the best investment you will make this year.</p>
          <div onClick={() => rootStore.router.push('/account/pricing')} className={styles.buttonModal}>See pricing plans 🚀</div>
          <p>or <a style={{ cursor: 'pointer' }} onClick={() => builderUI.togglePremiumModal(false)} >I'm good, take me back</a></p>
        </div>
      </Modal>
    </div>
  )
}

export default hot(withRouterApp(observer(App)));
