
import * as React from 'react';
import { observer } from 'mobx-react';
import RichTextEditor from 'react-rte';
import * as styles from './editor.module.sass';
import MarkdownField from '@models/builder/common/MarkdownField';
import BlockLabel from '@components/builder/common/BlockLabel';

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ],
};

export interface IRichTextareaProps {
  field: MarkdownField;
  label?: string;
}

interface State {
  editorValue: any;
}

@observer
export default class RichTextarea extends React.Component<IRichTextareaProps, State> {
  constructor(props: IRichTextareaProps) {
    super(props);
    
    this.state = {
      editorValue: RichTextEditor.createValueFromString(this.props.field.markdown, 'markdown')
    }

    this.onUpdate = this.onUpdate.bind(this)
  }

  onUpdate(value: any) {
    this.setState({ editorValue: value }, () => {
      let markdown = this.state.editorValue.toString('markdown')
      this.props.field.updateMarkdown(markdown);
    })
  }

  public render() {
    return (
      <div className={styles.wrapper}>
        {this.props.label ?
          <div className={styles.labelBlock}>
            <BlockLabel text={this.props.label} />
          </div>
          : null}
        <RichTextEditor
          className={styles.root}
          toolbarClassName={styles.toolbar}
          onChange={this.onUpdate}
          value={this.state.editorValue}
          toolbarConfig={toolbarConfig}
          editorClassName={styles.editor}
          placeholder='Here you can describe to the detail what you want'
        />
        {this.props.field.charactersRemaining ? 
          <p
            style={{ color: this.props.field.remainingColor!.color }}
            className={styles.characters}>{this.props.field.remainingColor!.text.replace('{}', this.props.field.charactersRemaining.toString())}</p>
        : null}
      </div>
    );
  }
}
