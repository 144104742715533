import Proposal, { IProposal } from "@models/builder/proposal";
import { action, reaction, observable, when, IObservableArray, IReactionDisposer } from "mobx";
import BuilderUI from '@stores/ui/builder';
import { serialize } from 'serializr';
import { ElementTypes } from "@app/models/builder/elements";
import { ISection } from "@models/builder/section";
import { TEMPLATE_URL } from "@util/apiRoot";

interface IPreviewData {
  scale: number;
}


/**
 * This class is responsible for sending and reciving data in iframe preview.
 * We have a from_process (Propsal) that includes data about the entire proposal (including the from)
 * We use this data to display to build an actual form that user can fill. But then we need to send data to template
 * You can think of this class a as big JSON stringify where you add @param Proposal and @param builderUI to make it happen.
 * 
 * Since we are using MOBX the class also takes care of creating reactions for specific events and elements. Forexample if you start
 * to edit element after inital load there will be a reaction ready that will send only an form element JSON to template. We can then use
 * that to update the template and not diff an entire proposal state
 * 
 * Current functions:
 * - Tell app when iframe is ready to recive data
 * - Send entire proposal data to template
 * - Send diffing updates directly to template (only one element changed). Creates MOBX reactions for that
 * - Sent proposal customization elements using MOBX reactions
 */
export default class IframePreview {
  @observable
  created = false;

  proposal: Proposal;
  builderUI: BuilderUI;
  iframeRef: HTMLIFrameElement | null;

  history: IProposal[];

  reactions: {
    [key: string]: IReactionDisposer[]
  } = {};

  initialUpdate = false;

  constructor(proposal: Proposal, builderUI: BuilderUI, iframeLoaded: () => void) {
    this.proposal = proposal;
    this.builderUI = builderUI;

    window.addEventListener('message', (message) => {
      this.receiveMessage(message, iframeLoaded);
    }, false);


    // register all of the things we want to react to in realtime if anything changes
    reaction(
      () => this.proposal.elements,
      (elements) => this.createReactions(elements)
    )

    reaction(
      () => this.proposal.sections,
      (sections) => this.createReactions(sections, 'section_updated')
    )

    reaction(
      () => this.proposal.customizationElements,
      (elements) => this.createReactions(elements, 'element_update_customization')
    )

    this.createReactions(this.proposal.customizationElements, 'element_update_customization')
    this.createReactions(this.proposal.elements)

    this.createReactions(this.proposal.sections, 'section_visiblity_updated', ['visible_for_user', 'id'])
    this.createReactions(this.proposal.sections, 'section_settings_updated', ['id', 'settings']);

    this.createReactions(this.proposal.pages, 'page_visiblity_updated', ['hidden', 'id'])

    reaction(
      () => serialize(this.proposal.settings),
      (settings) => this.updateTemplate(settings, 'update_settings')
    )
  }

  /** Reaction creator. Creates a buch of mobx reactions for each item in array and if something changes uses the @param updateType 
   * to send a JSON to template. Basically a super fast diff algorithm. By creating a buch of reactions at startup time we can avoid
   * sending an entire JSON proposal to template on each change in for example Text element inside the form
   */
  @action.bound
  createReactions(items: IObservableArray<ElementTypes> | IObservableArray<any>, updateType: string = 'element_update', keys: string[] = []) {
    let reactions: IReactionDisposer[] = [];

    if (!this.reactions[updateType]) {
      this.reactions[updateType] = [];
    }

    for (let item of items) {
      const react = reaction(
        () => item.json,
        (json: any) => {
          if (keys.length === 0) {
            this.updateTemplate(json, updateType)
          } else {
            let data: any = {}
            for (let key of keys) {
              data[key] = json[key];
            }
            this.updateTemplate(data, updateType)
          }
        }
      )
      reactions.push(react);
    }

    for (let react of this.reactions[updateType]) {
      react();
      this.reactions[updateType].shift()
    }

    this.reactions[updateType] = reactions;
  }


  /** Send JSON data to template Iframe. Template will decide what to do with the data by
   * @param type
   */
  @action.bound
  updateTemplate(json: IProposal | IPreviewData | any, type?: string) {
    let dataType = type ? type : 'props';

    if (this.iframeRef && this.iframeRef.contentWindow) {
      let data = JSON.stringify({ data: json, type: dataType })
      this.iframeRef.contentWindow.postMessage(data, '*');
    }
  }

  /** Recive msg from iframe. For example iframeReady event signals that we can send data to iFrame */
  receiveMessage(e: MessageEvent, iframeLoaded: () => void) {
    if (e.data === 'iframeReady') {
      this.updateTemplate(this.proposal.json);
      iframeLoaded();
    }
  }

  /** Called when iframe ref is created. Duo to animations and other stuff can be called more times. */
  @action.bound
  iframeCreated(ref: HTMLIFrameElement | null) {

    // Guard to not get called more then once. It will still probably work but it will be much slower since it will reacreate iframe on animation start
    if (this.created) {
      return;
    }

    if (!this.proposal.template) {
      return;
    }

    let url = `/templates/${this.proposal.template.name}`;

    if (process.env.NODE_ENV === 'development') {
      url = 'http://localhost:3001?preview=true';
    } else {
      let org = this.proposal.root.user.organization || 'organization';
      url = `https://${org}.${TEMPLATE_URL}/${this.proposal.slug}?preview=true`
    }

    if (ref) {
      ref.src = url;
      this.iframeRef = ref;
      this.created = true;
    }
  }
}