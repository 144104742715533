import * as React from 'react'
import { observer } from 'mobx-react';
import Icon from '@components/common/Icon';
import Button from '@components/common/Button';
import * as styles from './navbar.module.sass';
import cn from 'classnames';
import {rootContext} from "@app/index";

interface INavbarProps {
  previewOpen: boolean;
  customization: boolean;
  mobileActive: boolean;
  toggleCustomization: () => void;
  togglePreview: () => void;
  toggleMobile: (mobile: boolean) => void;
  generatePdf: (proposalId: string) => void;
  proposalId: string;
  pdfExportActive: boolean;
}

const Navbar: React.FunctionComponent<INavbarProps> = (props) => {

  let customization = (
    <div className={styles.buttons}>
      <Button
        onClick={() => props.toggleCustomization()}
        type='control'
        state={props.customization ? 'active' : null}
        text={props.customization ? 'Close customization' : 'Customize template'}
        icon={props.customization ? 'close' : 'sliders-h'}
      />
    </div>
  )

  let icons = (
    <div className={styles.icons}>
      <Icon
        type='desktop'
        tooltip='Desktop Preview'
        onClick={() => props.toggleMobile(false)}
        className={cn({ [styles.iconActive]: !props.mobileActive })}
        inheritStyle
      />
      <Icon
        type='mobile'
        tooltip='Mobile Preview'
        onClick={() => props.toggleMobile(true)}
        inheritStyle
        className={cn({ [styles.iconActive]: props.mobileActive })}
      />
    </div>
  )

  return (
    <div className={styles.navbar}>
      {props.customization &&
        customization
      } 
      {!props.customization &&
        icons
      }
      <div className={styles.buttons}>
        <Button
          onClick={() => props.togglePreview()}
          state={props.previewOpen ? 'active' : null}
          type='light'
          text={props.previewOpen ? 'Close preview' : 'Preview'}
          icon={props.previewOpen ? 'close' : 'eye-regular'}
        />
        {props.customization &&
          icons
        }
        {!props.customization &&
          customization
        }
      </div>
    </div> 
  )
}

export default observer(Navbar);