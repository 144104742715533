import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './loading.module.sass';

interface ILoadingProps {
  style?: React.CSSProperties;
  loadingID: string;
  fullScreen?: boolean;
}

const Loading: React.FunctionComponent<ILoadingProps> = (props) => {
  let animation: any;

  React.useEffect(() => {
    (window as any).lottie.loadAnimation({
      container: document.getElementById(props.loadingID),
      renderer: 'svg',
      loop: true,
      animationData: (window as any).ANIMATION_LOADING_DATA
    });
  }, [])

  return (
    <div className={props.fullScreen ? styles.fullscreen : undefined} style={props.style} id={props.loadingID}></div>
  );
}

export default observer(Loading);