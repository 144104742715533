import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { rootContext } from '@app/index';
import * as styles from './general.module.sass';
import Button from '../Button';
import Input from '@app/components/common/Input';
import cn from 'classnames';

interface IGeneralProps {}

const General: React.FunctionComponent<IGeneralProps> = (props) => {
  const rootStore = React.useContext(rootContext);
  const [orgOpen, toggleOrg] = React.useState(false);
  const [orgName, changeOrgName] = React.useState('');

  let organizationValue = orgName || rootStore.user.organization;

  return (
    <div className={styles.content}>
      <h2 className={styles.headline}>General settings</h2>
      <div className={styles.control}>
        <p className={styles.label}>Your email</p>
        <div className={styles.buttonWrap}>
          <Button confirmText={<p style={{ marginTop: '20px', textAlign: 'center' }}>Are you sure that you want to change your email? <br /> <br /> We will send you a email reset link to your old email account.</p>} icon='email' text='Change email' onClick={() => rootStore.auth.requestNewEmail()} />
          <p>Your current email is <b>{rootStore.user.email}</b></p>
        </div>
      </div>
      <div className={styles.control}>
        <p className={styles.label}>Your password</p>
        <Button text='Change password' icon='key' onClick={() => rootStore.auth.resetPassEmail()} />
      </div>
      <div className={styles.control}>
        <p className={styles.label}>Organization name</p>
        <div className={styles.buttonWrap}>
          <Button text='Change organization name' icon='users' onClick={() => toggleOrg(!orgOpen)} />
          <p>Your current Organization name is <b>{rootStore.user.organization}.</b> <br /> And your URL is <b>https://{rootStore.user.organization}.sponseasy.com</b></p>
        </div>
        {orgOpen &&
          <div className={styles.downInputs}>
            <div className={styles.buttonWrap}>
              <Input
                label='Organization name'
                className={cn(styles.input, styles.orgInput)}
                name='current-email'
                onChange={(val) =>  changeOrgName(val)}
                value={organizationValue!}
              />
              <p style={{ fontSize: '14px', marginLeft: '6px', marginTop: '19px'}}>.sponseasy.com</p>
            </div>
            <p className={styles.orgText}>
              <b>Note</b>: <span>If you change the name, URL of your current proposal is going to be changed too.</span><br /> <br />
            </p>
            <Button disabled={!!!orgName} save inlineBlock text='Change Name' onClick={() => rootStore.user.updateOrganization(orgName)} />
          </div>
        }
      </div>
      <div className={styles.control}>
        <p className={styles.label}>Account</p>
        <Button confirmText='Are you sure you want to delete your account?'  text='Delete account' icon='trashcan' onClick={() => rootStore.user.deleteUser()} />
      </div>
      <div className={styles.control}>
        <p className={styles.label}>API Key</p>
        <p>{rootStore.user.api_key}</p>
      </div>
      <div className={styles.control}>
        <p className={styles.label}>Authentication</p>
        <Button text='Sign-out' icon='sign-out' onClick={() => rootStore.auth.logOut()} />
      </div>
    </div>
  )
}

export default observer(General);
