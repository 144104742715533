import * as React from 'react';
import * as styles from './connection.module.sass';
import PackageItemToTierConnection from '@models/builder/elements/packages/connection';
import { observer } from 'mobx-react';
import Icon from '@components/common/Icon';

export interface ConnectionProps {
  item: PackageItemToTierConnection;
}

@observer
export default class Connection extends React.Component<ConnectionProps, any> {

  handleClick() {
    this.props.item.changeState();
  }

  render() {
    let className = `package-cell ${styles.border}`;

    return (
      <div
        onMouseEnter={() => this.props.item.packageItem.toggleSelect(true)}
        onMouseLeave={() => this.props.item.packageItem.toggleSelect(false)}
        onClick={this.handleClick.bind(this)}
        className={className}
      >
        {this.props.item.active ? <Icon color='primary3' type='checkmark' /> : <Icon color='primary4' type='cross' />}
      </div>
    );
  }
}
