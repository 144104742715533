
import * as React from 'react';
import { observer } from 'mobx-react';
import Proposal from '@models/builder/proposal';
import PageModel from '@models/builder/page';
import * as styles from './pages.module.sass';
import Icon from '@components/common/Icon';
import Page from './Page';
import Scrollbar, { ScrollbarRendererProps } from 'react-scrollbars-custom';
import cn from 'classnames';
import Mesure, { BoundingRect } from 'react-measure';
import { reaction } from 'mobx';
import ease from '@util/animation/ease';
import Nav from './Nav';

export interface IPagesProps {
  proposal: Proposal;
}

interface State {
  hover: boolean;
  dimensions: {
    width: number;
    height: number;
  },
  pagesRefs: {
    uuid: string;
    ref: HTMLDivElement | null
  }[];
}

@observer
export default class Pages extends React.Component<IPagesProps, State> {
  scrollBar: Scrollbar | null;

  constructor(props: IPagesProps) {
    super(props);

    this.state = {
      hover: false,
      dimensions: {
        width: -1,
        height: -1
      },
      pagesRefs: [],
    }

    this.contentRenderer = this.contentRenderer.bind(this);
    this.renderTrack = this.renderTrack.bind(this);
    this.trackDimensions = this.trackDimensions.bind(this);
    this.addPageRef = this.addPageRef.bind(this);

    //** if editing page change then update scroll position */
    reaction(
      () => this.props.proposal.editingPage,
      (page) => this.scrollTo(page)
    )
  }

  contentRenderer(props: ScrollbarRendererProps & {children?: React.ReactNode}) {
    const { elementRef, ...restProps } = props;
    let element = <div {...restProps} ref={elementRef as any} className={styles.content}></div>
    return element;
  }

  renderTrack(props: ScrollbarRendererProps & { children?: React.ReactNode }) {
    const { elementRef, ...restProps } = props;
    let className = cn(styles.track, {[styles.activeHover]: this.state.hover})
    let element = <div {...restProps} ref={elementRef as any} className={className}></div>
    return element;
  }

  trackDimensions(bounds: BoundingRect | undefined) {
    if (!bounds) {
      return;
    }

    this.setState({
      dimensions: bounds
    })
  }

  addPageRef(ref: HTMLDivElement | null, uuid: string) {
    if (ref && !this.state.pagesRefs.find((item) => item.uuid === uuid)) {
      this.setState(prevState => ({
        pagesRefs: [...prevState.pagesRefs, { uuid: uuid, ref: ref }],
      }));
    }
  }

  scrollTo(page: PageModel | undefined) {
    if (!page) {
      return;
    }

    let pageOffset = this.state.pagesRefs.find((item) => item.uuid === page.id)
    if (pageOffset && this.scrollBar && pageOffset.ref) {
      ease(this.scrollBar.scrollLeft, pageOffset.ref.offsetLeft - 250, 400, (value) => {
        this.scrollBar!.scrollLeft = value;
      })
    }
  }

  public render() {
    let pages = this.props.proposal.visiblePages.map((item, index) => {
      return <Page key={item.id} pageRef={this.addPageRef} order={index + 1} page={item} />
    })

    let containerClass = cn(styles.container);

    let splitPages = insertIntoArray(pages, <div className={styles.line}></div>)
  
    return (
      <div onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })} className={containerClass}>
        <Scrollbar ref={(e) => this.scrollBar = e} trackXRenderer={this.renderTrack} contentRenderer={this.contentRenderer} className={styles.scrollBar}>
          {splitPages}
        </Scrollbar>
        <Nav pages={this.props.proposal.activePages} />
      </div>
    );
  }
}

const insertIntoArray = (arr: any[], value: JSX.Element) => {

  return arr.reduce((result, element, index, array) => {

    result.push(element);

    if (index < array.length - 1) {
      result.push(value);
    }

    return result;
  }, []);
};
