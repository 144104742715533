import { observable, computed, action, reaction,  } from "mobx";
import { serializable, deserialize, Context, custom, serialize, getDefaultModelSchema, update } from "serializr";
import Section, { ISection } from './section';
import Proposal from './proposal';
import RootStore from "@app/stores/RootStore";
import { Omit } from '@util/types';
import { trackBuilderPageHiddenChange, trackBuilderPageFilled } from "@app/util/analytics";

export interface IPage {
  id: string;
  name: string;
  premium: boolean;
  hidden: boolean;
  description?: string;
  href?: string;
  status: 'active' | 'not_active';
  sections: ISection[];
  svg_icon: string | undefined;
}

interface IPageApp extends Omit<IPage, 'sections'> {
  sections: Section[]
}

function serializeSections(sections: Section[]) {
  let data = [];
  for (let section of sections) {
    data.push(section.json)
  }
  return data;
}

function deserializeSections(jsonValue: any, context: Context, oldSections: Section[]) {

  let data = [];


  for (let jsonSection of jsonValue) {
    let oldSection = oldSections.find((item) => item.id === jsonSection.id);

    if (oldSection) {
      update(oldSection, jsonSection, () => {}, context.args)
      data.push(oldSection)
    } else {
      let section = deserialize(Section, jsonSection, undefined, context.args);
      data.push(section);
    }
  }

  return data;
}

export default class Page implements IPageApp {

  @serializable
  @observable
  id: string;

  @serializable
  @observable
  name: string;

  @observable
  @serializable
  premium: boolean;

  @observable
  @serializable
  hidden: boolean = false;

  @observable
  @serializable
  description: string;

  @observable
  @serializable
  href: string;

  @observable
  @serializable
  status: 'active' | 'not_active';

  @observable
  @serializable
  svg_icon: string | undefined;

  @observable
  @serializable(custom(serializeSections, deserializeSections))
  readonly sections = observable<Section>([]);

  @computed get activeSections() {
    return this.sections.filter((item) => item.status === 'active')
  }

  @computed get json() {
    return serialize(this)
  }

  @computed get complete() {
    return this.sections.every((item) => item.complete);
  }

  @computed get isEditing() {
    return this.proposal.editingPage === this;
  }

  @computed get canOpenPage() {
    return !this.premium;
  }

  @observable
  proposal: Proposal;
  root: RootStore;

  constructor(root: RootStore, proposal: Proposal) {
    this.root = root;
    this.proposal = proposal;

    reaction(
      () => this.complete,
      () => {
        trackBuilderPageFilled(
          this.proposal.pages.filter(item => item.complete).length,
          this.proposal.pages.filter(item => item.complete),
          this.proposal.pages.filter(item => item.complete && item.premium),
          this.proposal.id)
      }
    )

    reaction(
      () => this.hidden,
      (hidden) => {
        this.root.api.togglePageHide(hidden, this.id)
        trackBuilderPageHiddenChange(
          this.proposal.pages.filter(item => !item.hidden).length,
          this.proposal.pages.filter(item => !item.hidden),
          this.proposal.pages.filter(item => item.hidden),
          this.proposal.id)
      }
    )
  }

  @action.bound
  toggleHide(hide: boolean) {
    if (this.isEditing) {
      this.proposal.shiftPage(true);
    }

    this.hidden = hide;
  }

  @action.bound
  switchToPage() {
    this.proposal.setEditingPage(this);
  }

  static schema() {
    return getDefaultModelSchema(Page).factory = (context: Context) => {
      return new Page(context.args.root, context.args.proposal);
    };
  }
}

Page.schema()