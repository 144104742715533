
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/detailedDescription';
import RichTextarea from '@components/builder/common/RichTextarea';
import * as styles from './description.module.sass';
import FileUpload from '@components/builder/common/FileUpload';
import classNames from 'classnames';
import Icon from '@components/common/Icon';
import Input from '@components/builder/common/Input';

export interface IInstanceProps {
  instance: Model.DetailedDescriptionInstance;
  configuration: Model.IDetailedDescriptionConfig;
}

interface State {}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {

    let uploaded = this.props.instance.file.uploaded
    let fileClass = classNames(styles.fileUpload, { [styles.fileActive]: uploaded })

    return (
      <div className={styles.wrapper}>
        <div className={styles.headline}>
          <Input placeholder='Your headline' value={this.props.instance.headline} onChange={this.props.instance.changeHeadline} label='Headline' />
          <div className={styles.deleteIcon}>
            <Icon onClick={() => this.props.instance.delete()} type='trashcan' />
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.textArea}>
            <RichTextarea label='Text of the section' field={this.props.instance.description} />
          </div>
          <div className={fileClass}>
            <FileUpload allowVideo label='Picture of the section' file={this.props.instance.file} />
          </div>
        </div>
      </div>
    );
  }
}
