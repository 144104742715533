import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { ColorPicker as ColorPickerModel, IColorPicker } from '@models/builder/customization/elements';
import * as styles from './color.module.sass';
import Label from '../Label';
import Icon from '@components/common/Icon';
import Color from 'color';
import { ChromePicker } from 'react-color';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import ElementContent from '../ElementContent';

interface IColorPickerProps {
  help_text?: string;
  label: string;
  premium?: boolean;
}

interface IMultiColor extends IColorPickerProps {
  isMultiple: true;
  colors: string[];
  color_presets: string[][];

  changeColor: (index: number, color: string) => void;
  activatePreset: (colors: string[]) => void;
}

interface ISingleColor extends IColorPickerProps {
  isMultiple: false;
  color: string;
  color_presets?: string[];

  changeColor: (color: string) => void;
  activatePreset?: (color: string) => void;
}

type ColorProps = ISingleColor | IMultiColor;

let ColorPicker: React.FunctionComponent<ColorProps> = (props) => {

  const [expand, toggleExpand] = React.useState(false);

  let propColor = props.isMultiple ? props.colors : [props.color];
  let propPresets = props.isMultiple ? props.color_presets : []
  
  function changeColor(color: string, index?: number) {
    if (props.isMultiple) {
      props.changeColor(index!, color);
    } else {
      props.changeColor(color);
    }
  }

  function activatePreset(preset: string[] | string) {
    if (props.isMultiple) {
      props.activatePreset(preset as string[]);
    }
  }

  let colors = propColor.map((color, index) => {
    return <ColorElement
      key={index}
      index={index}
      changeColor={changeColor}
      color={color} />
    }
  )

  let colorsUsed = propColor.map((color, index) => {
    return <div onClick={() => toggleExpand(!expand)} key={color} style={{ backgroundColor: color }} className={styles.color}></div>
  })

  let presets = propPresets.map(preset => {
    return (
      <div onClick={() => {
        activatePreset(preset);
        toggleExpand(false);
      }} className={styles.preset}
        key={preset.toString()}
      >
        <div className={styles.colors}>
          {preset.map(color => {
            return <div key={color} style={{ backgroundColor: color }} className={styles.color}></div>
          })}
        </div>
      </div>
    )
  })

  return (
    <div>
      <Label helpText={props.help_text} label={props.label} premiumLabel={props.premium} />
      <ElementContent premium={props.premium}>
        <div onClick={(e) => toggleExpand(!expand)} className={styles.picker}>
          <div className={styles.row}>
            <div className={styles.colors}>
              {colorsUsed}
            </div>
            <div onClick={(e) => toggleExpand(!expand)} className={cn(styles.icon, { [styles.iconActive]: expand })}>
              <Icon type='ange-down' color='primary5' />
            </div>
          </div>
          {presets.length > 0 &&
            <div onClick={(e) => e.stopPropagation()} className={cn(styles.presets, { [styles.presetActive]: expand })}>
              <p className={styles.label}>Pre-made combinations</p>
              {presets}
            </div>
          }
          <div onClick={(e) => e.stopPropagation()} className={cn(styles.presets, { [styles.presetActive]: expand })}>
            <p className={styles.label}>Your combination</p>
            <div onClick={(e) => e.stopPropagation()} className={styles.colors}>
              {colors}
            </div>
          </div>
        </div>
      </ElementContent>
    </div>
  )
}

interface IColor {
  color: string;
  index: number;
  changeColor: (color: string, index: number) => void;
}

let ColorElement: React.FunctionComponent<IColor> = (props) => {
  const [hovered, toggleHover] = React.useState(false);
  const [displayPicker, toggleDisplay] = React.useState(false);

  let color = Color(props.color);

  return (
    <div
      onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}
      style={{ backgroundColor: hovered ? Color(props.color).lighten(0.1).string() : props.color }}
      className={styles.color}
      onClick={() => toggleDisplay(true)}
    >
      {displayPicker ?
        <div className={styles.pickerColor}>
          <OutsideClickHandler onOutsideClick={() => toggleDisplay(false)}>
            <ChromePicker
              color={{r: color.red(), g: color.green(), b:color.blue(), a: color.alpha() }}
              onChange={(color) => {
                let rgba = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                props.changeColor(rgba, props.index)
              }} />
          </OutsideClickHandler>
        </div>
        : null
      }
      <div className={styles.colorSelectedText}>
        <Icon type='eye-dropper' inheritStyle />
        <p>{Color(props.color).hex()}</p>
      </div>
    </div>
  )
}

ColorElement = observer(ColorElement);
ColorPicker = observer(ColorPicker);
export default ColorPicker;