export type CurrencyKey = 'USD' | 'EUR' | 'GBP' | 'IDR' | 'JPY' | 'CAD' | 'AUD' | 'CHF' | 'HKD';

interface ICurrencyDefinition {
  prefix?: string;
  suffix?: string;
  label: string;
}

const currencies: { [key in CurrencyKey]: ICurrencyDefinition } = {
  'USD': {
    prefix: '$',
    label: '$ - US Dollar'
  },
  'EUR': {
    prefix: '€',
    label: '€ - Euro'
  },
  'GBP': {
    prefix: '£',
    label: '£ - British Pound'
  },
  'IDR': {
    prefix: 'Rp',
    label: 'Rp - Rupiah'
  },
  'JPY': {
    prefix: '¥',
    label: '¥ - Yen'
  },
  'CAD': {
    prefix: '$',
    label: '$ - Canadian Dollar'
  },
  'AUD': {
    prefix: '$',
    label: '$ - Australian Dollar'
  },
  'CHF': {
    prefix: '₣',
    label: '₣ - Swiss Franc'
  },
  'HKD': {
    prefix: '$',
    label: '$ - Hong Kong Dollar'
  }
}

export default currencies;