import axios, { AxiosInstance } from 'axios';
import API from '@api/index';

export default class ProposalAPI {
  axios: AxiosInstance;
  parent: API;

  constructor(parent: API ,axios: AxiosInstance) {
    this.axios = axios;
    this.parent = parent;
  }

  fetchAllProposalCategories() {
    return this.axios.get('/proposal/categories').then((res) => ['test1', 'test2', 'test3']);
  }

  createProposal(name: string, category: string) {
    return this.axios.post('/proposal/new', { name, category }).then((res) => res.data);
  }
}