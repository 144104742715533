import { action, observable } from 'mobx';
import moment from 'moment';
import { momentSerializer } from '@util/serializer';
import { serializable, serialize } from 'serializr';
import { Omit } from '@util/types';

export interface IDateTimePeriod {
  startDate: string | null;
  endDate: string | null;
  startTime: string | undefined;
  endTime: string | undefined;
}

export interface IDateTimePeriodApp extends Omit<IDateTimePeriod, 'startDate' | 'endDate'> {
  endDate: moment.Moment | null;
  startDate: moment.Moment | null;
}

export default class DateTimePeriod implements IDateTimePeriodApp {
  @observable
  @serializable(momentSerializer())
  startDate: moment.Moment | null = moment();

  @observable
  @serializable(momentSerializer())
  endDate: moment.Moment | null = moment();

  @observable
  @serializable
  startTime: string | undefined;

  @observable
  @serializable
  endTime: string | undefined;

  @action.bound
  changeStartDate(date: moment.Moment | null) {
    this.startDate = date;
  }

  @action.bound
  changeEndDate(date: moment.Moment | null) {
    this.endDate = date;
  }

  @action.bound
  changeStartTime(time: string) {
    this.startTime = time;
  }

  @action.bound
  changeEndTime(time: string) {
    this.endTime = time;
  }
}