import * as shortid from 'shortid';
import { observable, action, computed, when } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, getDefaultModelSchema, Context, custom, SKIP } from 'serializr';
import characterRemainColor from '@util/helpers/characterRemainColor';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

// releated interfacess

// model interfaces

export interface IHeadlineParagraphConfig extends IBaseConfiguration {
  maxLength: number;
}

interface IHeadlineParagraphInstance extends IBaseInstanceElement  {
  markdown: string;
  headline: string;
}

export interface IHeadlineParagraph extends IElement<IHeadlineParagraphConfig, IHeadlineParagraphInstance> {}

// model classes

export class HeadlineParagraphInstance implements IHeadlineParagraphInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable
  markdown = ''

  @observable
  @serializable
  headline = ''

  parent: HeadlineParagraph;

  @action 
  updateEditor(value: string) {
    this.markdown = value;
  }

  @action
  updateHeadline(value: string) {
    this.headline = value;
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP ))
  @computed get filled() {
    return this.charactersCount > 0 && this.headline.length > 0;
  }

  @computed get remainingColor() {
    
    let currentPercent = (1 - this.charactersRemaining / this.parent.configuration.maxLength);

    let data = [{
      color: '#a7b6c7',
      percent: 0,
      text: '{} characters remaining'
    },{
      color: '#7de40b',
      percent: 0.7,
      text: '{} characters remaining. Good job!'
    }, {
      color: '#e49d0b',
      percent: 0.9,
      text: 'Wow, only {} characters left. Let’s keep it short :)'
    }, {
      color: '#ff0000',
      percent: 1,
      text: 'Yep, we are done. {} characters left'
    }]
    
    return characterRemainColor(data, currentPercent);
  }

  @computed get charactersRemaining() {
    if (!this.parent) {
      return 0;
    }

    let remain = this.parent.configuration.maxLength - this.charactersCount
    if (remain <= 0) {
      remain = 0
    }
  
    return remain;
  }

  @computed get charactersCount() {
    let markdown = this.markdown
    let length = markdown.replace(/[#_*↵+_]/g, '').length;
    return length;
  }

  constructor(parent: HeadlineParagraph) {
    this.parent = parent;

  }

  static schema() {
    return getDefaultModelSchema(HeadlineParagraphInstance).factory = (context: Context) => {
      return new HeadlineParagraphInstance(context.args.parent);
    };
  }
}

export default class HeadlineParagraph extends BaseElement<IHeadlineParagraph, IHeadlineParagraphConfig, HeadlineParagraphInstance> {

  @observable
  @serializable(elementInstanceSerializer(HeadlineParagraphInstance))
  readonly instances = observable<HeadlineParagraphInstance>([]);

  static getInstanceClass() {
    return HeadlineParagraphInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new HeadlineParagraphInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(HeadlineParagraph).factory = (context: Context) => {
      return new HeadlineParagraph(context.args.root, context.args.proposal);
    };
  }
}

HeadlineParagraphInstance.schema();