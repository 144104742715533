
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Proposal from '@models/builder/proposal';
import Page from '@components/builder/Form/Page';
import * as styles from './form.module.sass';
import Pages from './Pages';
import Scrollbar, { ScrollbarRendererProps } from 'react-scrollbars-custom';
import Navigation from '@components/builder/Form/Navigation'
import { SettingsContext } from '@components/builder/Form/context';

export interface IBuilderProps {
  proposal: Proposal;
}

const Builder: React.FunctionComponent<IBuilderProps> = (props) => {
  const [scrollRef, addScrollRef] = React.useState(null as Scrollbar | null)

  React.useEffect(() => {
    if (!scrollRef) {
      return;
    }

    if (props.proposal.editingPage) {
      scrollRef.scrollToTop();
    }
  })

  function renderTrack(props: ScrollbarRendererProps & { children?: React.ReactNode }) {
    const { elementRef, ...restProps } = props;
    let element = <div {...restProps} ref={elementRef as any} className={styles.track}></div>
    return element;
  }

  return (
    <SettingsContext.Provider value={props.proposal.settings}>
      <div className={styles.form}>
        <Scrollbar
          trackYRenderer={renderTrack}
          ref={(instance) => addScrollRef(instance)}
          style={{ height: '100%' }}
          wrapperProps={{ style: { marginRight: 0 } }}
        >
          <div style={{ position: 'relative' }}>
            <Pages proposal={props.proposal} />

            <div className={styles.elements}>
              {props.proposal.editingPage ?
                <Page page={props.proposal.editingPage} />
                : null}
            </div>

            <Navigation
              shiftPage={props.proposal.shiftPage}
              nextPageEnabled={!props.proposal.isLastPage}
              prevPageEnabled={!props.proposal.isFirstPage}
            />
          </div>
        </Scrollbar>
      </div>
    </SettingsContext.Provider>
  )
}

export default observer(Builder);