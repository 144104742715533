import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './lead.module.sass';
import Icon from '@app/components/common/Icon';
import cn from 'classnames';
import { Lead as LeadModel } from '@app/models/builder/crm';
import CurrencyFormat from '@app/components/builder/common/CurrencyFormat';
import Proposal from '@app/models/builder/proposal';
import moment from 'moment';
import Tippy from '@tippy.js/react';

interface ILeadProps {
  lead: LeadModel;
  proposal: Proposal;
  hide?: boolean;
}

let Lead: React.FunctionComponent<ILeadProps> = (props) => {
  const [open, toggleOpen] = React.useState(false);

  if (!open) {
    return <LeadClosed toggleOpen={toggleOpen} open={open} lead={props.lead} proposal={props.proposal} hide={props.hide} />
  }

  return <LeadOpen toggleOpen={toggleOpen} open={open} lead={props.lead} proposal={props.proposal} />
}

interface ILead {
  toggleOpen: (open: boolean) => void;
  open: boolean;
  lead: LeadModel;
  proposal: Proposal;
  hide?: boolean;
}

let LeadClosed: React.FunctionComponent<ILead> = (props) => {
  let packages: string | undefined = props.lead.tiers.length !== 1 && props.lead.tiers.length !== 0 ? `${props.lead.tiers.length} packages` : `${props.lead.tiers.length} package`;
  let offers: string | undefined = props.lead.additional_offers.length !== 1 && props.lead.additional_offers.length !== 0 ? `${props.lead.additional_offers.length} additional offers` : `${props.lead.additional_offers.length} Additional offer`;

  packages = props.lead.tiers.length > 0 ? packages : undefined;
  offers = props.lead.additional_offers.length > 0 ? offers : undefined

  let msg = props.lead.customer_data.note;

  if (msg && msg.length > 72) {
    msg = msg.substr(0, 69) + '...';
  }



  return (
    <Tippy
      content={'Change plan to see this lead'}
      trigger='mouseenter'
      theme='honeybee'
      animateFill={false}
      placement='top'
      animation='shift-toward'
      inertia={true}
      isEnabled={!!props.hide}
    >
      <div>
        <div className={cn(styles.lead, { [styles.leadBlur]: props.hide })}>
          {props.lead.example &&
            <div className={styles.example}>Example</div>
          }
          <div className={styles.columnMedium}>
            {props.lead.customer_data.name &&
              <p className={styles.headline}>{props.lead.customer_data.name}</p>
            }

            {props.lead.customer_data.email &&
              <p className={styles.smaller}>{props.lead.customer_data.email}</p>
            }
          </div>
          <div className={styles.columnMedium}>
            {props.lead.customer_data.company &&
              <p className={styles.headline}>{props.lead.customer_data.company}</p>
            }

            {props.lead.customer_data.position &&
              <p className={styles.smaller}>{props.lead.customer_data.position}</p>
            }
          </div>
          <div className={styles.columnMedium}>
            <p className={styles.headline}>
              <CurrencyFormat currency={props.proposal.settings.currency} value={props.lead.totalPrice} />
            </p>
            <p className={styles.smaller}>
              {packages}
              {offers &&
                `, ${offers}`
              }</p>
          </div>
          <div className={styles.columnLong}>
            {msg &&
              <p className={styles.msg}>{msg}</p>
            }
          </div>
          <div className={styles.columnSmall}>
            <p className={styles.msg}>{moment(props.lead.created_at).format(props.proposal.settings.dateFormat)}</p>
          </div>
          <div style={{ maxWidth: '20%' }}>
            <div className={styles.moreContainer}>
              <div className={cn(styles.status, styles.statusWin)}>
                <p className={styles.statusDot}></p>
                <p>RECEIVED</p>
              </div>
              <div className={styles.flex}>
                <div onClick={() => props.toggleOpen(!props.open)} className={styles.more}>
                  <p>View More</p>
                  <Icon type='eye' inheritStyle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tippy>
  )
}

let LeadOpen: React.FunctionComponent<ILead> = (props) => {
  let customer = props.lead.customer_data;

  return (
    <div className={styles.openLead}>
      {props.lead.example &&
        <div className={styles.example}>Example</div>
      }
      <div className={styles.openLeadHeader}>
        <div>
          <p className={styles.leadName}>{props.lead.customer_data.name}</p>
        </div>
        <div style={{ width: '20%' }} className={styles.leadControl}>
          <div className={cn(styles.status, styles.statusWin)}>
            <p className={styles.statusDot}></p>
            <p>RECEIVED</p>
          </div>
          <div onClick={() => props.toggleOpen(!props.open)} className={cn(styles.more, styles.moreActive)}>
            <p>View more</p>
            <Icon type='eye' inheritStyle />
          </div>
        </div>
      </div>
      <div className={styles.leadInfo}>
        {customer.email &&
          <div className={styles.info}>
            <p className={styles.label}>email</p>
            <p className={styles.infoText}>{customer.email}</p>
          </div>
        }
        {customer.phoneNumber &&
          <div className={styles.info}>
            <p className={styles.label}>Phone</p>
            <p className={styles.infoText}>{customer.phoneNumber}</p>
          </div>
        }
        {customer.company &&
          <div className={styles.info}>
            <p className={styles.label}>Company</p>
            <p className={styles.infoText}>{customer.company}</p>
          </div>
        }
        {customer.position &&
          <div className={styles.info}>
            <p className={styles.label}>Position</p>
            <p className={styles.infoText}>{customer.position}</p>
          </div>
        }
        {customer.website &&
          <div className={styles.info}>
            <p className={styles.label}>Website</p>
            <p className={styles.infoText}>{customer.website}</p>
          </div>
        }
        <div className={styles.info}>
          <p className={styles.label}>Date</p>
          <p className={styles.infoText}>{moment(props.lead.created_at).format(props.proposal.settings.dateFormat)}</p>
        </div>
      </div>
      <div className={styles.openColumns}>
        {customer.note &&
          <div className={styles.msg}>
            <p className={styles.openLabelHeadline}>Message</p>
            <p className={styles.msgText}>{customer.note}</p>
          </div>
        }
        {(props.lead.additional_offers.length > 0 || props.lead.tiers.length > 0) &&
    
          <div className={styles.intrestedIn}>
            <p className={styles.openLabelHeadline}>Interested in</p>
            {props.lead.tiers.length > 0 &&
              <div className={styles.packages}>
                <p className={styles.label}>Packages</p>
                <div className={styles.packageList}>
                  {props.lead.tiers.map((item) => {
                    let price = item.pricing.price || 0;

                    let formatCurrency = <CurrencyFormat currency={props.proposal.settings.currency} value={price} />
                
                    return (
                      <div key={item.id} className={styles.package}>
                        <p className={styles.packageName}>{item.name}</p>
                        <p className={styles.packagePrice}>
                          {item.pricing.type === 'one-time' ? formatCurrency : <span>{formatCurrency} / {item.pricing.humanType}</span>}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            }
            {props.lead.additional_offers.length > 0 &&
              <div className={styles.offers}>
              <p className={styles.label}>ADDITIONAL OFFERS</p>
                <div>
                  {props.lead.additional_offers.map(item => {
                    let price = item.value || 0;

                    let formatCurrency = <CurrencyFormat currency={props.proposal.settings.currency} value={price} />

                    return (
                      <div key={item.id} className={styles.offer}>
                        <p className={styles.offerName}>{item.name}</p>
                        <p className={styles.offerPrice}>
                          {formatCurrency}
                        </p>
                      </div>
                    )
                  })}

                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}

LeadClosed = observer(LeadClosed);
LeadOpen = observer(LeadOpen);
export default observer(Lead);