import { observable, computed, action, flow, reaction, when } from 'mobx';
import Elements, { ElementTypes, ElementInterfaces } from './elements';
import { serializable, custom, deserialize, serialize, Context, getDefaultModelSchema, update, object } from 'serializr';
import RootStore from '@stores/RootStore';
import Proposal from './proposal';
import { Omit } from '@app/util/types';
import { debounce } from "debounce";

export interface ISection {
  name: string;
  id: string;
  internal_name: string;
  description: string;
  status: 'active' | 'not_active';
  elements: ElementInterfaces[];
  visible_for_user: boolean;
  settings: ISectionSettings;
}

export interface ISectionApp extends Omit<ISection, 'elements'> {
  elements: ElementTypes[];
  settings: SectionSettings;
}

function serializeElements(elements: ElementTypes[]) {
  let data = [];
  for (let component of elements) {
    data.push(component.json)
  }
  return data;
}

interface ISectionSettings {
  headline: string;
}

class SectionSettings implements ISectionSettings {
  @observable
  @serializable
  headline: string;

  @computed get json() {
    return serialize(this);
  }

  section: Section;
  updateSectionDebounce = debounce(this.updateSettingsSection, 500)
  constructor(section: Section) {
    this.section = section;

    reaction(
      () => this.json,
      (json) => this.updateSectionDebounce(this.section.id, json)
    )
  }

  @action.bound
  updateSettingsSection(id: string, settings: any) {
    this.section.root.api.updateSectionSettings(id, settings)
  }

  @action.bound
  setHeadline(headline: string) {
    this.headline = headline;
  }

  static schema() {
    return getDefaultModelSchema(SectionSettings).factory = (context: Context) => {
      return new SectionSettings(context.parentContext.target);
    };
  }

}

function deserializeElements(jsonValue: any, context: Context, oldElements: ElementTypes[]) {
  let data = [];
  for (let jsonElement of jsonValue) {
    let oldElement = oldElements.find((item) => item.id === jsonElement.id);
    if (oldElement) {
      update(oldElement, jsonElement, () => {}, context.args)
      data.push(oldElement)
    } else {
      let type: keyof typeof Elements = jsonElement.type;
      let componentClass: any = Elements[type];
      let component: any = deserialize(componentClass, jsonElement, undefined, context.args);
      data.push(component);
    }

  }
  return data;
}

export default class Section implements ISectionApp {
  
  @serializable
  @observable
  name: string;

  @serializable
  @observable
  internal_name: string;

  @serializable
  @observable
  description: string;

  @serializable
  @observable
  id: string;

  @observable
  @serializable
  status: 'active' | 'not_active';

  @observable
  @serializable
  visible_for_user: boolean;

  @observable
  @serializable(object(SectionSettings))
  settings: SectionSettings;

  @observable
  @serializable(custom(serializeElements, deserializeElements))
  readonly elements = observable<ElementTypes>([]);

  @computed get activeElements() {
    return this.elements.filter((item) => item.status === 'active')
  }

  @computed get json() {
    return serialize(this)
  }

  @computed get complete() {
    return this.elements.some((item) => item.complete)
  }

  root: RootStore;
  proposal: Proposal;
  constructor(root: RootStore, proposal: Proposal) {
    this.root = root;
    this.proposal = proposal;
  }

  @action.bound
  toggleVisibility = flow(function* (this: Section) {
    this.visible_for_user = !this.visible_for_user;

    try {
      yield this.root.api.toogleSectionVisibility(this.visible_for_user, this.id);
    } catch (error) {
      this.visible_for_user = !this.visible_for_user;
    }
  })

  static schema() {
    return getDefaultModelSchema(Section).factory = (context: Context) => {
      return new Section(context.args.root, context.args.proposal);
    };
  }
}

SectionSettings.schema();
Section.schema();