import RootStore from '@stores/RootStore';
import { observable, computed, action, reaction } from 'mobx';
import UiStore from '@stores/uiStore';
import emitter from '@util/emitter';
import { trackMobilePreviewClick } from '@app/util/analytics';


export type CustomizationTabs = 'general' | 'section';

export default class Builder {
  root: RootStore;
  ui: UiStore;

  @observable resizerDrag = {
    'form-template': false,
    'template-customization': false
  }

  @observable
  premiumModalOpen = false;

  @observable
  premiumModalType = 'instance'

  @observable
  premiumModalText: {
    headline: string;
    text: string;
  } | undefined;

  @observable
  openCustomizationTab: CustomizationTabs = 'general';

  @observable
  publishOpen = false;

  @observable
  previewOpen = false;

  @observable
  resizerWidth = 8;

  @observable
  customizationOpen = false;

  @observable
  animateCustomization = false;

  @observable
  animatePreview = false;

  @observable
  mobileView = false

  @observable
  animateColumnResize = false;

  @observable
  lastTemplateWidth: number;

  @observable
  // relative to viewport width, NOT each other
  private formWidthPercent = 60;

  @observable
  // relative to viewport width, NOT each other
  private templateWidthPercent = 40;

  @observable
  // relative to viewport width, NOT each other
  private customizationWidthPercent = 30;

  @observable
  private minElementsWidth = 400;

  @computed get templateDefaultWidth() {
    let width = this.ui.windowDimensions.width || 1440;
    return width > 1300 && !this.previewOpen ? 1300 : width;
  }

  @computed get minElementsWidthPercent() {
    return (this.minElementsWidth / this.root.ui.windowDimensions.width) * 100
  }

  @computed get formWidth() {
    return this.root.ui.windowDimensions.width * (this.formWidthPercent / 100) - this.resizerWidth / 2
  }

  @computed get templateWidth() {
    // if template is open we want preview to take 100% of vw. If not then normal calucation
    let templateWidthPercent = this.previewOpen ? 100 : this.templateWidthPercent;

    return this.root.ui.windowDimensions.width * (templateWidthPercent / 100) - this.resizerWidth / 2
  }

  @computed get customizationWidth() {
    return this.root.ui.windowDimensions.width * (this.customizationWidthPercent / 100) - this.resizerWidth / 2
  }

  @computed get transition() {
    return this.formWidth + this.resizerWidth
  }

  @computed get templateScale() {
    return Math.min(this.templateWidth / this.templateDefaultWidth, 1);
  }

  @computed get animationInProgress() {
    return this.animateCustomization || this.animateColumnResize || this.animatePreview
  }

  constructor(root: RootStore, ui: UiStore) {
    this.root = root;
    this.ui = ui;

    reaction(
      () => this.root.router.location && this.root.router.location.pathname,
      () => {
        this.togglePremiumModal(false)
      }
    )

    reaction(
      () => this.mobileView,
      (bool) => {
        if (bool) {
          trackMobilePreviewClick(this.root.proposalStore.editingProposal!.id)
        }
      }
    )

    emitter.on('PREMIUM_MODAL_REQUEST', (data) => {
      this.togglePremiumModal(true);
      this.premiumModalType = data.type
      if (data.text) {
        this.premiumModalText = data.text
      } else {
        this.premiumModalText = undefined
      }
    })
  }

  @action.bound
  resizeColumn(clientX: number, type: 'form-template' | 'template-customization') {
    let windowWidth = this.root.ui.windowDimensions.width;
    let left = (clientX / windowWidth) * 100;
    let right = 100 - left;

    if (left < this.minElementsWidthPercent) {
      left = this.minElementsWidthPercent;
      right = 100 - left;
    } else if (right < this.minElementsWidthPercent) {
      right = this.minElementsWidthPercent;
      left = 100 - right;
    }


    if (type === 'form-template') {
      this.formWidthPercent = left
      this.templateWidthPercent = right;
    } else {
      this.templateWidthPercent = left;
      this.customizationWidthPercent = right;
    }
  }

  @action.bound
  toggleDrag(drag: boolean, type: 'form-template' | 'template-customization') {
    this.lastTemplateWidth = this.templateWidth;
    this.animateColumnResize = drag
    this.resizerDrag[type] = drag;
    this.root.ui.toggleUserSelect(!drag);
  }

  @action.bound
  openCustomization() {
    this.togglePreview(false);

    this.lastTemplateWidth = this.templateWidth;
    this.templateWidthPercent = 100 - this.customizationWidthPercent;
    this.customizationOpen = true;
    this.animateCustomization = true;
  }

  @action.bound
  closeCustomization() {
    this.lastTemplateWidth = this.templateWidth;
    this.templateWidthPercent = 100 - this.formWidthPercent;
    this.customizationOpen = false;
    this.animateCustomization = true;
  }

  @action.bound
  toggleCustomization() {
    if (this.customizationOpen) {
      this.closeCustomization();
    } else {
      this.openCustomization();
    }
  }

  @action.bound
  togglePreview(open?: boolean) {
    this.closeCustomization();

    this.animatePreview = true;
    this.previewOpen = open !== undefined ? open : !this.previewOpen
  }

  @action.bound
  animationsDone() {
    this.animateCustomization = false;
    this.animatePreview = false;
  }

  @action.bound
  toggleMobile(open: boolean) {
    this.mobileView = open;
  }

  @action.bound
  togglePublish(toggle: boolean) {
    this.publishOpen = toggle;
  }

  @action.bound
  toggleCustomizationTab(tab: CustomizationTabs) {
    this.openCustomizationTab = tab;
  }

  @action.bound
  togglePremiumModal(toggle: boolean) {
    this.premiumModalOpen = toggle;
  }
}