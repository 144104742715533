import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './label.module.sass';
import { rootContext } from '@app/index';
import Icon from '@app/components/common/Icon';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface ILabelProps {
  label: string,
  helpText?: string;
  premiumLabel?: boolean;
  labelOpacity?: number;
}

let Label: React.FunctionComponent<ILabelProps> = (props) => {
  const user = React.useContext(rootContext).user;
  let isPremium = !!user.premium;
  let isPremiumLabel = !!props.premiumLabel || false;

  let showHelpText = !!props.helpText;
  let showPremium = !isPremium && isPremiumLabel;

  if (isPremiumLabel) {
    showHelpText = isPremium;
  }


  return (
    <div className={cn(styles.container,  { [styles.containerPremium]: showPremium })}>
      <p style={{ opacity: props.labelOpacity }} className={styles.label}>
        {props.label}
      </p>
      {showPremium &&
        <Link to='/account/pricing' className={styles.premium}>
          <Icon type='crown' inheritStyle />
          <p>Change plan to use this customization</p>
        </Link>
      }
      {showHelpText &&
        <p className={styles.helpText}>{props.helpText}</p>
      }
    </div>
  );
}

Label = observer(Label);

export default Label;