import { custom, deserialize, serialize, ClazzOrModelSchema, Context, alias, update } from 'serializr';
import Field, { SelectField } from '@util/elements/Field';
import SocialMediaPicker, { ISocialMediaHandle, SocialMediaHandle } from '@models/builder/common/SocialMediaPicker';
import moment from 'moment';
import { ElementTypes } from '@app/models/builder/elements';
import { IBaseInstanceElement } from '@app/models/builder/elements/baseElement';

export const elementInstanceSerializer = function <T extends ClazzOrModelSchema<any>>(elementClazz: T) {
  return alias('data', custom((instances: ElementTypes[]) => {

    let copy = instances.concat();
    let instancesJSON = serialize(copy);

    return {
      instances: instancesJSON
    }

  }, (data: any, context: Context, oldValue: IBaseInstanceElement[]) => {
    let instances = []

    if (data.hasOwnProperty('instances')) {
      for (let instanceData of data.instances) {
        let instance = deserialize(elementClazz, instanceData, undefined, Object.assign(context.args, {
          parent: context.target
        }))

        instances.push(instance);
      }
    }

    return instances.length === 0 ? oldValue : instances;
  }))
}

export const customFieldSerializer = function <T>() {
  return custom((item: Field<T>) => {
    return item.value
  }, (json: any) => {
    return new Field(json)
  })
}

export const customDropdownSerializer = function() {
  return custom((item: SelectField) => {
    return item.value;
  }, (value: string) => {
    return new SelectField(value)
  })
}

export const socialPickerSerializer = () => {
  return custom((item: SocialMediaPicker) => {
    if (!item) {
      return [];
    }

    return item.options.map((item) => serialize(item))
  }, (json: ISocialMediaHandle[]) => {
      let data = {
        options: json
      }
  
      return deserialize(SocialMediaPicker, data)
    }
  )
}

export const momentSerializer = () => {
  return custom((item: moment.Moment | null) => {
    if (!item) {
      return null;
    }

    return item.toISOString();
  }, (json: any) => {
    if (!json) {
      return null
    }

    return moment(json);
  })
}