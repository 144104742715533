import * as React from 'react'
import { observer } from 'mobx-react-lite';
import Layout from '../Layout';
import * as styles from '../auth.module.sass';
import Icon from '@app/components/common/Icon';
import cn from 'classnames';
import { rootContext } from '@app/index';
import EventBrite from '@assets/logos/eventbrite-vector-logo.svg';
import { Link } from 'react-router-dom';
import API_URL from '@util/apiRoot';

interface ISignInProps {}

let SignIn: React.FunctionComponent<ISignInProps> = (props) => {

  const rootStore = React.useContext(rootContext);
  const authStore = rootStore.auth;

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    authStore.login();
  }

  return (
    <div>
      <Layout>
        
        <div className={styles.newsContent}>
          <h3 className={styles.headlineNews}>There has been some update while you were gone</h3>
          <div className={styles.card}>
            <p className={styles.cardHeadline}>Transitions More Powerful Than Ever</p>
            <p className={styles.cardDescription}>Now in “Customize” tab in your proposal you can add a transition with background image, headline, text and even button. Use this to get most out of your proposal!</p>
          </div>
        </div>

        <div className={styles.content}>
          <h2 className={styles.headline}>Log-in to your account</h2>
          <form onSubmit={onSubmit}>
            <div className={styles.inputContainer}>
              <label>Email</label>
              <input onChange={(e) => authStore.setEmail(e.target.value)} value={authStore.values.email} required type='email' placeholder='email@gmail.com'></input>
            </div>
            <div className={styles.inputContainer}>
              <label>Password</label>
              <input onChange={(e) => authStore.setPassword(e.target.value)} value={authStore.values.password} required type='password' placeholder='password'></input>
            </div>
            <div className={styles.buttons}>
              <button disabled={authStore.inProgress} className={styles.button}>
                {authStore.inProgress ? 'Loading...' : 'Log-in'}
                <Icon rotate={180} type='arrow' />
              </button>
              <p className={styles.or}>OR</p>
              <a href={`${API_URL}/sessions/eventbrite`} className={cn(styles.button, styles.eventbrite)}>
                <p>Log-in with</p>
                <EventBrite />
              </a>
            </div>

            <div className={styles.links}>
              <Link className={styles.linkText} to='/auth/signup'>Don't have an account? Sign Up.</Link>
              <Link className={styles.linkText} to='/auth/reset/password/email'>Forgot password? Reset here.</Link>
            </div>

          </form>
        </div>
      </Layout>
    </div>
  )
}

SignIn = observer(SignIn);
export default SignIn;