
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/detailedDropdown';
import * as styles from './dropdown.module.sass';
import { DropdownCreatable } from '@components/common/Dropdown';
import Textarea from '@components/builder/common/Textarea';
import Icon from '@components/common/Icon';
import SingleLine from '@assets/helpers/single-line.svg';
import ItemDelete from '@assets/helpers/item-delete.svg';

export interface IInstanceProps {
  instance: Model.DetailedDropdownInstance,
  configuration: Model.IDetailedDropdownConfig
}

interface State { }

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {
    return (
      <div className={styles.container}>
        <div className={styles.dropdownWrap}>
          <DropdownCreatable
            onChange={this.props.instance.changeOption}
            options={this.props.configuration.options}
            value={this.props.instance.selectedOption}
            placeholder='Select...'
            maxHeight={120}
          />
          <div className={styles.deleteIcon}>
            <Icon onClick={this.props.instance.delete} type='trashcan' />
          </div>
        </div>
        {this.props.instance.descriptionOpen || this.props.instance.hasDescription ?
          <div className={styles.moreInfo}>
            <div className={styles.lineContainer}>
              <SingleLine />
              <ItemDelete onClick={this.props.instance.closeDescription} className={styles.hideMoreInfo} />
            </div>
            <div className={styles.description}>
              <Textarea
                placeholder='Description...'
                label='description'
                onChange={this.props.instance.updateDescription}
                value={this.props.instance.description}
              />
            </div>
          </div>
          :
          <p onClick={this.props.instance.openDescription} className={styles.explainExpand} >+ Add more information</p>
        }
      </div>
    );
  }
}
