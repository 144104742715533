import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { IExample } from '@app/stores/ProposalStore';
import * as styles from './example.module.sass';
import Icon from '@app/components/common/Icon';
import Typewriter from '@assets/dashboard/typewriter.png'
import { Link } from 'react-router-dom';

interface IExampleProps {
  example: IExample;
}

let Example: React.FunctionComponent<IExampleProps> = (props) => {
  return (
    <div className={styles.container}>
      {props.example.image_url &&
        <div className={styles.img} style={{
          background: `url(${props.example.image_url}) no-repeat`,
          backgroundSize: 'cover',
          width: '100%',
          height: '49%'
        }}></div>
      }
      <p className={styles.headline}>{props.example.name}</p>
      <div className={styles.buttons}>
        <Link to={`/proposals/new/example?id=${props.example.id}`} className={styles.button}>
          <Icon type='pen-solid' inheritStyle />
          <p>Use</p>
        </Link>
        <a href={props.example.published_website_url} target='_blank' className={styles.preview}>
          Preview
        </a>
      </div>
    </div>
  );
}

export default observer(Example);