import * as shortid from 'shortid';
import { observable, toJS, action, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, custom, SKIP } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';
import { description } from '@app/components/builder/elements/Quote/quote.module.sass';

// model interfaces
export interface IMediaCoverageConfig extends IBaseConfiguration {}

export interface IMediaCoverageInstance extends IBaseInstanceElement {
  tags: string[];
  description: string;
}

export interface IMediaCoverage extends IElement<IMediaCoverageConfig, IMediaCoverageInstance> {}

// model classes
export class MediaCoverageInstance implements IMediaCoverageInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable(custom((item: string[]) => toJS(item), (item: any) => item))
  readonly tags = observable<string>([]);

  @observable
  @serializable
  description = ''

  @observable
  descriptionOpen = false;

  @computed get hasDescription() {
    return this.description.length > 0;
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return this.tags.length > 0;
  }

  parent: MediaCoverage
  constructor(parent: MediaCoverage) {
    this.parent = parent;
  }

  @action.bound
  updateTags(tags: string[]) {
    this.tags.replace(tags);
  }

  @action.bound
  updateDescription(value: string) {
    this.description = value;
  }

  @action.bound
  openDescription() {
    this.descriptionOpen = true;
  }

  @action.bound
  closeDescription() {
    this.descriptionOpen = false;
    this.description = '';
  }

  static schema() {
    return getDefaultModelSchema(MediaCoverageInstance).factory = (context: Context) => {
      return new MediaCoverageInstance(context.args.parent);
    };
  }
}

export default class MediaCoverage extends BaseElement<IMediaCoverage, IMediaCoverageConfig, MediaCoverageInstance> {

  @observable
  @serializable(elementInstanceSerializer(MediaCoverageInstance))
  readonly instances = observable<MediaCoverageInstance>([]);

  static getInstanceClass() {
    return MediaCoverageInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new MediaCoverageInstance(this));
    }
  }

  static schema() {
    return getDefaultModelSchema(MediaCoverage).factory = (context: Context) => {
      return new MediaCoverage(context.args.root, context.args.proposal);
    };
  }
}

MediaCoverageInstance.schema();
MediaCoverage.schema();