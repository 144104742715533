import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Wording as WordingModel } from '@app/models/builder/customization/elements';
import Proposal from '@app/models/builder/proposal';
import TextInput from '../common/TextInput';
import * as styles from './wording.module.sass';
import ElementContent from '../common/ElementContent';
import Label from '../common/Label';

export interface WordingProps {
  element: WordingModel;
  proposal: Proposal;
}

const Wording = (props: WordingProps) => {

    let wordingInputs = Object.entries(props.element.wording).map(item => {
      return <TextInput
        key={item[0]}
        labelOpacity={0.5}
        onChange={(val) => props.element.updateWording(item[0], val)}
        value={item[1]}
        label={item[0].replace(/_/g, ' ')}
      />
    })

    return (
      <div className={styles.container}>
        <Label helpText={props.element.help_text} label={props.element.label} premiumLabel />
        <ElementContent premium>
          <div className={styles.elements}>
            {wordingInputs}
          </div>
        </ElementContent>
      </div>
    );
};

export default observer(Wording);