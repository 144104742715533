let URL = 'https://api.staging.sponseasy.com/api/v1';

if (process.env.TARGET === 'production') {
  URL = 'https://api.sponseasy.com/api/v1'
}

let TEMPLATE_URL_ROOT = 'staging.sponseasy.com';

if (process.env.TARGET === 'production') {
  TEMPLATE_URL_ROOT = 'sponseasy.com'
}

export const TEMPLATE_URL = TEMPLATE_URL_ROOT
export default URL;