import * as shortid from 'shortid';
import { observable, action, when, computed } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, Context, getDefaultModelSchema, custom, SKIP} from 'serializr';
import Field, { SelectField } from '@util/elements/Field';
import { customFieldSerializer, customDropdownSerializer, elementInstanceSerializer } from '@util/serializer';
import File, { IFile } from '@util/elements/FileUpload';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';


// model interfaces

export interface ISponsorConfig extends IBaseConfiguration {
  sponsor_types: string[];
  type: 'sponsor' | 'quote' | 'team';
}

export interface ISponsorInstance extends IBaseInstanceElement  {
  description: string | Field<string>;
  name: string | Field<string>;
  website: string | Field<string>;
  file: IFile
  sponsorType: string | SelectField;
}

export interface ISponsor extends IElement<ISponsorConfig, ISponsorInstance> {}

// model classes
export class SponsorInstance implements ISponsorInstance {
  @observable
  @serializable
  id = shortid.generate()

  @observable
  @serializable(customFieldSerializer<string>())
  description = new Field('');

  @observable
  @serializable(customFieldSerializer<string>())
  name = new Field('');
  
  @observable
  @serializable(customFieldSerializer<string>())
  website = new Field('');

  @observable
  @serializable(customDropdownSerializer())
  sponsorType = new SelectField();

  @observable
  @serializable(object(File))
  file = new File();

  parent: Sponsor;

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return !!this.name.value || !!this.file.fileURL
  }

  constructor(parent: Sponsor) {
    this.parent = parent;

    when(
      () => !!this.parent.configuration,
      () => this.init()
    )
  }

  init() {}

  @action.bound
  delete() {
    this.parent.deleteInstance(this);
  }

  static schema() {
    return getDefaultModelSchema(SponsorInstance).factory = (context: Context) => {
      return new SponsorInstance(context.args.parent);
    };
  }
}

export default class Sponsor extends BaseElement<ISponsor, ISponsorConfig, SponsorInstance> {

  @observable
  @serializable(elementInstanceSerializer(SponsorInstance))
  readonly instances = observable<SponsorInstance>([]);


  static getInstanceClass() {
    return SponsorInstance;
  }

  @action.bound
  addInstance() {
    this.instances.push(new SponsorInstance(this));
  }

  @action.bound
  deleteInstance(instance: SponsorInstance) {
    this.instances.remove(instance);
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new SponsorInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(Sponsor).factory = (context: Context) => {
      return new Sponsor(context.args.root, context.args.proposal);
    };
  }
}

SponsorInstance.schema();
Sponsor.schema();