type Theme = 'dark' | 'light';

const styles = (isMulti: boolean, hideDropdownMarker: boolean, theme: Theme = 'light') => {
  return {
    control: (provided: any, state: any) =>{
      return (
      {
      ...provided,
      padding: isMulti ? '3px 10px' : '3px',
      borderRadius: '4px',
      borderBottomLeftRadius: state.isFocused && state.selectProps.menuIsOpen ? '0px' : '4px',
      borderBottomRightRadius: state.isFocused && state.selectProps.menuIsOpen  ? '0px' : '4px',
      borderWidth: '1px',
      boxShadow: 'none',
      fontSize: '14px',
      height: isMulti ? 'initial' : '40px',
      lineHeight: '2',
      '&:hover': {
        border: `1px solid ${state.theme.colors.primary}`
      }
    })},
    valueContainer: (provided: any, state: any) => {
      return {
        ...provided,
        padding: isMulti ? '0px' : provided.padding,
        position: 'relative',
        top: !isMulti ? '-6px' : '0px'
      }
    },
    menu: (provided: any, state: any) => {
      return {
        ...provided,
        borderRadius: '4px',
        marginTop: '-1px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        border: `1px solid ${state.theme.colors.primary}`,
        borderTop: `1px solid ${state.theme.colors.neutral20}`,
        boxShadow: '0 2px 0 rgba(0, 118, 255, 0.2)',
        color: 'white',
        fontWeight: 'bold',
      }
    },
    indicatorSeparator: (provided: any, state: any) => {
      return {
        ...provided,
        width: '0px'
      }
    },
    dropdownIndicator: (provided: any, state: any) => {
      return {
        ...provided,
        display: hideDropdownMarker ? 'none' : 'inital'
      }
    },
    option: (provided: any, state: any) => {
      let option = {
        ...provided,
        fontWeight: state.isSelected ? 'bold' : '400',
        color: theme === 'dark' ? 'white' : 'black',
        fontSize: '14px',
        backgroundColor: state.isFocused ? state.theme.colors.primary25 : 'transparent',
        ':hover': {
          backgroundColor: state.theme.colors.primary,
          color: 'white',
          cursor: 'pointer'
        },
        ':active': {
          color: 'white',
          fontWeight: 'bold'
        }
      }
      return option
    },
    multiValue: (provided: any, state: any) => {
      return {
        ...provided,
        border: `1px solid ${state.theme.colors.primary}`,
        backgroundColor: 'rgba(0, 118, 255, 0.05)'
      }
    },
    multiValueLabel: (provided: any, state: any) => {
      return {
        ...provided,
        color: `${state.theme.colors.primary}`,
        fontWeight: '500'
      }
    },
    multiValueRemove: (provided: any, state: any) => {
      return {
        ...provided
      }
    },
  }
}

export default styles;