import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from '../auth.module.sass';
import { rootContext } from '@app/index';
import Icon from '@app/components/common/Icon';

interface IOrganizationProps {}

let Organization: React.FunctionComponent<IOrganizationProps> = (props) => {
  const [org, setOrg] = React.useState('');
  const [inProgress, toggleProgress] = React.useState(false);

  const rootStore = React.useContext(rootContext);

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    toggleProgress(true);
    e.preventDefault()
    await rootStore.user.updateOrganization(org);
    toggleProgress(false);
  }

  return (
    <div className={styles.contentSimple}>
      <div className={styles.content}>
        <h2 className={styles.headline}>Let’s set your organization name</h2>
        <form onSubmit={onSubmit}>
          <div className={styles.inputContainer}>
            <label>Organization name</label>
            <input
              onChange={(e) => setOrg(e.target.value)}
              value={org}
              required
              type='text'
              placeholder='Leons'>
            </input>
          </div>
          <div className={styles.buttons}>
            <button disabled={inProgress} type='submit' className={styles.button}>
              {inProgress ? 'Loading...' : 'Set organization'}
              <Icon rotate={180} type='arrow' />
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

Organization = observer(Organization);
export default Organization;