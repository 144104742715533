import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './content.module.sass';
import cn from 'classnames';
import { rootContext } from '@app/index';

interface IElementContentProps {
  premium?: boolean;
  children: JSX.Element
}

const ElementContent: React.FunctionComponent<IElementContentProps> = (props) => {
  const user = React.useContext(rootContext).user;
  let isPremium = !!props.premium;

  let lockElement = false;

  if (isPremium && !user.premium) {
    lockElement = true;
  }

  return (
    <div className={cn({ [styles.locked]: lockElement })}>
      {props.children}
    </div>
  )
}

export default observer(ElementContent);