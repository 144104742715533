import RootStore from "@stores/RootStore";
import { FormState, FieldState } from 'formstate';
import { computed, flow, action, runInAction, observable } from "mobx";
import { trackProposalCreate } from "@app/util/analytics";

export default class ProcessCreatorForm {

  @observable
  inProgress = false;

  @observable
  fromExample = false;

  name = new FieldState('').validators((val) => !val && 'Process name required');
  formId = new FieldState('').validators((val) => !val && 'You need to select Form');
  categoryId = new FieldState('').validators((val) => !val && 'Category is required');

  @computed get selectedForm() {
    let form = this.root.formStore.forms.find(item => item.id === this.formId.value);

    if (!form) {
      return undefined;
    }

    return {
      value: this.formId.value,
      label: form.name
    }
  }

  @computed get formattedGroup() {
    return this.root.formStore.groupedCategories.map(group => {
      return {
        label: group.name,
        options: group.forms.map(form => { return {label: form.name, value: form.id } })
      }
    })
  }

  @computed get formsForSelectedCategory() {
    if (!this.categoryId.value) {
      return [];
    }

    return this.root.formStore.forms.filter((item) => item.template_category_id === this.categoryId.value)
  }

  @computed get categoryValue() {
    let category = this.root.formStore.categoryOptions.find((item) => item.value === this.categoryId.value)

    if (category) {
      return category;
    }

    return;
  }

  @computed get categoryOptions() {
    return this.root.formStore.formCategories.map((item) => {
      return {
        value: item.id,
        label: item.name
      }
    })
  }

  form = new FormState({
    name: this.name,
    formId: this.formId,
    category: this.categoryId
  })

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }

  @action.bound
  selectForm(id: string) {
    let form = this.root.formStore.forms.find(item => item.id === id)!
    let category = this.root.formStore.formCategories.find(item => item.id === form.template_category_id)!;
    this.formId.onChange(form.id);
    this.categoryId.onChange(category.id);
  }

  onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.inProgress = true;

    const res = await this.form.validate();

    if (res.hasError) {
      this.inProgress = false;
      return;
    }

    let values = res.value;

    try {
      let res = await this.root.api.createProcess(values.name.$, values.formId.$);
      let proposal = this.root.proposalStore.createProposalFromObject(res);
      this.root.proposalStore.proposals.push(proposal);
      this.root.router.push(`/process/builder/${proposal.id}`)
      this.form.reset();
      trackProposalCreate(proposal.name, proposal.id);
    } catch (error) {
      console.error(error);
    }

    this.inProgress = false;
  }
}