import * as shortid from 'shortid';
import { observable, computed, action, when } from 'mobx';
import BaseElement, { IBaseConfiguration, IBaseInstanceElement, IElement } from "./baseElement";
import { serializable, object, list, getDefaultModelSchema, Context, primitive, SKIP, custom } from 'serializr';
import RootStore from '@app/stores/RootStore';
import Proposal from '../proposal';
import { elementInstanceSerializer } from '@app/util/serializer';

// releated interfacess

// model interfaces

export interface ICheckboxSelectorConfig extends IBaseConfiguration {
  options: string[];
}

interface ICheckboxSelectorInstance extends IBaseInstanceElement  {
  selectedOptions: string[];
}

export interface ICheckboxSelector extends IElement<ICheckboxSelectorConfig, ICheckboxSelectorInstance> { }

// model classes

export class CheckboxSelectorInstance implements ICheckboxSelectorInstance {
  @observable
  @serializable
  id = shortid.generate()

  @serializable(list(primitive()))
  @observable
  readonly selectedOptions = observable<string>([]);

  parent: CheckboxSelector;

  @computed get selected() {
    let selected = false;

    if (this.selectedOptions.length > 0) {
      selected = true;
    }

    return selected;
  }

  @action.bound
  unselelectAll() {
    this.selectedOptions.replace([]);
  }

  @action.bound
  selectOption(value: string) {
    if (this.selectedOptions.includes(value)) {
      this.selectedOptions.remove(value);
      return;
    }

    this.selectedOptions.push(value);
  }

  @serializable(custom((item: any) => item, (item: any) => SKIP))
  @computed get filled() {
    return this.selected;
  }

  constructor(parent: CheckboxSelector) {
    this.parent = parent;
  }

  static schema() {
    return getDefaultModelSchema(CheckboxSelectorInstance).factory = (context: Context) => {
      return new CheckboxSelectorInstance(context.args.parent);
    };
  }
}

export default class CheckboxSelector extends BaseElement<ICheckboxSelector, ICheckboxSelectorConfig, CheckboxSelectorInstance> {

  @observable
  @serializable(elementInstanceSerializer(CheckboxSelectorInstance))
  readonly instances = observable<CheckboxSelectorInstance>([]);

  static getInstanceClass() {
    return CheckboxSelectorInstance;
  }

  constructor(rootStore: RootStore, proposal: Proposal) {
    super(rootStore, proposal);

    if (!this.hasInstance) {
      this.instances.push(new CheckboxSelectorInstance(this))
    }
  }

  static schema() {
    return getDefaultModelSchema(CheckboxSelector).factory = (context: Context) => {
      return new CheckboxSelector(context.args.root, context.args.proposal);
    };
  }
}

CheckboxSelectorInstance.schema();
CheckboxSelector.schema();