
import * as React from 'react';
import { observer } from 'mobx-react';
import ElementHeadline from '@components/builder/common/ElementHeadline';
import * as styles from './dropdown.module.sass';
import DropdownModel from '@models/builder/elements/Dropdown';
import Instance from './Instance';

export interface IDropdownProps {
  model: DropdownModel;
}

interface State {}

@observer
export default class Dropdown extends React.Component<IDropdownProps, State> {
  constructor(props: IDropdownProps) {
    super(props);
  }

  public render() {

    let instances = this.props.model.instances.map((item) => {
      return <Instance key={item.id} configuration={this.props.model.configuration} instance={item} />
    })

    return (
      <div>
        <ElementHeadline complete={this.props.model.complete} text={this.props.model.name} markdown={this.props.model.configuration.tooltip} />
        {instances}
      </div>
    );
  }
}
