import * as React from 'react'
import { observer } from 'mobx-react';
import * as styles from './label.module.sass';

interface IBlockLabelProps {
  text: string,
}

const BlockLabel: React.FunctionComponent<IBlockLabelProps> = (props) => {
  return (
    <div>
      <p className={styles.text}>{props.text}</p>
    </div>
  )
}

export default observer(BlockLabel);