import * as React from 'react'
import { observer } from 'mobx-react-lite';

interface IView404Props {}

const View404: React.FunctionComponent<IView404Props> = (props) => {
  return (
    <div style={{overflow: 'hidden', height: '100vh'}}>
      <iframe style={{height: '100vh', width: '100vw'}} src='/static/pages/404.html' frameBorder='0' />
    </div>
  )
}

export default observer(View404);