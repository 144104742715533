import Page from "@app/models/builder/page";

export const indetifyUser = (id: string, data: any) => {
  analytics.identify(id, data);
}

export const trackPage = () => {
  analytics.page();
}

export const trackProposalCreate = (name: string, id: string) => {
  analytics.track('Proposal Created', {
    name,
    id,
  })
}

export const trackPoroposalPublished = (link: string, id: string, name: string) => {
  analytics.track('Proposal Published', {
    link,
    id,
    name
  })
}

export const trackBuilderPageHiddenChange = (numberOfActivePages: number, activePages: Page[], inactivePages: Page[], proposal_id: string) => {
  analytics.track('Builder Section Actived', {
    number_of_active_pages: numberOfActivePages,
    active_section_names: activePages.map(item => item.name),
    inactive_section_names: inactivePages.map(item => item.name),
    proposal_id
  })
}

export const trackBuilderPageFilled = (numberOfFilledPages: number, names: Page[], premiumNames: Page[], proposal_id: string) => {
  analytics.track('Builder Section Filled', {
    number_of_filled_sections: numberOfFilledPages,
    filled_section_names: names.map(item => item.name),
    filled_premium_section_names: premiumNames.map(item => item.name),
    proposal_id
  })
}

export const trackPackageTierChange = (numberOfTiers: number, proposal_id: string) => {
  analytics.track('Builder Section Package Details', {
    number_of_packages: numberOfTiers,
    proposal_id
  })
}

export const trackVirtualSectionAddOrRemove = (proposal_id: string) => {
  analytics.track('Builder Settings Transition Section Added', {
    proposal_id
  })
}

export const trackCustomisationElementUpdate = (name: string, proposal_id: string, internal_name: string) => {
  analytics.track(`builder customization ${name} updated`, {
    proposal_id,
    internal_name
  })
}

export const trackPreviewClick = async (proposal_id: string) => {
   return analytics.track('Builder Proposal Viewed from Builder', {
    proposal_id
  })
}

export const trackMenuBuilderOpen = (open: boolean) => {
  analytics.track('Builder More Section Clicked', {
    open
  })
}

export const trackProposalPageChange = (page_name: string, proposal_id: string) => {
  analytics.track('Builder Page Active', {
    proposal_id,
    page_name
  })
}

export const trackElementFilledChange = (element_name: string, complete: boolean, proposal_id: string) => {
  analytics.track('Element Filled Status Change', {
    element_name,
    complete,
    proposal_id
  })
}

export const trackAddedRemovedElementInstance = (element_name: string, proposal_id: string, instances: number) => {
  analytics.track('Added/Removed element instance', {
    proposal_id,
    element_name,
    instances
  })
}

export const trackSuggestAutocomplete = (element_name: string, items: string[], proposal_id: string) => {
  analytics.track('Suggest Autocomplete Used', {
    element_name,
    items,
    proposal_id
  })
}

export const trackMobilePreviewClick = (proposal_id: string) => {
  analytics.track('Mobile Preview Clicked', {
    proposal_id
  })
}


export const trackRegister = (id: string, email: string, org: string) => {
  analytics.track('User Registration', {id, email, organization: org})
}