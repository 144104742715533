
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@app/models/builder/elements/venue';
import Input from '@components/builder/common/Input';
import Icon from '@components/common/Icon';
import DateTimePeriod from '@components/builder/common/DateTimePeriod';
import * as styles from './venue.module.sass';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import FileUpload from '@components/builder/common/FileUpload';
import Textarea from '@components/builder/common/Textarea';
import PlacesAutocomplete from '@components/builder/common/PlacesAutocomplete';

export interface IInstanceProps {
  instance: Model.VenueInstance;
  configuration: Model.IVenueConfig;
}

interface State {}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {

    let dates = this.props.instance.dates.map((item) => {
      return (
        <div className={styles.additionDate}>
          <DateTimePeriod deletePeriod={this.props.instance.deleteDate} period={item} />
        </div>
      );
    })

    let place = this.props.instance.place;
    let center = place ? { lat: place.lat, lng: place.lng } : this.props.instance.location || { lat: -34.397, lng: 150.644 }

    return (
      <div className={styles.container}>
        <div className={styles.inputs}>
          <div className={styles.placesContainer}>
            <PlacesAutocomplete locationBias={this.props.instance.location} onChange={this.props.instance.changePlace} place={this.props.instance.place} />
            <Icon className={styles.deleteIcon} onClick={this.props.instance.delete} type='trashcan' />
          </div>
          {this.props.instance.showVenueName ? 
            <div>
              <Input label='Venue name' placeholder='K10 Coworking' value={this.props.instance.place!.name} onChange={this.props.instance.changeVenueName} />
            </div>
          : null}
        </div>
        <div className={styles.contentContainer}>
          <PlaceMap
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            location={center}
            zoom={place? 14 : 10}
            place={this.props.instance.place}
          />
        </div>
        <div className={styles.eventDetails}>
            <div className={styles.dates}>
              <DateTimePeriod period={this.props.instance.venueDateTime} />
              {dates}
              <p onClick={() => this.props.instance.addDate()} className={styles.addNew}>+ Add a new date</p>
            </div>
            <div className={styles.pictureContainer}>
              <div className={styles.picture}>
                <FileUpload
                  file={this.props.instance.picture}
                  label='Picture of venue'
                />
              </div>
              <div className={styles.pictureDescription}>
                <Textarea
                  style={{minHeight: '60px'}}
                  value={this.props.instance.pictureDescription}
                  onChange={this.props.instance.changePictureDescription}
                  placeholder='You can describe the venue. ex. “Red building, office in the 2nd floor“'
                />
              </div>
            </div>
        </div>
      </div>
    );
  }
}

const PlaceMap = withGoogleMap(observer((props) =>
  <GoogleMap
    zoom={props.zoom}
    center={props.location}
  >
    {props.place ? <Marker position={{ lat: props.place.lat, lng: props.place.lng }} /> : null}
  </GoogleMap>
))