
import * as React from 'react';
import { observer } from 'mobx-react';
import ElementHeadline from '@components/builder/common/ElementHeadline';
import Instance from './Instance';
import CheckboxSelectorModel from '@models/builder/elements/checkboxSelector';

export interface ICheckboxSelectorProps {
  model: CheckboxSelectorModel
}

interface State {}

@observer
export default class CheckboxSelector extends React.Component<ICheckboxSelectorProps, State> {
  public render() {
    let configuration = this.props.model.configuration;

    let instances = this.props.model.instances.map((item) => {
      return <Instance key={item.id} configuration={this.props.model.configuration} instance={item} />
    })

    return (
      <div>
        <ElementHeadline complete={this.props.model.complete} text={this.props.model.name} markdown={configuration.tooltip} />
        <div>
          {instances}
        </div>
      </div>
    );
  }
}
