
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/text';
import TextArea from '@components/builder/common/Textarea';

export interface IInstanceProps {
  configuration: Model.ITextConfig;
  instance: Model.TextInstance;
}

interface State {}

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {
    return (
      <div>
        <TextArea
          placeholder='Your text here'
          value={this.props.instance.text}
          onChange={this.props.instance.updateValue}
          characters={this.props.instance.characters}
        />
      </div>
    );
  }
}
