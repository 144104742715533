import currencies, { CurrencyKey } from "@common/util/currencies";

/** Find and replace currency in string
 * @text $CURRENCY{4000} to $CURRENCY{8000}
 * as an example
 */
export function findAndReplaceCurrency(currencyCode: CurrencyKey, text: string) {
  let currencyData = currencies[currencyCode];
  let value = text.replace(new RegExp(/\$CURRENCY{(.*?)}/, 'g'), function (_, amountGroup) {
    let amount = amountGroup;
    amount = `${currencyData.prefix || ''}${amount}${currencyData.suffix || ''}`
    
    return amount;
  })

  return value;
}