import * as React from 'react'
import { observer } from 'mobx-react';
import { DropdownBasic } from '@components/common/Dropdown';
import Input from '@components/builder/common/Input';
import * as styles from './link.module.sass';
import { SocialMediaHandle } from '@models/builder/common/SocialMediaPicker';
import Icon from '@components/common/Icon';
import DoubleLine from '@assets/helpers/double-line.svg';
import Textarea from '@components/builder/common/Textarea';
import ItemDelete from '@assets/helpers/item-delete.svg';


interface ISocialMediaLinkProps {
  handle: SocialMediaHandle;
  networks: { name: string; example_link: string; }[];
  showLabel: boolean;
  remove: (item: SocialMediaHandle) => void;
  moreInfo?: boolean;
  toggleMoreInfo?: (toggle: boolean) => void;
  expand?: boolean;
}

const SocialMediaLink: React.FunctionComponent<ISocialMediaLinkProps> = (props) => {
  let placeholder: string | undefined;

  let value = props.networks.find((item) => item.name === props.handle.name);

  if (value) {
    placeholder = value.example_link;
  }

  return (
    <div>
      <div className={styles.container}>
        <div>
          <div style={{ maxWidth: '210px' }}>
            <DropdownBasic
              value={props.handle.name}
              onChange={(option) => { props.handle.updateName(option as string) }}
              options={props.networks.map((item) => item.name)}
              label={props.showLabel ? 'Social Media' : undefined}
              placeholder='Select...'
            />
          </div>
          <Input placeholder={placeholder} label={props.showLabel ? 'Link' : undefined} onChange={props.handle.updateLink} value={props.handle.link} />
        </div>
        <div onClick={() => props.remove(props.handle)} className={styles.deleteIcon}>
          <Icon type='trashcan' />
        </div>
      </div>
      {
      props.moreInfo ? 
        props.expand ?
          <div className={styles.moreInfo}>
            <div className={styles.lineContainer}>
              <DoubleLine />
              <ItemDelete onClick={() => props.toggleMoreInfo!(false)} className={styles.hideMoreInfo} />
            </div>
            <div className={styles.item}>
              <Input
                label={'Key metric'}
                onChange={props.handle.updateKeyMetric}
                value={props.handle.keyMetric}
                placeholder='4.5 of 5 stars in Facebook review'
              />
            </div>
            <div className={styles.item}>
              <Textarea
                label={'description'}
                onChange={props.handle.updateDescription}
                value={props.handle.description}
                placeholder='Add a description explaining how this exposure can benefit to your sponsors and whats your strategy on this platform.'
              />
            </div>
          </div>
          : <p className={styles.explainExpand} onClick={() => props.toggleMoreInfo!(true)}>+ Add more information</p>
      : null}

    </div>
  )
}

export default observer(SocialMediaLink);