import { observable, computed, reaction, action, when } from "mobx";
import { serializable, deserialize, serialize, Context, update, custom, object, getDefaultModelSchema } from "serializr";
import { ElementTypes, ElementNames, Elements, IElementInterfaces, TextInput, Checkbox, FontSelector, Wording } from './elements/index';
import { Omit } from "@app/util/types";
import RootStore from "@app/stores/RootStore";
import Proposal from "../proposal";
import { debounce } from "debounce";
import { trackCustomisationElementUpdate } from "@app/util/analytics";

function serializeElements(elements: ElementTypes[]) {
  let data = [];
  for (let component of elements) {
    data.push(component.json);
  }
  return data;
}

function deserializeElements(jsonValue: any, context: Context, oldElements: ElementTypes[]) {
  let data = [];
  jsonValue = jsonValue.reverse();
  for (let jsonElement of jsonValue) {
    let oldElement = oldElements.find((item) => item.id === jsonElement.id);
    if (oldElement) {
      update(oldElement, jsonElement, () => {}, context.args)
      data.push(oldElement)
    } else {
      let type: ElementNames = jsonElement.type;
      let elementClass: any = Elements[type];
      let element: any = deserialize(elementClass, jsonElement, undefined, context.args);
      data.push(element);
    }
  }

  return data;
}

export interface ICustomization {
  general: IElementInterfaces[];
}

interface ICustomizationApp {
  general: ElementTypes[];
}

export class Customization implements ICustomizationApp {

  @observable
  @serializable(custom(serializeElements, deserializeElements))
  readonly general = observable<ElementTypes>([]);

  @computed get json() {
    return serialize(this)
  }

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }

  static schema() {
    return getDefaultModelSchema(Customization).factory = (context: Context) => {
      return new Customization(context.args.root)
    };
  }
}

export interface ITemplate {
  id: string,
  name: string,
  user_id: string,
  status: 'active' | 'not_active',
  customization: ICustomization;
}

interface ITemplateApp extends Omit<ITemplate, 'customization'> {
  customization: Customization
}

export default class Template implements ITemplateApp {
  @observable
  @serializable
  name: string = 'minimalista';

  @observable
  @serializable
  id: string;

  @observable
  @serializable
  user_id: string;

  @observable
  @serializable
  status: 'active' | 'not_active';

  @observable
  @serializable(object(Customization))
  customization: Customization;

  @computed get elements() {
    return this.customization.general
  }

  @computed get json() {
    return serialize(this);
  }

  root: RootStore;
  proposal: Proposal;
  reactions: any[] = [];
  deboucedUpdateElements = debounce(this.updateElement, 400);
  constructor(root: RootStore, proposal: Proposal) {
    this.root = root;
    this.proposal = proposal;

    when(
      () => !!this.elements,
      () => this.createReactions()
    )
  }

  @action.bound
  updateElement(json: any, id: string) {
    this.root.api.updateTemplateCustomization(json, id)
  }

  createReactions() {
    let reactions = [];

    for (let reaction of this.reactions) {
      reaction();
    }

    for (let element of this.elements) {
      let reactionStore = reaction(
        () => element.json,
        (json) => { 
          this.deboucedUpdateElements(json, element.id)
          trackCustomisationElementUpdate(element.label, this.proposal.id, element.internal_name)
        }
      )

      reactions.push(reactionStore);
    }


    this.reactions = reactions;
  }

  static schema() {
    return getDefaultModelSchema(Template).factory = (context: Context) => {
      let template = new Template(context.args.root, context.parentContext.target)
      return template;
    };
  }
}

Customization.schema();
Template.schema();