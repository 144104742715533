import { observable, computed, reaction, action, when } from 'mobx';
import { serializable, serialize, Context, getDefaultModelSchema, list, primitive, object } from 'serializr';
import { CurrencyKey } from '@common/util/currencies';
import Proposal from './proposal';
import VirtualSection, { IVirtualSection } from './customization/VirtualSection';
import { trackVirtualSectionAddOrRemove } from '@app/util/analytics';
import emitter from '@util/emitter';

export interface ISettings {
  currency: CurrencyKey;
  dateFormat: string;
  sectionOrder: string[];
  virtualSections: IVirtualSection[];
}

export default class Settings implements ISettings {

  @observable
  @serializable
  currency: CurrencyKey = 'USD';

  @observable
  @serializable
  dateFormat: string = 'MM/DD/YYYY';

  @observable
  @serializable(list(primitive()))
  readonly sectionOrder = observable<string>([]);

  @observable
  @serializable(list(object(VirtualSection)))
  readonly virtualSections = observable<VirtualSection>([]);

  @action.bound
  changeCurrency(currencyCode: CurrencyKey) {
    this.currency = currencyCode;
  }

  @action.bound
  changeDateFormat(dateFormat: string) {
    this.dateFormat = dateFormat;
  }

  @computed get json() {
    return serialize(this);
  }

  proposal: Proposal;
  constructor(proposal: Proposal) {
    this.proposal = proposal;
    reaction(
      () => this.json,
      (json) => this.proposal.root.api.updateProposalSettings(this.proposal.id, json)
    )

    reaction(
      () => this.virtualSections.length,
      () => {
        trackVirtualSectionAddOrRemove(this.proposal.id)
      }
    )

    when(
      () => 
        !!this.proposal 
        && !!this.proposal.sections.length 
        && this.sectionOrder.length === 0
        && this.virtualSections.length === 0,
      () => {
        this.sectionOrder.replace(this.proposal.sections.map(item => item.id));
      }
    )
  }

  @action.bound
  changeSectionPositionTo(currentPosition: number, newPosition: number) {
    if (!this.proposal.root.user.premium) {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'section',
        text: {
          headline: 'Change plan to reorder sections',
          text: 'If you want to reorder sections'
        }
      })

      return;
    }

    const [removed] = this.sectionOrder.splice(currentPosition, 1);
    this.sectionOrder.splice(newPosition, 0, removed);
  }

  @action.bound
  addVirtualSection() {
    if (!this.proposal.root.user.premium) {
      emitter.emit('PREMIUM_MODAL_REQUEST', {
        type: 'transition'
      })

      return;
    }

    let newVirtualSection = new VirtualSection(this, `Transition #${this.virtualSections.length + 1}`);
    this.virtualSections.push(newVirtualSection);
    this.sectionOrder.push(newVirtualSection.id);
  }

  static schema() {
    return getDefaultModelSchema(Settings).factory = (context: Context) => {
      return new Settings(context.parentContext.target);
    };
  }
}

Settings.schema();
