
import * as React from 'react';
import { observer } from 'mobx-react';
import * as Model from '@models/builder/elements/checkboxSelector';
import CheckboxInput from '@components/builder/common/CheckboxInput';
import * as styles from './checkbox.module.sass';

export interface IInstanceProps {
  instance: Model.CheckboxSelectorInstance,
  configuration: Model.ICheckboxSelectorConfig
}

interface State { }

@observer
export default class Instance extends React.Component<IInstanceProps, State> {
  public render() {

    let options = this.props.configuration.options;

    if (typeof options === 'string') {
      options = (options as any).split(',')
    }
  
    let checkboxes = options.map((item) => {
      return <CheckboxInput
        checked={this.props.instance.selectedOptions.includes(item)}
        value={item}
        key={item}
        name={this.props.instance.id}
        onChange={this.props.instance.selectOption}
      />
    })

    let input = <CheckboxInput
      key={'Not Specifed'}
      checked={this.props.instance.selectedOptions.length === 0}
      value={'Not Specifed'}
      name={this.props.instance.id}
      onChange={this.props.instance.unselelectAll} />

    checkboxes.push(input)


    return (
      <div className={styles.checkboxInputs}>
        {checkboxes}
      </div>
    );
  }
}
