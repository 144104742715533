import * as React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import UserStore from '@stores/userStore';
import { userInfo } from 'os';

interface IProps {
  component: React.ComponentClass,
  isAuthorized: boolean;
  appData: {
    requestOrganization: boolean;
    requestOrganizationPath: string;
  }
  userLoaded: boolean;
  [key: string]: any;
}

/**
* Mark route as private (required auth).
* - It also has abilty to return null (dont load the route) if user is not loaded. (Becase many routes require user to be able to display data).
* - You can also specify what data are required in order for routes to load. Forexample if the user is loaded we required an org name to display the UI. If that is not 
* present if will redirect to get org view where the user can fill org and update the name
*
* In general this component is used as a pre render hook that will check if you can load the route (do you have all the data? Do you have auth?) and if not it will execute other operations (like redirect).
* 
* Use this component only for App wide checks! Auth is app wide so belongs here. Wait for template load in form_process builder? Not app wide. Put in more specific place.
*/
const PrivateRoute = ({ component: Component, isAuthorized, appData, userLoaded, ...rest }: IProps) => (
  <Route {...rest} render={(props) => {

    if (!isAuthorized) {
      return <Redirect to={{ pathname: '/auth/signin', state: { from: props.location.pathname + props.location.search } }} />
    }

    if (!userLoaded) {
      return null;
    }

    if (appData.requestOrganization && appData.requestOrganizationPath !== props.location.pathname) {
      return <Redirect to={{ pathname: appData.requestOrganizationPath, state: { from: props.location.pathname  + props.location.search } }} />
    }

    return <Component {...props} />
  }} />
)

export default (observer(PrivateRoute));