import * as React from 'react'
import { observer } from 'mobx-react';
import * as styles from './add.module.sass';

interface IAddInstanceProps {
  text: string;
  addInstance: () => void;
}

const AddInstance: React.FunctionComponent<IAddInstanceProps> = (props) => {
  return (
    <div onClick={() => props.addInstance()} className={styles.button}>
      <p className={styles.textButton}>{props.text}</p>
    </div>
  )
}

export default observer(AddInstance);