import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { rootContext } from '@app/index';
import { withRouter } from 'react-router-dom';
import QS from 'query-string';

interface IEventbriteProps {
  location: any
}

let Eventbrite: React.FunctionComponent<IEventbriteProps> = (props) => {
  const rootStore = React.useContext(rootContext);
  const authStore = rootStore.auth;

  let queryString = QS.parse(props.location.search);

  React.useEffect(() => {
    rootStore.setToken(queryString.token as string)
    authStore.loginWithToken();
  }, []);

  return null;
}

Eventbrite = observer(Eventbrite);
export default withRouter(Eventbrite);