
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import * as Model from '@models/builder/elements/AdditionalOpportunity';
import BlockLabel from '@components/builder/common/BlockLabel';
import Input, { CurrencyInputFormat } from '@components/builder/common/Input';
import * as styles from './additional.module.sass';
import Icon from '@components/common/Icon';
import Modal from '@app/components/common/Modal';
import Textarea from '../../common/Textarea';
import Button from '@app/components/common/Button';
import FileUpload from '@components/builder/common/FileUpload';
import { SettingsContext } from '@components/builder/Form/context';

export interface IInstanceProps {
  instance: Model.AdditionalOpportunityInstance;
  configuration: Model.IAdditionalOpportunityConfig;
  index: number;
}

let Instance: React.FunctionComponent<IInstanceProps> = (props) => {
  const settings = React.useContext(SettingsContext);

  const [hover, toggleHover] = React.useState(false);
  const [modalOpen, modalToggle] = React.useState(false);
  const [noteText, changeTextNote] = React.useState(props.instance.note);

  let instance = props.instance;
  let showBlocks = props.index === 0 ? true : false;

  function saveNote() {
    props.instance.setNote(noteText);
    modalToggle(false);
  }

  return (
    <div
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
      className={styles.metricInstance}>
      <div className={styles.container}>
        <div>
          {showBlocks ?
            <div className={styles.metricBlock}>
              <BlockLabel text='Opportunity name' />
            </div>
            : null
          }
          <Input
            onChange={instance.changeName}
            value={instance.name}
            placeholder='Flyers, Printed bags, Flashdisks'
          />
          <div className={styles.icons}>
            <FileUpload file={props.instance.file}>
              <Icon color={props.instance.file.uploaded ? 'primary1' : 'primary0'} tooltip={props.instance.file.uploaded ? 'Edit attachment' : 'Add attachment'} type='paperclip' />
            </FileUpload>
            <Icon color={props.instance.note ? 'primary1' : 'primary0'} tooltip={props.instance.note ? 'Edit note' : 'Add note'} onClick={() => modalToggle(true)} type='sticky-note' />
          </div>
        </div>
        <div className={styles.metricValue}>
          {showBlocks ?
            <div className={styles.metricBlock}>
              <BlockLabel text='Price' />
            </div>
            : null
          }
          <CurrencyInputFormat
            placeholder='1000'
            onValueChange={(value) => instance.changeValue(value.floatValue)}
            value={instance.value}
            currency={settings.currency}
          />
        </div>
      </div>
      <div className={styles.deleteIcon}>
        <Icon onClick={instance.delete} type='trashcan' />
      </div>
      <Modal maxWidth={350} isOpen={modalOpen} onRequestClose={() => {
          modalToggle(false);
          changeTextNote(props.instance.note);
        
        }}>
        <div>
          <div className={styles.textarea}>
            <Textarea label='Add note' value={noteText} onChange={(val) => changeTextNote(val)} />
          </div>
          <div className={styles.button}>
            <Button type='primary' onClick={saveNote} text='Save Note' icon='sticky-note' />
          </div>
        </div>
      </Modal>
    </div>
  )
}

Instance = observer(Instance)

export default Instance